import React from 'react';

function ListIcon(): any {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2 1.4625H14.0625V0.5625C14.0625 0.225 13.8375 0 13.5 0H4.3875C4.1625 0 3.825 0.225 3.825 0.5625V1.4625H1.8C0.7875 1.4625 0 2.25 0 3.2625V16.3125C0 17.2125 0.7875 18 1.8 18H16.2C17.2125 18 18 17.2125 18 16.2V3.2625C18 2.25 17.2125 1.4625 16.2 1.4625ZM4.95 1.125H13.05V2.025C13.05 2.3625 12.7125 2.7 12.375 2.7H5.85C5.4 2.5875 4.95 2.25 4.95 1.6875V1.125ZM16.2 16.875C16.5375 16.875 16.875 16.5375 16.875 16.2H16.7625V3.26249C16.7625 2.92499 16.425 2.58749 16.0875 2.58749H13.95C13.725 3.26249 13.1625 3.71249 12.375 3.71249H5.85C5.0625 3.71249 4.3875 3.26249 4.05 2.58749H1.8C1.4625 2.58749 1.125 2.92499 1.125 3.26249V16.2C1.125 16.65 1.35 16.875 1.8 16.875H16.2Z"
        />
        <path d="M12.6 6.63672H7.19995C6.86245 6.63672 6.63745 6.86172 6.63745 7.19922C6.63745 7.53672 6.86245 7.76172 7.19995 7.76172H12.6C12.9375 7.76172 13.1625 7.53672 13.1625 7.19922C13.1625 6.86172 12.9375 6.63672 12.6 6.63672Z" />
        <path d="M5.2875 6.74908C5.0625 6.63658 4.8375 6.74908 4.725 6.86158C4.6125 6.97408 4.5 7.08658 4.5 7.19908C4.5 7.31158 4.6125 7.42408 4.6125 7.53658C4.6125 7.64908 4.725 7.64908 4.8375 7.64908C4.95 7.64908 4.95 7.64908 5.0625 7.64908C5.175 7.64908 5.175 7.64908 5.2875 7.64908C5.4 7.64908 5.4 7.53658 5.5125 7.53658C5.625 7.53658 5.625 7.31158 5.625 7.19908C5.625 7.08658 5.625 6.97408 5.5125 6.86158C5.4 6.74908 5.4 6.74908 5.2875 6.74908Z" />
        <path d="M12.6 9.22461H7.19995C6.86245 9.22461 6.63745 9.44961 6.63745 9.78711C6.63745 10.1246 6.86245 10.3496 7.19995 10.3496H12.6C12.9375 10.3496 13.1625 10.1246 13.1625 9.78711C13.1625 9.44961 12.9375 9.22461 12.6 9.22461Z" />
        <path d="M4.725 9.45078C4.6125 9.56328 4.5 9.67578 4.5 9.78828C4.5 9.90078 4.5 9.90078 4.5 10.0133C4.6125 10.1258 4.6125 10.1258 4.725 10.2383C4.8375 10.3508 4.95 10.3508 5.0625 10.3508C5.175 10.3508 5.2875 10.3508 5.4 10.2383C5.5125 10.1258 5.625 10.1258 5.625 10.0133C5.625 9.90078 5.625 9.90078 5.625 9.78828C5.625 9.67578 5.625 9.56328 5.5125 9.45078C5.2875 9.22578 4.95 9.22578 4.725 9.45078Z" />
        <path d="M12.6 11.9258H7.19995C6.86245 11.9258 6.63745 12.1508 6.63745 12.4883C6.63745 12.8258 6.86245 13.0508 7.19995 13.0508H12.6C12.9375 13.0508 13.1625 12.8258 13.1625 12.4883C13.1625 12.1508 12.9375 11.9258 12.6 11.9258Z" />
        <path d="M4.72502 12.0367C4.72502 12.0367 4.61252 12.1492 4.61252 12.2617C4.61252 12.3742 4.61252 12.3742 4.61252 12.4867C4.61252 12.5992 4.72502 12.7117 4.72502 12.8242C4.72502 12.8242 4.83752 12.9367 4.95002 12.9367C5.06252 12.9367 5.06252 12.9367 5.17502 12.9367C5.28752 12.9367 5.40002 12.9367 5.51252 12.8242C5.62502 12.7117 5.62502 12.5992 5.62502 12.3742C5.62502 12.2617 5.51252 12.0367 5.51252 12.0367C5.28752 11.8117 4.95002 11.8117 4.72502 12.0367Z" />
      </svg>
    </>
  );
}

export default ListIcon;
