import React from 'react';
import { getValueSafely } from 'utils/utils';

export default function AssetStatusCell({ value }: { value: string | undefined }) {
  const hasStatus = value !== null && value !== undefined;

  return (
    <span className={`${!hasStatus ? 'u-no-status-text' : ''} u-no-pointer-event`}>
      {hasStatus ? getValueSafely(value) : 'No status set'}
    </span>
  );
}
