import React from 'react';
import logo from 'assets/images/navenio-landscape.svg';
import { StyledAside, StyledNav, StyledTop } from 'components/Navigation/styled';
import { StyledPlaceholderNavLink } from '../styled';
import useDevice from 'hooks/useDevice';
import { Horizontal } from 'gls';

export default function LoadingNav() {
  const { isTabletDevice } = useDevice();

  if (isTabletDevice) {
    return (
      <StyledTop>
        <Horizontal spacing="auto" className="nav-container" horizontalAlign="center">
          <img src={logo} height="35px" alt="Navenio Logo" />
        </Horizontal>
      </StyledTop>
    );
  }

  return (
    <StyledAside>
      <div className="nav-container">
        <div className="logo-container">
          <img src={logo} alt="Navenio Logo" />
        </div>

        <StyledNav>
          <ul className="nav-menu-list">
            <StyledPlaceholderNavLink />
            <StyledPlaceholderNavLink />
            <StyledPlaceholderNavLink />
            <StyledPlaceholderNavLink />
          </ul>
        </StyledNav>
      </div>
    </StyledAside>
  );
}
