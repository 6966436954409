import React from 'react';

export default function MapIcon(): any {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16">
      <path
        d="M15.2,1.7L10.8,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0l-5,1.8
	L1.6,0.4C1.2,0.2,0.8,0.3,0.5,0.5C0.2,0.7,0,1.1,0,1.4v11.8c0,0.5,0.3,0.9,0.8,1.1L5.2,16c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
	l5-1.8l3.9,1.4c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.5-0.1,0.7-0.2c0.3-0.2,0.5-0.6,0.5-0.9V2.8C16,2.3,15.7,1.8,15.2,1.7z M5.9,2.6
	l4.2-1.5v12.2l-4.2,1.5V2.6z M1,13.2V1.4c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.2,0l3.7,1.4v12.2l-3.8-1.4C1,13.4,1,13.3,1,13.2z
	 M15,14.6c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0-0.2,0l-3.7-1.4V1.2l3.8,1.4C15,2.6,15,2.7,15,2.8V14.6z"
      />
    </svg>
  );
}
