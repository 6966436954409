import axios, { AxiosError } from "axios";
import download from "downloadjs";
import { debounce } from "lodash";
import { ErrorResponseDto } from "api/config/ErrorResponseDto";

const API_TIMEOUT = 60000;
const DEBOUNCE_DELAY = 300;

export class RequestError extends Error {
  constructor(public readonly status: number) {
    super();
  }
}

function errorHandler(err: AxiosError): ErrorResponseDto {
  const msg = (err.response?.data as Record<string, string>);
  const error = new ErrorResponseDto(msg.detail, err.response?.status);
  return error;
}

export async function genericGet<TResult>(url: string): Promise<TResult> {

  return axios.get(url, { timeout: API_TIMEOUT })
    .then((response) => {
      return response.data as unknown as TResult;
    })
    .catch((error) => { throw errorHandler(error) });
}

export function blobDownload<TPostData>(url: string, body?: TPostData): Promise<boolean> {
  let filename = "";
  return fetch(url, body ? {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body)
  } : {})
    .then(res => {
      const disposition = res.headers.get('Content-Disposition');
      filename = disposition?.split(/;(.+)/)[1].split(/=(.+)/)[1] || `${new Date().toLocaleDateString()}`;
      filename = filename.replaceAll('"', '');
      return res.blob();
    })
    .then((blob) => {
      download(blob, filename)
      return Promise.resolve(true)
    })
    .catch((error) => {
      throw error
    });
}

async function _genericPost<TResult, TPostData>(
  url: string,
  body: TPostData,
): Promise<TResult> {

  return axios.post(url, body || null, { timeout: API_TIMEOUT })
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

export function genericDelete<TResult>(
  url: string,
  body: any = "",
): Promise<TResult> {
  return axios.delete(
    url,
    {
      data: body,
      timeout: API_TIMEOUT
    }
  )
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

async function _genericPut<TResult, TPostData>(url: string, body?: TPostData): Promise<TResult> {
  return axios.put(url, body || null, { timeout: API_TIMEOUT })
    .then((response): Promise<TResult> => {
      return Promise.resolve((response.data || true) as unknown as TResult);
    })
    .catch((error) => { throw errorHandler(error) });
}

//------------------------------------------

const debouncedGenericPost = debounce(
  (url: string, body: any, resolve: any, reject: any) => {
    _genericPost(url, body).then(resolve).catch(reject);
  },
  DEBOUNCE_DELAY
);

export function genericPost<TResult, TPostData>(
  url: string,
  body: TPostData,
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    debouncedGenericPost(url, body, resolve, reject);
  });
}

const debouncedGenericPut = debounce(
  (url: string, body: any, resolve: any, reject: any) => {
    _genericPut(url, body).then(resolve).catch(reject);
  },
  DEBOUNCE_DELAY
);

export function genericPut<TResult, TPostData>(
  url: string,
  body?: TPostData
): Promise<TResult> {
  return new Promise((resolve, reject) => {
    debouncedGenericPut(url, body, resolve, reject);
  });
}