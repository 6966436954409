/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useContext } from 'react';
import { UserContext } from 'contexts/user.context';
import { Dropdown, MenuProps } from 'antd';
import { coloursNames } from 'styling/colours';
import config from 'react-global-configuration';
import useBroadcastChannel from 'hooks/useBroadcastChannel';
import { AssetManagementContext } from 'contexts/assetManagement.context';
import { useNavigate } from 'react-router-dom';
import { StyledButton, StyledDropdownWrapper, StyledMenu, overlayStyle } from './style';

const UserInfoDropdown = ({ closeMobileMenu }: { closeMobileMenu?: () => void }) => {
  const userContext = useContext(UserContext);
  const { name, username } = userContext;
  const navigate = useNavigate();
  const { assetFormHasChanges, setShowCancelConfirmationDialog } =
    useContext(AssetManagementContext);
  const { postBroadcastMessage } = useBroadcastChannel();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <StyledButton
          onClick={(ev) =>
            handleClick(ev, () => {
              navigate('/support');
              closeMobileMenu && closeMobileMenu();
            })
          }
        >
          Support
        </StyledButton>
      ),
    },
    {
      key: '2',
      label: (
        <StyledButton
          onClick={(ev) =>
            handleClick(ev, () => {
              window.open(`${config.get('HELP_URL')}`, '_blank');
            })
          }
        >
          Help
        </StyledButton>
      ),
    },
    {
      key: '3',
      label: (
        <StyledButton
          style={{ color: `${coloursNames.cinnabarOrange}` }}
          onClick={(ev) =>
            handleClick(ev, () => {
              postBroadcastMessage('broadcast_signout');
            })
          }
        >
          Sign out
        </StyledButton>
      ),
    },
  ];

  function handleClick(ev: React.MouseEvent, callback: any) {
    ev.preventDefault();
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
      return;
    } else {
      callback();
    }
  }

  return (
    <StyledDropdownWrapper>
      <Dropdown
        trigger={['click']}
        destroyPopupOnHide
        placement="bottomRight"
        menu={{ items }}
        arrow
        overlayStyle={overlayStyle}
        dropdownRender={(menu) => <StyledMenu>{menu}</StyledMenu>}
      >
        {/* // name should take preference followed by username, if name doesnt exist. */}

        <p tabIndex={0}>{name ? name : username}</p>
      </Dropdown>
    </StyledDropdownWrapper>
  );
};

export default UserInfoDropdown;
