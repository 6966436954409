import React from 'react';

// Error page to be displayed if errors occur at critical API calls essential for functionality. EG:
// /config/bootstrap/ils_web
// /config/all/web
export default function SystemErrorPage() {
  return (
    <>
      <main className="u-flex-center">
        <div className="container">
          <h1 style={{ marginBottom: '54px' }}>Error in loading the portal</h1>
          <p style={{ color: '#414042', marginBottom: '42px' }}>
            We apologise for the inconvenience.
            <br />
            Please try refreshing the page.
            <br />
            If the problem persists, please email us at{' '}
            <a href="mailto:ilssupport@navenio.com">ilssupport@navenio.com</a>
          </p>
        </div>
      </main>
    </>
  );
}
