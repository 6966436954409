import { getCallerSystemPermissions } from "api/config/systemAdmin";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { SystemAdminPermission } from "views/config/SystemAdministrators/SystemAdminPermissionsRequestDto";

export class SystemAdminPermissionStore {
  root: RootStore;
  systemAdminPermissions: Partial<Record<SystemAdminPermission, boolean>> = {};
  loading = true;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      systemAdminPermissions: observable,
      loading: observable,

      canManageRoles: computed,
      canManageSystemAdministrators: computed,
      canManageUsers: computed,
      canManageGroupsAndRoles: computed,
      canViewConfig: computed,
      configLoginEnabled: computed,
      
      setLoading: action.bound,
      loadPermissions : action.bound,
      setSystemAdminPermissions: action.bound,
    });
    this.loadPermissions();
  }

  get canManageRoles() {
    return this.systemAdminPermissions.manage_groups_and_roles || false;
  }

  get canManageSystemAdministrators() {
    return this.systemAdminPermissions.manage_system_admins || false;
  }

  get canManageUsers() {
    return this.systemAdminPermissions.manage_users || false;
  }

  get canManageGroupsAndRoles() {
    return this.systemAdminPermissions.manage_groups_and_roles || false;
  }

  get canViewConfig() {
    return this.systemAdminPermissions.view_system_configuration || false;
  }

  get configLoginEnabled() {
    return (
      this.systemAdminPermissions.view_system_configuration ||
      this.systemAdminPermissions.manage_groups_and_roles ||
      false
    );
  }
  //#endregion "getters"

  loadPermissions(){
    this.setLoading(true);
    getCallerSystemPermissions()
      .then((response) => this.setSystemAdminPermissions(response))
      .catch((err) => {
        return undefined;
      })
      .finally(() => this.setLoading(false));
  }

  //#region "setters"
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setSystemAdminPermissions(systemAdminPermissions: SystemAdminPermission[]) {
    const permissions: Partial<Record<SystemAdminPermission, boolean>> = {};

    systemAdminPermissions.forEach(
      (permission) => (permissions[permission] = true)
    );
    this.systemAdminPermissions = permissions;
    this.setLoading(false);
  }
  //#endregion "setters"
}
