import React from 'react';
import { StyledLabel, StyledFieldset, RequiredAsterisk } from './Styled';

type FormFieldsetLabelContainerTypes = {
  fieldID: string;
  fieldName: string;
  children: React.ReactChild;
  isRequired?: boolean;
};

export default function FormFieldsetLabelContainer({
  fieldName,
  fieldID,
  children,
  isRequired,
}: FormFieldsetLabelContainerTypes) {
  return (
    <StyledFieldset>
      <StyledLabel htmlFor={fieldID}>
        {fieldName}: {isRequired && <RequiredAsterisk />}
      </StyledLabel>
      {children}
    </StyledFieldset>
  );
}
