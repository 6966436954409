import AssetsApi from 'api/assets/assets.api';
import { regex } from 'config/regexPatterns';
import { AssetManagementContext } from 'contexts/assetManagement.context';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { checkStringMatchesRegex, getAddAssetSuccessMessage, getIdToken } from 'utils/utils';

export default function useAssetManagementHooks(
  handleSubmit: any,
  formFieldsValid: boolean,
  setError: any,
  getValues: any,
) {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { assetFormHasChanges, setShowCancelConfirmationDialog } =
    useContext(AssetManagementContext);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);

  const createAssetMutation = useMutation((assetDetails: any) => {
    return assetsApi.createNewAsset(assetDetails);
  });

  function checkAndSetErrorsForAssetID(formData: any) {
    let hasErrors = false;
    const assetIDMatchesAlphaNumeric = checkStringMatchesRegex(
      formData.assetId,
      regex.assetIDAlphaNumeric,
    );
    const assetIDMatchesStartEnd = checkStringMatchesRegex(formData.assetId, regex.assetIDStartEnd);

    if (!assetIDMatchesAlphaNumeric || !assetIDMatchesStartEnd) {
      setError('assetId', {
        types: {
          patternAssetidAlphaNum:
            !assetIDMatchesAlphaNumeric &&
            'Asset ID can only contain numbers, letters, hyphens or underscores',
          patternAssetidStartend:
            !assetIDMatchesStartEnd && 'Asset ID must start and end with a number or letter.',
        },
      });

      hasErrors = true;
      setApiCallInProgress(false);
    }

    return hasErrors;
  }

  function handleCancel(ev: React.SyntheticEvent) {
    ev.preventDefault();
    // The allFieldsEmpty is there to allow for the user
    // starting to add an asset and then clearing everything
    // before pressing cancel In this case they are taken to '/'
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
    } else {
      navigate('/assets');
    }
  }

  function handleCloseModal() {
    setShowCancelConfirmationDialog(false);
    navigate('/assets');
  }

  function handleCloseModalAndSave() {
    // submit form and close modal.
    setShowCancelConfirmationDialog(false);
    if (formFieldsValid) formSubmitHandler(getValues());
  }

  const formSubmitHandler = handleSubmit((formData: any) => {
    const assetNameTrimmed = formData.assetName.trim();
    const trimmedFormData = {
      ...formData,
      assetName: assetNameTrimmed,
    };

    setFormSubmitted(true);
    setApiCallInProgress(true);
    // if no clientside validation errors
    if (!checkAndSetErrorsForAssetID(formData)) {
      assetsApi
        .checkAssetExistsByAssetID(formData.assetId)
        .then(async (assetIDExists: boolean) => {
          if (!assetIDExists) {
            assetsApi
              .checkAssetExistsByAssetName(assetNameTrimmed)
              .then(async (assetNameExists) => {
                if (!assetNameExists) {
                  // if all prior checks pass, we can add the asset
                  createAssetMutation.mutate(getPostAssetDataObject(trimmedFormData));
                } else {
                  setError('assetName', {
                    type: 'custom',
                    message: 'Asset name specified already exists.',
                  });
                  setApiCallInProgress(false);
                  return;
                }
              })
              .catch((err) => {
                handleError(err);
              });

            return;
          } else {
            setError('assetId', {
              type: 'asset-id-exists',
            });
            setApiCallInProgress(false);
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
  });

  function handleError(err: any) {
    console.log(err);
    alertErrorMessage('An error occurred. The asset has not been saved');
    setApiCallInProgress(false);
  }

  const getPostAssetDataObject = (formData: any) => {
    return {
      asset: {
        asset_id: formData.assetId,
        asset_name: formData.assetName,
        asset_type_id: formData.assetSubType.value,
        owner_id: formData.assetOwner.value,
        description: formData.description,
        assigned_beacon_id: null,
      },
    };
  };

  useEffect(() => {
    //useeffect to execute mutation state outcomes.

    if (createAssetMutation.isSuccess) {
      const assetSuccessMessage = getAddAssetSuccessMessage(createAssetMutation);

      navigate('/assets');
      alertSuccessMessage(assetSuccessMessage);
    }

    if (createAssetMutation.isError) {
      createAssetMutation.reset();
      setApiCallInProgress(false);
      alertErrorMessage('An error occurred. The asset has not been saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAssetMutation]);

  return {
    formSubmitHandler,
    createAssetMutation,
    getPostAssetDataObject,
    apiCallInProgress,
    formSubmitted,
    setFormSubmitted,
    handleCancel,
    handleCloseModal,
    handleCloseModalAndSave,
  };
}
