import React from 'react';
import RegisteredBeaconFlyoutDetails from './RegisteredBeaconFlyoutDetails';
import { StyledRegisteredBeaconFlyout } from '../Styled';

const RegisteredBeaconFlyout = ({
  selectedBeaconDetails,
  setFlyoutOpen,
  flyoutOpen,
}: {
  selectedBeaconDetails: any;
  setFlyoutOpen: (arg: boolean) => void;
  flyoutOpen: boolean;
}) => {
  return (
    <>
      <StyledRegisteredBeaconFlyout
        isOpen={flyoutOpen}
        onRequestClose={() => {
          setFlyoutOpen(false);
        }}
        title="Beacon Details"
      >
        <RegisteredBeaconFlyoutDetails selectedBeaconDetails={selectedBeaconDetails} />
      </StyledRegisteredBeaconFlyout>
    </>
  );
};
export default RegisteredBeaconFlyout;
