import AssetsApi from 'api/assets/assets.api';
import { AssetManagementContext } from 'contexts/assetManagement.context';
import { AuthenticationContext } from 'contexts/authentication.context';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { getAssetDetailsFromLocalStorage, getIdToken } from 'utils/utils';

// note we use navigate(-1) to go to URL with search params from asset list. Previous page will always be asset list.
export default function useAssetManagementHooks(
  handleSubmit: any,
  formFieldsValid: boolean,
  setError: any,
) {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { assetFormHasChanges, setShowCancelConfirmationDialog } =
    useContext(AssetManagementContext);
  const assetDetails = getAssetDetailsFromLocalStorage();
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);

  const editAssetMutation = useMutation((assetDetails: any) => {
    return assetsApi.updateAssetDetails(assetDetails);
  });

  function handleCancel(ev: React.SyntheticEvent) {
    ev.preventDefault();
    // The allFieldsEmpty is there to allow for the user
    // starting to add an asset and then clearing everything
    // before pressing cancel In this case they are taken to '/'
    if (assetFormHasChanges) {
      setShowCancelConfirmationDialog(true);
    } else {
      navigate(-1);
    }
  }

  function handleCloseModal() {
    setShowCancelConfirmationDialog(false);
    navigate(-1);
  }

  function handleCloseModalAndSave() {
    // submit form and close modal.
    setShowCancelConfirmationDialog(false);
    if (formFieldsValid) formSubmitHandler(assetDetails);
  }

  const formSubmitHandler = handleSubmit((formData: any) => {
    const originalAssetName = assetDetails.asset_name;
    const assetNameChanged = originalAssetName !== formData.assetName;

    setFormSubmitted(true);
    setApiCallInProgress(true);

    // get asset info.
    assetsApi
      .getSingleAssetById(assetDetails.asset_id)
      .then((res) => {
        // only run if asset name changed.
        if (assetNameChanged) {
          assetsApi
            .checkAssetExistsByAssetName(formData.assetName)
            .then(async (assetExists) => {
              if (assetExists) {
                setError('assetName', {
                  type: 'validate',
                  message: 'Asset name specified already exists.',
                });
                setApiCallInProgress(false);
                return;
              } else {
                editAssetMutation.mutate(getPostAssetDataObject(formData, res));
              }
            })
            .catch((err) => {
              console.log(err);
              alertErrorMessage('An error occurred. The asset has not been saved');
              setApiCallInProgress(false);
            });
        } else {
          editAssetMutation.mutate(getPostAssetDataObject(formData, res));
        }
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('An error occurred. The asset has not been saved');
        setApiCallInProgress(false);
      });
  });

  const getPostAssetDataObject = (formData: any, originalAssetDetails: any) => {
    return {
      asset: {
        data: {
          asset_id: originalAssetDetails.data.asset_id,
          asset_name: formData.assetName,
          asset_type_id: formData.assetSubType.value,
          owner_id: formData.assetOwner.value,
          description: formData.description,
          assigned_beacon_id: originalAssetDetails.data.assigned_beacon_id
            ? originalAssetDetails.data.assigned_beacon_id
            : null,
        },
        revision: originalAssetDetails.revision,
      },
    };
  };

  useEffect(() => {
    //useeffect to execute mutation state outcomes.

    if (editAssetMutation.isSuccess) {
      navigate(-1);
      alertSuccessMessage('Changes saved for the asset');
    }

    if (editAssetMutation.isError) {
      editAssetMutation.reset();
      setApiCallInProgress(false);
      alertErrorMessage('An error occurred. The asset has not been saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAssetMutation]);

  return {
    formSubmitHandler,
    editAssetMutation,
    getPostAssetDataObject,
    apiCallInProgress,
    formSubmitted,
    setFormSubmitted,
    handleCancel,
    handleCloseModal,
    handleCloseModalAndSave,
  };
}
