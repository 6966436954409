import React, { useContext } from 'react';

import { getValueSafely } from 'utils/utils';
import ViewAssetOnMapLink from '../ViewAssetOnMapLink';
import { colours } from 'styling/colours';
import useDevice from 'hooks/useDevice';
import { VenuesContext } from 'contexts/venues.context';
import { EstimateType } from 'types/Estimate';

export default function LocationSection({ assetDetails }: { assetDetails: EstimateType }) {
  const buildingNamesArray = assetDetails.location?.building_level?.possible_buildings || [];
  const buildingName = getValueSafely(buildingNamesArray[0]?.name);
  const { isMobileDevice } = useDevice();
  const { isSingleVenue } = useContext(VenuesContext);

  const buildingLevelName = getValueSafely(assetDetails.location?.building_level?.level.name);
  const assetIsOutdoor = assetDetails.location?.is_outdoors;
  return (
    <>
      {!isSingleVenue && (
        <div style={{ marginBottom: !isMobileDevice ? '15px' : '0px' }}>
          {getValueSafely(assetDetails?.location?.venue.name)}
        </div>
      )}

      {assetIsOutdoor ? (
        <>
          <span style={{ marginBottom: '10px' }}>Outside Mapped Buildings</span>

          <div className="region">
            <div
              style={{
                color: colours.secondaryText,
                margin: !isMobileDevice ? '15px 0 10px 0' : '15px 0 0 0',
              }}
            >
              Possible region:
            </div>
            <div>{getValueSafely(assetDetails.location?.region?.name)}</div>
          </div>
        </>
      ) : (
        <>
          <span style={{ marginBottom: '10px' }}>{`${buildingLevelName}/${buildingName}`}</span>
          <div className="region">
            <div
              style={{
                color: colours.secondaryText,
                margin: !isMobileDevice ? '15px 0 10px 0' : '15px 0 0 0',
              }}
            >
              Possible region:
            </div>
            <div>{getValueSafely(assetDetails.location?.region?.name)}</div>
          </div>
        </>
      )}
      {!isMobileDevice && assetDetails.location && (
        <ViewAssetOnMapLink assetEstimate={assetDetails} />
      )}
    </>
  );
}
