import React from 'react';
import LastSeenCell from '../../../../../components/DataTable/cells/LastSeenCell';
import AccuracyRangeSpan from '../AccuracyRangeSpan';
import BatterySpan from '../BatterySpan';
import infoImg from '../../icons/infoBg.svg';
import LocationSection from '../LocationSection';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import { EstimateType } from 'types/Estimate';
import { StyledTable } from '../../styles';

export default function AssetDetailsTabContent({ asset }: { asset: EstimateType }) {
  const deviceSource = asset.assigned_beacon?.import_info.source;

  return (
    <>
      <div className="information-sentance">
        <img src={infoImg} alt="information" />
        <p>Please note, estimated information was derived from the beacon.</p>
      </div>
      <StyledTable data-test-id="asset-details-estimate" style={{ marginBottom: 0 }}>
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={asset.last_location_update_timestamp} />
              {asset.last_location_update_timestamp &&
                ` ${getLastSeenString(asset.last_location_update_timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={asset} />{' '}
            </td>
          </tr>

          <tr>
            <td>Battery:</td>
            <td>
              <BatterySpan assetDetails={asset} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              <LocationSection assetDetails={asset} />
            </td>
          </tr>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(asset.assigned_beacon?.id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>
              {getValueSafely(asset.assigned_beacon?.beacon_type)}
              {deviceSource && ` - ${deviceSource}`}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
}
