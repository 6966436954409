import { getSearchParamValue } from './utils';

export function checkFullAssetURLParamsExist(): boolean {
  const urlParamBuildingID = getSearchParamValue('building_id');
  const urlParamlevelID = getSearchParamValue('level_id');
  const urlParamBeaconID = getSearchParamValue('beacon_id');
  const urlParamVenueID = getSearchParamValue('venue_id');

  const fullAssetURLParamsExist =
    urlParamBeaconID !== null &&
    urlParamVenueID !== null &&
    urlParamBuildingID !== null &&
    urlParamlevelID !== null;

  return fullAssetURLParamsExist;
}

export function checkFullOutdoorAssetURLParamsExist(): boolean {
  const urlParamAssetIsOutdoors = getSearchParamValue('is_outdoors');
  const urlParamBeaconID = getSearchParamValue('beacon_id');
  const urlParamVenueID = getSearchParamValue('venue_id');

  const fullOutdoorAssetParamsExist =
    urlParamAssetIsOutdoors === 'true' && urlParamBeaconID !== null && urlParamVenueID !== null;

  return fullOutdoorAssetParamsExist;
}

export function checkFullMobileClickThroughParamsExist(): boolean {
  const urlParamBuildingID = getSearchParamValue('building_id');
  const urlParamlevelID = getSearchParamValue('level_id');
  const urlParamUserLat = getSearchParamValue('user_lat');
  const urlParamUserLong = getSearchParamValue('user_long');

  const fullMobileClickThroughParamsExist =
    urlParamUserLat !== null &&
    urlParamUserLong !== null &&
    urlParamBuildingID !== null &&
    urlParamlevelID !== null;

  return fullMobileClickThroughParamsExist;
}
