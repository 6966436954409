import React from 'react';
import { LocalizedBeaconType } from 'types/BeaconType';
import { EstimateType } from 'types/Estimate';

import { getValueSafely } from 'utils/utils';

export default function LocationSpan({
  assetDetails,
}: {
  assetDetails: EstimateType | LocalizedBeaconType;
}) {
  const buildingNamesArray = assetDetails.location?.building_level.possible_buildings || [];
  const buildingName = getValueSafely(buildingNamesArray[0]?.name);
  const buildingLevelName = getValueSafely(assetDetails.location?.building_level.level.name);

  return <span style={{ marginBottom: '10px' }}>{`${buildingLevelName}/${buildingName}`}</span>;
}
