import React from 'react';
import { NoSearchResultsPanelStyledDiv } from '../Styled';
import errorImg from '../icons/error.svg';

function NoResultsText({ searchTerm, searchType }: { searchTerm: string; searchType: string }) {
  return searchType === 'users' ? (
    <>Sorry, no people matched {searchTerm}</>
  ) : (
    <>Sorry, no assets were found for {searchTerm}</>
  );
}

export default function NoSearchResultsPanel({
  searchTerm,
  searchType,
}: {
  searchTerm: string;
  searchType: string;
}) {
  return (
    <NoSearchResultsPanelStyledDiv>
      <img src={errorImg} alt="error" />
      <span>
        <NoResultsText searchTerm={searchTerm} searchType={searchType} />
      </span>
    </NoSearchResultsPanelStyledDiv>
  );
}
