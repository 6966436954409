import React, { useContext, useEffect } from 'react';
import { StyledDropDownContainer } from '../../Styled';
import { getReactSelectOptionsFromAssetStatusData } from '../../utils/utils';
import { AssetStatesContext } from 'contexts/assetStates.context';
import { FullConfigContext } from 'contexts/fullConfig.context';
import MapDropdownCheckboxInputSelect from './DropDownInput/MapDropdownCheckboxInputSelect';
import { AssetConfigContext } from 'contexts/assetConfig.context';
import { getSortedArrayByValue } from 'utils/utils';
import { SearchFilterContext } from '../../contexts/searchFilter.context';
import useCallSearchAssets from '../../hooks/useCallSearchAssets';

// This component stores query state used to call API for all dropdowns.
// We pass states down, so they can be set and consumed from the api calls in children
export default function DropdownFilterInputController({
  currentMenuOpenType,
  setCurrentMenuOpenType,
}: {
  currentMenuOpenType: string | null;
  setCurrentMenuOpenType: (arg: string | null) => void;
}) {
  const { availableFeaturesObject } = useContext(FullConfigContext);
  const { availableAssetStates } = useContext(AssetStatesContext);
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);
  const {
    setAssetOwnerCheckedListArray,
    setAssetSubtypeCheckedListArray,
    setAssetStatusCheckedListArray,
    assetOwnerCheckedListArray,
    assetSubtypeCheckedListArray,
    assetStatusCheckedListArray,

    filtersModified,
    setFiltersModified,
  } = useContext(SearchFilterContext);

  const { callFilterAssets } = useCallSearchAssets();

  const defaultAssetSubtypeOptions = getSortedArrayByValue(assetSubtype, 'value');
  const defaultAssetOwnerOptions = getSortedArrayByValue(assetOwners, 'value');
  const defaultAssetStatusOptions = getReactSelectOptionsFromAssetStatusData(availableAssetStates);

  useEffect(() => {
    // prevent call on initial map load

    if (filtersModified) {
      callFilterAssets(
        null,
        assetOwnerCheckedListArray,
        assetSubtypeCheckedListArray,
        assetStatusCheckedListArray,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetOwnerCheckedListArray, assetSubtypeCheckedListArray, assetStatusCheckedListArray]);

  return (
    <StyledDropDownContainer>
      <div className="test-element">
        <MapDropdownCheckboxInputSelect
          options={defaultAssetSubtypeOptions}
          preplaceholdertext="Asset Type:"
          type="subtype"
          setStateMethod={setAssetSubtypeCheckedListArray}
          setCurrentMenuOpenType={setCurrentMenuOpenType}
          currentMenuOpenType={currentMenuOpenType}
          dataTestId={'asset_type_filter'}
          setFiltersModified={setFiltersModified}
        />
      </div>
      <div className="test-element">
        <MapDropdownCheckboxInputSelect
          options={defaultAssetOwnerOptions}
          preplaceholdertext="Owner:"
          type="owner"
          setStateMethod={setAssetOwnerCheckedListArray}
          setCurrentMenuOpenType={setCurrentMenuOpenType}
          currentMenuOpenType={currentMenuOpenType}
          dataTestId={'owner_filter'}
          setFiltersModified={setFiltersModified}
        />
      </div>
      {availableFeaturesObject.asset_status && (
        <div className="test-element">
          <MapDropdownCheckboxInputSelect
            options={defaultAssetStatusOptions}
            preplaceholdertext="Status:"
            type="status"
            setStateMethod={setAssetStatusCheckedListArray}
            setCurrentMenuOpenType={setCurrentMenuOpenType}
            currentMenuOpenType={currentMenuOpenType}
            dataTestId="asset_status_filter"
            setFiltersModified={setFiltersModified}
          />
        </div>
      )}
    </StyledDropDownContainer>
  );
}
