export const coloursNames = {
  deepSkyBlue: '#00AEEF',
  deepSkyBlueDisabled: '#91DBF6',
  cobaltBlue: '#0055B8',
  otherCobaltBlue: ' #0051BD',
  darkBlue: '#0083b4',
  disabledTableGrey: '#EAECF2',
  disabledSelectGrey: '#f1f1f1',
  cinnabarOrange: '#E64B38',
  brightRed: '#A32035',
  supernovaYellow: '#FBB034',
  pigmentGreen: '#00B259',
  lightPurple: '#e5e0ee',
  mediumPurple: '#d2ccd7',
  studioPurple: '#74489D',
  lightGrey: '#BCBEC0',
  midGrey: '#808285',
  darkGrey: '#414042',
  blueishGrey: '#F6FAFD',
  bgGray: '#f8f9fb',
  mercury: '#E6E6E6',
  veryLightGrey: 'rgba(205, 208, 227, 0.5)',
  darkRed: '#a32035',
  whiteout: '#fbfbfb',
  white: '#fff',
  blue: '#0056ba',
  black: '#212121',
  lightGrey15: 'rgba(188, 190, 192, 0.15)',
  lightGrey30: 'rgba(188, 190, 192, 0.3)',
  lightGrey1: 'rgba(246, 247, 249, 1)',
};
export const colours: any = {
  primaryText: coloursNames.darkGrey,
  secondaryText: coloursNames.midGrey,
  brightText: coloursNames.whiteout,
  tertiaryText: coloursNames.lightGrey,
  primary: coloursNames.cobaltBlue,
  secondary: coloursNames.deepSkyBlue,
  secondaryDisabled: coloursNames.deepSkyBlueDisabled,
  tertiary: coloursNames.darkBlue,
  warning: coloursNames.supernovaYellow,
  highlight: coloursNames.cinnabarOrange,
  error: coloursNames.cinnabarOrange,
  success: coloursNames.pigmentGreen,
  inform: coloursNames.studioPurple,
  secundaryInform: coloursNames.mediumPurple,
  tertiaryInform: coloursNames.lightPurple,
  background: coloursNames.bgGray,
  blueishBg: coloursNames.blueishGrey,
  border: coloursNames.mercury,
  lightBorder: coloursNames.veryLightGrey,
  unresponsive: coloursNames.darkRed,
  white: coloursNames.white,
  disableTableRow: coloursNames.disabledTableGrey,
  disableSelect: coloursNames.disabledSelectGrey,
  verificationPassed: coloursNames.blue,
  assetDetailsFlyoutHeader: coloursNames.otherCobaltBlue,
  assetDetailsFlyoutAddToMap: coloursNames.lightGrey1,
};
