import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import warning from './icons/warning.svg';

export const StyledFormControlContainer = styled.div`
  min-height: 133px;
`;

export const StyledFormControlContainerShort = styled.div`
  min-height: 116px;
`;

export const StyledLabel = styled.label`
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0px;
  width: auto;
  margin: 0 0 10px;
  display: block;
`;

export const StyledSup = styled.sup`
  color: ${colours.highlight};
`;

export const RequiredAsterisk: React.FunctionComponent = () => {
  return <StyledSup>*</StyledSup>;
};

export const StyledInput = styled.input`
  border: 1px solid ${colours.tertiaryText};
  border-radius: 3px;
  padding: 16px;
  color: ${colours.primaryText};
  font-size: 15px;
  letter-spacing: 0px;
  width: 100%;
  :disabled,
  :read-only,
  &[readonly] {
    background-color: ${colours.disableSelect};
    border: none;
  }
  &.error {
    border: 1px solid ${colours.highlight};
  }
  &.error:focus {
    outline: none;
  }
`;

export const StyledTextarea = styled.textarea`
  border: 1px solid ${colours.tertiaryText};
  border-radius: 3px;
  padding: 16px;
  color: ${colours.primaryText};
  font-size: 15px;
  letter-spacing: 0px;
  width: 100%;
  height: 100px;
  :disabled {
    background-color: ${colours.disableSelect};
    border: none;
  }
  &.error {
    border: 1px solid ${colours.highlight};
  }
  &.error:focus {
    outline: none;
  }
`;

export const StyledError = styled.div`
  background: url(${warning}) 0 0 no-repeat;
  background-size: 17px 18px;
  color: ${colours.error};
  margin: 5px 0 0 0;
  padding: 3px 0 0 28px;
  font-size: 12px;
`;
export const StyledFieldset = styled.fieldset`
  height: 145px;
`;

export const StyledCharactersRemaining = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
  color: ${colours.secondaryText};
  margin-top: 5px;
`;
export const StyledSubHeadingForPage = styled.h2`
  margin-top: 70px;
  font-size: 18px;
  color: ${colours.secondaryText};
`;
export const StyledAddEditContainer = styled.div`
  padding-bottom: 16px;
  .content {
    margin-top: 10px;
    background: ${colours.white};
    padding: 40px;
    height: calc(100vh - 275px);
  }
`;

export const StyledEditContainer = styled.div`
  padding-bottom: 16px;
  .content {
    margin-top: 10px;
    background: ${colours.white};
    padding: 40px;
    height: calc(100vh - 200px);
  }
`;
