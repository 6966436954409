import React from 'react';
import AppRouter from './router/AppRouter';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';
import AppProviderContexts from 'contexts/app.contexts';

export default function App() {
  const { screenIsMinimumSupportedWidth } = useDevice();

  return (
    <AppProviderContexts>
      {screenIsMinimumSupportedWidth ? <AppRouter /> : <WidthNotSupportedPage notPortalAccess />}
    </AppProviderContexts>
  );
}
