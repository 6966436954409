import { isArray, isObject, isString } from "lodash";

type ValidationLocation = {
  location: string;
  entity: string;
  index: number;
  elementId: string;
};

export type ValidationError = {
  [key: string]: any;

  loc?: string[];
  location?: ValidationLocation;
  detail?: string;
  msg: string;
  type?: string;
}
export class ErrorResponseDto {
  statusCode: number = 0;
  errorMsg: string = "";
  detail: ValidationError[] = [];

  constructor(error: string | any[], statusCode: number = 0) {
    this.statusCode = statusCode;
    this.errorMsg = "An unexpected error occurred."

    if (isArray(error)) {
      this.detail = error;
      if (this.detail.length > 0) {
        if (this.detail[0].msg && isString(this.detail[0].msg) && this.detail[0].msg !== "")
          this.errorMsg = this.detail[0].msg;
        else if (this.detail[0].message && isString(this.detail[0].message) && this.detail[0].message !== "")
          this.errorMsg = this.detail[0].message;
      }
    }
    else if (isObject(error)) {
      this.detail = error;
    }
    else if (isString(error))
      this.errorMsg = error;
  }
}
