import React, { createContext, ReactNode, useEffect, useState } from 'react';
import MapPlaceholderLoadingScreen from '../components/MapPlaceholderLoadingScreen';
import { getSearchParamValue } from '../utils/utils';

type URLParamObjectTypes = {
  beaconID: string;
  venueID: string;
  buildingID: string;
  levelID: string;
  userLat?: any | undefined;
  userLong?: any | undefined;
};
type URLParamContextTypes = {
  urlParams: URLParamObjectTypes;
};
export const URLParamContext = createContext({} as URLParamContextTypes);

type URLParamProviderProps = { children: ReactNode };

const URLParamProvider = ({ children }: URLParamProviderProps) => {
  const [hasRunCheck, setHasRunCheck] = useState(false);
  const [urlParams, setUrlParams] = useState<URLParamObjectTypes>({
    beaconID: '',
    venueID: '',
    buildingID: '',
    levelID: '',
    userLat: undefined,
    userLong: undefined,
  });

  const value: URLParamContextTypes = {
    urlParams,
  };

  useEffect(() => {
    // useeffect to detect url params, and use appropriate usecase depending on params available.
    const urlParamBeaconID = getSearchParamValue('beacon_id');
    const urlParamVenueID = getSearchParamValue('venue_id');
    const urlParamBuildingID = getSearchParamValue('building_id');
    const urlParamlevelID = getSearchParamValue('level_id');
    const urlParamUserLat = getSearchParamValue('user_lat');
    const urlParamUserLong = getSearchParamValue('user_long');
    const urlParamAssetIsOutdoors = getSearchParamValue('is_outdoors');

    const fullIndoorAssetURLParamsExist =
      urlParamBeaconID !== null &&
      urlParamVenueID !== null &&
      urlParamBuildingID !== null &&
      urlParamlevelID !== null;

    const fullMobileClickThroughParamsExist =
      urlParamUserLat !== null &&
      urlParamUserLong !== null &&
      urlParamBuildingID !== null &&
      urlParamlevelID !== null;

    const fullOutdoorAssetParamsExist =
      urlParamAssetIsOutdoors === 'true' && urlParamBeaconID !== null && urlParamVenueID !== null;

    if (fullIndoorAssetURLParamsExist) {
      // params from click through from asset list to map
      setUrlParams({
        beaconID: urlParamBeaconID,
        venueID: urlParamVenueID,
        buildingID: urlParamBuildingID,
        levelID: urlParamlevelID,
        userLat: '',
        userLong: '',
      });
    } else if (fullOutdoorAssetParamsExist) {
      // params for outdoor asset click through from asset list to map

      setUrlParams({
        beaconID: urlParamBeaconID,
        venueID: urlParamVenueID,
        buildingID: '', // not required for outdoor asset.
        levelID: '', // not required for outdoor asset.
      });
    } else if (fullMobileClickThroughParamsExist) {
      // params from mobile app click through to portal
      setUrlParams({
        beaconID: '',
        venueID: '', // venue will not be passed from mobile
        buildingID: urlParamBuildingID,
        levelID: urlParamlevelID,
        userLat: urlParamUserLat,
        userLong: urlParamUserLong,
      });
    }

    setHasRunCheck(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <URLParamContext.Provider value={value}>
      {hasRunCheck ? children : <MapPlaceholderLoadingScreen />}
    </URLParamContext.Provider>
  );
};

export default URLParamProvider;
