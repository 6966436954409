import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import LoadingDots from 'components/Loading/LoadingDots';

const ShowMoreButtonStyled = styled.button`
  width: 135px;
  padding: 0 12.5px;
  height: 25px;
  border: 1px solid ${colours.tertiaryText};
  border-radius: 12.5px;
  font-family: MuseoSlab;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1.125px;
  text-align: center;
  background-color: ${colours.background};
  color: ${colours.secondaryText};
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const ShowMoreLine = styled.div`
  margin-top: 25px;
  height: 12.5px;
  width: 100%;
  border-bottom: 1px solid ${colours.tertiaryText};
  text-align: center;
  overflow: visible;
`;
type ShowMoreButtonType = {
  fetchFunction: () => void;
  nextIsLoading: boolean;
  nextCount: number;
};
const ShowMoreButton = ({ fetchFunction, nextIsLoading, nextCount }: ShowMoreButtonType) => {
  return (
    <ShowMoreLine>
      <ShowMoreButtonStyled
        data-test-id="show-next"
        disabled={nextIsLoading}
        tabIndex={0}
        onClick={fetchFunction}
      >
        {nextIsLoading ? <LoadingDots /> : `Show Next (${nextCount})`}
      </ShowMoreButtonStyled>
    </ShowMoreLine>
  );
};
export default ShowMoreButton;
