import { KeyValuePair } from "utils/common";
import { WorkGroup } from "../workgroups/WorkgroupDto";

export type roleListItem = {

  type: string;
  id: string;
  name: string;
  workgroup_id: string;
}
export class WorkgroupsAndRolesDto {
  version: string = "";
  workgroup_id_to_workgroups: Record<string, WorkGroup> = {};
  workgroup_id_to_roles: Record<string, roleListItem[]> = {};
}

export enum SecurityPermissions {
  login_mobile_app = "login_mobile_app",
  login_portal_app = "login_portal_app",
  locate_person = "locate_person"
}

export enum AssetPermissions {
  view = "view",
  create = "create",
  report = "report"
}

export enum PeoplePermissions {
  view = "view",
  report = "report"
}

export enum AdminPermissions {
  view = "view",
  create = "create",
}

export type role = {
  id: string;
  name: string;
  workgroup_id: string;
  security_permissions: SecurityPermissions[];
  asset_types: string[] | null,
  owners: string[] | null,
  asset_permissions: AssetPermissions[],
  people_roles: string[],
  people_permissions: PeoplePermissions[],
  admin_permissions: AdminPermissions[],
}

export class RoleConfigDto {
  version: string = "";
  role: role = {
    id: "",
    name: "",
    workgroup_id: "",
    security_permissions: [],
    asset_types: null,
    owners: null,
    asset_permissions: [],
    people_roles: [],
    people_permissions: [],
    admin_permissions: []
  }
}

export class accessedWorkgroupsDto {
  workgroups: KeyValuePair[] = [];
}