import React from 'react';

type valueType = string | string[] | null;

function getValueString(value: valueType) {
  if (!value || value.length < 1) return '––';

  if (Array.isArray(value)) {
    const arrayToString = value.join(', ');
    return arrayToString;
  }
  return value;
}

export default function DefaultCell({ value }: { value: valueType }): React.ReactElement {
  const valueStr = getValueString(value);

  return <>{valueStr}</>;
}
