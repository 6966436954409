import React, { useContext, useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import { icons } from 'assets/icons';
import AntModalStyled from 'components/Modals/AntModalStyled';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { Horizontal, StretchSpacer } from 'gls/lib';
import { Controller, useForm } from 'react-hook-form';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, programaticallyDownloadFileFromHref } from 'utils/utils';
import UserLogApi from 'api/user/userLog.api';
import { subMonths } from 'date-fns';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { getISOFormatStartEndDates, getSuccessAlertString } from './utils';
import { DatePickInputComponent } from 'components/Dates/DatePickInputComponent';

type ModalProps = {
  onClose: () => void;
};

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${colours.secondaryText};
  border-radius: 50%;
  img {
    width: 20px;
  }
`;

const StyledBody = styled.div`
  margin: 30px 0 70px;
`;

type downloadFormType = {
  startDate: number | Date;
  endDate: number | Date;
  name: string;
};

export enum DownloadStatusEnum {
  'processing' = 'processing',
  'export_succeeded' = 'export_succeeded',
  'failed' = 'failed',
}

export const DownloadUserLogsModal = (props: ModalProps) => {
  const {
    control,
    getValues,
    watch,
    resetField,
    formState: { isValid },
  } = useForm<downloadFormType>({
    defaultValues: {
      startDate: undefined,
      endDate: undefined,
    },
  });
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const userLogApi = new UserLogApi(token);

  const [jobId, setJobId] = useState<null | string>(null);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatusEnum | null>(null);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  function handleStatusResponse(response: any, interval?: any) {
    switch (response.status) {
      case DownloadStatusEnum.processing:
        break;

      case DownloadStatusEnum.export_succeeded:
        clearInterval(interval);
        programaticallyDownloadFileFromHref(response.url);
        alertSuccessMessage(getSuccessAlertString(getValues().startDate, getValues().endDate));
        closeHandler();
        break;

      case DownloadStatusEnum.failed:
        clearInterval(interval);
        handleError(response);
        closeHandler();

        break;

      default:
        break;
    }
  }
  function handleError(err: any) {
    console.log(err);
    setApiCallInProgress(false);
    alertErrorMessage('Sorry, the job failed and your file has not been created.');
  }

  const formSubmitHandler = () => {
    setApiCallInProgress(true);
    // create export job & set JOB_ID from res.
    const { startISOString, endISOString } = getISOFormatStartEndDates(
      getValues().startDate,
      getValues().endDate,
    );

    userLogApi
      .CreateExportJob(startISOString, endISOString)
      .then((res) => {
        setJobId(res.data.job_id);

        return res.data.job_id;
      })
      .then((job_id) => {
        userLogApi
          .GetExportJobStatus(job_id)
          .then((res) => {
            // then set initial res status.
            setDownloadStatus(res.data.status);
            handleStatusResponse(res.data);
          })
          .catch((err) => handleError(err));
      })
      .catch((err) => handleError(err));
  };

  const closeHandler = () => {
    resetField('startDate');
    resetField('endDate');
    props.onClose();
  };

  useEffect(() => {
    // once status is set to processing, create interval that will poll status API and handle res appropriately.
    if (downloadStatus === DownloadStatusEnum.processing) {
      const interval = setInterval(() => {
        userLogApi.GetExportJobStatus(jobId).then((res) => {
          setDownloadStatus(res.data.status);
          handleStatusResponse(res.data, interval);
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadStatus]);

  return (
    <AntModalStyled onCancel={closeHandler} open centered footer={null} maskClosable={false}>
      <header>
        <StyledIconWrapper>
          <icons.DownloadIcon2 fill={colours.secondaryText} />
        </StyledIconWrapper>
        <h3>
          {!downloadStatus && 'Download Custom Log'}
          {downloadStatus === DownloadStatusEnum.processing && 'Download in Progress'}
        </h3>
      </header>
      <StyledBody>
        {!downloadStatus && (
          <Horizontal>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <DatePickInputComponent
                  {...field}
                  popupStyle={{ maxWidth: '300px', zIndex: '999999' }}
                  required
                  key={field.value}
                  label="Start Date:"
                  onChange={(date) => {
                    field.onChange(date);
                    if (endDate && date?.isAfter(endDate)) {
                      resetField('endDate');
                    }
                  }}
                  minDate={subMonths(new Date(), 2)}
                  maxDate={new Date()}
                />
              )}
            />
            <StretchSpacer />
            <Controller
              name="endDate"
              control={control}
              rules={{ required: true }}
              render={({ field }: any) => (
                <DatePickInputComponent
                  {...field}
                  popupStyle={{ maxWidth: '300px', zIndex: '999999' }}
                  required
                  label="End Date:"
                  onChange={field.onChange}
                  key={field.value}
                  minDate={startDate ? new Date(startDate) : subMonths(new Date(), 2)}
                  maxDate={new Date()}
                />
              )}
            />
          </Horizontal>
        )}
        {downloadStatus === DownloadStatusEnum.processing && (
          <p>The file is being prepared. Please do not navigate away from the page.</p>
        )}
      </StyledBody>
      <footer>
        {downloadStatus !== DownloadStatusEnum.processing && (
          <Button id="archiveAssetCancelButton" onClick={closeHandler} outline>
            Cancel
          </Button>
        )}
        <Button
          isLoading={apiCallInProgress}
          disabled={!isValid}
          id="downloadUserLogs"
          onClick={formSubmitHandler}
        >
          Download
        </Button>
      </footer>
    </AntModalStyled>
  );
};
