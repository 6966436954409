import React from 'react';
import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';

export default function AccuracyRangeSpan({ asset }: { asset: EstimateType }) {
  if (!asset.assigned_beacon) return <>––</>;

  const accuracyRangeString = getValueSafely(asset.location.uncertainty?.toFixed(1), ' metres');
  return <span>{accuracyRangeString}</span>;
}
