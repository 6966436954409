import React from 'react';
import { DataSortDirection, MobileDataTable } from './MobileDataTable';
import { MobileAssetColumn } from 'views/AssetListPage/components/MobileAssetColumn';
import { getAssetsNonExpired } from 'utils/utils';
import NoDataComponent from './NoDataComponent';
import { EstimateType } from 'types/Estimate';

type MobileAssetsTableType = {
  sortColumnField: string | undefined;
  sortDirection: string;
  assetTableData: EstimateType[];
  handleSort: (sortField: string, direction: string) => Promise<void>;
  nextToken: string;
  handlePagination: () => void;
  fetchingNextPage: boolean;
  handleRowSelected: (row: any) => void;
  isSorting: boolean;
  paginationIndex: number;
  totalCount: number;
  networkError: any;
  noResultsText: string;
  selectedSearchMenuOptionLabel: string;
};
export default function MobileAssetsTable({
  sortColumnField,
  sortDirection,
  assetTableData,
  handleSort,
  nextToken,
  handlePagination,
  fetchingNextPage,
  handleRowSelected,
  isSorting,
  paginationIndex,
  totalCount,
  networkError,
  noResultsText,
  selectedSearchMenuOptionLabel,
}: MobileAssetsTableType) {
  return (
    <MobileDataTable
      RowElement={(row) => <MobileAssetColumn row={row} onClick={handleRowSelected} />}
      defaultSortField={sortColumnField}
      defaultSortDirection={sortDirection as DataSortDirection}
      SortOptions={[
        {
          title: 'Asset Name',
          sortField: 'asset_name',
        },
        {
          title: 'Asset Type',
          sortField: 'asset_type',
        },
        {
          title: 'Last Seen',
          sortField: 'last_update',
          DescTitle: 'descending',
          AscTitle: 'ascending',
        },
        {
          title: 'Status',
          sortField: 'status',
        },
        {
          title: 'Location',
          sortField: 'location',
        },
        {
          title: 'Owner',
          sortField: 'asset_owner',
        },
        {
          title: 'Battery',
          sortField: 'battery_level',
          DescTitle: 'high to low',
          AscTitle: 'low to high',
        },
      ]}
      data={getAssetsNonExpired(assetTableData)}
      nextPageToken={nextToken}
      handleSort={handleSort}
      handlePagination={handlePagination}
      fetchingNextPage={fetchingNextPage}
      handleRowSelected={handleRowSelected}
      isSorting={isSorting}
      paginationIndex={paginationIndex}
      totalCount={totalCount}
      noDataComponent={
        !networkError && (
          <NoDataComponent
            selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
            text={noResultsText}
          />
        )
      }
    />
  );
}
