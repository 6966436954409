import React from 'react';
import { Tooltip, Marker } from 'react-leaflet';
import L from 'leaflet';
import transparentImg from '../../icons/transparent.png';

type BuildingLabelType = { buildingName: string; feature: any };

export default function BuildingLabelTooltip({ buildingName, feature }: BuildingLabelType) {
  const displayPoint: any = [...feature.properties.display_point.coordinates].reverse(); // reversed to correct order

  const customIcon = new L.Icon({
    iconUrl: transparentImg, // 1px alpha image to show nothing.
    iconSize: new L.Point(0, 0),
  });

  return (
    <Marker icon={customIcon} position={displayPoint}>
      <Tooltip className="tooltip-building" direction="center" permanent opacity={1}>
        {buildingName}
      </Tooltip>
    </Marker>
  );
}
