import React from 'react';
import LoadingPlaceholderTableRow from './LoadingPlaceholderTableRow';
import { StyledPlaceholderDataTableContainer, StyledLoadingPlaceholderTableHeader } from './styled';

export default function LoadingPlaceholderDataTable() {
  return (
    <StyledPlaceholderDataTableContainer>
      <StyledLoadingPlaceholderTableHeader />

      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
      <LoadingPlaceholderTableRow />
    </StyledPlaceholderDataTableContainer>
  );
}
