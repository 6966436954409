export const apiAssetAssignEnum: any = {
  assigned: true,
  unassigned: false,
  all: null,
};
// enum for filters required by api to change difference from catagory of assets.
export const getAssetAPIFilterEnum: any = {
  asset_name: 'name_filter',
  owner_filter: 'owner_filter',
  asset_type: 'asset_type_filter',
  location_name_filter: 'location_name_filter',
};
