import React, { useEffect, useContext } from 'react';
import { StyledAsyncSelect, StyledInputContainer } from 'components/AsyncSelect/Styled';
import ClearInputButton from 'components/Button/ClearInputButton';
import OptionMenu from 'components/AsyncSelect/OptionMenu';
import { BeaconManagementContext } from 'views/BeaconManagementPage/contexts/BeaconManagment.context';
import { menuOptionsAssetNotAssigned, menuOptionsAssigned } from './menuOptions';
import useRegisteredBeaconSearchHandlers from '../../hooks/useRegisteredBeaconSearchHandlers';

type AsyncSelectOptionsTypes = {
  placeholder: string;
  width?: number;
};

// This field will call specified enterApiCall method on enter, and optionClickApiCall when clicking on one of the options.
export default function RegisteredBeaconAsyncSelect({
  placeholder,
  width,
}: AsyncSelectOptionsTypes) {
  const {
    searchTerm,
    setSearchTerm,
    isSearching,
    setIsSearching,
    assetAssignOptionSelectedObj,
    selectedSearchMenuOptionLabel,
    setSelectedSearchMenuOptionLabel,
    resetToCachedRefs,
  } = useContext(BeaconManagementContext);

  const {
    handleClearField,
    searchInProgress,
    handleEnterSearch,
    handleClickOptionSearch,
    menuIsOpen,
    setMenuIsOpen,
    selectedSearchMenuOptionLabelRef,
    inputHasFocus,
    setInputHasFocus,
  } = useRegisteredBeaconSearchHandlers();

  const hasSearchTerm = searchTerm.length > 0;
  const showPlaceholder = selectedSearchMenuOptionLabel !== '' && !inputHasFocus;

  const filteredMenuOptions =
    assetAssignOptionSelectedObj.value === 'unassigned'
      ? menuOptionsAssetNotAssigned
      : menuOptionsAssigned;

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setIsSearching(false); // allows us to revert to cached results ref in parent useeffect.
        setMenuIsOpen(true);
        setInputHasFocus(true);
        resetToCachedRefs();

        break;
      case 'menu-close':
        // close dropdown when menu is closed.
        if (searchInProgress) return; // prevent closing if call in progress.
        setMenuIsOpen(false);
        setInputHasFocus(false);
        break;
      case 'input-blur':
        if (searchInProgress) return; // prevent closing if call in progress.
        // close dropdown when input blurred.
        setMenuIsOpen(false);
        setInputHasFocus(false);
        if (hasSearchTerm) {
          // revert to cached label so we can populate it in search box.
          setSelectedSearchMenuOptionLabel(selectedSearchMenuOptionLabelRef.current);
        }
        break;
      default:
        break;
    }
  }

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;

    setMenuIsOpen(hasSearchTerm);
    setInputHasFocus(true);
  }

  useEffect(() => {
    if (hasSearchTerm) {
      // clear search if input loses focus only BEFORE search takes place.
      if (!inputHasFocus && !isSearching) {
        setSearchTerm('');
        setSelectedSearchMenuOptionLabel('');
        selectedSearchMenuOptionLabelRef.current = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHasFocus, isSearching]);

  useEffect(() => {
    //clear and close menu if search term is less than 1
    if (searchTerm.length < 1) {
      setMenuIsOpen(false);
      setInputHasFocus(false);
      setSelectedSearchMenuOptionLabel('');
      selectedSearchMenuOptionLabelRef.current = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    // clear field after assignment dropdown is changed.
    setSearchTerm('');
    setSelectedSearchMenuOptionLabel('');
    selectedSearchMenuOptionLabelRef.current = '';

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetAssignOptionSelectedObj.value]);

  return (
    <StyledInputContainer
      role="button"
      className="input-container"
      onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
      width={width}
    >
      {/* onClick on parent element to open menu, as React-Select does not expose an onclick event. A slightly hacky solution. a11y disabled because we do not want the parent div to be focusable.*/}
      <StyledAsyncSelect
        selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel} // NOTE - this is passed to styled component to render label in ::before.
        searchInProgress={searchInProgress}
        placeholder={placeholder}
        onInputChange={(newValue: string, action: any) => handleInputChange(newValue, action)}
        className={showPlaceholder ? 'asyncSearch --has-search' : 'asyncSearch'}
        classNamePrefix="asyncSearchPrefix"
        menuIsOpen={menuIsOpen}
        onKeyDown={handleEnterSearch}
        inputValue={searchTerm}
        escapeClearsValue
        components={{
          Menu: (props: any) => (
            <OptionMenu
              searchTerm={searchTerm}
              menuOptions={filteredMenuOptions}
              handleClickOptionSearch={handleClickOptionSearch}
              {...props}
            />
          ),
        }}
      />
      {hasSearchTerm && (
        <ClearInputButton onClick={handleClearField} searchInProgress={searchInProgress} />
      )}
    </StyledInputContainer>
  );
}
