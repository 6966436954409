import { icons } from 'assets/icons';
import React, { useMemo, useState } from 'react';
import logo from 'assets/images/navenio-landscape.svg';
import logoSmall from 'assets/images/navenio-small.svg';
import VersionReadout from './VersionReadout';
import CollapseButton from './CollapseButton';
import { StyledAside, StyledMobileMenu, StyledNav, StyledTop } from './styled';
import NavLinkItem from './NavLinkItem';
import useNavHooks from './useNavHooks';
import useDevice from 'hooks/useDevice';
import { Horizontal, StretchSpacer, Vertical } from 'gls';
import IconButton from 'components/Button/IconButton';
import UserInfoDropdown from 'components/Header/components/UserInfoDropdown/UserInfoDropdown';
import { PERMISSIONS } from 'config/permissions';
import { configRoutePath } from 'router/routePaths';
import { featurePermissionType, userPermissionType } from 'types/permissions';

export type navItemsType = {
  name: string;
  href: string;
  matchingPaths?: string[];
  icon: any;
  userPermissions: userPermissionType[];
  featurePermissions: featurePermissionType[];
  submenu?: any[];
};

const ConfigNavigation = ({
  navCollapsedDefault,
}: {
  navCollapsedDefault: boolean;
}): React.ReactElement => {
  const [userHasCollapsed, setUserHasCollapsed] = useState(false);
  const [navIsCollapsed, setNavIsCollapsed] = useState(userHasCollapsed);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isTabletDevice } = useDevice();

  const navItems: navItemsType[] = useMemo(() => {
    return [
      {
        name: 'System Administrators',
        href: configRoutePath.systemAdminList,
        icon: icons.SystemAdministratorsIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [ PERMISSIONS.FEATURE.USER_TRACKING],
      },
      {
        name: 'Workgroups',
        href: configRoutePath.Workgroups,
        icon: icons.WorkgroupsIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [ PERMISSIONS.FEATURE.USER_TRACKING],
      },
      {
        name: 'Roles',
        href: '',
        icon: icons.RolesIcon,
        userPermissions: [PERMISSIONS.USER.READ],
        featurePermissions: [ PERMISSIONS.FEATURE.USER_TRACKING],
        submenu: [{
          name: 'Create Role',
          href: configRoutePath.addRole,
          userPermissions: [PERMISSIONS.USER.READ],
          featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
        },{
          name: 'View and Manage Roles',
          href: configRoutePath.listRoles,
          userPermissions: [PERMISSIONS.USER.READ],
          featurePermissions: [PERMISSIONS.FEATURE.USER_TRACKING],
        }]
        
      },
    ];
  }, []);

  const { toggleNavOpen, activeHref, setActiveHref } = useNavHooks({
    userHasCollapsed,
    navCollapsedDefault,
    navIsCollapsed,
    setNavIsCollapsed,
    setUserHasCollapsed,
  });

  if (mobileMenuOpen) {
    return (
      <StyledMobileMenu>
        <Vertical className="container" horizontalAlign="left" spacing={10}>
          <IconButton
            className="nav-close-button"
            icon={<icons.CloseIcon width={15} height={15} />}
            onClick={() => setMobileMenuOpen(false)}
          />
          <div className="nav-userinfo">
            <UserInfoDropdown closeMobileMenu={() => setMobileMenuOpen(false)} />
          </div>
          <div className="nav-seperator"></div>
          <StyledNav>
            <ul className="nav-menu-list">
              {navItems.map((link) => {
                return (
                  <NavLinkItem
                    key={link.href}
                    link={link}
                    navIsCollapsed={false}
                    activeHref={activeHref}
                    setActiveHref={setActiveHref}
                    setNavIsCollapsed={setNavIsCollapsed}
                    setUserHasCollapsed={setUserHasCollapsed}
                    closeMobileMenu={() => setMobileMenuOpen(false)}
                  />
                );
              })}
            </ul>
          </StyledNav>
          <StretchSpacer />
          <VersionReadout />
        </Vertical>
      </StyledMobileMenu>
    );
  }

  if (isTabletDevice) {
    return (
      <StyledTop>
        <Horizontal spacing="auto" className="nav-container" horizontalAlign="center">
          <IconButton
            id="mobile-nav-button"
            icon={<icons.MobileMenu width={22} height={22} />}
            onClick={() => setMobileMenuOpen(true)}
          />
          <img src={logo} height="35px" alt="Navenio Logo" />
          <div className="nav-search">&nbsp;</div>
        </Horizontal>
      </StyledTop>
    );
  }

  return (
    <StyledAside className={navIsCollapsed ? '--collapsed' : ''}>
      <div className="nav-container">
        <div className="logo-container">
          <img src={navIsCollapsed ? logoSmall : logo} alt="Navenio Logo" />
        </div>

        <CollapseButton onClick={toggleNavOpen} />

        <StyledNav>
          <ul className="nav-menu-list">
            {navItems.map((link) => {
              return (
                <NavLinkItem
                  key={link.href}
                  link={link}
                  navIsCollapsed={navIsCollapsed}
                  activeHref={activeHref}
                  setActiveHref={setActiveHref}
                  setNavIsCollapsed={setNavIsCollapsed}
                  setUserHasCollapsed={setUserHasCollapsed}
                />
              );
            })}
          </ul>
        </StyledNav>
        <VersionReadout />
      </div>
    </StyledAside>
  );
};
export default ConfigNavigation;
