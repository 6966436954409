import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { UnregisteredBeaconType } from 'types/BeaconType';
import { getValueSafely } from 'utils/utils';

const StyledDiv = styled.div`
  header {
    margin-bottom: 20px;

    h2 {
      font-size: 18px;
      color: ${colours.secondaryText};
      font-weight: 600;
    }
  }
  .beacon-information-content {
    display: flex;
    .col {
      width: 300px;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          display: flex;
          margin: 14px 0;
          font-size: 16px;

          span:first-child {
            width: 100%;
            max-width: 110px;
            display: inline-block;
            color: ${colours.secondaryText};
          }
        }
      }
    }
  }
`;

export default function BeaconInformationPanel({
  selectedBeacon,
}: {
  selectedBeacon: UnregisteredBeaconType;
}) {
  return (
    <StyledDiv>
      <header>
        <h2>BEACON INFORMATION</h2>
      </header>
      <div className="beacon-information-content">
        <div className="col">
          <ul>
            <li>
              <span>Beacon ID:</span> {getValueSafely(selectedBeacon.beacon_id)}
            </li>
            <li>
              <span>Beacon Type: </span> {getValueSafely(selectedBeacon.beacon_type)}
            </li>
            <li>
              <span>Order ID: </span> {getValueSafely(selectedBeacon.order_id)}
            </li>
          </ul>
        </div>
        <div className="col">
          <ul>
            <li>
              <span>Notes: </span> {getValueSafely(selectedBeacon.notes)}
            </li>
          </ul>
        </div>
      </div>
    </StyledDiv>
  );
}
