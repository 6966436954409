import React, { createContext, ReactNode, useContext, useState } from 'react';
import useSubscribeToGlobalContext from '../hooks/useSubscribeToGlobalContext';
import { URLParamContext } from './urlParam.context';
import useURLParamEffects from '../hooks/useURLParamEffects';
import {
  checkFullAssetURLParamsExist,
  checkFullMobileClickThroughParamsExist,
  checkFullOutdoorAssetURLParamsExist,
} from '../utils/urlParamUtils';

type MapControlContextTypes = {
  assetMarkerInfoChecked: boolean;
  setAssetMarkerInfoChecked: (arg: boolean) => void;
  userMarkerInfoChecked: boolean;
  setUserMarkerInfoChecked: (arg: boolean) => void;
  floorSelectedIDArray: string[];
  setFloorSelectedIDArray: (arg: string[]) => void;
  buildingSelectedID: string;
  setBuildingSelectedID: (arg: string) => void;
  selectedFloorOrdinal: number | null;
  setSelectedFloorOrdinal: (arg: number | null) => void;
  buildingIsSelected: boolean;
  clickedMarkerID: string;
  setClickedMarkerID: (arg: string) => void;
  shouldRecalculateFloor: boolean;
  setShouldRecalculateFloor: (arg: boolean) => void;
  apiCallInProgress: boolean;
  setApiCallInProgress: (arg: boolean) => void;
  usersApiCallInProgress: boolean;
  setUsersApiCallInProgress: (arg: boolean) => void;
  assetsApiCallInProgress: boolean;
  setAssetsApiCallInProgress: (arg: boolean) => void;
  recallGetAssets: boolean;
  setRecallGetAssets: (arg: boolean) => void;
  recallGetUsers: boolean;
  setRecallGetUsers: (arg: boolean) => void;
  showAssetsChecked: boolean;
  setShowAssetsChecked: (arg: boolean) => void;
  showUsersChecked: boolean;
  setShowUsersChecked: (arg: boolean) => void;
  pollAssetsFinished: boolean;
  setPollAssetsFinished: (arg: boolean) => void;
  pollUsersFinished: boolean;
  setPollUsersFinished: (arg: boolean) => void;
};
export const MapControlContext = createContext({} as MapControlContextTypes);

type MapControlProviderProps = { children: ReactNode };

// A context that stores all values needed for controls and functioning UI elements within the Map.
// Note there are different use-cases for certain variables being initially set from URL Params.
//1: URL Params Constructed from Asset List 'view asset on map'.
//2: URL Params Constructed from IWS mobile app, where the user clicks from the app and is redirected to the ILS portal.
const MapControlProvider = ({ children }: MapControlProviderProps) => {
  const { getGlobalCTXValueOrFallback } = useSubscribeToGlobalContext();
  const { urlParams } = useContext(URLParamContext);
  const { checkBuildingAndLevelAreWithinVenueData } = useURLParamEffects();

  // variables ===================
  const [buildingSelectedID, setBuildingSelectedID] = useState(() => {
    // check url params exist first.
    if (checkFullAssetURLParamsExist()) {
      return urlParams.buildingID;
    } else if (checkFullMobileClickThroughParamsExist()) {
      if (checkBuildingAndLevelAreWithinVenueData()) {
        return urlParams.buildingID;
      }
    } else return getGlobalCTXValueOrFallback('GLOBAL_buildingSelectedID', '');
  });
  const [selectedFloorOrdinal, setSelectedFloorOrdinal] = useState<number | null>(() => {
    return getGlobalCTXValueOrFallback('GLOBAL_mapFloorOrdinal', null);
  }); // note this is null until a building is selected, ordinal is set in getOrdinalFromConditions() func

  // checkboxes
  const [assetMarkerInfoChecked, setAssetMarkerInfoChecked] = useState(false);
  const [userMarkerInfoChecked, setUserMarkerInfoChecked] = useState(false);
  // api call flags
  const [pollAssetsFinished, setPollAssetsFinished] = useState(true);
  const [pollUsersFinished, setPollUsersFinished] = useState(true);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [usersApiCallInProgress, setUsersApiCallInProgress] = useState(false);
  const [assetsApiCallInProgress, setAssetsApiCallInProgress] = useState(false);
  const [recallGetAssets, setRecallGetAssets] = useState(true); // set to true for inital load.
  const [recallGetUsers, setRecallGetUsers] = useState(true); // set to true for inital load.

  const [floorSelectedIDArray, setFloorSelectedIDArray] = useState(['']); // sets associated ids of floor to filter geojson units to display by
  const [clickedMarkerID, setClickedMarkerID] = useState(
    () =>
      checkFullOutdoorAssetURLParamsExist() || checkFullAssetURLParamsExist()
        ? urlParams.beaconID
        : '', // check value exists within URL Params constructed from asset list click through.
  );
  const [shouldRecalculateFloor, setShouldRecalculateFloor] = useState(false);

  // dropdowns
  const [showAssetsChecked, setShowAssetsChecked] = useState(true);
  const [showUsersChecked, setShowUsersChecked] = useState(true);

  const buildingIsSelected = typeof buildingSelectedID === 'string' && buildingSelectedID !== '';

  const value: MapControlContextTypes = {
    pollUsersFinished,
    setPollUsersFinished,
    assetMarkerInfoChecked,
    setAssetMarkerInfoChecked,
    userMarkerInfoChecked,
    setUserMarkerInfoChecked,
    floorSelectedIDArray,
    setFloorSelectedIDArray,
    selectedFloorOrdinal,
    setSelectedFloorOrdinal,
    buildingSelectedID,
    setBuildingSelectedID,
    buildingIsSelected,
    clickedMarkerID,
    setClickedMarkerID,
    shouldRecalculateFloor,
    setShouldRecalculateFloor,
    apiCallInProgress,
    setApiCallInProgress,
    usersApiCallInProgress,
    setUsersApiCallInProgress,
    assetsApiCallInProgress,
    setAssetsApiCallInProgress,
    recallGetAssets,
    setRecallGetAssets,
    recallGetUsers,
    setRecallGetUsers,
    showAssetsChecked,
    setShowAssetsChecked,
    showUsersChecked,
    setShowUsersChecked,
    pollAssetsFinished,
    setPollAssetsFinished,
  };

  return <MapControlContext.Provider value={value}>{children}</MapControlContext.Provider>;
};

export default MapControlProvider;
