import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  margin-top: auto;
  text-align: center;
  padding: 10px;
  word-wrap: anywhere;
`;
export default function VersionReadout() {
  const [versionReadout, setVersionReadout] = useState(false);
  const { version } = useContext(FullConfigContext);

  function handleVersionClick() {
    setVersionReadout(true);
    setTimeout(() => {
      setVersionReadout(false);
    }, 5000);
  }
  return (
    <StyledDiv data-test-id="version-readout" onClick={handleVersionClick}>
      {versionReadout ? version : process.env.REACT_APP_VERSION}
    </StyledDiv>
  );
}
