import React from 'react';
import errorImg from '../../icons/error.svg';
import { NoSearchResultsPanelStyledDiv } from '../Styled';

export default function NoSearchResultsPanel() {
  return (
    <NoSearchResultsPanelStyledDiv>
      <img src={errorImg} alt="error" />
      <span>Please enter at least 3 characters</span>
    </NoSearchResultsPanelStyledDiv>
  );
}
