import styled from 'styled-components';
import { colours } from 'styling/colours';

export const AssetManagementFormContainer = styled.div`
  height: 100%;
  padding: 10px 0;
  .content {
    margin-top: 10px;
    background: ${colours.white};
    padding: 40px;
    overflow-y: auto;
    .react-select__control,
    input {
      max-width: 350px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  button {
    &:last-child {
      margin-left: 10px;
    }
  }
`;
