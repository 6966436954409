import React, { useContext } from 'react';
import L from 'leaflet';
import transparentImg from '../../icons/transparent.png';
import { Marker, Tooltip } from 'react-leaflet';
import { StyledAssetTotalTooltip } from '../../Styled';
import { AssetsContext } from '../../contexts/assets.context';
import { getBuildingAssetCountFromFeatureBuildingUUIDArray } from '../../utils/utils';
import { MapControlContext } from '../../contexts/mapcontrol.context';
import useFilteredAssets from 'views/MapPage/hooks/useFilteredAssets';

type AssetMarkersLayerType = {
  feature: any;
};
export default function BuildingAssetTotalCountTooltip({ feature }: AssetMarkersLayerType) {
  const { rawIndoorAssets } = useContext(AssetsContext);
  const { buildingSelectedID } = useContext(MapControlContext);
  const getVisibleAssets = useFilteredAssets();

  const displayPoint: any = [...feature.properties.display_point.coordinates].reverse(); // reversed to correct order
  const buildingIsSelected = feature.properties.building_ids.includes(buildingSelectedID);

  const customIcon = new L.Icon({
    iconUrl: transparentImg, // 1px alpha image to show nothing.
    iconSize: new L.Point(0, 0),
  });

  const buildingAssetCount = getBuildingAssetCountFromFeatureBuildingUUIDArray(
    feature.properties.building_ids,
    getVisibleAssets(rawIndoorAssets),
  );

  // only show tooltip if count is over 0
  // dont show if building is selected.
  return buildingAssetCount > 0 && !buildingIsSelected ? (
    <Marker icon={customIcon} position={displayPoint}>
      <Tooltip
        className="tooltip-asset-total"
        permanent
        opacity={1}
        direction="bottom"
        offset={[-25, 15]}
      >
        <StyledAssetTotalTooltip>{buildingAssetCount}</StyledAssetTotalTooltip>
      </Tooltip>
    </Marker>
  ) : null;
}
