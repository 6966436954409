import { Validator } from "formstate";
import { isArray, isNumber, isString } from "lodash";
import XRegExp from "xregexp";

export const telValidatorError = "We allow + - ( ) /  and up to 20 digits";
export const RequiredValidationError = "This field is required.";
export const isTel = (value: string | undefined) => {
  const trimmedValue = value?.trim();
  if (trimmedValue) {
    const telRegEx = new RegExp(/^([\d+\-()\\/]{0,20})$/);
    return !telRegEx.test(trimmedValue);
  }
};

export const required: Validator<string[] | string | number | null | undefined> = (value) => {
  if (value === null || value === undefined){
    return RequiredValidationError;
  }
  if (isArray(value) && value.length === 0) {
    return RequiredValidationError
  }
  if (isNumber(value) && value === 0) {
    return RequiredValidationError;
  }
  if (isString(value) && (value == null || !value.trim())) {
    return RequiredValidationError;
  }
  return "";
}
export const requiredDate: Validator<Date | null | undefined> = (value) => {
  return !value && "This field is required.";
};

export const requiredPositiveNumber: Validator<number | null | undefined> = (
  value
) =>
  (value === null || value === undefined || value <= 0) &&
  "This field is required.";

export const telValidator: Validator<string | undefined> = (value) =>
  isTel(value) && telValidatorError;

export const hasNumber = (value: string | undefined) => {
  if (value) {
    for (let i = 0; i < value.length; i++) {
      if (!isNaN(+value[i])) {
        return true;
      }
    }
  }
  return false;
};

export const EmailValidator = (email: string | undefined) => {
  if (!email) {
    return "";
  }
  const RegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return RegEx.test(email) ? "" : "Please enter a valid email address";
}

export const hasUppercase = (value: string | undefined) => {
  if (value) {
    const alphaNumericRegEx = XRegExp("^[\\p{Latin}]");
    for (let i = 0; i < value.length; i++) {
      if (
        alphaNumericRegEx.test(value[i]) &&
        value[i] === value[i].toLocaleUpperCase()
      ) {
        return true;
      }
    }
  }
  return false;
};

export const hasLowercase = (value: string | undefined) => {
  if (value) {
    const alphaNumericRegEx = XRegExp("^[\\p{Latin}]");
    for (let i = 0; i < value.length; i++) {
      if (
        alphaNumericRegEx.test(value[i]) &&
        value[i] === value[i].toLocaleLowerCase()
      ) {
        return true;
      }
    }
  }
  return false;
};

export const hasLengthOrMore = (value: string | undefined, limit: number) =>
  value && value.length >= limit;

export function requiredArray<T>(value: T[], message?: string) {
  return value.length <= 0 && (message || "The option is required");
}

export const hasSpecialCharacter = (value: string | undefined) =>
  value &&
  new RegExp(
    /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|;|:/
  ).test(value);
