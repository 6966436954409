import React, { useRef, useState } from 'react';
import ScrollToTopButton from './ScrollToTopButton/ScrollToTopButton';

export const ScrolledMain = ({ children }: { children: React.ReactNode }) => {
  const [showOnScroll, setShowOnScroll] = useState(false);
  // const boundingElementRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLElement>(null);

  const handleScroll = () => {
    elementRef.current?.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const scrollPosition = () => {
    setShowOnScroll((elementRef.current?.scrollTop || 0) > 300);
  };

  return (
    <main ref={elementRef} onScroll={scrollPosition}>
      {children}
      <ScrollToTopButton show={showOnScroll} onScroll={handleScroll} />
    </main>
  );
};
