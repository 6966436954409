import React, { useContext, useRef, useState } from 'react';
import useAssetUserSearchMenuOptions from '../../hooks/useAssetUserSearchMenuOptions';
import { StyledAsyncSelect } from 'components/AsyncSelect/Styled';
import useCallSearchAssets from 'views/MapPage/hooks/useCallSearchAssets';
import { SearchFilterContext, searchTypeType } from 'views/MapPage/contexts/searchFilter.context';
import NoSearchResultsPanel from '../NoSearchResultsPanel';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import ClearInputButton from 'components/Button/ClearInputButton';
import MapSearchOptionsMenu from './MapSearchOptionsMenu';
import useCallSearchUsers from 'views/MapPage/hooks/useCallSearchUsers';
import { FullConfigContext } from 'contexts/fullConfig.context';

export default function DerivedAsyncSelectSearch() {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const { callSearchAssets, noAssetResultsReturned, setNoAssetResultsReturned } =
    useCallSearchAssets();
  const { callSearchUsers, setNoUserResultsReturned, noUserResultsReturned } = useCallSearchUsers();
  const {
    searchTerm,
    setSearchTerm,
    menuIsOpen,
    setMenuIsOpen,
    setDoNotCalculateFloor,
    setInSearchMode,
    inFilterMode,
    searchType,
    setSearchType,
    searchAPIcallInProgress,
  } = useContext(SearchFilterContext);
  const { assetTrackingPermissionEnabled, userTrackingPermissionEnabled } =
    useContext(FullConfigContext);
  const userHasFullPermissions = assetTrackingPermissionEnabled && userTrackingPermissionEnabled;

  const { setRecallGetAssets, setRecallGetUsers, setClickedMarkerID } =
    useContext(MapControlContext);
  const { getMenuOptions } = useAssetUserSearchMenuOptions();
  const inputRef: any = useRef(null);
  const shouldShowNoResultsMsg = noAssetResultsReturned || noUserResultsReturned;

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setNoAssetResultsReturned(false);
        setNoUserResultsReturned(false);
        setShowPlaceholder(false);

        break;
      case 'menu-close':
        setMenuIsOpen(false);
        break;
      case 'input-blur':
        setMenuIsOpen(false);
        break;
      default:
        break;
    }
  }

  function handleKeyDown(ev: React.KeyboardEvent) {
    if (ev.code === 'Enter') {
      if (userHasFullPermissions) return; // do not allow enter search if permissions exist, as dropdown will be present.
      const userOnlyPermissions = !assetTrackingPermissionEnabled && userTrackingPermissionEnabled;
      const assetOnlyPermissions = assetTrackingPermissionEnabled && !userTrackingPermissionEnabled;

      if (userOnlyPermissions) {
        handleClickOptionSearch({ label: 'user', value: 'users' });
      } else if (assetOnlyPermissions) {
        handleClickOptionSearch({ label: 'asset', value: 'assets' });
      }
    }
  }

  function handleClickOptionSearch(selectedOptionObj: { value: searchTypeType; label: string }) {
    if (searchTerm.length < 1) return;
    const derivedApiCall =
      selectedOptionObj.value === 'assets' ? callSearchAssets : callSearchUsers;
    const termTrimmed = searchTerm.trim();

    setShowPlaceholder(true);
    setSearchTerm(termTrimmed);
    setNoAssetResultsReturned(false);
    setNoUserResultsReturned(false);

    derivedApiCall(termTrimmed).then((res) => {
      if (res && res.length < 1) {
        setNoAssetResultsReturned(selectedOptionObj.value === 'assets');
        setNoUserResultsReturned(selectedOptionObj.value === 'users');
      }
    });

    setSearchType(selectedOptionObj.value);

    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  function handleClearField() {
    // clear search field, and trigger recall API in Asset Context, to show unfiltered assets.
    setDoNotCalculateFloor(true); // set to true so we dont recenter map.
    setInSearchMode(false);
    setSearchTerm('');
    setClickedMarkerID('');
    setNoAssetResultsReturned(false);
    setNoUserResultsReturned(false);
    setShowPlaceholder(false);
    setSearchType('none');
    if (!inFilterMode) {
      // we do not want to recall api if filters are present and user cancels search.
      setRecallGetAssets(true);
      setRecallGetUsers(true);
    }
  }

  function getSearchMenuOptionLabel() {
    if (userHasFullPermissions) {
      var str = parseInt('0027', 10); // weird hack to get apostrophy to appear in string in css content.
      return searchType === 'users' ? `Person\\${str}s Name` : 'Asset Name';
    } else {
      return null;
    }
  }

  return (
    <>
      <StyledAsyncSelect
        selectedSearchMenuOptionLabel={getSearchMenuOptionLabel()}
        placeholder="Search"
        onInputChange={(term: any, actionObj: any) => handleInputChange(term, actionObj)}
        onKeyDown={handleKeyDown}
        inputValue={searchTerm}
        className={showPlaceholder ? 'asyncSearch --has-search' : 'asyncSearch'}
        classNamePrefix="asyncSearchPrefix"
        menuIsOpen={menuIsOpen}
        ref={inputRef}
        components={{
          Menu: (props: any) => (
            <MapSearchOptionsMenu
              {...props}
              searchTerm={searchTerm}
              menuOptions={getMenuOptions()}
              handleClickOptionSearch={handleClickOptionSearch}
            />
          ),
        }}
      />

      {shouldShowNoResultsMsg && (
        <NoSearchResultsPanel searchType={searchType} searchTerm={searchTerm} />
      )}
      {searchTerm.length > 0 && (
        <ClearInputButton searchInProgress={searchAPIcallInProgress} onClick={handleClearField} />
      )}
    </>
  );
}
