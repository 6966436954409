import { useContext } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { windowBeforeUnloadEventHandler } from 'utils/utils';
import useBroadcastChannel from './useBroadcastChannel';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Cache } from 'aws-amplify/utils';

const useAmplifyRefresh = (): any => {
  const { authState, setAuthState } = useContext(AuthenticationContext);
  const { postBroadcastMessage } = useBroadcastChannel();

  async function refreshToken() {
    try {
      const { accessToken } = (await (await fetchAuthSession({ forceRefresh: true })).tokens) ?? {};
      if (accessToken !== undefined) {
        const newState = { ...authState, jwtToken: accessToken?.toString() };
        const timeNow: any = Date.now();

        setAuthState(newState);
        Cache.setItem('authState', newState);
        console.log(`%c[Auth Token Refreshed]: ${timeNow}`, 'color: lightblue;');
      } else throw new Error('could not refresh');
    } catch (e) {
      // check were not using authState that is being mocked for cypress tests
      if (!authState.isMock) {
        // if refresh token has expired, remove unload event handler that may have been attached when editing forms
        // then sign userout
        window.removeEventListener('beforeunload', windowBeforeUnloadEventHandler, {
          capture: true,
        });
        console.log(e, 'Logging User Out');
        postBroadcastMessage('broadcast_signout');
      }
    }
  }

  return refreshToken;
};

export default useAmplifyRefresh;
