/*
WorkgroupStore class handles all states, API calls, form validation
  and logic of listing, Adding, Editing and Deleting workgroup records.
*/
import { WorkgroupDto } from "views/config/workgroups/WorkgroupDto";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";

export type CreateWorkgroup = {
  workgroupName: string;
  defaultRoles: boolean;
};

export class WorkgroupsStore {

  createWorkgroupForm() {
    return new FormState({
      createWorkgroups: new FieldState<CreateWorkgroup[]>([
        { workgroupName: "", defaultRoles: false },
      ]).validators((val) => {
        this.errors = {};
        var error: string = "";
        val.forEach((entry, index) => {
          if (entry.workgroupName.trim().length === 0) {
            error = "error";
          }
          if (this.workgroups.some(workgroup => workgroup.name.trim().toLowerCase() === entry.workgroupName.trim().toLowerCase()) ||
            this.createWorkgroupsForm.$.createWorkgroups.value.some((workgroup, index2) =>
              workgroup.workgroupName !== "" && workgroup.workgroupName.trim().toLowerCase() === entry.workgroupName.trim().toLowerCase() && index2 < index)) {
            this.errors[`workgroupName${index}`] = ["A workgroup with the name specified already exists."];
            error = "error";
          }
        })
        return error;
      }),
    });
  }

  root: RootStore;
  createWorkgroupsForm = this.createWorkgroupForm();
  loading: boolean = true;
  workgroups: WorkgroupDto[] = [];
  workgroupsLoading: boolean = false;
  errors: Record<string, string[]> = {};
  currentWorkgroup: WorkgroupDto | undefined = undefined;

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      createWorkgroupsForm: observable,
      isValidForm: computed,
      loading: observable,
      errors: observable,
      resetErrors: action.bound,
      setLoading: action.bound,
      setWorkgroups: action.bound,
      setWorkgroupsLoading: action.bound,
      workgroups: observable,
      workgroupsLoading: observable,
      currentWorkgroup: observable,
      setCurrentWorkgroup: action.bound
    });
  }

  //#region "getters"
  get isValidForm() {
    return this.createWorkgroupsForm.$.createWorkgroups.dirty && !this.createWorkgroupsForm.$.createWorkgroups.hasError;
  }

  //#endregion "getters"

  //#region "setters"
  setLoading(value: boolean) {
    this.loading = value;
  }

  setCurrentWorkgroup(value: WorkgroupDto | undefined) {
    this.currentWorkgroup = value;
  }

  setWorkgroups(workgroups: WorkgroupDto[]) {
    this.workgroups = workgroups;
  }

  setWorkgroupsLoading(value: boolean) {
    this.workgroupsLoading = value;
  }

  resetErrors() {
    this.errors = {};
  }
  //#endregion "setters"
}
