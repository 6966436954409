import React, { useState } from 'react';
import { icons } from 'assets/icons';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import ShowMoreButton from 'components/DataTable/ShowMoreButton';
import { getNextCount } from 'utils/utils';
import { MobileSort } from './MobileSort';
import { EstimateType } from 'types/Estimate';

export enum DataSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type MobileSortOption = {
  title: string;
  sortField: string;
  AscTitle?: string;
  DescTitle?: string;
};
type MobileDataTableProps = {
  RowElement: (row: any) => React.ReactElement;
  noDataComponent: React.ReactElement | false;
  isSorting: boolean;
  data: EstimateType[];
  nextPageToken: string;
  handleSort: (sortField: string, direction: string) => Promise<void>;
  handleRowSelected: (arg: any) => void;
  handlePagination: () => void;
  fetchingNextPage: boolean;
  totalCount: number;
  paginationIndex: number;
  SortOptions?: MobileSortOption[];
  defaultSortField?: string;
  defaultSortDirection?: DataSortDirection;
};
export const MobileDataTable = (props: MobileDataTableProps) => {
  const {
    data,
    nextPageToken,
    handlePagination,
    fetchingNextPage,
    totalCount,
    paginationIndex,
    RowElement,
    noDataComponent,
    SortOptions,
    handleSort,
    defaultSortField = '',
    defaultSortDirection = 'desc',
  } = props;
  const nextCount = getNextCount(paginationIndex, totalCount);
  const [showSort, setShowSort] = useState(false);
  const [sortValue, setSortValue] = useState<{
    field: string;
    direction: string;
  }>({ field: defaultSortField, direction: defaultSortDirection });

  const onSort = (sortField: string, direction: string) => {
    setSortValue({
      field: sortField,
      direction: direction,
    });
    return handleSort(sortField, direction);
  };
  if (showSort) {
    return (
      <MobileSort
        SortOptions={SortOptions}
        handleSort={onSort}
        defaultSortField={sortValue.field}
        defaultSortDirection={sortValue.direction}
        onClose={() => setShowSort(false)}
      />
    );
  }
  return (
    <ContainerStyle>
      {SortOptions && (
        <SortStyle onClick={() => setShowSort(true)}>
          Sort
          <icons.SortIcon width={20} fill={colours.secondary} />
        </SortStyle>
      )}
      <DataTable
        columns={
          [
            {
              name: '',
              selector: (row: any) => RowElement(row),
              sortable: false,
              width: '100%',
              wrap: true,
            },
          ] as any[]
        }
        data={data}
        customStyles={datatableStyle}
        noDataComponent={noDataComponent}
      />
      {nextPageToken && nextCount > 0 && (
        <ShowMoreButton
          fetchFunction={handlePagination}
          nextIsLoading={fetchingNextPage}
          nextCount={nextCount}
        />
      )}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  margin-bottom: 50px;
`;

const SortStyle = styled.div`
  margin-bottom: 10px;
  float: right;
  cursor: pointer;
  color: ${colours.secondary};
  svg {
    vertical-align: sub;
  }
`;

const datatableStyle = {
  table: {
    style: {
      backgroundColor: 'transparent',
      width: '100%',
    },
  },
  headRow: {
    style: {
      display: 'none',
    },
  },
  rows: {
    style: {
      margin: '0 0 10px 0',
      borderBottomColor: `${colours.lightBorder}!important`,
      border: `1px solid ${colours.lightBorder}`,
      padding: `20px 0`,
      borderRadius: '6px',
      cursor: 'pointer',
      height: 'fit-content',
      fontSize: '15px',
    },
  },
  cells: {
    style: {
      width: '100%',
      ' div': {
        width: '100%',
      },
    },
  },
};
