import React from 'react';
import DefaultCell from '../cells/DefaultCell';
import { UnregisteredBeaconType } from 'types/BeaconType';

export const newBeaconColumns: any[] = [
  {
    name: 'BEACON ID',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.beacon_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'BEACON TYPE',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.beacon_type} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },

  {
    name: 'NOTES',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.notes} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },

  {
    name: 'MANAGER',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.manager_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'ORDER ID',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.order_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'SOURCE',
    selector: (row: UnregisteredBeaconType) => <DefaultCell value={row.source} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'VENUE',
    selector: () => <DefaultCell value={null} />, // selector: not available yet.
    sortable: true,
    overflow: 'false',
    grow: 1.5,
  },
];
