import { Map } from 'leaflet';
import React, { createContext, ReactNode, useState } from 'react';

type MapContainerRefTypes = {
  mapContainerRef: Map | undefined;
  setMapContainerRef: (arg: Map) => void;
};
export const MapContainerRefContext = createContext({} as MapContainerRefTypes);

type MapContainerRefProviderProps = { children: ReactNode };

// simple context to store and provide MapContainer ref from leaflet, so leaflet methods can be used and accessed outside of MapContainer component
const MapContainerRefProvider = ({ children }: MapContainerRefProviderProps) => {
  const [mapContainerRef, setMapContainerRef] = useState<Map | undefined>(undefined); // passed to components outside of mapContainer so they can access leaflet methods

  const value: MapContainerRefTypes = { mapContainerRef, setMapContainerRef };

  return (
    <MapContainerRefContext.Provider value={value}>{children}</MapContainerRefContext.Provider>
  );
};

export default MapContainerRefProvider;
