import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import { format } from 'date-fns';
import React from 'react';
import { colours } from 'styling/colours';
import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';
import errorIcon from 'assets/icons/error-2.svg';

export default function BatteryReadoutSpan({
  asset,
  darkText,
}: {
  asset: EstimateType;
  darkText?: boolean;
}) {
  if (!asset.assigned_beacon || !asset.assigned_beacon.battery)
    return (
      <span className="u-no-pointer-event" style={{ color: darkText ? 'black' : 'white' }}>
        ––
      </span>
    );
  const hasTimestamp = asset.last_location_update_timestamp !== null;

  if (asset.assigned_beacon.battery <= LOW_BATTERY_THRESHOLD) {
    return (
      <span
        className="u-no-pointer-event"
        style={{
          color: `${colours.error}`,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {getValueSafely(asset.assigned_beacon.battery, '%')}
        <img
          style={{
            marginLeft: '10px',
            width: '18px',
          }}
          src={errorIcon}
          alt="low battery"
        />
        {hasTimestamp && (
          <span style={{ color: darkText ? 'black' : 'white', marginLeft: '10px' }}>
            {`  (${format(new Date(asset.last_location_update_timestamp), 'dd/MM/yyyy - HH:mm')})`}
          </span>
        )}
      </span>
    );
  } else {
    return (
      <span className="u-no-pointer-event" style={{ color: darkText ? 'black' : 'white' }}>
        {getValueSafely(asset.assigned_beacon.battery, '%')}

        {hasTimestamp && (
          <span style={{ color: darkText ? 'black' : 'white', marginLeft: '10px' }}>
            {`  (${format(new Date(asset.last_location_update_timestamp), 'dd/MM/yyyy - HH:mm')})`}
          </span>
        )}
      </span>
    );
  }
}
