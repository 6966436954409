import React, { useState } from 'react';
import mobileLegendIcon from '../../icons/mobile-legend.svg';
import {
  StyledKeyLegend,
  StyledKeyLegendMobileCloseButton,
  StyledMobileLegendButton,
} from 'views/MapPage/Styled';
import KeyLegendPanelContents from '../KeyLegendPanelContents';

export default function MobileLegendButtonPanel() {
  const [mobileLegendOpen, setMobileLegendOpen] = useState(false);

  function handleLegendClick() {
    setMobileLegendOpen(!mobileLegendOpen);
  }

  return mobileLegendOpen ? (
    <>
      <StyledKeyLegend data-test-id="legend">
        <StyledKeyLegendMobileCloseButton onClick={() => setMobileLegendOpen(false)} />

        <KeyLegendPanelContents />
      </StyledKeyLegend>
    </>
  ) : (
    <StyledMobileLegendButton data-test-id="mobile-legend-button" onClick={handleLegendClick}>
      <img src={mobileLegendIcon} alt="mobile legend" />
    </StyledMobileLegendButton>
  );
}
