import { useContext, useEffect, useRef, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import AssetsApi from 'api/assets/assets.api';
import { AssetsContext } from '../contexts/assets.context';
import {
  getIntersectionFilteredAssets,
  getAssetsWithBuildingAndEstimate,
  getOutdoorEstimates,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenuesContext } from '../contexts/venues.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';
import useHandleSearchResults from './useHandleSearchResults';
import { EstimateType } from 'types/Estimate';

export default function useCallSearchAssets() {
  const [noAssetResultsReturned, setNoAssetResultsReturned] = useState(false);

  const { setRawIndoorAssets, setRawOutsideMappedBuildingsAssets } = useContext(AssetsContext);
  const authContext = useContext(AuthenticationContext).authState;
  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const { levelsFeatures } = useContext(VenueFilesContext);

  const { setApiCallInProgress, setClickedMarkerID } = useContext(MapControlContext);
  const {
    setDoNotCalculateFloor,
    setInSearchMode,
    setInFilterMode,
    setSearchAPIcallInProgress,
    cachedEstimateRef,
  } = useContext(SearchFilterContext);
  const { panMapToProvidedCombinedLevelsAssetUserBounds } = useMapHooksExternalMapRef();
  const { handleUserAssetSearchResults } = useHandleSearchResults();

  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);
  const abortControllerRef: any = useRef(null);

  const callSearchAssets = (term: string) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;
    const termTrimmed = term.trim();

    abortControllerRef.current = new AbortController();
    cachedEstimateRef.current = null; // reset cached asset.
    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);
    setDoNotCalculateFloor(false);
    setInSearchMode(false);

    return assetsApi
      .getRegisteredBeaconMapAssets(selectedVenueID, termTrimmed, abortControllerRef.current)
      .then((res) => {
        const { items } = res.data;
        const estimatesWithBuilding = getAssetsWithBuildingAndEstimate(items);
        const outdoorEstimates = getOutdoorEstimates(items) as EstimateType[];
        const combinedEstimates = [...estimatesWithBuilding, ...outdoorEstimates];

        setInSearchMode(true);
        setInFilterMode(false);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setClickedMarkerID('');
        setRawIndoorAssets(estimatesWithBuilding);
        setRawOutsideMappedBuildingsAssets(outdoorEstimates);

        handleUserAssetSearchResults(combinedEstimates, estimatesWithBuilding, outdoorEstimates);
        return combinedEstimates;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        console.log(err.message);
        if (abortControllerRef.current?.signal.aborted) return; // prevent alert if API call was cancelled.
        alertErrorMessage(`Search Failed`);
      });
  };

  const callFilterAssets = (
    inputRef: any,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
    assetStatusQueryArray: string[],
  ) => {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;
    abortControllerRef.current = new AbortController();

    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);

    return assetsApi
      .getRegisteredBeaconFilteredMapAssets(
        selectedVenueID,
        assetOwnerQueryArray,
        assetSubtypeQueryArray,
        assetStatusQueryArray,
      )
      .then((res) => {
        const { items } = res.data;
        const filteredItems = getAssetsWithBuildingAndEstimate(items);
        const outdoorItems = getOutdoorEstimates(items) as EstimateType[];
        const intersectionalFilteredIndoorAssets = getIntersectionFilteredAssets(
          filteredItems,
          assetOwnerQueryArray,
          assetSubtypeQueryArray,
          assetStatusQueryArray,
        );
        const intersectionalFilteredOutdoorAssets = getIntersectionFilteredAssets(
          outdoorItems,
          assetOwnerQueryArray,
          assetSubtypeQueryArray,
          assetStatusQueryArray,
        );
        const combinedEstimates = [
          ...intersectionalFilteredIndoorAssets,
          ...intersectionalFilteredOutdoorAssets,
        ];

        setRawIndoorAssets(intersectionalFilteredIndoorAssets);
        setRawOutsideMappedBuildingsAssets(intersectionalFilteredOutdoorAssets);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setInFilterMode(true);
        setInSearchMode(false);

        // keep focus on  input after api call, so we can close menu properly.
        if (inputRef && inputRef.current) inputRef.current.focus();

        if (combinedEstimates.length > 0) {
          // only re-center map if assets returned from search.
          panMapToProvidedCombinedLevelsAssetUserBounds(combinedEstimates, levelsFeatures);
        }
        if (combinedEstimates.length === 1) {
          // cache single asset estimate after filter in ref
          // so we can compare this in polling.
          // note the single estimate behaviour in filters differs from the search in that we do not select the building.

          cachedEstimateRef.current = combinedEstimates[0];
        }

        return combinedEstimates;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        console.log(err.message);
        if (abortControllerRef.current?.signal.aborted) return; // prevent alert if API call was cancelled.
        alertErrorMessage('Search Failed');
      });
  };

  useEffect(() => {
    // useEffect to abort api call if component unmounts.
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    callSearchAssets,
    callFilterAssets,
    noAssetResultsReturned,
    setNoAssetResultsReturned,
  };
}
