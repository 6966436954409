import { AssetConfigContext } from 'contexts/assetConfig.context';
import { AssetStatesContext } from 'contexts/assetStates.context';
import React, { useContext } from 'react';
import { getReactSelectOptionsFromAssetStatusData } from 'views/MapPage/utils/utils';
import { ReactSelectType } from '../../VenueSelectDropdownControl';

export default function DropdownControlReadoutString({
  checkedList,
  checkAll,
  type,
}: {
  checkedList: ReactSelectType[];
  checkAll: boolean;
  type: string;
}) {
  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);
  const { availableAssetStates } = useContext(AssetStatesContext);

  let readoutString = '';
  const singleSelected = checkedList.length === 1;

  function getReadoutString(type: string, value: any): string {
    let derivedOption = null;
    switch (type) {
      case 'owner':
        derivedOption = assetOwners;
        break;
      case 'subtype':
        derivedOption = assetSubtype;
        break;
      case 'status':
        derivedOption = getReactSelectOptionsFromAssetStatusData(availableAssetStates);
        break;
      default:
        break;
    }

    return derivedOption.filter((val: any) => val.value === value.value)[0].label;
  }
  if (checkAll) readoutString = 'All';
  else if (checkedList.length === 0) {
    readoutString = ` (0)`;
  } else {
    readoutString = singleSelected ? getReadoutString(type, checkedList[0]) : '';
  }

  return (
    <>
      {readoutString}
      {!singleSelected && checkedList.length !== 0 && ` (${checkedList.length})`}
    </>
  );
}
