import React from 'react';
import { UserType } from 'types/userInfo';
import DefaultCell from '../cells/DefaultCell';
import LocationCellRecentUser from '../cells/LocationCellRecentUser';
import RecentUserToolTipCell from '../cells/RecentUserToolTipCell';
import RecentUsersLastSeenCell from '../cells/RecentUsersLastSeenCell';

export const recentUsersColumns = [
  {
    name: 'NAME',
    selector: (row: UserType) => <DefaultCell value={row?.name} />,
    sortable: false,
    overflow: 'false',
    grow: 1.5,
  },
  {
    name: 'LOCATION',
    selector: (row: UserType) => <LocationCellRecentUser user={row} />,
    sortable: true,
    overflow: 'false',
    grow: 2,
    sortField: 'region_name',
  },
  {
    name: 'LAST SEEN',
    selector: (row: UserType) => <RecentUsersLastSeenCell timestamp={row.last_update_timestamp} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'last_update',
  },
  {
    sortable: false,
    maxWidth: '15px',
    minWidth: '65px',
    cell: (row: UserType) => <RecentUserToolTipCell user={row} />,
    sortField: '',
  },
];
