import { GeoJSON } from 'react-leaflet';
import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { getUnitFeatureDataFromLevelIDs } from '../utils/utils';
import { VenueFilesContext } from '../contexts/venuefiles.context';

// this component will render a single geoJson layer from unit.geojson, with feature data filtered by level id that was clicked.
function UnitGeoJsonLayer() {
  const { floorSelectedIDArray } = useContext(MapControlContext);
  const { unitFeatures } = useContext(VenueFilesContext);
  const [dataToShow, setDataToShow] = useState(unitFeatures); // need better way to set this initially.

  const keyRef: any = useRef();

  useLayoutEffect(() => {
    // filter all units by selected floor id.
    // update when new floor selected.
    // note that we store the key in a ref, so that we dont update it and cause a re-render.
    const unitFeaturesFilteredBySelectedFloor = getUnitFeatureDataFromLevelIDs(
      floorSelectedIDArray,
      unitFeatures,
    );
    setDataToShow(unitFeaturesFilteredBySelectedFloor);

    keyRef.current = Math.random() * 1000;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorSelectedIDArray]);

  return (
    <GeoJSON
      style={{
        fillColor: '#ffffff',
        fillOpacity: 1,
        color: '#B3C5DA',
        weight: 1.5,
      }}
      key={keyRef.current}
      data={dataToShow}
    />
  );
}

export default React.memo(UnitGeoJsonLayer);
