import styled from 'styled-components';
import { colours } from 'styling/colours';

export const UserLogsStyled = styled.div`
  .header-title {
    margin-right: 10px;
  }
`;

export const TopBarContainerStyle = styled.div`
  background: ${colours.disableSelect};
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
`;
