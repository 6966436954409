import FileUploadModal from 'components/FileUpload/Modals/FileUploadModal';
import ProcessingErrorModal from 'components/FileUpload/Modals/ProcessingErrorModal';
import React, { useState } from 'react';
import StyledUploadFormCollapsedButton from '../components/StyledUploadFormCollapsedButton';
import UploadFileForm from '../Form/UploadFileForm';
import { UploadFailedStatusMessageType } from '../type';

export default function BeaconUploadFormController() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [formCollapsed, setFormCollapsed] = useState(false);
  const [proccesingErrorModalOpen, setProccesingErrorModalOpen] = useState(false);
  const [processingErrors, setProcessingErrors] = useState<UploadFailedStatusMessageType[] | []>(
    [],
  );

  return (
    <>
      {formCollapsed ? (
        <StyledUploadFormCollapsedButton setFormCollapsed={setFormCollapsed} />
      ) : (
        <UploadFileForm
          setFormCollapsed={setFormCollapsed}
          isCollapsable
          setSelectedFile={setSelectedFile}
          setUploadModalOpen={setUploadModalOpen}
        />
      )}
      {uploadModalOpen && selectedFile && (
        <FileUploadModal
          isAssetUpload={false}
          setUploadModalOpen={setUploadModalOpen}
          setProcessingErrors={setProcessingErrors}
          selectedFile={selectedFile}
          setProccesingErrorModalOpen={setProccesingErrorModalOpen}
        />
      )}

      {proccesingErrorModalOpen && (
        <ProcessingErrorModal
          setProccesingErrorModalOpen={setProccesingErrorModalOpen}
          processingErrors={processingErrors}
        />
      )}
    </>
  );
}
