import config from 'react-global-configuration';

class VenueApi {
  protected axiosConfig: any;

  public constructor(token: string) {
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/x-uri',
        Authorization: `Bearer ${token}`,
      },
    };
  }

  public getVenueFile = async (venueID: string, fileName: string): Promise<any> => {
    return (
      // initial request to venues API will return a text URL with the location of the geojson file on an S3 bucket.
      fetch(
        `${
          config.get().VENUE_API_URL
        }/${venueID}/dataset/physical_map/latest/file/buildings/${fileName}`,
        this.axiosConfig,
      )
        .then((res) => res.text())
        .then((url) => {
          // we then perform a new fetch with this file and return the res.
          return fetch(url)
            .then((res) => res.json())
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };

  public getVenuesList = async (): Promise<any> => {
    return fetch(`${config.get().VENUE_API_URL}`, this.axiosConfig)
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log(err);
      });
  };
}
export default VenueApi;
