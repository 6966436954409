import { getCognitoDetailsFromConfigBootstrap, getMappedUrl } from 'utils/utils';

// reducer to encapsulate ILS application config that will be stored in react globol config.
// so it can be accessed by components and non component functions
const configReducer = (state: any, action: any): any => {
  const { data } = action;

  switch (action.type) {
    case 'BOOTSTRAP': {
      const { cognitoUserPoolID, cognitoDomain, cognitoRegion } =
        getCognitoDetailsFromConfigBootstrap(data);

      return {
        ...state,
        COGNITO_CLIENT_ID: data.client_id,
        COGNITO_REGION: cognitoRegion,
        COGNITO_USERPOOL_ID: cognitoUserPoolID,
        COGNITO_DOMAIN: cognitoDomain,
      };
    }

    case 'LOCAL': {
      return {
        ...state,
        DATA_API_URL: getMappedUrl(data.DATA_API_URL),
        CONFIG_API_URL: getMappedUrl(data.CONFIG_API_URL),
      };
    }

    case 'SYSTEM_SERVICES': {
      return {
        ...state,
        HELP_URL: getMappedUrl(data.HELP_URL),
        VENUE_API_URL: getMappedUrl(data.VENUE_API_URL),
      };
    }

    case 'AUTHENTICATION': {
      return {
        ...state,
        SHOULD_USE_COGNITO: data.SHOULD_USE_COGNITO,
      };
    }

    default:
      throw new Error();
  }
};

export default configReducer;
