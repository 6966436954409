import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <main className="u-flex-center">
      <div className="container">
        <h1>Page Not Found</h1>

        <br />
        <Link to="/">Go Back</Link>
      </div>
    </main>
  );
}
