import React, { useContext, useState } from 'react';
import { colours } from 'styling/colours';
import styled from 'styled-components';
import bluemapIcon from './icons/bluemap.svg';
import { getIdToken, getWindowURL } from 'utils/utils';
import LoadingDots from 'components/Loading/LoadingDots';
import { AuthenticationContext } from 'contexts/authentication.context';
import { alertErrorMessage } from 'utils/alerts';
import { EstimateType } from 'types/Estimate';
import AssetsApi from 'api/assets/assets.api';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
  height: 40px;
  font-size: 15px;
  text-align: center;
  background: #f6f7f9;
  outline: none;
  border: none;
  margin: 15px 0 5px 0;
  color: ${colours.secondary};
  text-decoration: underline;
  img {
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
    background: #f6f7f9;
    text-decoration: underline;
  }
`;

export default function ViewAssetOnMapLink({ assetEstimate }: { assetEstimate: EstimateType }) {
  const [fetchingEstimate, setFetchingEstimate] = useState(false);
  const { authState } = useContext(AuthenticationContext);
  if (!assetEstimate || !assetEstimate.assigned_beacon) return null;
  // simple a tag link to render on asset detail flyout with URL params.
  // will not render without required params.

  const assetIsOutdoor = assetEstimate.location.is_outdoors;
  const venueID = assetEstimate.location.venue.id;
  const buildingID = assetEstimate.location?.building_level?.possible_buildings[0]?.id;
  const beaconID = assetEstimate.assigned_beacon.id;
  const levelID = assetEstimate.location.building_level?.level.id;
  const allRequiredValuesExist = assetIsOutdoor
    ? beaconID !== null
    : venueID !== null && buildingID !== null && beaconID !== null && levelID !== null;

  function handleClick(asset: EstimateType) {
    if (!asset.assigned_beacon) return;
    const token = getIdToken(authState);
    const assetsApi = new AssetsApi(token);

    setFetchingEstimate(true);

    assetsApi
      .getLocalizedAssetById(assetEstimate.asset_id)
      .then((res: any) => {
        const locatedEstimate = res.items[0];
        const assetIsOutdoor = locatedEstimate.location?.is_outdoors;
        const resVenueID = locatedEstimate.location?.venue.id;
        let constructedURL = '';

        if (assetIsOutdoor) {
          constructedURL = `${getWindowURL()}/map?is_outdoors=true&venue_id=${resVenueID}&beacon_id=${beaconID}`;
        } else {
          const resBuildingID = locatedEstimate.location?.building_level.possible_buildings[0].id;
          const resLevelID = locatedEstimate.location.building_level?.level.id;

          constructedURL = `${getWindowURL()}/map?venue_id=${resVenueID}&building_id=${resBuildingID}&level_id=${resLevelID}&beacon_id=${beaconID}`;
        }

        window.open(constructedURL, '_blank');
        setFetchingEstimate(false);
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('Error fetching estimate details');
      });
  }

  return allRequiredValuesExist ? (
    <StyledButton onClick={() => handleClick(assetEstimate)}>
      {fetchingEstimate ? (
        <LoadingDots />
      ) : (
        <>
          <img src={bluemapIcon} alt="view on map" />
          View asset on the map
        </>
      )}
    </StyledButton>
  ) : null;
}
