import { FullConfigContext } from 'contexts/fullConfig.context';
import { formatDistanceToNowStrict } from 'date-fns';
import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { assetEstimateIsNotFresh, replaceWordsInDistanceString } from 'utils/utils';

const LastSeenCell = ({ timestamp }: { timestamp: string }) => {
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);

  if (!timestamp) return <span>––</span>;
  const distance = formatDistanceToNowStrict(new Date(timestamp), {
    roundingMethod: 'floor',
  });

  if (assetEstimateIsNotFresh(timestamp, freshPeriodThresholdSeconds)) {
    return (
      <span className="u-no-pointer-event" style={{ color: `${colours.error}` }}>
        {replaceWordsInDistanceString(distance)} ago
      </span>
    );
  }
  return <span className="u-no-pointer-event">{replaceWordsInDistanceString(distance)} ago</span>;
};

export default LastSeenCell;
