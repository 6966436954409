import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { userPermissionType } from 'types/permissions';
import { getArraysIntersection } from 'utils/utils';

export default function UserPermissionCheckRouteWrapper({
  children,
  routeUserPermissions,
}: {
  children: React.ReactNode;
  routeUserPermissions?: userPermissionType[];
}): any {
  const { userAccessPermissionsArray } = useContext(FullConfigContext);
  const navigate = useNavigate();
  let routeHasPermissions = true;

  if (routeUserPermissions) {
    routeHasPermissions =
      getArraysIntersection(userAccessPermissionsArray, routeUserPermissions).length !== 0;
  }

  useEffect(() => {
    // if no permissions returned from config, redirect user to no-permissions page.
    if (userAccessPermissionsArray.length === 0) {
      navigate('/no-permission');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return routeHasPermissions ? children : <Navigate to="/no-feature-permission" />;
}
