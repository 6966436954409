import React from 'react';

import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';

export default function AccuracyRangeSpan({ assetDetails }: { assetDetails: EstimateType }) {
  const accuracyRangeString = getValueSafely(
    assetDetails.location?.uncertainty?.toFixed(1),
    ' metres',
  );

  return <span>{accuracyRangeString}</span>;
}
