import { useContext } from 'react';
import { PageInfoContext } from 'contexts/pageInfo.context';
const usePageInfo = () => {
  const context = useContext(PageInfoContext);
  if (context === undefined) {
    throw new Error('usePageInfo must be used within a PageInfoContext');
  }
  return context;
};
export default usePageInfo;
