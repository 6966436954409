import { PERMISSIONS } from 'config/permissions';
import React, { useContext } from 'react';
import { GeoJSON } from 'react-leaflet';
import { clearWindowURLParams } from 'utils/utils';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import BuildingAssetTotalCountTooltip from '../components/ToolTips/BuildingAssetTotalCountTooltip';
import BuildingLabelTooltip from '../components/ToolTips/BuildingLabelTooltip';
import BuildingUsersTotalCountTooltip from '../components/ToolTips/BuildingUsersTotalCountTooltip';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import useMapHooks from '../hooks/useMapHooks';
import { getLevelFeatureDataArrayFromBuildingID } from '../utils/utils';

// this layer will render the initial buildings outline GEOJSON.
// once a building is clicked, we render the unitGeoJsonLayer over the top of this, that will be dependant on floor selected.
function BuildingsGeoJsonLayer() {
  const { buildingFeatures, levelsFeatures } = useContext(VenueFilesContext);
  const {
    setBuildingSelectedID,
    buildingSelectedID,
    setClickedMarkerID,
    showAssetsChecked,
    showUsersChecked,
    apiCallInProgress,
    setSelectedFloorOrdinal,
  } = useContext(MapControlContext);
  const { fitMapToBoundsOfGeoJSON } = useMapHooks();

  function handleBuildingGeoJsonClick(buildingID: string, buildingLevelData: any) {
    // clicking on a building will pass the buildingID, which we then use to render the unit GeoJSON.
    const newBuildingSelected = buildingID !== buildingSelectedID;

    setBuildingSelectedID(buildingID);

    if (newBuildingSelected) {
      fitMapToBoundsOfGeoJSON(buildingLevelData); // center map to feature bounds.
      setClickedMarkerID(''); // reset id, so we hide info boxes when we change building selection.
      setSelectedFloorOrdinal(null); // reset selected floor to null on building change, so that floor is calculated correctly in hook.
    }
    clearWindowURLParams();
  }

  // note we are using buildingFeatures to map over iterations, but levelFeatures to render the actual geojson.
  // this is because buildingFeatures.json does not have geometry data, but contains a building ID that can be referenced in levels to obtain level geometry.
  // we then loop over each building, then render geoJson with all combined level data for that building.
  // this will render the levels outline of each building
  return buildingFeatures.map((buildingFeature: any) => {
    const buildingIDLevelsData = getLevelFeatureDataArrayFromBuildingID(
      buildingFeature.id,
      levelsFeatures,
    );
    const buildingLevelDataFirstIndex = buildingIDLevelsData[0]; // use first index as reference for positioning of tooltips etc.
    const buildingIsSelected = buildingSelectedID === buildingFeature.id;
    const buildingName = buildingFeature.properties.name.en;
    const showAssetTotalTip = !apiCallInProgress && showAssetsChecked;

    return (
      <div key={buildingFeature.id}>
        <BuildingLabelTooltip buildingName={buildingName} feature={buildingLevelDataFirstIndex} />

        {showAssetTotalTip && (
          <ComponentFeaturePermissionRenderWrapper
            featurePermissions={PERMISSIONS.FEATURE.ASSET_TRACKING}
          >
            <BuildingAssetTotalCountTooltip feature={buildingLevelDataFirstIndex} />
          </ComponentFeaturePermissionRenderWrapper>
        )}
        {showUsersChecked && (
          <ComponentFeaturePermissionRenderWrapper
            featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
          >
            <BuildingUsersTotalCountTooltip feature={buildingLevelDataFirstIndex} />
          </ComponentFeaturePermissionRenderWrapper>
        )}

        <GeoJSON
          eventHandlers={{
            click: () => {
              handleBuildingGeoJsonClick(buildingFeature.id, buildingLevelDataFirstIndex);
            },
          }}
          style={{
            fillColor: '#E6F0FC',
            fillOpacity: buildingIsSelected ? 0 : 1, // note we set opacity to 0 of building selected, as units covers this.
            color: '#719ED3',
          }}
          data={buildingIDLevelsData}
        />
      </div>
    );
  });
}

export default React.memo(BuildingsGeoJsonLayer);
