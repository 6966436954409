import React from 'react';
import { NoFilterResultsPanelStyledDiv } from '../Styled';

export default function NoFilterResultsPanel() {
  return (
    <NoFilterResultsPanelStyledDiv>
      <span>Sorry, no assets were found using these filters</span>
    </NoFilterResultsPanelStyledDiv>
  );
}
