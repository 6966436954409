import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { getIdToken, getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';

import infoImg from '../../icons/infoBg.svg';
import { StyledTable } from 'views/AssetListPage/components/styles';
import AccuracyRangeSpan from 'views/AssetListPage/components/content/AccuracyRangeSpan';
import LocationSection from 'views/AssetListPage/components/content/LocationSection';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import styled from 'styled-components';
import AssetStatusCell from 'components/DataTable/cells/AssetStatusCell';
import { ButtonStyledAsLink } from 'components/Button/styled';
import { AssetStatesContext } from 'contexts/assetStates.context';
import { alertErrorMessage } from 'utils/alerts';
import AssetStatusUpdateModalController from 'components/Modals/AssetStatusUpdateModal/AssetStatusUpdateModalController';
import { AuthenticationContext } from 'contexts/authentication.context';
import AssetsApi from 'api/assets/assets.api';
import { FullConfigContext } from 'contexts/fullConfig.context';

const StyledAssetStatusCell = styled.div`
  display: flex;
  justify-content: space-between;
`;
function getCreatedDateString(asset: EstimateType) {
  if (!asset.creation_timestamp) return '––';

  const addedOnDate = asset.creation_timestamp
    ? format(new Date(asset.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  return addedOnDate;
}

const MobileAssetInfoFlyoutContent = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: EstimateType;
}) => {
  const [currentAsset, setCurrentAsset] = useState(selectedAssetDetails);

  const deviceSource = selectedAssetDetails.assigned_beacon!.import_info.source;
  const [updateAssetStatusModalOpen, setUpdateAssetStatusModalOpen] = useState(false);
  const { assetStatusApisHaveSucceeded } = useContext(AssetStatesContext);
  const { availableFeaturesObject } = useContext(FullConfigContext);

  const { authState } = useContext(AuthenticationContext);

  function handleSuccessfulUpdate() {
    const idToken = getIdToken(authState);
    const assetsApi = new AssetsApi(idToken);

    assetsApi
      .getLocalizedAssetById(currentAsset.asset_id)
      .then((res) => {
        const returnedAsset: EstimateType = res.items[0];
        setCurrentAsset(returnedAsset);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  function handleCloseModal() {
    setUpdateAssetStatusModalOpen(false);
  }
  function handleUpdateAssetStatusModal() {
    // alert user and dont open modal if required apis have failed
    if (!assetStatusApisHaveSucceeded) {
      alertErrorMessage("Asset Status can't be updated right now, try again in a few moments");
      return;
    }
    setUpdateAssetStatusModalOpen(true);
  }

  useEffect(() => {
    // update current asset with new asset details on poll.
    setCurrentAsset(selectedAssetDetails);
  }, [selectedAssetDetails]);

  return (
    <>
      <StyledTable data-test-id="asset-details-id">
        <tbody>
          {availableFeaturesObject.asset_status && (
            <tr>
              <td>Asset Status:</td>
              <td>
                <StyledAssetStatusCell>
                  <span>
                    <AssetStatusCell
                      value={currentAsset.status_assignment?.asset_status?.description}
                    />
                  </span>

                  <ButtonStyledAsLink onClick={() => handleUpdateAssetStatusModal()}>
                    Update Asset Status
                  </ButtonStyledAsLink>
                </StyledAssetStatusCell>
              </td>
            </tr>
          )}
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(currentAsset.asset_id)}</td>
          </tr>
          <tr>
            <td>Added On:</td>
            <td>{getCreatedDateString(currentAsset)}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              <DefaultCell value={currentAsset.owner.name} />
            </td>
          </tr>
          <tr>
            <td valign="top">Description:</td>
            <td id="assetDetailsDescription">{getValueSafely(currentAsset.description)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <div className="information-sentance">
        <img src={infoImg} alt="information" />
        <p>Please note, estimated information was derived from the beacon.</p>
      </div>
      <StyledTable data-test-id="asset-details-estimate">
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={currentAsset.last_location_update_timestamp} />
              {currentAsset.last_location_update_timestamp &&
                ` ${getLastSeenString(currentAsset.last_location_update_timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={currentAsset} />{' '}
            </td>
          </tr>

          <tr>
            <td>Battery:</td>
            <td>
              <BatteryReadoutSpan asset={currentAsset} darkText={true} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              <LocationSection assetDetails={currentAsset} />
            </td>
          </tr>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(currentAsset.assigned_beacon!.id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>
              {getValueSafely(currentAsset.assigned_beacon!.beacon_type)}
              {deviceSource && ` - ${deviceSource}`}
            </td>
          </tr>
        </tbody>
      </StyledTable>
      {updateAssetStatusModalOpen && (
        <AssetStatusUpdateModalController
          selectedAssetDetails={currentAsset}
          closeHandler={handleCloseModal}
          callback={handleSuccessfulUpdate}
        />
      )}
    </>
  );
};

export default MobileAssetInfoFlyoutContent;
