import { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';

import { UserType } from 'types/userInfo';
import { EstimateType } from 'types/Estimate';
import { checkEstimatesAreOfSameBuilding } from '../utils/utils';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';

export default function useHandleSearchResults() {
  const { buildingIsSelected, setBuildingSelectedID, buildingSelectedID } =
    useContext(MapControlContext);
  const { cachedEstimateRef } = useContext(SearchFilterContext);
  const { levelsFeatures } = useContext(VenueFilesContext);

  const {
    fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors,
    fitMapToBoundsOfBuildingLevelsFromBuildingID,
    panMapToCombinedAssetsInMultipleBuildings,
    panMapToBuildingLevelsAndEstimates,
  } = useMapHooksExternalMapRef();

  function handleUserAssetSearchResults(
    combinedEstimates: UserType[] | EstimateType[],
    estimatesWithBuilding: UserType[] | EstimateType[],
    outdoorEstimates: UserType[] | EstimateType[],
  ) {
    if (combinedEstimates.length < 1) {
      return; // do nothing
    }
    if (combinedEstimates.length === 1) {
      // cache single asset estimate after search in ref
      // so we can compare this in polling.

      const estimateHasBuilding =
        !combinedEstimates[0].location.is_outdoors &&
        combinedEstimates[0].location.building_level !== undefined;

      if (!buildingIsSelected && estimateHasBuilding) {
        // if one result is returned, no building is selected & this asset has a building,
        // programatically select the building that this asset belongs to
        // so the user can see it.
        // NOTE that this only sets the associated builiding GeoJSON to display,
        // the actual programatic floor change is handled in useFloorSelectionHook.
        // which uses buildingSelectedID as a dep.

        const singleMatcheUserEstimateBuildingID =
          estimatesWithBuilding[0].location.building_level.possible_buildings[0].id;

        setBuildingSelectedID(singleMatcheUserEstimateBuildingID);
      }

      // cache single estimate after filter in ref
      // so we can compare this in polling.
      // note the single estimate behaviour in filters differs from the search in that we do not select the building.
      cachedEstimateRef.current = combinedEstimates[0];
    }
    // execute appropriate panning function, depending if building is selected or not.
    if (buildingSelectedID) {
      fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors(
        levelsFeatures,
        combinedEstimates,
        buildingSelectedID,
      );
    } else if (!buildingIsSelected) {
      // ensure to only run if there are no outdoor assets.
      if (outdoorEstimates.length < 1) {
        const estimatesAreOfSameBuilding = checkEstimatesAreOfSameBuilding(estimatesWithBuilding);

        if (estimatesAreOfSameBuilding) {
          // if they are all of the same building, pan to the outline of this building,
          const buildingID =
            estimatesWithBuilding[0].location.building_level.possible_buildings[0].id;

          fitMapToBoundsOfBuildingLevelsFromBuildingID(buildingID, levelsFeatures);
        } else {
          // otherwise, pan to the combined asset bounds.
          panMapToCombinedAssetsInMultipleBuildings(
            estimatesWithBuilding,
            outdoorEstimates,
            levelsFeatures,
          );
        }
      } else if (outdoorEstimates.length === 1) {
        panMapToBuildingLevelsAndEstimates(outdoorEstimates, levelsFeatures);
      } else {
        // if there are outdoor and indoor assets,
        panMapToCombinedAssetsInMultipleBuildings(
          estimatesWithBuilding,
          outdoorEstimates,
          levelsFeatures,
        );
      }
    }
  }
  return {
    handleUserAssetSearchResults,
  };
}
