import React, { ReactElement } from 'react';
import closeImg from 'assets/icons/close.svg';
import successIcon from 'assets/icons/success-bang.svg';
import { message } from 'antd';
import { StyledAlert } from './StyledAlert';

export default function SuccessAlert({ messageEl }: { messageEl: string | ReactElement }) {
  function handleClose() {
    message.destroy();
  }

  return (
    <StyledAlert>
      <img src={successIcon} alt="success" />
      <span>{messageEl}</span>
      <button onClick={handleClose}>
        <img src={closeImg} alt="close" />
      </button>
    </StyledAlert>
  );
}
