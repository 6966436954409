import { latLngBounds, LatLngExpression } from 'leaflet';
import { useMap } from 'react-leaflet';
import {
  getCombinedLevelAssetUserBounds,
  getCoordsFromAssets,
  getCoordsFromUsers,
} from '../utils/utils';
import L from 'leaflet';

import { UserType } from 'types/userInfo';
import { useContext } from 'react';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import { AssetsContext } from '../contexts/assets.context';
import { UsersContext } from '../contexts/users.context';
import { EstimateType } from 'types/Estimate';

export default function useMapHooks() {
  const map = useMap(); // note: requires components to be decendant of mapcontainer to invoke this
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { rawIndoorAssets, rawOutsideMappedBuildingsAssets } = useContext(AssetsContext);
  const { usersMarkers, usersOutsideMappedBuildingsMarkers } = useContext(UsersContext);

  // for obtaining latlong at cursor.
  // useMapEvents({
  //   click: (ev) => console.log(ev.latlng),
  // });

  function recenterMapToCombinedMarkerBounds(assets: EstimateType[], users: UserType[]) {
    if (assets.length < 1 && users.length < 1) return;

    const assetCoords: any = getCoordsFromAssets(assets);
    const userCoords: any = getCoordsFromUsers(users);
    const combinedCoords = [...assetCoords, ...userCoords];
    const bounds = latLngBounds(assetCoords[0]);

    bounds.extend(combinedCoords);

    map.panInsideBounds(bounds, { animate: false });
  }

  function recenterMapToAssetMarkerBounds(assets: EstimateType[]) {
    if (assets.length < 1) return;

    const assetCoords: any = getCoordsFromAssets(assets);
    const bounds = latLngBounds(assetCoords[0]);

    bounds.extend(assetCoords);

    map.panInsideBounds(bounds, {
      noMoveStart: true,
    });
  }

  function removeMapLayerByClassName(className: string) {
    // programatically remove map tooltips,
    map.eachLayer((layer: any) => {
      if (layer.options && layer.options.className === className) {
        map.removeLayer(layer);
      }
    });
  }

  function fitMapToBoundsOfGeoJSON(geoJSONData: any, maxZoom: number = 21) {
    const geojsonLayer = L.geoJSON(geoJSONData);
    map.fitBounds(geojsonLayer.getBounds(), { padding: [50, 50], maxZoom: maxZoom });
  }

  function panToLatLong(eTargetLatLong: LatLngExpression) {
    map.panTo(eTargetLatLong);
  }

  function zoomMapToSingleAsset(asset: EstimateType) {
    const { latitude, longitude } = asset.location.coordinates;
    map.flyTo([latitude, longitude], 20);
  }

  function checkAssetLatLngIsWithinMapBounds(assetLatLongArr: any) {
    return map.getBounds().contains(assetLatLongArr);
  }

  function panMapToCombinedLevelsAssetUserBounds() {
    const combinedAssetBounds = [...rawIndoorAssets, ...rawOutsideMappedBuildingsAssets];
    const combinedUserBounds = [...usersMarkers, ...usersOutsideMappedBuildingsMarkers];
    const bounds = getCombinedLevelAssetUserBounds(
      combinedAssetBounds,
      combinedUserBounds,
      levelsFeatures,
    );

    map.fitBounds(bounds, { animate: true });
  }

  return {
    removeMapLayerByClassName,
    fitMapToBoundsOfGeoJSON,
    panToLatLong,
    zoomMapToSingleAsset,
    recenterMapToAssetMarkerBounds,
    recenterMapToCombinedMarkerBounds,
    checkAssetLatLngIsWithinMapBounds,
    panMapToCombinedLevelsAssetUserBounds,
  };
}
