import styled from 'styled-components';
import { colours } from 'styling/colours';

export const StyledText = styled.p`
  text-align: center;
  font-family: MuseoSans;
  color: #808285;
  line-height: 22px;
  font-size: 15px;
  a {
    color: #00aeef;
    text-decoration: underline;
    font-weight: 600;
  }
`;

export const StyledUploadFileForm = styled.form`
  position: relative;
  height: 180px;
  margin-bottom: -75px;
  width: 100%;
  // extend form to below container to allow for greater drop area
  .form-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 104px;
    background: #f5f5f6;
    border-radius: 7px;
    border: 1px #808285 dashed;
    padding: 10px 0;
    .label-button {
      display: inline;
      color: #00aeef;
      text-decoration: underline;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
    button.arrow-button {
      position: absolute;
      top: 3px;
      right: 3px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &.--dragging {
    .form-inner {
      background: #ecf5fa;
      border: 1px #00aeef dashed;
    }
  }
`;

export const StyledUploadFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colours.white};
  width: 100%;
  height: 145px;
  margin-bottom: 20px;
  padding: 0 20px;
`;

export const StyledArrowButton = styled.button`
  background: none;
  outline: none;
  border: 0;
`;
