import React, { useState } from 'react';
import { TopBarContainerStyle } from './Styled';
import Button from 'components/Button/Button';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { DownloadUserLogsModal } from './DownloadUserLogsModal';
import ComponentUserPermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentUserPermissionRenderWrapper';

type UserLogTopBarProps = {
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  isLoading?: boolean;
  onSearch: (value: string) => void;
};

export const UserLogTopBar = ({
  searchTerm,
  setSearchTerm,
  isLoading,
  onSearch,
}: UserLogTopBarProps) => {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const handleChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleDownloadLog = () => {
    setOpenDownloadModal(true);
  };

  return (
    <>
      <TopBarContainerStyle>
        <SearchInput
          value={searchTerm}
          onChange={handleChange}
          onSearch={onSearch}
          isLoading={isLoading}
        />
        <ComponentUserPermissionRenderWrapper userPermissions={'ILS:WRITE'}>
          <Button
            data-test-id="user-log-button"
            outline
            size="large"
            isLoading={isLoading}
            onClick={handleDownloadLog}
          >
            Download Custom Log
          </Button>
        </ComponentUserPermissionRenderWrapper>
      </TopBarContainerStyle>

      {openDownloadModal && <DownloadUserLogsModal onClose={() => setOpenDownloadModal(false)} />}
    </>
  );
};
