import React from 'react';
import Button from 'components/Button/Button';
import plusIcon from 'assets/icons/plus.svg';
import AntModalStyled from 'components/Modals/AntModalStyled';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';

type ModalProps = {
  closeModal: () => void;
  xClick: () => void;
  actionFunction: () => void;
};
const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${coloursNames.midGrey};
  border-radius: 50%;
  img {
    width: 20px;
  }
`;

const ConfirmCancelModal: React.FC<ModalProps> = (props: ModalProps) => {
  return (
    <AntModalStyled
      onCancel={() => props.xClick()}
      visible
      centered
      footer={null}
      maskClosable={false}
      style={{ maxHeight: '269px', height: '100%' }}
    >
      <header>
        <StyledIconWrapper>
          <img src={plusIcon} alt="Information" />
        </StyledIconWrapper>
        <h3>Add Asset</h3>
      </header>
      <p id="confirmCancelText" style={{ margin: '30px 0 60px 0', fontSize: '15px' }}>
        Your unsaved changes will be lost. Do you want to add asset before exiting this page?
      </p>
      <footer>
        <Button id="archiveAssetCancelButton" onClick={() => props.closeModal()} outline>
          Discard
        </Button>
        <Button id="archiveAssetArchiveButton" onClick={() => props.actionFunction()}>
          Add
        </Button>
      </footer>
    </AntModalStyled>
  );
};

export default ConfirmCancelModal;
