import React from 'react';
import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';

export default function LocationSpan({ asset }: { asset: EstimateType }) {
  if (asset.location !== undefined) {
    const assetIsOutdoor = asset.location.is_outdoors;
    return assetIsOutdoor ? (
      <span>
        <div>Outside Mapped Buildings</div>
        <div style={{ color: '#CCCCCB', margin: '5px 0' }}>Possible region(s):</div>
        <div>{getValueSafely(asset.location.region?.name)}</div>
      </span>
    ) : (
      <span>
        <div>
          {asset.location.building_level.level.name}
          <br />
          {asset.location.building_level.possible_buildings[0].name}
        </div>

        <div style={{ color: '#CCCCCB', margin: '5px 0' }}>Possible region(s):</div>
        <div>{getValueSafely(asset?.location?.region?.name)}</div>
      </span>
    );
  }
  return <span>––</span>;
}
