import React from 'react';
import { ReactSelectType } from '../../VenueSelectDropdownControl';

type DropdownMenuProps = {
  dropdownRef: any;
  type: string;
  checkAll: boolean;
  searchAPIcallInProgress: boolean;
  options: ReactSelectType[];
  checkedOptionsArray: ReactSelectType[];
  handleCheckAllChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};
export default function DropdownMenu({
  dropdownRef,
  type,
  checkAll,
  searchAPIcallInProgress,
  options,
  checkedOptionsArray,
  handleCheckAllChange,
  handleCheckboxChange,
}: DropdownMenuProps) {
  return (
    <div className="list-container" ref={dropdownRef}>
      <ul>
        <li tabIndex={0} role="button">
          <label htmlFor={`all${type}`} className={checkAll ? '--checked' : ''}>
            <span className="custom-checkbox" />
            All
            <input
              id={`all${type}`}
              type="checkbox"
              name={`all${type}`}
              checked={checkAll}
              value={`all${type}`}
              onChange={(ev) => handleCheckAllChange(ev)}
              disabled={searchAPIcallInProgress}
            />
          </label>
        </li>
        {options.map((option: ReactSelectType) => {
          const isChecked = checkedOptionsArray.some((optionObj: ReactSelectType) => {
            return optionObj.value === option.value;
          });

          return (
            <li key={option.value} role="button" tabIndex={0}>
              <label htmlFor={option.value} className={isChecked ? '--checked' : ''}>
                <span className="custom-checkbox" />
                {option.label}

                <input
                  id={option.value}
                  type="checkbox"
                  name={option.value}
                  onChange={(ev) => handleCheckboxChange(ev, option.value)}
                  disabled={searchAPIcallInProgress}
                />
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
