import { useContext } from 'react';
import { EstimateType, isEstimateType } from 'types/Estimate';
import { UserType } from 'types/userInfo';
import useMapHooks from './useMapHooks';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import useMapHooksExternalMapRef from './useMapHooksExternalMapRef';
import {
  checkEstimateIsOnSelectedFloor,
  checkEstimateIsInSameBuilding,
  checkEstimateLocationIsDifferentToRef,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';

export default function useHandleSingleEstimateReturnedFromSearch() {
  const { levelsFeatures } = useContext(VenueFilesContext);
  const { checkAssetLatLngIsWithinMapBounds } = useMapHooks();
  const {
    fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors,
    panMapToBuildingLevelsAndEstimates,
  } = useMapHooksExternalMapRef();

  const {
    floorSelectedIDArray,
    buildingSelectedID,
    setBuildingSelectedID,
    setShouldRecalculateFloor,
  } = useContext(MapControlContext);

  function handleSingleEstimateReturnedFromSearch(
    singleEstimate: EstimateType | UserType,
    cachedEstimateRef: EstimateType | UserType | any,
  ) {
    // logic for single asset returned usecases.
    const { latitude, longitude } = singleEstimate.location.coordinates;
    const estimateCoordsArray = [latitude, longitude];
    const assetLocationChanged = checkEstimateLocationIsDifferentToRef(
      singleEstimate,
      cachedEstimateRef,
    );
    const assetIsOutdoors = singleEstimate.location.is_outdoors;
    const assetIsWithinMapBounds = checkAssetLatLngIsWithinMapBounds(estimateCoordsArray);
    const assetIsInSelectedBuilding = assetIsOutdoors
      ? false
      : checkEstimateIsInSameBuilding(singleEstimate, buildingSelectedID);
    const assetIsOnSelectedFloor = assetIsOutdoors
      ? false
      : checkEstimateIsOnSelectedFloor(singleEstimate, floorSelectedIDArray);
    const assetIsVisibleToUser = assetIsOutdoors
      ? assetIsWithinMapBounds
      : assetIsWithinMapBounds && assetIsOnSelectedFloor && assetIsInSelectedBuilding;
    const singleEstimateArr = isEstimateType(singleEstimate)
      ? [singleEstimate as EstimateType]
      : [singleEstimate as UserType];

    if (assetLocationChanged && !assetIsVisibleToUser) {
      const assetWasInSelectedBuildingLastPoll = checkEstimateIsInSameBuilding(
        cachedEstimateRef.current,
        buildingSelectedID,
      );
      const assetWasOutdoorsLastPoll = cachedEstimateRef.current.location.is_outdoors;

      if (assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding && !assetIsOutdoors) {
        // if asset was in selected building and  has moved buildings, select new building, and pan map to show asset and prev building

        fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors(
          levelsFeatures,
          singleEstimateArr,
          buildingSelectedID,
        );
        setBuildingSelectedID(singleEstimate.location.building_level.possible_buildings[0].id); // this will automatically trigger floor calc.
      } else if (assetWasOutdoorsLastPoll && !assetIsOutdoors) {
        // check if asset was outdoors in last poll, and has now moved indoors. Select building it moved to floor, and pan.

        fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors(
          levelsFeatures,
          singleEstimateArr,
          buildingSelectedID,
        );
        setBuildingSelectedID(singleEstimate.location.building_level.possible_buildings[0].id); // this will automatically trigger floor calc.
        setShouldRecalculateFloor(true); // set recaculatefloorflag to true so that useFloorSelectionHook logic will run
      } else if (!assetWasOutdoorsLastPoll && assetIsOutdoors) {
        // if asset was indoors, and has now moved outdoors,
        // check asset is within bounds, if not, pan, with selected building

        if (!assetIsWithinMapBounds) {
          panMapToBuildingLevelsAndEstimates(singleEstimateArr, levelsFeatures);
        }
        // check if asset is within bounds of map, and adjust if not.
      } else if (!assetWasInSelectedBuildingLastPoll && !assetIsInSelectedBuilding) {
        // if asset has moved buildings, adjust map if necessary for building of asset, whilst keeping current building within view.
        fitMapToBoundsOfBuildingLevelsWithEstimatesAndOutdoors(
          levelsFeatures,
          singleEstimateArr,
          buildingSelectedID,
        );
      } else if (assetIsInSelectedBuilding) {
        // if asset as only moved in selected building
        setShouldRecalculateFloor(true); // set recaculatefloorflag to true so that useFloorSelectionHook logic will run
      }
    }
    cachedEstimateRef.current = singleEstimate; // set ref to the latest location so we can check against it on next poll.
  }

  return { handleSingleEstimateReturnedFromSearch };
}
