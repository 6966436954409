import styled from 'styled-components';

export const StyledDataTableWrapper = styled.div`
  padding-bottom: 50px;
  position: relative;
`;

export const StyledTableContainer = styled.div`
  display: flex;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .bar {
    height: 10px;
    width: 5px;
  }
  p {
    margin-top: 30px;
    font-size: 12px;
  }
`;
