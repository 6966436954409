import React, { useContext } from 'react';
import { Marker } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';
import LargeInfoBoxTooltip from '../ToolTips/LargeInfoBoxTooltip';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import useMapHooks from 'views/MapPage/hooks/useMapHooks';
import { getIconFromEstimateFreshness } from 'views/MapPage/utils/getIconFromEstimateFreshness';
import SmallInfoBoxTooltip from '../ToolTips/SmallInfoBoxTooltip';
import { clearWindowURLParams } from 'utils/utils';
import useDevice from 'hooks/useDevice';
import MobileAssetInfoFlyout from '../mobile/MobileAssetInfoFlyout';
import { FullConfigContext } from 'contexts/fullConfig.context';
import { EstimateType } from 'types/Estimate';

export default function AssetMarker({ asset }: { asset: EstimateType }) {
  const { clickedMarkerID, setClickedMarkerID, assetMarkerInfoChecked } =
    useContext(MapControlContext);
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);
  const { panToLatLong } = useMapHooks();
  const { isMobileDevice } = useDevice();

  const showMarkerLargeInfoBox = clickedMarkerID === asset.assigned_beacon!.id;

  const markerLong = asset.location.coordinates.longitude;
  const markerLat = asset.location.coordinates.latitude;

  function handleMarkerClick(e: LeafletMouseEvent, asset: EstimateType) {
    setClickedMarkerID(asset.assigned_beacon!.id);
    panToLatLong(e.latlng);
    clearWindowURLParams();
  }

  const customIcon = new L.Icon({
    iconUrl: getIconFromEstimateFreshness(asset, freshPeriodThresholdSeconds),
    iconSize: new L.Point(20, 20),
    iconAnchor: [10, 10], // half of icon size.
    className: 'leaflet-custom-icon',
  });

  function handleCloseInfoBox() {
    setClickedMarkerID('');
    clearWindowURLParams();
  }

  return (
    <Marker
      eventHandlers={{
        click: (e) => {
          handleMarkerClick(e, asset);
        },
      }}
      icon={customIcon}
      position={[markerLat, markerLong]}
    >
      {showMarkerLargeInfoBox && !isMobileDevice && (
        <LargeInfoBoxTooltip asset={asset} handleCloseInfoBox={handleCloseInfoBox} />
      )}
      {showMarkerLargeInfoBox && isMobileDevice && (
        <MobileAssetInfoFlyout
          selectedAssetDetails={asset}
          handleCloseInfoBox={handleCloseInfoBox}
        />
      )}
      {assetMarkerInfoChecked && !showMarkerLargeInfoBox && <SmallInfoBoxTooltip asset={asset} />}
    </Marker>
  );
}
