import React from 'react';
import { StyledPlaceholderHeading, StyledPlaceholderInfoBox } from '../styled';
import { HeaderStyled } from 'components/Header/style';

export default function LoadingPlaceholderHeader() {
  return (
    <div className="container">
      <HeaderStyled>
        <div className="header-item title">
          <StyledPlaceholderHeading />
        </div>
        <div className="header-item search">
          <StyledPlaceholderInfoBox />
        </div>
      </HeaderStyled>
    </div>
  );
}
