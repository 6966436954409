import React from 'react';
import AssetMarker from '../components/Markers/AssetMarker';
import { EstimateType } from 'types/Estimate';

type SelectedBuildingAssetMarkersLayerType = {
  assetMarkers: EstimateType[];
};

function SelectedBuildingAssetMarkersLayer({
  assetMarkers,
}: SelectedBuildingAssetMarkersLayerType) {
  return (
    <>
      {assetMarkers.map((asset) => {
        return <AssetMarker key={asset.asset_id} asset={asset} />;
      })}
    </>
  );
}

export default SelectedBuildingAssetMarkersLayer;
