import UserApi from 'api/user/user.api';
import { UsersContext } from '../contexts/users.context';
import { useContext } from 'react';
import { getIdToken, getUsersActiveLastHour } from 'utils/utils';
import { getOutoorUserEstimates, getIndoorUsersWithEstimate } from '../utils/utils';
import { alertErrorMessage } from 'utils/alerts';
import { VenuesContext } from '../contexts/venues.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { MapControlContext } from '../contexts/mapcontrol.context';
import useHandleSingleEstimateReturnedFromSearch from './useHandleSingleEstimateReturnedFromSearch';

export default function useCallUsersAPIIntervalFunction() {
  const { setUsersMarkers, setUsersOutsideMappedBuildingsMarkers } = useContext(UsersContext);
  const { cachedEstimateRef, searchType } = useContext(SearchFilterContext);

  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const { searchTerm, inSearchMode } = useContext(SearchFilterContext);
  const { setPollUsersFinished, setShouldRecalculateFloor } = useContext(MapControlContext);
  const { handleSingleEstimateReturnedFromSearch } = useHandleSingleEstimateReturnedFromSearch();
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

  function callUsersAPIIntervalFunction() {
    // using localstorage for token instead of context, as we need the latest token each time, and cant use usecontext inside the interval function.
    const localStorageState: any = localStorage.getItem('aws-amplify-cacheauthState');
    const authState = JSON.parse(localStorageState).data;
    const token = getIdToken(authState);
    const userApi = new UserApi(token);
    const derivedSearchTerm = inSearchMode ? searchTerm : null;

    setPollUsersFinished(false);
    setShouldRecalculateFloor(false);

    userApi
      .getUsersMap(selectedVenueID, derivedSearchTerm)
      .then((res) => {
        const { items } = res.data;
        const activeUsers = getUsersActiveLastHour(items);
        const filteredItems = getIndoorUsersWithEstimate(activeUsers);
        const outdoorFilteredItems = getOutoorUserEstimates(activeUsers);
        const combinedEstimates = [...filteredItems, ...outdoorFilteredItems];

        setUsersOutsideMappedBuildingsMarkers(outdoorFilteredItems);
        setUsersMarkers(filteredItems);
        if (inSearchMode && searchType === 'users' && combinedEstimates.length === 1) {
          handleSingleEstimateReturnedFromSearch(combinedEstimates[0], cachedEstimateRef);
        }
        setPollUsersFinished(true);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
      });
  }
  return { callUsersAPIIntervalFunction };
}
