import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { ReactNode, useContext } from 'react';
import { userPermissionType } from 'types/permissions';
import { getArraysIntersection } from 'utils/utils';

type ComponentUserPermissionRenderWrapperProps = {
  userPermissions: userPermissionType[] | any;
  children: ReactNode;
};

const ComponentUserPermissionRenderWrapper = ({
  userPermissions,
  children,
}: ComponentUserPermissionRenderWrapperProps) => {
  const { userAccessPermissionsArray } = useContext(FullConfigContext);

  const componentHasPermission =
    getArraysIntersection(userAccessPermissionsArray, userPermissions).length !== 0;

  return <> {componentHasPermission ? children : null}</>;
};

export default ComponentUserPermissionRenderWrapper;
