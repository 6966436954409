import styled, { css } from 'styled-components';
import { colours } from 'styling/colours';

export const shimmerAfterCSS = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: '';

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export const StyledPlaceholderNavLink = styled.li`
  background: ${colours.disableSelect};
  width: 100%;
  max-width: 200px;
  height: 34px;
  margin: 15px auto !important;
  border-radius: 10px;
  &:after {
    ${shimmerAfterCSS}
  }
`;

export const StyledPlaceholderHeading = styled.h1`
  margin: 0;
  border-radius: 10px;
  background: ${colours.disableSelect};
  width: 500px;
  height: 50px;
  &:after {
    ${shimmerAfterCSS}
  }
`;

export const StyledPlaceholderInfoBox = styled.div`
  background: ${colours.disableSelect};
  width: 250px;
  height: 30px;
  border-radius: 10px;
  &:after {
    ${shimmerAfterCSS}
  }
`;

//placeholder table.
export const StyledPlaceholderDataTableContainer = styled.div`
  overflow: hidden;
  position: relative;
  margin-top: 50px;
`;

export const StyledLoadingPlaceholderTableHeader = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 40px;
  border-radius: 6px;
  background: ${colours.disableSelect};
  width: 100%;

  &:after {
    ${shimmerAfterCSS}
  }
`;

export const StyledPlaceholderDataTableRow = styled.div`
  width: 100%;
  height: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;

  span {
    border-radius: 6px;
    background: ${colours.disableSelect};
    width: 100%;
    display: block;
    max-width: 50px;
    height: 100%;

    &:after {
      ${shimmerAfterCSS}
    }
    &:nth-child(1) {
      max-width: 40px;
    }
    &:nth-child(2) {
      max-width: 120px;
    }
    &:nth-child(3) {
      max-width: 240px;
    }
    &:nth-child(4) {
      max-width: 120px;
    }
    &:nth-child(5) {
      max-width: 240px;
    }
    &:nth-child(6) {
      max-width: 120px;
    }
    &:nth-child(7) {
      max-width: 120px;
    }
  }
`;
