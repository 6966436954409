import { UserType } from 'types/userInfo';
import React from 'react';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';
import { getValueSafely } from 'utils/utils';
import emailImg from './email.svg';
import ComponentAnimationWrapper from 'wrappers/ComponentAnimationWrapper';

const StyledDiv = styled.div`
  position: absolute;
  top: 33px;
  right: -5px;
  width: auto;

  background: ${coloursNames.darkGrey};
  border-radius: 6px;
  padding: 20px;
  z-index: 1;
  span {
    display: flex;
    align-items: center;
    color: white;
    font-size: 15px;
    font-family: 'MuseoSlab';
    word-break: normal;
    img {
      margin-right: 10px;
    }
  }
  &:before {
    // override for arrow.
    bottom: 91.2%;
    right: 9px;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-right-color: ${coloursNames.darkGrey};
    border-width: 15px;
    margin-right: -8px;
    transform: rotate(90deg);
  }
`;

export default function RecentUserTooltipPanel({ user }: { user: UserType }) {
  const email = getValueSafely(user.email);

  return (
    <ComponentAnimationWrapper>
      <StyledDiv className="more-info-tooltip">
        <span>
          <img src={emailImg} alt="email" />
          {email}
        </span>
      </StyledDiv>
    </ComponentAnimationWrapper>
  );
}
