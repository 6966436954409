import { FullConfigContext } from 'contexts/fullConfig.context';
import { VenuesContext } from 'contexts/venues.context';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { EstimateType } from 'types/Estimate';
import { assetEstimateIsNotFresh, getValueSafely } from 'utils/utils';

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  div:first-of-type {
    margin-right: 5px;
  }

  @media (max-width: 1800px) {
    flex-direction: column;
    div:first-of-type {
      margin-left: initial;
    }
  }
`;

const LocationCellAsset = ({ value }: { value: EstimateType }) => {
  const { isSingleVenue } = useContext(VenuesContext);
  let showError = false;
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);

  if (assetEstimateIsNotFresh(value.last_location_update_timestamp, freshPeriodThresholdSeconds)) {
    showError = true;
  }
  if (value.location !== null) {
    const assetIsOutdoor = value.location.is_outdoors;
    return (
      <div
        className="u-no-pointer-event"
        data-tag="allowRowEvents"
        data-test={showError}
        style={{ color: showError ? `${colours.error}!important` : '' }}
      >
        <>
          {!isSingleVenue && (
            <div data-test-id="venue-name" style={{ color: showError ? `${colours.error}` : '' }}>
              {value.location.venue.name}
            </div>
          )}
          {assetIsOutdoor ? (
            <StyledDiv style={{ color: showError ? `${colours.error}` : '' }}>
              <div>Outside Mapped Buildings</div>

              <div>{getValueSafely(value.location.region?.name, '', true)} </div>
            </StyledDiv>
          ) : (
            <StyledDiv>
              <div data-test-id="floor-name" style={{ color: showError ? `${colours.error}` : '' }}>
                {getValueSafely(value.location?.building_level?.level.name)}/
                {getValueSafely(value.location?.building_level?.possible_buildings[0]?.name)}
              </div>
              <div
                data-test-id="region-name"
                style={{ color: showError ? `${colours.error}` : '' }}
              >
                {getValueSafely(value.location.region?.name)}
              </div>
            </StyledDiv>
          )}
        </>
      </div>
    );
  }

  return <>––</>;
};
export default LocationCellAsset;
