import { genericDelete, genericGet, genericPost, genericPut } from "api/config/base";
import { RoleConfigDto, WorkgroupsAndRolesDto, accessedWorkgroupsDto, role } from "views/config/roles/RoleConfigDto";
import { KeyValuePair } from "utils/common";
import { getConfigApiUrl } from "utils/utils";

export function GetWorkgroupsAndRoles(): Promise<WorkgroupsAndRolesDto> {
  return Promise.resolve(
    {
      "version": "de60893fdcaf4fd8d7a357835b1bfeb6e3e2644b141285bb317c63e7a6589725",
      "workgroup_id_to_workgroups": {
        "00000000-0000-0000-0000-000000000010": {
          "type": "workgroup",
          "id": "00000000-0000-0000-0000-000000000010",
          "name": "Portering"
        },
        "00000000-0000-0000-0000-000000000020": {
          "type": "workgroup",
          "id": "00000000-0000-0000-0000-000000000020",
          "name": "Cleaning"
        },
        "150dcd65-0c40-4baf-8ccd-c368c72f8888": {
          "type": "workgroup",
          "id": "150dcd65-0c40-4baf-8ccd-c368c72f8888",
          "name": "Asset Move"
        },
        "2d315384-0e09-4cb2-83d7-4e5044b59028": {
          "type": "workgroup",
          "id": "2d315384-0e09-4cb2-83d7-4e5044b59028",
          "name": "Integration"
        },
        "f3027455-67b5-4104-a6ae-98835cd02fc2": {
          "type": "workgroup",
          "id": "f3027455-67b5-4104-a6ae-98835cd02fc2",
          "name": "Workgroup without role"
        }
      },
      "workgroup_id_to_roles": {
        "00000000-0000-0000-0000-000000000010": [
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000011",
            "name": "Baseuser",
            "workgroup_id": "00000000-0000-0000-0000-000000000010",
          },
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000012",
            "name": "Senior Baseuser",
            "workgroup_id": "00000000-0000-0000-0000-000000000010",
            
          },
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000013",
            "name": "Supervisor",
            "workgroup_id": "00000000-0000-0000-0000-000000000010",
            
          },
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000014",
            "name": "Manager",
            "workgroup_id": "00000000-0000-0000-0000-000000000010",
            
          }
        ],
        "00000000-0000-0000-0000-000000000020": [
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000021",
            "name": "Cbaseuser",
            "workgroup_id": "00000000-0000-0000-0000-000000000020",
            
          },
          {
            "type": "role",
            "id": "00000000-0000-0000-0000-000000000022",
            "name": "Senior Cbaseuser",
            "workgroup_id": "00000000-0000-0000-0000-000000000020",
            
          }
        ],
        "150dcd65-0c40-4baf-8ccd-c368c72f8888": [
          {
            "type": "role",
            "id": "08d6376f-d846-4c28-8d57-d19742b2445a",
            "name": "Manager",
            "workgroup_id": "150dcd65-0c40-4baf-8ccd-c368c72f8888",
            
          },
          {
            "type": "role",
            "id": "491bffab-9a31-4878-8486-0b00d120a0c7",
            "name": "Base User",
            "workgroup_id": "150dcd65-0c40-4baf-8ccd-c368c72f8888",
            
          }
        ]
      }
    });
  // return genericGet<WorkgroupsAndRolesDto>(
  //   `${getConfigApiUrl()}/v1/workgroups_and_roles`
  // )
}

export function DeleteRole(roleId: string, version: string): Promise<any> {
  return genericDelete(
    `${getConfigApiUrl()}/v1/role/${roleId}`,
    version
  );
}

export function UsersAffectedByRole(roleId: string,): Promise<number> {
  return genericGet(
    `${getConfigApiUrl()}/v1/role/${roleId}/affected_users`
  );
}

export function accessedWorkgroups(): Promise<accessedWorkgroupsDto> {
  return Promise.resolve({
    "workgroups": [
      {
        "key": "Portering",
        "value": "00000000-0000-0000-0000-000000000010"
      },
      {
        "key": "Cleaning",
        "value": "00000000-0000-0000-0000-000000000020"
      },
      {
        "key": "Asset Move",
        "value": "150dcd65-0c40-4baf-8ccd-c368c72f8888"
      },
      {
        "key": "Integration",
        "value": "2d315384-0e09-4cb2-83d7-4e5044b59028"
      },
      {
        "key": "Workgroup without role",
        "value": "f3027455-67b5-4104-a6ae-98835cd02fc2"
      }
    ]
  })
  // return genericGet(`${getConfigApiUrl()}/v1/workgroups/create_role`)
}

export function getDataVisibility(): Promise<KeyValuePair[]> {
  return Promise.resolve([
    { key: "365 days", value: "365" },
    { key: "270 days", value: "270" },
    { key: "180 days", value: "180" },
    { key: "90 days", value: "90" },
    { key: "30 days", value: "30" },
  ]);
}

export function GetRole(roleId: string): Promise<RoleConfigDto> {
  return Promise.resolve({
    "version": "de60893fdcaf4fd8d7a357835b1bfeb6e3e2644b141285bb317c63e7a6589725",
    "role": {
        "admin_permissions": [
          "create",
          "view"
        ],
        "asset_permissions": [
          "view",
          "create",
          "report"
        ],
        "asset_types": [
          "maternity_wheelchair",
          "gas_cylinder"
        ],
        "id": "",
        "name": "test",
        "owners": [
            "anaesthesiology",
            "a_e"
          ],
        "people_permissions": [
          "view",
          "report"
        ],
        "people_roles": [
          "00000000-0000-0000-0000-000000000022"
        ],
        "security_permissions": [
          "login_mobile_app",
          "login_portal_app",
          "locate_person"
        ],
        "workgroup_id": "00000000-0000-0000-0000-000000000020"
    }
  } as RoleConfigDto);
  return genericGet(
    `${getConfigApiUrl()}/v1/role/${roleId}`
  )
}

export function CreateRole(roleDto: role): Promise<any> {
  return genericPost(
    `${getConfigApiUrl()}/v1/roles`,
    roleDto
  )
}

export function UpdateRole(roleId: string, roleDto: role): Promise<any> {
  return genericPut(
    `${getConfigApiUrl()}/v1/role/${roleId}`,
    roleDto
  )
}