import React, { createContext, useState, ReactNode } from 'react';

type MustUpdateProps = { children: ReactNode };

interface IMustUpdateProps {
  mustUpdate: boolean;
  setMustUpdate: (value: boolean) => void;
}

export const MustUpdateContext = createContext({} as IMustUpdateProps);

const MustUpdateProvider = ({ children }: MustUpdateProps) => {
  const [mustUpdate, setMustUpdate] = useState<boolean>(false);

  return (
    <MustUpdateContext.Provider value={{ mustUpdate, setMustUpdate }}>
      {children}
    </MustUpdateContext.Provider>
  );
};
export default MustUpdateProvider;
