import React from 'react';
import RecentUsersTableContainer from './RecentUsersTableContainer';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';
import RecentUsersProvider from 'contexts/recentUsers.context';

export default function RecentUsersPage() {
  const { isMobileDevice } = useDevice();

  if (isMobileDevice) {
    return <WidthNotSupportedPage text={'This feature is not available on narrow screens.'} />;
  }

  return (
    <RecentUsersProvider>
      <RecentUsersTableContainer />
    </RecentUsersProvider>
  );
}
