import { isThisHour, isToday, set } from 'date-fns';
import { AssetStatesObjectType } from 'types/AssetStatus';
import { EstimateType } from 'types/Estimate';

export function getDateFromHHMMTimeStr(HHMMStr: string, date: Date) {
  const dateStringSplitArray = HHMMStr.split(':');
  const hours = parseInt(dateStringSplitArray[0], 10);
  const mins = parseInt(dateStringSplitArray[1], 10);
  const derivedDate = set(new Date(date), { hours: hours, minutes: mins });

  return derivedDate;
}

export function rangeArray(start: number, end: number) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function disabledRangeTime(pickerDate: any) {
  // creates an object with array of number values that should not be displayed in timepicker.
  // we do not want the user to be able to select the past,
  const d = new Date();
  const currentHour = d.getHours();
  const currentMin = d.getMinutes();

  return isToday(new Date(pickerDate))
    ? {
        disabledHours: () => rangeArray(0, 24).splice(0, currentHour),
        disabledMinutes: () => rangeArray(0, isThisHour(new Date(pickerDate)) ? currentMin + 1 : 0), // add extra min only for current hour so clear time isnt immediate.
      }
    : {};
}

export function getAssetsCurrentStatusObj(
  selectedAssetDetails: EstimateType,
  availableAssetStates: AssetStatesObjectType[],
): AssetStatesObjectType {
  const assetHasStatus =
    selectedAssetDetails.status_assignment?.asset_status !== null &&
    selectedAssetDetails.status_assignment?.asset_status !== undefined;

  const assetCurrentStatus = assetHasStatus
    ? availableAssetStates.filter(
        (availableState) =>
          availableState.asset_status_id ===
          selectedAssetDetails.status_assignment?.asset_status?.asset_status_id,
      )[0]
    : availableAssetStates[0];
  return assetCurrentStatus;
}
