/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import config from 'react-global-configuration';
import { DownloadStatusEnum } from 'views/UserLogsPage/DownloadUserLogsModal';

class UserLogApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getUsersLogs = async (): Promise<any> => {
    return Promise.resolve({
      data: {
        items: [
          // {
          //   id: '1',
          //   name: 'User Logs Mar 26 - Apr 08, 2023',
          //   start_date: 1679788800000,
          //   end_date: 1680912000000,
          // },
          // {
          //   id: '2',
          //   name: 'User Logs Mar 12 - Mar 25, 2023',
          //   start_date: 1678579200000,
          //   end_date: 1679702400000,
          // },
        ],
        count: 0,
        total: 0,
        next: null,
      },
    });
  };

  public CreateExportJob = async (start_date: string, end_date: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('start_date', start_date);
    queryString.append('end_date', end_date);

    const url = `${
      config.get().DATA_API_URL
    }/event/change/user/export/job?${queryString.toString()}`;

    return axios.post(url, null, this.axiosConfig);
  };

  public GetExportJobStatus = async (jobId: string | null): Promise<any> => {
    return axios.get(
      `${config.get().DATA_API_URL}/event/change/user/export/job/${jobId}/status`,
      this.axiosConfig,
    );
  };
}
export default UserLogApi;
