import { useContext } from 'react';
import { MustUpdateContext } from 'contexts/mustUpdate.context';

const useMustUpdate = () => {
  const context = useContext(MustUpdateContext);
  if (context === undefined) {
    throw new Error('useMustUpdate must be used within an MustUpdateContext');
  }
  return context;
};
export default useMustUpdate;
