import React from 'react';
import styled from 'styled-components';
import usePageInfo from 'hooks/pageInfo.hook';

const StyledHeading = styled.h1`
  margin: 0;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`;

const PageTitle = ({ pageTitle }: { pageTitle: string }) => {
  const { contextPageTitle = '' } = usePageInfo();

  return <StyledHeading>{contextPageTitle ? contextPageTitle : pageTitle}</StyledHeading>;
};

export default PageTitle;
