import React, { useState } from 'react';
import { StyledArrowButton } from '../Form/Styled';
import arrowImg from './icons/arrow.svg';

export default function CollapseArrowButton({
  setFormCollapsed,
}: {
  setFormCollapsed: (arg: boolean) => void;
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    setIsCollapsed(!isCollapsed);
    setFormCollapsed(!isCollapsed);
  }

  return (
    <StyledArrowButton className="arrow-button" onClick={handleClick}>
      <img
        style={{ transform: `rotate(${isCollapsed ? 180 : 0}deg)` }}
        src={arrowImg}
        alt="collapse"
      />
    </StyledArrowButton>
  );
}
