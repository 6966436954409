import React from 'react';
import styled from 'styled-components';
import clearFieldIcon from 'assets/icons/clear-field.svg';

const StyledButton = styled.button`
  background: transparent;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

type ClearInputButtonTypes = {
  onClick: (arg: any) => void;
  searchInProgress?: boolean;
};
export default function ClearInputButton({ onClick, searchInProgress }: ClearInputButtonTypes) {
  return (
    <StyledButton
      disabled={searchInProgress}
      onClick={(e) => onClick(e)}
      className="clear-input-button"
    >
      <img src={clearFieldIcon} alt="clear" />
    </StyledButton>
  );
}
