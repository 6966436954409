/*
RoleStore class handles all states, API calls, form filling and form validation,
  and logic of Add and Edit role records.
*/

import { CreateRole, GetRole, GetWorkgroupsAndRoles, UpdateRole, accessedWorkgroups } from "api/config/roles";
import { RoleConfigDto, roleListItem } from "views/config/roles/RoleConfigDto";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { KeyValuePair } from "utils/common";
import { required } from "utils/formstate";
import { isDeepEqual } from "utils/utils";

export class RoleStore {
  root: RootStore;
  version: string = "";
  dataLoading = false;
  dataLoadingError: boolean = false;
  workgroups: KeyValuePair[] = [];
  accessedWorkgroups: KeyValuePair[] = [];
  allRoles: Record<string, roleListItem[]> = {};
  currentRoleId: string | undefined = undefined;
  currentRole: RoleConfigDto = new RoleConfigDto();
  roleForm = this.createRoleForm();
  saveLoading: boolean = false;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      dataLoading: observable,
      saveLoading: observable,
      dataLoadingError: observable,
      workgroups: observable,
      accessedWorkgroups: observable,
      roleForm: observable,
      currentRoleId: observable,

      LoadData: action.bound,
      reset: action.bound,
      SaveRole: action.bound,

      getRoles: computed,
      isAnyChange: computed,
    });
  }

  setCurrentRoleId = (roleId: string | undefined) => {
    this.currentRoleId = roleId;
  }

  createRoleForm() {
    return new FormState({
      workgroupId: new FieldState(this.currentRole.role.workgroup_id).validators(required),
      name: new FieldState(this.currentRole.role.name).validators(required, (value) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [workgroup, roles] of Object.entries(this.allRoles)) {
          if (roles.some(role => role.workgroup_id === this.roleForm.$.workgroupId.value
            && role.id !== this.currentRoleId && role.name.toLocaleLowerCase() === value.toLocaleLowerCase()))
            return "A role with the name specified already exists."
        }
      }),
      assetSegment: new FieldState(this.currentRole.role.owners && this.currentRole.role.owners.length > 0 ? "owner" : "type"),
      securityPermissions: new FieldState(this.currentRole.role.security_permissions),
      assetTypes: new FieldState(this.currentRole.role.asset_types),
      owners: new FieldState(this.currentRole.role.owners),
      assetPermissions: new FieldState(this.currentRole.role.asset_permissions),
      PeopleRoles: new FieldState(this.currentRole.role.people_roles),
      peoplePermissions: new FieldState(this.currentRole.role.people_permissions),
      adminPermissions: new FieldState(this.currentRole.role.admin_permissions),
    });
  }

  fillForm(data: RoleConfigDto | null) {
    if (data) {
      this.currentRole = data;
    }
    this.roleForm = this.createRoleForm();
    this.roleForm.validate();
  }

  reset() {
    this.currentRole = new RoleConfigDto();
    this.roleForm = this.createRoleForm();
  }

  LoadData(roleId: string | null = null) {
    this.dataLoading = true;
    return Promise.all([
      GetWorkgroupsAndRoles(),
      roleId ? GetRole(roleId) : Promise.resolve(null),
      accessedWorkgroups(),
    ])
      .then(res => {
        this.workgroups = Object.entries(res[0].workgroup_id_to_workgroups)
          .map(([key, value]) => ({ key: value.name, value: key }))
        this.allRoles = res[0].workgroup_id_to_roles;
        this.fillForm(res[1]);
        if (res[1]) {
          this.version = res[1].version;
        }
        this.accessedWorkgroups = res[2].workgroups;
      })
      .catch(() => {
        this.dataLoadingError = true;
      })
      .finally(() => {
        this.dataLoading = false;
      })
  }

  BuildRoleConfigDto() {
    const obj = new RoleConfigDto();
    obj.version = this.version;
    obj.role.name = this.roleForm.$.name.value;
    obj.role.workgroup_id = this.roleForm.$.workgroupId.value;
    obj.role.security_permissions = this.roleForm.$.securityPermissions.value;
    if (this.roleForm.$.assetSegment.value === "type")
      obj.role.asset_types = this.roleForm.$.assetTypes.value;
    else
      obj.role.owners = this.roleForm.$.owners.value;

    obj.role.asset_permissions = this.roleForm.$.assetPermissions.value;
    obj.role.people_permissions = this.roleForm.$.peoplePermissions.value;
    obj.role.people_roles = this.roleForm.$.PeopleRoles.value;
    obj.role.admin_permissions = this.roleForm.$.adminPermissions.value;

    return obj.role;
  }

  async SaveRole(roleId: string | undefined) {
    this.saveLoading = true;
    const promise = roleId
      ? UpdateRole(roleId, this.BuildRoleConfigDto())
      : CreateRole(this.BuildRoleConfigDto())
    return promise
      .catch((error) => Promise.reject(error))
      .finally(() => this.saveLoading = false)
  }

  get getRoles() {
    if (!this.roleForm.$.workgroupId.value)
      return [];

    return this.allRoles[this.roleForm.$.workgroupId.value].map(r => ({ key: r.name, value: r.id }))
  }

  get isAnyChange() {
    const srcRole = this.currentRole.role;
    const finalRole = this.BuildRoleConfigDto();

    //............... always return true for create mode .....................
    if (!this.currentRoleId) {
      return false;
    }
    if (!isDeepEqual(srcRole, finalRole)) {
      return true;
    }

    return false;
  }
}
