import React from 'react';
import 'antd/dist/reset.css';
import './assets/scss/index.scss';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://b96bb8269ada464aa43138bd68acd80f@o1289795.ingest.sentry.io/6509188',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);

// hashed to disable antd V5 dev-css only classes
root.render(
  <ConfigProvider theme={{ hashed: false }}>
    <App />
  </ConfigProvider>,
);
