import React from 'react';
import Button from 'components/Button/Button';
import { CloseOutlined } from '@ant-design/icons';
import { DialogContainerStyled } from '../Styled';

type NewBeaconDialogTypes = {
  selectedCount: number;
  handleCloseDialog: () => void;
  handleDialogAction: () => void;
};

export default function NewBeaconDialog({
  selectedCount,
  handleCloseDialog,
  handleDialogAction,
}: NewBeaconDialogTypes) {
  return (
    <DialogContainerStyled>
      <dialog style={{ maxWidth: '423px' }} open>
        <div>
          <CloseOutlined
            style={{ color: '#ffffff', width: '16px', height: '16px' }}
            role="button"
            onClick={handleCloseDialog}
          />

          <div className="selected-readout">{selectedCount}</div>
          <div className="selected-text"> beacons selected</div>
        </div>
        <div className="button-container">
          <Button onClick={handleDialogAction}>Import</Button>
        </div>
      </dialog>
    </DialogContainerStyled>
  );
}
