import React, { ReactNode } from 'react';
import PageInfoProvider from 'contexts/pageInfo.context';
import MustUpdateProvider from 'contexts/mustUpdate.context';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalProvider from './global.context';
import AssetManagementProvider from './assetManagement.context';

type AppProviderContextsType = { children: ReactNode };

export default function AppProviderContexts({ children }: AppProviderContextsType) {
  const queryClient = new QueryClient();

  return (
    <GlobalProvider>
      <QueryClientProvider client={queryClient}>
        <AssetManagementProvider>
          <PageInfoProvider>
            <MustUpdateProvider>{children}</MustUpdateProvider>
          </PageInfoProvider>
        </AssetManagementProvider>
      </QueryClientProvider>
    </GlobalProvider>
  );
}
