import ReactSlidingPane from 'react-sliding-pane';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';
import { colours } from 'styling/colours';
import bigX from '../icons/bigX.svg';

export const StyledRegisteredBeaconFlyout = styled(ReactSlidingPane)`
  max-width: 617px;
  .information-sentance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
      width: 14px;
      height: 14px;
    }
    p {
      color: #808285;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
  & .slide-pane__header {
    background: ${colours.assetDetailsFlyoutHeader};
    position: relative;
    padding: 35px 0;
    height: 80px;
  }
  & .slide-pane__content {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
  }
  .hr {
    width: 100%;
    background: #62626233;
    height: 1px;
    margin: 40px auto;
    padding: 0.5px 0;
  }
  & .slide-pane__header * {
    overflow-x: ellipsis;
  }
  & .slide-pane__close {
    position: absolute;
    right: 20px;
    top: 25px;
    background: url(${bigX}) 0 0 no-repeat;
    background-size: 16px 16px;
    background-position-x: center;
    background-position-y: center;
    opacity: 100%;
  }
  & .slide-pane__close svg {
    display: none;
  }
  & h2.slide-pane__title {
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0px;
    color: ${colours.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & .slide-pane__subtitle {
    color: ${colours.white};
    font-size: 15px;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const DialogContainerStyled = styled.div`
  width: 100%;
  position: fixed;
  bottom: 20px;
  left: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  dialog {
    border-radius: 7px;
    padding: 0 15px;
    max-width: 533px;
    width: 100%;
    height: 100%;
    border: none;
    background: ${coloursNames.black};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: all;
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    svg {
      width: 100%;
      height: 100%;
    }
    .selected-readout {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      min-height: 40px;
      border-radius: 50%;
      text-align: center;
      color: white;
      background: #4e4e4e;
      margin: 0 10px;
    }
    .selected-text {
      color: white;
    }
    button {
      width: 100px;
      height: 38px;
      border-radius: 3px;
      font-size: 15px;
      margin-left: 10px;
    }
  }
`;

export const SearchBarStyled = styled.div`
  background: ${colours.disableSelect};
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;

  .react-select-container {
    max-width: 270px;
    width: 100%;
    margin-left: 10px;
    .react-select__control {
      padding: 0 12px;
      border-radius: 6px;
      height: 40px;
    }
  }
`;

export const StyledImportCreateAssetsContainer = styled.div`
  height: 100%;
  padding-bottom: 10px;
  .content {
    margin-top: 40px;
    background: ${colours.white};
    padding: 40px;
    .dashed-line {
      margin: 40px 0;
      width: 100%;
      border: none;
      border-top: 1px dashed #cfcfcf;
      color: #fff;
      background-color: #fff;
      height: 1px;
    }
    .asset-owner-field {
      fieldset {
        height: 80px;
      }
    }
  }

  .button-container {
    margin: 15px 0;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${coloursNames.midGrey};
  border-radius: 50%;
  img {
    width: 20px;
  }
`;

export const StyledRegisteredDropdownControlWrapper = styled.div`
  .react-select__control {
    padding-left: 16px;
    .react-control__preplaceholder {
      color: ${coloursNames.midGrey};
    }
    .react-select__value-container {
      padding-left: 2px;
    }
    .react-select__single-value {
      overflow: initial;
    }
  }
`;

export const NoSearchResultsPanelStyledDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -44px;
  background: white;
  width: 100%;
  height: 40px;
  padding: 0 9px;
  z-index: 9999;
  border: 1px solid rgba(188, 190, 192, 0.3);
  border-radius: 3px;
  overflow: hidden;

  span {
    padding-left: 5px;
    color: ${coloursNames.cinnabarOrange};
  }
`;

export const StyledUploadFormContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  form {
    p {
      margin-bottom: 8px;
    }
  }
`;

export const StyledUploadFormCollapsedButton = styled.button`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 38px;
  background: #f5f5f6;
  border-radius: 7px;
  border: 1px #808285 dashed;
  color: #808285;
  font-size: 15px;
  font-family: MuseoSans;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  img {
    margin-left: 10px;
  }
`;

export const StyledTable = styled.table`
  padding: 0 30px;
  display: block;
  .profile-title {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #000 !important;
    width: 100% !important;
  }
  tr {
    vertical-align: text-top;
    &:last-of-type {
      td {
        padding-bottom: 0;
      }
    }
  }
  td {
    font-size: 15px;
    letter-spacing: 0.09px;
    padding-bottom: 15px;
    display: inline-block;
    word-break: break-word;

    &:first-child {
      width: 160px;
      color: #808285;
    }
    &:nth-of-type(2) {
      width: 380px;
    }
  }

  td#assetDetailsDescription {
    overflow: hidden;
    word-break: break-word;
    white-space: pre-line;
  }
  &:last-of-type {
    margin-bottom: 30px;
  }
`;
