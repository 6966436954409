import assetLocationIcon from '../icons/asset-location-icon.svg';
import staleIcon from '../icons/stale-asset.svg';
import { EstimateType } from 'types/Estimate';
import { differenceInSeconds } from 'date-fns';

export function getIconFromEstimateFreshness(
  estimate: EstimateType,
  freshPeriodThresholdSeconds: number,
) {
  // function to return correct icon based on estimate age, from config defined values.
  const timeNow = new Date();
  const timeDifferenceInSeconds = differenceInSeconds(
    timeNow,
    new Date(estimate.last_location_update_timestamp),
  );

  const estimateIsFresh = timeDifferenceInSeconds <= freshPeriodThresholdSeconds;

  return estimateIsFresh ? assetLocationIcon : staleIcon;
}
