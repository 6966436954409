import React from 'react';
import { getValueSafely } from 'utils/utils';
export default function AssetStatusReadoutSpan({ value }: { value: string | undefined }) {
  const hasStatus = value !== null && value !== undefined;

  return hasStatus ? (
    <span>{getValueSafely(value)}</span>
  ) : (
    <span style={{ color: '#BCBEC0', fontStyle: 'italic' }}>No status set</span>
  );
}
