import { format } from 'date-fns';

import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { AssetStatusType } from 'types/AssetStatus';

const StyledDiv = styled.div`
  width: 100%;
  padding: 0 20px;
  overflow-y: auto;
  p {
    color: ${colours.secondaryText};
    text-align: center;
    margin: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      display: flex;
      margin: 30px 0;
      align-items: flex-start;

      p {
        padding: 0;
        margin: 0;
        margin-bottom: 4px;
      }
      .status-content {
      }
      .name-description {
        display: flex;
      }
      p.status-name {
        color: ${colours.secondaryText};
      }
      p.status-date {
        display: block;
        color: ${colours.secondaryText};
        text-align: left;
      }
    }
  }
`;

const StyledTimelineMarkerDiv = styled.div`
  width: 32px;
  height: 32px;
  background: #74489d;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 14px;
    top: -13px;
    background: #74489d;
    height: 10px;
    width: 5px;
    border-radius: 2px;
  }
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 14px;
    bottom: -39px;
    background: #74489d;
    height: 36px;
    width: 5px;
    border-radius: 2px;
  }
`;

function TimelineTextComponent({ statusObj }: { statusObj: AssetStatusType }) {
  const formatStr = 'dd MMM yyyy, HH:mm';

  if (statusObj.asset_status && statusObj.set_by?.name) {
    // full status
    return (
      <div className="status-content">
        <div className="name-description">
          <p className="status-name">{statusObj.set_by?.name}</p>
          <span style={{ marginLeft: '5px' }}>
            set the Asset Status to: {statusObj.asset_status?.description}
          </span>
        </div>
        {statusObj.set_at_timestamp && (
          <p className="status-date">{format(statusObj.set_at_timestamp, formatStr)}</p>
        )}
      </div>
    );
  } else if (!statusObj.set_by?.name) {
    // auto clear if no user
    return (
      <div className="status-content">
        <p>Asset Status automatically cleared</p>
        {statusObj.set_at_timestamp && (
          <p className="status-date">{format(statusObj.set_at_timestamp, formatStr)}</p>
        )}
      </div>
    );
  } else {
    // user cleared status.
    return (
      <div className="status-content">
        <div className="name-description">
          <p className="status-name">{statusObj.set_by?.name}</p>
          <span style={{ marginLeft: '5px' }}>cleared the status</span>
        </div>
        {statusObj.set_at_timestamp && (
          <p className="status-date">{format(statusObj.set_at_timestamp, formatStr)}</p>
        )}
      </div>
    );
  }
}

export default function AssetActivityTimelineComponent({
  activityDataArray,
}: {
  activityDataArray: AssetStatusType[] | [];
}) {
  return activityDataArray.length > 0 ? (
    <StyledDiv>
      <ul>
        {activityDataArray.map((statusObj: AssetStatusType) => {
          return (
            <li key={statusObj.asset_id}>
              <StyledTimelineMarkerDiv />
              <TimelineTextComponent statusObj={statusObj} />
            </li>
          );
        })}
      </ul>
    </StyledDiv>
  ) : (
    <StyledDiv>
      <p>No asset activity recorded yet</p>
    </StyledDiv>
  );
}
