import React from 'react';

export function getFileValidityStatus(selectedFile: any) {
  // check filesize and type are valid for API to accept
  if (!selectedFile) return null;

  const allowedFileTypesArray = [
    'text/csv', // .csv
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
    'application/vnd.ms-excel', // .xls
  ];
  const maxSizeBytes = 5000000;
  const fileTypeIsValid = allowedFileTypesArray.includes(selectedFile.type);
  const fileSizeIsValid = selectedFile.size <= maxSizeBytes;

  if (!fileTypeIsValid) return 'invalid_type';
  if (!fileSizeIsValid) return 'invalid_size';
  return 'valid';
}

export function getStatusText(status: string) {
  switch (status) {
    case 'invalid_type':
      return (
        <>
          . Only files with following extensions are allowed:
          <br />
          <br />
          <b>.xls, .xlsx, .csv</b>
        </>
      );

    case 'invalid_size':
      return ' as it exceeded 5mb limit. Please make sure the file size is 5mb or less.';

    default:
      break;
  }
}
