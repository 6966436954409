import React, { useContext } from 'react';
import DataTable from 'react-data-table-component';
import { datatableRecentUsersStyle } from 'components/DataTable/DataTableStyling.config';
import { ReactComponent as UpDownArrow } from 'components/DataTable/icons/upAndDownArrow.svg';
import { StyledDataTableWrapper } from '../../components/DataTable/style';
import { RecentUsersContext } from 'contexts/recentUsers.context';
import SortingOverlay from 'components/DataTable/SortingOverlay';

type RecentUsersTableTypes = {
  data: any;
  columns: any;
  noDataComponent: React.ReactElement;
  defaultSortFieldId: string;
  handleSort: (sortcol: string, sortDir: string) => void;
};

export default function RecentUsersTable({
  data,
  columns,
  noDataComponent,
  defaultSortFieldId,
  handleSort,
}: RecentUsersTableTypes) {
  const { isSorting } = useContext(RecentUsersContext);

  return (
    <StyledDataTableWrapper
      className={`table-container ${isSorting ? '--sorting' : ''}`}
      data-test-id="table"
    >
      <DataTable
        columns={columns}
        data={data}
        customStyles={datatableRecentUsersStyle}
        noDataComponent={noDataComponent}
        persistTableHead
        defaultSortFieldId={defaultSortFieldId}
        defaultSortAsc
        sortIcon={<UpDownArrow />}
        sortServer
        onSort={(column, direction) => column.sortField && handleSort(column.sortField, direction)}
      />
      {isSorting && <SortingOverlay />}
    </StyledDataTableWrapper>
  );
}
