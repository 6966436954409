import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, getPhysicalMapVenuesOnly, getSortedArrayByValue } from 'utils/utils';
import VenueApi from 'api/venue/venue.api';
import MapPlaceholderLoadingScreen from '../components/MapPlaceholderLoadingScreen';
import useSubscribeToGlobalContext from '../hooks/useSubscribeToGlobalContext';
import { VenueListType } from 'types/venue';
import { URLParamContext } from './urlParam.context';

type VenuesContextTypes = {
  venuesList: VenueListType[];
  selectedVenueObj: VenueListType;
  setSelectedVenueObj: (arg: VenueListType) => void;
  isSingleVenue: boolean;
};

const initialVenueObj: VenueListType = {
  venue_id: '',
  name: '',
  latitude: 0,
  longitude: 0,
  latest_versions: [{ name: '', dataset: '', released_at: '' }],
};

export const VenuesContext = createContext({} as VenuesContextTypes);

type VenuesProviderProps = { children: ReactNode };

// A context to fetch a list of available venues for the user.
// then pass these into context for venueFiles to consume.
const VenuesProvider = ({ children }: VenuesProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [venuesList, setVenuesList] = useState([]);
  const [selectedVenueObj, setSelectedVenueObj] = useState<VenueListType>(initialVenueObj);

  const { getGlobalCTXValueOrFallback } = useSubscribeToGlobalContext();
  const { urlParams } = useContext(URLParamContext);

  const isSingleVenue = venuesList.length < 2;

  const value: VenuesContextTypes = {
    venuesList,
    selectedVenueObj,
    setSelectedVenueObj,
    isSingleVenue,
  };
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  useEffect(() => {
    // call venues API for list of all venues.
    const venueApi = new VenueApi(token);
    setIsLoading(true);

    venueApi
      .getVenuesList()
      .then((res) => {
        const physicalMapVenues = getPhysicalMapVenuesOnly(res.venues);
        const sortedVenuesList = getSortedArrayByValue(physicalMapVenues, 'name');
        const initialSelectedVenueObj: VenueListType = getGlobalCTXValueOrFallback(
          'GLOBAL_selectedMapVenueObj',
          sortedVenuesList[0],
        );
        const urlParamVenueObj = sortedVenuesList.filter((venueObj: VenueListType) => {
          return venueObj.venue_id === urlParams.venueID;
        })[0];

        setVenuesList(sortedVenuesList);
        // check venue exists within listed venues from URL params and use this.
        setSelectedVenueObj(
          urlParamVenueObj !== undefined ? urlParamVenueObj : initialSelectedVenueObj,
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('Error fetching Venues List');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <VenuesContext.Provider value={value}>{children}</VenuesContext.Provider>
  ) : (
    <MapPlaceholderLoadingScreen />
  );
};

export default VenuesProvider;
