import React from 'react';
import LoadingBars from 'components/Loading/LoadingBars';
import styled from 'styled-components';
const StyledDiv = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 100px 0;
  .bar {
    height: 10px;
    width: 5px;
  }
  p {
    margin-top: 30px;
    font-size: 12px;
  }
`;
export default function SortingOverlay() {
  return (
    <StyledDiv>
      <LoadingBars />
      <p>Sorting Data</p>
    </StyledDiv>
  );
}
