import React from 'react';
import { LocalizedBeaconType } from 'types/BeaconType';
import { EstimateType } from 'types/Estimate';
import { getValueSafely } from 'utils/utils';

export default function AccuracyRangeSpan({
  assetDetails,
}: {
  assetDetails: EstimateType | LocalizedBeaconType;
}) {
  const accuracyRangeString = getValueSafely(
    assetDetails.location?.uncertainty?.toFixed(1),
    ' metres',
  );

  return <span>{accuracyRangeString}</span>;
}
