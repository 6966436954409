import React from 'react';
import UserMarker from '../components/Markers/UserMarker';
import { UserType } from 'types/userInfo';

export default function OutsideMappedBuildingUserMarkersLayer({
  userMarkers,
}: {
  userMarkers: UserType[];
}) {
  return (
    <>
      {userMarkers.map((user: UserType) => {
        return <UserMarker key={user.user_id} user={user} />;
      })}
    </>
  );
}
