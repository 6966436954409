import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, getUsersActiveLastHour } from 'utils/utils';
import UserApi from 'api/user/user.api';
import { MapControlContext } from './mapcontrol.context';
import { alertErrorMessage } from 'utils/alerts';
import { UserType } from 'types/userInfo';
import { getOutoorUserEstimates, getIndoorUsersWithEstimate } from '../utils/utils';
import { FullConfigContext } from 'contexts/fullConfig.context';
import useDevice from 'hooks/useDevice';
import { VenuesContext } from './venues.context';

type UsersContextTypes = {
  usersMarkers: UserType[];
  setUsersMarkers: (arg: UserType[]) => void;
  usersOutsideMappedBuildingsMarkers: UserType[];
  setUsersOutsideMappedBuildingsMarkers: (arg: UserType[]) => void;
};

export const UsersContext = createContext({} as UsersContextTypes);

type UsersProviderProps = { children: ReactNode };

// A context that fetches all users and passes them into context.
const UsersProvider = ({ children }: UsersProviderProps) => {
  const [usersMarkers, setUsersMarkers] = useState<UserType[] | []>([]);

  const [usersOutsideMappedBuildingsMarkers, setUsersOutsideMappedBuildingsMarkers] = useState<
    UserType[] | []
  >([]);

  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;

  const { setApiCallInProgress, setUsersApiCallInProgress, recallGetUsers, setRecallGetUsers } =
    useContext(MapControlContext);

  const { userTrackingPermissionEnabled } = useContext(FullConfigContext);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const { isMobileDevice } = useDevice();

  const value: UsersContextTypes = {
    usersMarkers,
    setUsersMarkers,
    usersOutsideMappedBuildingsMarkers,
    setUsersOutsideMappedBuildingsMarkers,
  };

  function callGetUsers() {
    const userApi = new UserApi(token);

    setApiCallInProgress(true);
    setUsersApiCallInProgress(true);

    userApi
      .getUsersMap(selectedVenueID)
      .then((res) => {
        const { items } = res.data;
        const activeUsers = getUsersActiveLastHour(items);
        const filteredItems = getIndoorUsersWithEstimate(activeUsers);
        const outdoorFilteredItems = getOutoorUserEstimates(activeUsers);

        setUsersMarkers(filteredItems);
        setUsersOutsideMappedBuildingsMarkers(outdoorFilteredItems);
        setApiCallInProgress(false);
        setUsersApiCallInProgress(false);
        setRecallGetUsers(false);
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setUsersApiCallInProgress(false);
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
      });
  }

  useEffect(() => {
    // do not call users on mobile
    if (userTrackingPermissionEnabled && !isMobileDevice && recallGetUsers) {
      callGetUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recallGetUsers]);

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export default UsersProvider;
