import HeaderDefault from 'components/Header/HeaderDefault';
import React from 'react';

export default function NoFeaturePermissionsPage() {
  return (
    <>
      <HeaderDefault />
      <main className="u-flex-center">
        <div className="container">
          <h1 style={{ fontSize: '1.6rem' }}>
            You do not have permission to access this page.
            <br /> Please contact your manager/administrator.
          </h1>
        </div>
      </main>
    </>
  );
}
