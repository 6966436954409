import { VenueListType } from 'types/venue';
import React, { createContext, ReactNode, useState } from 'react';

export const GlobalContext = createContext({} as IGlobalContext);

type GlobalProviderProps = { children: ReactNode };

interface IGlobalContext {
  GLOBAL_selectedMapVenueObj: VenueListType | null;
  GLOBAL_setSelectedMapVenueObj: (arg: VenueListType | null) => void;
  GLOBAL_buildingSelectedID: string | null;
  GLOBAL_setBuildingSelectedID: (arg: string | null) => void;
  GLOBAL_mapFloorOrdinal: number | null;
  GLOBAL_setMapFloorOrdinal: (arg: number | null) => void;
}

// a simple context to store application wide state.
// note the prefix of GLOBAL_ for clarity within components & to avoid namespace polution.
// The initial value must be null.
const GlobalProvider = ({ children }: GlobalProviderProps) => {
  // map values ================
  const [GLOBAL_selectedMapVenueObj, GLOBAL_setSelectedMapVenueObj] =
    useState<VenueListType | null>(null);
  const [GLOBAL_buildingSelectedID, GLOBAL_setBuildingSelectedID] = useState<string | null>(null);
  const [GLOBAL_mapFloorOrdinal, GLOBAL_setMapFloorOrdinal] = useState<number | null>(null);

  return (
    <GlobalContext.Provider
      value={{
        GLOBAL_selectedMapVenueObj,
        GLOBAL_setSelectedMapVenueObj,
        GLOBAL_buildingSelectedID,
        GLOBAL_setBuildingSelectedID,
        GLOBAL_mapFloorOrdinal,
        GLOBAL_setMapFloorOrdinal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
