import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { ReactComponent as UpDownArrow } from 'components/DataTable/icons/upAndDownArrow.svg';
import ShowMoreButton from './ShowMoreButton';
import { StyledDataTableWrapper } from './style';
import SortingOverlay from './SortingOverlay';
import { getNextCount } from 'utils/utils';
import { UnregisteredBeaconType } from 'types/BeaconType';

type NewBeaconsTableTypes = {
  data: UnregisteredBeaconType[];
  clearSelectedRows?: boolean;
  handleSelectRow?: (selectedRows: any) => void;
  selectableRows?: boolean;
  columns: any[];
  preSelectedRows?: any;
  noDataComponent: React.ReactElement | false;
  handleSort?: (column: any, sortDirection: string) => void;
  fetchNextPage: () => void;
  nextIsLoading: boolean;
  searchInProgress?: boolean;
  nextToken: string;
  defaultSortFieldId?: number;
  disableSort?: boolean;
  isSorting?: boolean;
  totalCount: number;
  paginationIndex: number;
  selectedBeaconDialogIsOpen?: boolean;
  handleRowSelected?: (arg: any) => void;
  customStyles: any;
};

export default function RegisteredBeaconsTable({
  data,
  clearSelectedRows,
  handleSelectRow,
  selectableRows,
  columns,
  preSelectedRows,
  noDataComponent,
  handleSort,
  fetchNextPage,
  defaultSortFieldId,
  disableSort,
  nextToken,
  isSorting,
  searchInProgress,
  nextIsLoading,
  totalCount,
  paginationIndex,
  selectedBeaconDialogIsOpen,
  handleRowSelected,
  customStyles,
}: NewBeaconsTableTypes) {
  const nextCount = getNextCount(paginationIndex, totalCount);
  const shouldShowSortOverlay = isSorting || searchInProgress;

  const rowSelectCritera = useMemo(() => {
    //  check to see if any pre-selected rows are passed in,
    //  loop through preSelectedRows to check if any of them match,
    // return isMatched as boolean.
    return (row: UnregisteredBeaconType) => {
      let isMatched = false;

      if (!preSelectedRows) return isMatched;
      isMatched = preSelectedRows.some((preSelectedRow: UnregisteredBeaconType) => {
        return preSelectedRow.beacon_id === row.beacon_id;
      });

      return isMatched;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedRows]);

  return (
    <StyledDataTableWrapper
      className={`table-container ${isSorting ? '--sorting' : ''}${
        disableSort ? '--disable-sort' : ''
      }`}
      style={selectedBeaconDialogIsOpen ? { paddingBottom: '110px' } : {}}
      data-test-id="table"
    >
      <DataTable
        columns={columns}
        selectableRows={selectableRows}
        selectableRowDisabled={() => nextIsLoading} // disable row select while nextIsLoading
        data={data}
        customStyles={customStyles}
        onSelectedRowsChange={handleSelectRow}
        clearSelectedRows={clearSelectedRows}
        selectableRowSelected={rowSelectCritera}
        noDataComponent={noDataComponent}
        onRowClicked={handleRowSelected}
        persistTableHead
        defaultSortFieldId={defaultSortFieldId}
        sortIcon={<UpDownArrow />}
        onSort={handleSort}
        sortServer
      />
      {shouldShowSortOverlay && <SortingOverlay />}
      {nextToken && nextCount > 0 && (
        <ShowMoreButton
          nextIsLoading={nextIsLoading}
          fetchFunction={fetchNextPage}
          nextCount={nextCount}
        />
      )}
    </StyledDataTableWrapper>
  );
}
