import React, { useState } from 'react';
import { Radio, Space } from 'antd';
import Button from 'components/Button/Button';
import ImportIcon from '../../icons/ImportAssetIcon.svg';
import AntModalStyled from 'components/Modals/AntModalStyled';

type confirmationModalType = {
  setConfirmationModalOpen: (arg: boolean) => void;
  handleModalConfirm: (value: number) => void;
  selectedCount: number;
};

export default function ImportConfirmationModal({
  setConfirmationModalOpen,
  handleModalConfirm,
  selectedCount,
}: confirmationModalType) {
  const [value, setValue] = useState(1);

  function handleOptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value, 10);
    setValue(value);
  }

  return (
    <AntModalStyled
      onCancel={() => setConfirmationModalOpen(false)}
      visible
      centered
      footer={null}
      maskClosable={false}
    >
      <header>
        <img src={ImportIcon} alt="import" /> <h3>Import Beacons</h3>
      </header>
      <p style={{ color: '#808285' }}>Please select the action you want to take:</p>
      <Radio.Group size="large" onChange={(e: any) => handleOptionChange(e)} value={value}>
        <Space size="middle" direction="vertical">
          <Radio value={1}>Import only</Radio>
          {/* comment out feature for now until backend is ready */}
          {/* only show this option if single beacon selected */}
          {/* {selectedCount === 1 && <Radio value={2}>Import & Assign to an Asset</Radio>} */}
          {selectedCount === 1 && <Radio value={3}>Import & Create Asset(s)</Radio>}
        </Space>
      </Radio.Group>
      <footer>
        <Button onClick={() => setConfirmationModalOpen(false)} outline>
          Cancel
        </Button>
        <Button onClick={() => handleModalConfirm(value)}>Confirm</Button>
      </footer>
    </AntModalStyled>
  );
}
