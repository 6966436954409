import React from 'react';

import useDevice from 'hooks/useDevice';
import MobileLegendButtonPanel from '../components/mobile/MobileLegendButtonPanel';
import DesktopKeyLegendPanel from '../components/DesktopKeyLegendPanel';

// controller to govern different conditions for legend components to appear.
// mobile legend icon should appear on mobile or short screens.
export default function LegendController() {
  const { isMobileDevice, isShortViewportDevice } = useDevice();
  const shouldShowMobileLegendButton = isMobileDevice || isShortViewportDevice;

  return (
    <>
      {shouldShowMobileLegendButton && <MobileLegendButtonPanel />}
      {!shouldShowMobileLegendButton && <DesktopKeyLegendPanel />}
    </>
  );
}
