import LoadingBars from 'components/Loading/LoadingBars';
import React, { useContext, useEffect, useState } from 'react';
import { StyledLoadingOverlayDiv } from '../Styled';
import { MapControlContext } from '../contexts/mapcontrol.context';

export default function LoadingOverlay(): any {
  const [showOverlay, setShowOverlay] = useState(false);
  const { apiCallInProgress, usersApiCallInProgress, assetsApiCallInProgress } =
    useContext(MapControlContext);

  useEffect(() => {
    const apiCallIsInProgress =
      apiCallInProgress || usersApiCallInProgress || assetsApiCallInProgress;

    setShowOverlay(apiCallIsInProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCallInProgress, usersApiCallInProgress, assetsApiCallInProgress]);

  return showOverlay ? (
    <StyledLoadingOverlayDiv>
      <div className="content-wrapper">
        <LoadingBars barheight={10} barwidth={3} />
        <span>Loading...</span>
      </div>
    </StyledLoadingOverlayDiv>
  ) : null;
}
