import React from 'react';

export default function AssetInfoIcon({ isActive }: { isActive: boolean }) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.24261 0.627197C10.2847 0.627197 12.2296 1.48678 13.7856 3.0985C15.2442 4.81766 16.0222 6.96662 16.0222 9.22302C16.0222 11.4794 15.2442 13.6284 13.7856 15.3475C12.2296 16.9593 10.2847 17.8188 8.24261 17.8188C6.20047 17.8188 4.25558 16.9593 2.69966 15.2401C-0.314926 11.9092 -0.314926 6.42938 2.69966 3.0985C4.25558 1.48678 6.20047 0.627197 8.24261 0.627197ZM8.24261 16.7444C10.0903 16.7444 11.7434 15.9922 13.1048 14.5954C14.369 13.0911 15.0497 11.2645 15.0497 9.22302C15.0497 7.18151 14.369 5.3549 13.1048 3.95808C11.7434 2.45381 10.0903 1.70167 8.24261 1.70167C6.39496 1.70167 4.7418 2.45381 3.38037 3.85063C0.754766 6.85917 0.754766 11.5869 3.38037 14.5954C4.7418 15.9922 6.39496 16.7444 8.24261 16.7444Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
      <path
        d="M9.21505 13.1986H8.72883V8.14857C8.72883 7.82622 8.53434 7.61133 8.24261 7.61133H7.27016C6.97842 7.61133 6.78394 7.82622 6.78394 8.14857C6.78394 8.47091 6.97842 8.68581 7.27016 8.68581H7.85363V13.1986H7.27016C6.97842 13.1986 6.78394 13.4135 6.78394 13.7359C6.78394 14.0582 6.97842 14.2731 7.27016 14.2731H9.21505C9.50679 14.2731 9.70128 14.0582 9.70128 13.7359C9.70128 13.4135 9.50679 13.1986 9.21505 13.1986Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50688 5.35483C9.50688 6.10696 8.92341 6.75165 8.2427 6.75165C7.56198 6.75165 6.97852 6.21441 6.97852 5.35483C6.97852 4.49525 7.56198 3.95801 8.2427 3.95801C8.92341 3.95801 9.50688 4.60269 9.50688 5.35483ZM8.63168 5.24738C8.63168 5.03249 8.43719 4.81759 8.2427 4.81759C8.04821 4.81759 7.85372 5.03249 7.85372 5.24738C7.85372 5.46228 8.04821 5.67717 8.2427 5.67717C8.43719 5.67717 8.63168 5.46228 8.63168 5.24738Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
    </svg>
  );
}
