import Button from 'components/Button/Button';
import useAuth from 'hooks/useAuth.hook';
import React from 'react';

export default function NoPermissionsPage() {
  const { authSignout } = useAuth();

  async function handleClick() {
    await authSignout();
  }

  return (
    <main className="u-flex-center">
      <div className="container">
        <h1 style={{ fontSize: '1.6rem' }}>
          You do not have permission to use this application.
          <br /> Please contact your manager/administrator.
        </h1>

        <br />
        <Button onClick={handleClick}>Sign Out</Button>
      </div>
    </main>
  );
}
