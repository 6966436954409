import { FullConfigContext } from 'contexts/fullConfig.context';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { userEstimateIsNotFresh } from 'utils/utils';

const RecentUsersLastSeenCell = ({ timestamp }: { timestamp: string }) => {
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);

  if (!timestamp) return <span>--</span>;

  const formattedDate = format(new Date(timestamp), 'dd MMM yyyy, HH:mm');
  const showRedHighlight = userEstimateIsNotFresh(timestamp, freshPeriodThresholdSeconds);
  return (
    <span
      className="u-no-pointer-event"
      style={showRedHighlight ? { color: `${colours.error}` } : {}}
    >
      {formattedDate}
    </span>
  );
};

export default RecentUsersLastSeenCell;
