import { shouldUseCognito } from 'utils/utils';
import LocalLogin from 'views/LocalLogin/LocalLogin';

// route for accessing portal using local backend instead of cognito
export const localLoginRoutes = shouldUseCognito()
  ? []
  : [
      {
        url: 'local-login',
        title: 'Local Loging',
        isPrivate: false,
        navigation: false,
        component: LocalLogin,
        navCollapsedDefault: false,
      },
    ];
