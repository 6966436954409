import {
  genericDelete,
  genericGet,
  genericPost,
} from "api/config/base";
import {
  AdminPermissionsDto,
  SystemAdminPermission,
  SystemAdminUserDto
} from "views/config/SystemAdministrators/AdminPermissionsDto";
import { getConfigApiUrl } from "utils/utils";

export function getUser(id: string): Promise<SystemAdminUserDto> {
  return genericGet<SystemAdminUserDto>(`${getConfigApiUrl()}/v1/user/${id}`)
}

export function getIsSystemAdmin(): Promise<boolean> {
  return genericGet<boolean>(`${getConfigApiUrl()}/v1/user/system_admin`)
}

export function getCallerSystemPermissions(): Promise<SystemAdminPermission[]> {
  return Promise.resolve([
    "view_system_configuration",
    "manage_groups_and_roles",
    "manage_system_admins",
    "debug_and_maintenance",
    "manage_roles",
    "view_roles",
    "manage_users",
    "configure_system"
  ] as SystemAdminPermission[]);
  // return genericGet<Array<SystemAdminPermission>>(
  //   `${getConfigApiUrl()}/v1/user/system_admin_permissions`
  // )
}

export function getSystemAdmins(): Promise<Array<SystemAdminUserDto>> {
  return Promise.resolve([
    {
      "id": "843cc060-b068-58a1-b39e-36de028b961d",
      "name": "Aswathy R",
    },
    {
      "id": "d01fd49f-4e10-5a15-9551-8393c13450cb",
      "name": "BaseCreate Porter",

    },
    {
      "id": "d123e303-36c7-59a9-a3be-7dff27821704",
      "name": "BaseUser Porter One",

    },
    {
      "id": "090d1315-44cd-5297-a9dd-2d02384e4a13",
      "name": "Baseview Porter",

    }
  ])
  return genericGet<Array<SystemAdminUserDto>>(`${getConfigApiUrl()}/v1/user/system_admins`)
}

export function getNonSystemAdmins(): Promise<Array<SystemAdminUserDto>> {
  return Promise.resolve([
    {
      "id": "5fa8e341-70e4-5a9d-9439-7bbbc694cfc0",
      "name": "dsfsd sdf",
      "username": "testwriter@navenio.com",
      "email": "testwriter@navenio.com",
      "phone_number": "",
      "enabled": true,
      "federated": false,
      "can_override": false,
      "is_resend_welcome_email_available": true,
      "groups": [],
      "skills": [],
      "inabilities": [],
      "workgroups": {}
    },
    {
      "id": "e8450c7d-5aa8-5aee-89c5-f6a49a4e55ec",
      "name": "Adhoc Cleaner",
      "username": "emmaadhoc",
      "email": "emma.eldret@navenio.com",
      "phone_number": "",
      "enabled": true,
      "federated": false,
      "can_override": false,
      "is_resend_welcome_email_available": false,
      "groups": [
        "iota"
      ],
      "skills": [],
      "inabilities": [],
      "workgroups": {
        "00000000-0000-0000-0000-000000000020": [
          "8581c38a-b0b4-4e9e-b824-8b31ae2ed242"
        ]
      }
    },
    {
      "id": "c29c1148-0e42-5046-9091-05e858be0968",
      "name": "App Test",
      "username": "apptest",
      "email": "daniel.earle@navenio.com",
      "phone_number": "",
      "enabled": true,
      "federated": false,
      "can_override": false,
      "is_resend_welcome_email_available": false,
      "groups": [
        "analytics",
        "iota"
      ],
      "skills": [],
      "inabilities": [],
      "workgroups": {
        "00000000-0000-0000-0000-000000000010": [
          "00000000-0000-0000-0000-000000000014",
          "00000000-0000-0000-0000-000000000015"
        ]
      }
    }
  ])
  return genericGet<Array<SystemAdminUserDto>>(`${getConfigApiUrl()}/v1/users/not_system_admins`)
}

export function getUsers(): Promise<Array<SystemAdminUserDto>> {
  return genericGet<Array<SystemAdminUserDto>>(`${getConfigApiUrl()}/v1/users`)
}

export function getUserAdminPermissions(userId: string): Promise<SystemAdminPermission[]> {

  return Promise.resolve([
    "view_system_configuration",
    "manage_system_admins",
    "manage_users",
    "manage_groups_and_roles"
  ] as SystemAdminPermission[])
  return genericGet<Array<SystemAdminPermission>>(
    `${getConfigApiUrl()}/v1/user/${userId}/system_admin_permissions`
  )
}


export function postUpdateSystemAdminPermissions(
  systemAdminPermissions: AdminPermissionsDto
) {
  return genericPost<boolean, AdminPermissionsDto>(
    `${getConfigApiUrl()}/v1/users/system_admin_permissions`,
    systemAdminPermissions
  );
}

export function deleteRemoveSystemAdmin(
  userId: string
): Promise<boolean> {
  return genericDelete(
    `${getConfigApiUrl()}/v1/user/${userId}/system_admin_permissions`
  );
}
