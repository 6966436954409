import { useEffect, useState } from 'react';

export default function usePageVisibility() {
  const [windowIsVisible, setWindowIsVisible] = useState(
    () => document.visibilityState === 'visible',
  );

  function handleVisibilityChange() {
    setWindowIsVisible(document.visibilityState === 'visible');
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return { windowIsVisible };
}
