import styled from 'styled-components';

const StyledSelectContainer = styled.div`
  width: 100%;
  .react-select__control {
    height: 50px;
  }
`;

export default StyledSelectContainer;
