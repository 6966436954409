import React from 'react';
import DefaultCell from '../cells/DefaultCell';
import ArrowCell from '../cells/ArrowCell';
import { LocalizedBeaconType } from 'types/BeaconType';

export const registeredBeaconColumns = [
  {
    name: 'BEACON ID',
    selector: (row: LocalizedBeaconType) => <DefaultCell value={row.beacon_id} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'beacon_id',
  },
  {
    name: 'BEACON TYPE',
    selector: (row: LocalizedBeaconType) => <DefaultCell value={row.beacon_type} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'beacon_type',
  },
  {
    name: 'SOURCE',
    selector: (row: LocalizedBeaconType) => (
      <DefaultCell value={row.import_info.source || 'manual'} />
    ),
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'source',
  },
  {
    name: 'ASSET NAME',
    selector: (row: LocalizedBeaconType) => <DefaultCell value={row.assigned_asset?.name} />,
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'asset_name',
  },
  {
    name: 'ASSET TYPE',
    selector: (row: LocalizedBeaconType) => (
      <DefaultCell value={row.assigned_asset?.asset_type.name} />
    ),
    sortable: true,
    overflow: 'false',
    grow: 1.5,
    sortField: 'asset_type',
  },
  {
    name: 'ASSET ID',
    selector: (row: LocalizedBeaconType) => <DefaultCell value={row.assigned_asset?.id} />,
    sortable: true,
    minWidth: '230px',
    maxWidth: '410px',
    overflow: 'false',

    sortField: 'asset_id',
  },
  {
    name: 'NOTES',
    selector: (row: LocalizedBeaconType) => <DefaultCell value={row.notes} />,
    sortable: false,
    overflow: 'false',
    grow: 1.5,
  },
  {
    right: true,
    sortable: false,
    cell: () => <ArrowCell />,
    sortField: '',
    width: '10px',
  },
];
