export enum routePath {

  unauthorised = "no-permission",


  assetManagement = '/asset-management',
  beaconManagement = '/beacon-management',
  recentUsers = '/recent-users',
  userLogs = '/users-logs',
}

export enum configRoutePath {
  systemAdminList = "/config",

  Workgroups = "/config/workgroups",

  workgroupRoles = "/config/roles/workgroup-roles",
  addRole = "/config/roles/add-role",
  editRole = "/config/roles/edit-role",
  listRoles = "/config/roles/list-roles",

}