import React, { useEffect } from 'react';
import LoadingNav from './LoadingNav';
import LoadingBars from '../LoadingBars';
import styled from 'styled-components';
import LoadingPlaceholderHeader from './LoadingPlaceholderHeader';

const StyledP = styled.p`
  margin-top: 30px;
`;

export default function LoadingPlaceholderSkeletonPage() {
  useEffect(() => {
    document.querySelector('body')?.classList.add('--loading');

    return () => {
      document.querySelector('body')?.classList.remove('--loading');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper" style={{ paddingBottom: '250px' }}>
      <LoadingNav />
      <LoadingPlaceholderHeader />
      <main className="u-flex-center">
        <div className="container">
          <LoadingBars />
          <StyledP>Loading</StyledP>
        </div>
      </main>
    </div>
  );
}
