import React, { useContext, useState } from 'react';
import AntModalStyled from '../AntModalStyled';
import Button from 'components/Button/Button';
import ClearStatusContent from './ClearStatusContent';
import UpdateStatusContent from './UpdateStatusContent';
import updateStatusIcon from 'assets/icons/updateStatusIcon.svg';
import clearStatusIcon from 'assets/icons/remove-icon-grey.svg';
import { AssetStatesContext } from 'contexts/assetStates.context';
import { AssetStatesObjectType, AssetStatusType } from 'types/AssetStatus';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import AssetsApi from 'api/assets/assets.api';
import { EstimateType } from 'types/Estimate';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { add } from 'date-fns';
import { getAssetsCurrentStatusObj } from './utils';

export type TabContentType = {
  header: string;
  icon: any;
  setState: (arg: any) => void;
  availableAssetStates: AssetStatesObjectType[];
  currentValue: string;
  statusValue: string;
  selectedAssetDetails: EstimateType;
};

export default function AssetStatusUpdateModalController({
  closeHandler,
  selectedAssetDetails,
  callback,
}: {
  closeHandler: () => void;
  selectedAssetDetails: EstimateType;
  callback?: any;
}) {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [updateStatusScreenProgressIndex, setUpdateStatusScreenProgressIndex] = useState(0);
  const { availableAssetStates, assetClearTimes } = useContext(AssetStatesContext);
  const { authState } = useContext(AuthenticationContext);

  const [statusValue, setStatusValue] = useState(
    getAssetsCurrentStatusObj(selectedAssetDetails, availableAssetStates).asset_status_id,
  );
  const [clearStatusValue, setClearStatusValue] = useState<string>(
    assetClearTimes[0].minutes.toString(),
  );
  const [customDateUTCString, setCustomDateUTCString] = useState('');

  const statusScreenComponentsArray = [
    {
      component: UpdateStatusContent,
      props: {
        header: 'Update Asset Status',
        icon: updateStatusIcon,
        setState: setStatusValue,
        currentValue: statusValue,
        availableAssetStates,
        selectedAssetDetails,
      },
    },
    {
      component: ClearStatusContent,
      props: {
        header: 'Clear Status',
        icon: clearStatusIcon,
        setState: setClearStatusValue,
        currentValue: clearStatusValue,
        statusValue: statusValue,
        availableAssetStates,
        setCustomDateUTCString,
      },
    },
  ];
  const currentIndexObj: any = statusScreenComponentsArray[updateStatusScreenProgressIndex];
  const progressButtonText = updateStatusScreenProgressIndex === 0 ? 'Confirm' : 'Set';

  function handleConfirm() {
    if (statusValue === 'clear') {
      handleSubmitStatus(null);
    } else if (updateStatusScreenProgressIndex + 1 === statusScreenComponentsArray.length) {
      // handle finish
      handleSubmitStatus(customDateUTCString);
    } else {
      // progress to next screen
      setUpdateStatusScreenProgressIndex(updateStatusScreenProgressIndex + 1);
    }
  }
  function handleSubmitStatus(customDateUTCString: string | null) {
    const idToken = getIdToken(authState);
    const assetsApi = new AssetsApi(idToken);
    let postBody: AssetStatusType | {} = {};

    setApiCallInProgress(true);

    if (clearStatusValue === 'donotclear') {
      // do not clear case.
      postBody = {
        asset_id: selectedAssetDetails.asset_id,
        asset_status_id: statusValue,
        clear_at_timestamp: null,
      };
    } else if (customDateUTCString === null || '') {
      // clear status of asset.
      postBody = {
        asset_id: selectedAssetDetails.asset_id,
        asset_status_id: null,
        clear_at_timestamp: null,
      };
    } else {
      // cases for times, and custom time
      let customDateUTCStr = customDateUTCString;

      if (clearStatusValue !== 'custom') {
        // set date to value of added minutes from now.
        const derivedDate = add(new Date(), {
          minutes: parseInt(clearStatusValue, 10),
        });
        customDateUTCStr = derivedDate.toISOString();
      }

      postBody = {
        asset_id: selectedAssetDetails.asset_id,
        asset_status_id: statusValue,
        clear_at_timestamp: customDateUTCStr,
      };
    }

    assetsApi
      .postStatusAssignment(postBody)
      .then(() => {
        alertSuccessMessage(
          `${selectedAssetDetails.asset_name} - Status has been updated successfully`,
        );

        setApiCallInProgress(false);
        closeHandler();
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage(
          `${selectedAssetDetails.asset_name} - Status could not be updated, please try again in a few moments `,
        );
        setApiCallInProgress(false);
      });
  }

  return (
    <AntModalStyled onCancel={closeHandler} open centered footer={null} maskClosable={false}>
      <div data-test-id="asset-status-modal">
        {React.createElement(currentIndexObj.component, {
          ...currentIndexObj.props,
        })}

        <footer>
          <Button onClick={closeHandler} outline>
            Cancel
          </Button>

          <Button
            isLoading={apiCallInProgress}
            onClick={handleConfirm}
            data-test-id="submit-button"
          >
            {progressButtonText}
          </Button>
        </footer>
      </div>
    </AntModalStyled>
  );
}
