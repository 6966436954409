import { FullConfigContext } from 'contexts/fullConfig.context';
import { VenuesContext } from 'contexts/venues.context';
import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { UserType } from 'types/userInfo';
import { assetEstimateIsNotFresh, getValueSafely } from 'utils/utils';

const LocationCellRecentUser = ({ user }: { user: UserType }) => {
  const { isSingleVenue } = useContext(VenuesContext);
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);

  if (user !== undefined && user.location) {
    const assetIsOutdoor = user.location?.is_outdoors;

    let showError = false;
    if (assetEstimateIsNotFresh(user.last_update_timestamp, freshPeriodThresholdSeconds)) {
      showError = true;
    }

    return (
      <div
        className="u-no-pointer-event"
        data-tag="allowRowEvents"
        style={{
          color: showError ? `${colours.error}` : ``,
        }}
      >
        {!isSingleVenue && <div>{getValueSafely(user.location?.venue?.name)}</div>}
        <div>
          {assetIsOutdoor ? (
            <div>Outside Mapped Buildings</div>
          ) : (
            <div>
              <div data-test-id="floor-name">
                {getValueSafely(user.location?.building_level?.level.name)}/
                {getValueSafely(user.location?.building_level?.possible_buildings[0].name)}
              </div>
              <div data-test-id="region-name">{getValueSafely(user.location?.region?.name)}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
  return <>––</>;
};
export default LocationCellRecentUser;
