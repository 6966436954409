import { useNavigate } from 'react-router-dom';

export default function useFeaturePermissionRedirect() {
  const navigate = useNavigate();

  function redirectToFeatureDependantHomepage() {
    // a function to redirects to a homepage depending on the feature permission the user has.

    navigate('/map'); // currently redirecting any feature permission users to map view.
  }

  return redirectToFeatureDependantHomepage;
}
