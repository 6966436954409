import { Checkbox } from 'antd';
import React from 'react';
import { getArrayOfValuesFromOptions } from 'views/MapPage/utils/utils';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { StyledMobileAssetsPeopleCheckBoxGroup } from 'views/MapPage/Styled';

type optionsType = {
  value: string;
  label: string;
};

export const options: optionsType[] = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'people',
    label: 'People',
  },
];
type MobileAssetsPeopleCheckBoxGroupType = {
  setAssetUserCheckboxArray: (arg: CheckboxValueType[]) => void;
  assetUserCheckboxArray: CheckboxValueType[];
};

export default function MobileAssetsPeopleCheckBoxGroup({
  setAssetUserCheckboxArray,
  assetUserCheckboxArray,
}: MobileAssetsPeopleCheckBoxGroupType) {
  const defaultOptions = getArrayOfValuesFromOptions(options);

  const handleCheckboxClick = (e: CheckboxValueType[]) => {
    if (e.length >= 1) {
      setAssetUserCheckboxArray(e);
    } else {
      const nextSelected = getDeterminedSelection();

      setAssetUserCheckboxArray([nextSelected]);
    }
  };

  function getDeterminedSelection() {
    // ensure always one option is selected. If user unselects single checkbox, we select the opposite one.
    const previousSelected: any = assetUserCheckboxArray[0];
    const oppositeOfSelected = defaultOptions.find(
      (option: string[]) => option !== previousSelected,
    );

    return oppositeOfSelected;
  }

  return (
    <StyledMobileAssetsPeopleCheckBoxGroup>
      <Checkbox.Group
        value={assetUserCheckboxArray}
        options={options}
        defaultValue={defaultOptions}
        onChange={handleCheckboxClick}
      />
    </StyledMobileAssetsPeopleCheckBoxGroup>
  );
}
