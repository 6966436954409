import React from 'react';
import navenioLogo from 'assets/images/navenio-landscape.svg';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const StyledHeader = styled.header`
  margin: 0 0 40px 0;
  img {
    display: block;
    margin: 10px auto 100px auto;
  }
  h1 {
    font-size: 18px;
    text-align: center;
  }
`;

const MsgStyle = styled.div`
  p {
    text-align: center;
    color: ${colours.secondaryText};
  }
`;

function getText(notPortalAccess: boolean, text?: string) {
  if (text) return text;
  else {
    return notPortalAccess
      ? 'Please use a larger screen to access the ILS portal.'
      : 'Please use a wider screen to configure your site.';
  }
}

export default function WidthNotSupportedPage({
  notPortalAccess = false,
  text,
}: {
  notPortalAccess?: boolean;
  text?: string;
}) {
  return (
    <>
      {notPortalAccess && (
        <StyledHeader>
          <img src={navenioLogo} alt="navenio logo" />
          <h1>Intelligent Location Services</h1>
        </StyledHeader>
      )}
      <main className={notPortalAccess ? '' : 'u-flex-center'}>
        <MsgStyle className="container">
          <p>{getText(notPortalAccess, text)}</p>
        </MsgStyle>
      </main>
    </>
  );
}
