import React from 'react';
import { EstimateType } from 'types/Estimate';
import TableCellWarning from 'components/DataTable/cells/TableCellWarning';
import { getValueSafely } from 'utils/utils';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import warningIcon from './warning.svg';

const BatteryCell = (row: EstimateType) => {
  if (!row.assigned_beacon || !row.assigned_beacon.battery) return <>––</>;
  if (row.assigned_beacon.battery <= LOW_BATTERY_THRESHOLD) {
    return (
      <TableCellWarning>
        {getValueSafely(row.assigned_beacon.battery, '%')}
        <img
          style={{
            marginLeft: '13px',
            width: '20px',
          }}
          src={warningIcon}
          alt="low battery"
        />
      </TableCellWarning>
    );
  } else {
    return (
      <span className="u-no-pointer-event">{getValueSafely(row.assigned_beacon.battery, '%')}</span>
    );
  }
};

export default BatteryCell;
