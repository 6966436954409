import { FullConfigContext } from 'contexts/fullConfig.context';
import React, { useContext } from 'react';
import { featurePermissionType } from 'types/permissions';
import { getArraysIntersection } from 'utils/utils';

type ComponentFeaturePermissionRenderWrapperType = {
  children: React.ReactElement;
  featurePermissions: featurePermissionType[] | any;
  requiredPermissions?: featurePermissionType[];
};

// a simple wrapper to detect if the component has the required level of feature permissions to be displayed.
export default function ComponentFeaturePermissionRenderWrapper({
  children,
  featurePermissions, // must have one of these permissions
  requiredPermissions, // must have all permissions.
}: ComponentFeaturePermissionRenderWrapperType) {
  const { userFeaturePermissionsArray } = useContext(FullConfigContext);

  let componentHasPermission =
    getArraysIntersection(userFeaturePermissionsArray, featurePermissions).length !== 0;

  if (requiredPermissions) {
    let index = 0;
    requiredPermissions.forEach((permission) => {
      if (userFeaturePermissionsArray.includes(permission)) {
        index++;
      }
    });
    componentHasPermission = index === requiredPermissions.length;
  }

  return <> {componentHasPermission ? children : null}</>;
}
