import React, { useEffect } from 'react';
import useBroadcastChannel from 'hooks/useBroadcastChannel';

export default function UnauthenticatedPage() {
  const { postBroadcastMessage } = useBroadcastChannel();

  useEffect(() => {
    //redirect to Signout page after 1 second. To let user know what is happening.
    setTimeout(() => {
      postBroadcastMessage('broadcast_signout');
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container">
        <p>Redirecting To Signout Page</p>
      </div>
    </main>
  );
}
