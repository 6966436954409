import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const Warning = styled.span`
  color: ${colours.error};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableCellWarning = ({ children }: { children: React.ReactNode }) => {
  return <Warning className="u-no-pointer-event">{children}</Warning>;
};
export default TableCellWarning;
