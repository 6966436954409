import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalAuth } from 'utils/_localAuth';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function handleResponseError(params: URLSearchParams): string | undefined {
  if (params.get('error') === undefined) {
    return undefined;
  }
  const errorMessage = params.get('error_description') ?? '';
  return decodeURIComponent(errorMessage.replace(/\+/g, ' '));
}

const LocalLogin = () => {
  const [error, setError] = useState<string | undefined>(undefined);

  const params = useQuery();

  useEffect(() => {
    async function getToken() {
      const code = params.get('code');
      if (code == null) {
        setError(handleResponseError(params));
        return;
      }

      try {
        await LocalAuth.tokenRequest(code);
      } finally {
        window.location.href = '/';
      }
    }
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return error !== undefined ? <h1>{error}</h1> : <div></div>;
};
export default LocalLogin;
