import { formatISO, startOfDay, addDays, format } from 'date-fns';

export function getISOFormatStartEndDates(
  startDateParam: Date | number,
  endDateParam: Date | number,
) {
  // create ISO dates from users selections
  const startDate = new Date(startDateParam);
  const endDate = new Date(endDateParam); // add day so we use midnight the following day as end date.
  const startISOString = formatISO(startOfDay(startDate));
  const endISOString = formatISO(addDays(startOfDay(endDate), 1));
  return {
    startISOString,
    endISOString,
  };
}
export function getSuccessAlertString(startDate: number | Date, endDate: number | Date) {
  const formattedStartDate = format(new Date(startDate), 'MMM dd');
  const formattedEndDate = format(new Date(endDate), 'MMM dd');
  const formattedYear = format(new Date(endDate), 'yyyy');

  const string = `User Logs ${formattedStartDate} - ${formattedEndDate} ${formattedYear} log has been downloaded successfully`;
  return string;
}
