import React, { useContext, useEffect, useRef, useState } from 'react';
import { MapControlContext } from 'views/MapPage/contexts/mapcontrol.context';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import { StyledMapDropdownCheckboxInputSelect } from './styles';
import dropdownIcon from './dropdownIcon.svg';
import { ReactSelectType } from '../../VenueSelectDropdownControl';

type optionsType = {
  value: string;
  label: string;
};

const options: optionsType[] = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'people',
    label: 'People',
  },
];

function getTextToDisplay(selectedCheckboxArray: optionsType[]): string {
  if (selectedCheckboxArray.length === 2) return 'Assets & People';
  else return selectedCheckboxArray[0].label;
}

export default function ShowAssetPeopleDropdown({
  currentMenuOpenType,
  setCurrentMenuOpenType,
}: {
  currentMenuOpenType: string | null;
  setCurrentMenuOpenType: (arg: string | null) => void;
}) {
  const { setShowAssetsChecked, setShowUsersChecked } = useContext(MapControlContext);
  const { showAssetsPeopleSelectedCheckboxArrayCachedRef } = useContext(SearchFilterContext);

  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef: any = useRef();
  const menuType = 'assetspeople';

  const [selectedCheckboxArray, setSelectedCheckboxArray] = useState<optionsType[]>(() => {
    return showAssetsPeopleSelectedCheckboxArrayCachedRef.current !== null
      ? showAssetsPeopleSelectedCheckboxArrayCachedRef.current
      : options;
  });

  function handleMenuOpen(ev: React.MouseEvent, menuType: string) {
    ev.stopPropagation();
    setMenuOpen(!menuOpen);
    setCurrentMenuOpenType(menuType);
  }

  const handleCheckboxClick = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checkedValueObj: optionsType,
  ) => {
    let valuePresent = false;

    selectedCheckboxArray.forEach((obj: ReactSelectType) => {
      if (obj.value === checkedValueObj.value) {
        valuePresent = true;
      }
    });

    if (valuePresent) {
      // remove value
      const newOptions = selectedCheckboxArray.filter((optionObj: ReactSelectType) => {
        return optionObj.value !== checkedValueObj.value;
      });
      const nextSelected: any = getDeterminedSelection();

      setSelectedCheckboxArray(newOptions.length < 1 ? [nextSelected] : newOptions);
    } else {
      // add value
      if (!valuePresent) {
        const originalValue = options.filter((optionObj: optionsType) => {
          return optionObj.value === checkedValueObj.value;
        })[0];
        const newOptions = [...selectedCheckboxArray, originalValue];
        setSelectedCheckboxArray(newOptions);
      }
    }
  };

  function getDeterminedSelection() {
    // ensure always one option is selected. If user unselects single checkbox, we select the opposite one.
    const previousSelectedValue = selectedCheckboxArray[0].value;
    const oppositeOfSelected = options.find(
      (option: optionsType) => option.value !== previousSelectedValue,
    );

    return oppositeOfSelected;
  }

  useEffect(() => {
    // keep track of checkboxes in dropdown, and update states accordingly.
    const assetsChecked = selectedCheckboxArray.some((option) => option.value === 'assets');
    const usersChecked = selectedCheckboxArray.some((option) => option.value === 'people');

    setShowAssetsChecked(assetsChecked);
    setShowUsersChecked(usersChecked);
    showAssetsPeopleSelectedCheckboxArrayCachedRef.current = selectedCheckboxArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCheckboxArray]);

  useEffect(() => {
    // useEffect to close all menus when others are opened.
    if (currentMenuOpenType !== menuType) {
      setMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuOpenType]);

  function checkClickOutside(e: any) {
    if (dropdownRef && dropdownRef.current) {
      if (!dropdownRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    }
  }

  useEffect(() => {
    // attach ev listener to detect click outside of dropdown, and close menu if user clicks.
    document.addEventListener('click', checkClickOutside);
    return () => {
      document.removeEventListener('click', checkClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMapDropdownCheckboxInputSelect className="asset-people-input">
      <div
        className="input-container"
        onClick={(ev) => handleMenuOpen(ev, 'assetspeople')}
        tabIndex={0}
      >
        <span className="preplaceholder">Show:</span>
        <label htmlFor="showAssetsPeople">{getTextToDisplay(selectedCheckboxArray)}</label>
        <img src={dropdownIcon} alt="open menu" />
        <input placeholder="" type="text" id="showAssetsPeople" name="showAssetsPeople" />
      </div>

      {menuOpen && (
        <div className="list-container" ref={dropdownRef}>
          <ul>
            {options.map((option: optionsType) => {
              const isChecked = selectedCheckboxArray.some((optionObj: ReactSelectType) => {
                return optionObj.value === option.value;
              });

              return (
                <li key={option.value} role="button" tabIndex={0}>
                  <label htmlFor={option.value} className={isChecked ? '--checked' : ''}>
                    <span className="custom-checkbox" />
                    {option.label}

                    <input
                      id={option.value}
                      type="checkbox"
                      name={option.value}
                      onChange={(ev) => handleCheckboxClick(ev, option)}
                    />
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </StyledMapDropdownCheckboxInputSelect>
  );
}
