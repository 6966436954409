import React, { useContext, useEffect, useRef, useState } from 'react';
import DropdownControlReadoutString from './DropdownControlReadoutString';
import dropdownIcon from './dropdownIcon.svg';
import { StyledMapDropdownCheckboxInputSelect } from './styles';
import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import { ReactSelectType } from '../../VenueSelectDropdownControl';
import DropdownMenu from './DropdownMenu';
import useMapHooksExternalMapRef from 'views/MapPage/hooks/useMapHooksExternalMapRef';

function getOptionsValueArrayFromReactSelectOptions(options: ReactSelectType[]) {
  return options.map((option: ReactSelectType) => option.value);
}

export default function MapDropdownCheckboxInputSelect({
  options,
  preplaceholdertext,
  type,
  setStateMethod,
  setCurrentMenuOpenType,
  currentMenuOpenType,
  dataTestId,
  setFiltersModified,
}: {
  options: ReactSelectType[];
  preplaceholdertext: string;
  type: string;
  setStateMethod: (arg: string[]) => void;
  setCurrentMenuOpenType: (arg: string) => void;
  currentMenuOpenType: string | null;
  dataTestId: string;
  setFiltersModified: (arg: boolean) => void;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [checkedOptionsArray, setCheckedOptionsArray] = useState(options);
  const [checkAllSelected, setCheckAllSelected] = useState(
    checkedOptionsArray.length === options.length,
  );
  const { inSearchMode, searchAPIcallInProgress } = useContext(SearchFilterContext);
  const dropdownRef: any = useRef(null);
  const { mapZoomStartCallback } = useMapHooksExternalMapRef();

  mapZoomStartCallback(() => setMenuOpen(false)); // close menu anytime zoomchanges.

  function handleCheckboxChange(ev: React.ChangeEvent<HTMLInputElement>, checkedValue: string) {
    let valuePresent = false;

    checkedOptionsArray.forEach((obj: ReactSelectType) => {
      if (obj.value === checkedValue) {
        valuePresent = true;
      }
    });

    if (valuePresent) {
      // remove value
      const newOptions = checkedOptionsArray.filter((optionObj: ReactSelectType) => {
        return optionObj.value !== checkedValue;
      });
      setCheckedOptionsArray(newOptions);
    } else {
      // add value
      if (!valuePresent) {
        const originalValue = options.filter((optionObj: ReactSelectType) => {
          return optionObj.value === checkedValue;
        })[0];
        const newOptions = [...checkedOptionsArray, originalValue];
        setCheckedOptionsArray(newOptions);
      }
    }
    setFiltersModified(true);
  }

  function handleCheckAllChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = ev.target.checked;

    setCheckAllSelected(isChecked);
    setCheckedOptionsArray(isChecked ? options : []);
    setFiltersModified(true);
  }

  function checkClickOutside(e: React.MouseEvent) {
    if (dropdownRef && dropdownRef.current) {
      if (!dropdownRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    }
  }

  function handleMenuOpen(ev: any, type: string) {
    ev.stopPropagation();
    setMenuOpen(!menuOpen);
    setCurrentMenuOpenType(type);
  }

  useEffect(() => {
    // useeffect for logic when dropdown options changes.
    if (!inSearchMode) {
      const valueArray = getOptionsValueArrayFromReactSelectOptions(checkedOptionsArray);

      setStateMethod(valueArray.length === 0 ? ['nop'] : valueArray); // set to nop if no params set, to give api a non value to filter with so nothing is returned.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedOptionsArray]);

  useEffect(() => {
    // attach ev listener to detect click outside of dropdown, and close menu if user clicks.
    document.addEventListener('click', (e: any) => checkClickOutside(e));
    return () => {
      document.removeEventListener('click', (e: any) => checkClickOutside(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //reset check boxes, after performing a search

    return () => {
      const valueArray = getOptionsValueArrayFromReactSelectOptions(checkedOptionsArray);
      setCheckedOptionsArray(options);
      setStateMethod(valueArray);
      setMenuOpen(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inSearchMode]);

  useEffect(() => {
    // useEffect to close all menus when others are opened.
    if (currentMenuOpenType !== type) {
      setMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuOpenType]);

  useEffect(() => {
    setCheckAllSelected(checkedOptionsArray.length === options.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedOptionsArray]);

  return (
    <StyledMapDropdownCheckboxInputSelect data-test-id={dataTestId}>
      <div className="input-container" onClick={(ev) => handleMenuOpen(ev, type)} tabIndex={0}>
        <span className="preplaceholder"> {preplaceholdertext}</span>
        <label htmlFor={type}>
          <DropdownControlReadoutString
            type={type}
            checkAll={checkAllSelected}
            checkedList={checkedOptionsArray}
          />
        </label>
        <img src={dropdownIcon} alt="open menu" />
        <input placeholder="" type="text" id={type} name={type} />
      </div>

      {menuOpen && (
        <DropdownMenu
          dropdownRef={dropdownRef}
          type={type}
          checkAll={checkAllSelected}
          searchAPIcallInProgress={searchAPIcallInProgress}
          options={options}
          checkedOptionsArray={checkedOptionsArray}
          handleCheckAllChange={handleCheckAllChange}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </StyledMapDropdownCheckboxInputSelect>
  );
}
