import { FullConfigContext } from 'contexts/fullConfig.context';
import { format, formatDistanceToNowStrict } from 'date-fns';
import React, { useContext } from 'react';
import { colours } from 'styling/colours';
import { assetEstimateIsNotFresh, replaceWordsInDistanceString } from 'utils/utils';

const LastSeenReadoutSpan = ({ timestamp }: { timestamp: string }) => {
  const { freshPeriodThresholdSeconds } = useContext(FullConfigContext);
  if (!timestamp) return <span>––</span>;

  const distance = formatDistanceToNowStrict(new Date(timestamp), { roundingMethod: 'floor' });

  if (assetEstimateIsNotFresh(timestamp, freshPeriodThresholdSeconds)) {
    return (
      <span style={{ display: 'flex' }}>
        <p style={{ color: `${colours.error}`, paddingRight: '10px' }}>
          {replaceWordsInDistanceString(distance)} ago
        </p>
        ({format(new Date(timestamp), 'dd/MM/yyyy - HH:mm')})
      </span>
    );
  }
  return <span>{replaceWordsInDistanceString(distance)} ago</span>;
};

export default LastSeenReadoutSpan;
