import React, { useEffect, useState } from 'react';
import { outdoorLocationString } from './AssetSearchAsyncSelect';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const StyledSpan = styled.span`
  display: inline;
  color: ${colours.tertiaryText};
`;

// label readout with text highlighted that matches searchterm
export default function OutdoorMatchedSearchtermLabel({ searchTerm }: { searchTerm: string }) {
  const [highlightTextArray, setHighlightTextArray] = useState<number[]>([]);

  const splitStringArray = outdoorLocationString.split('');

  useEffect(() => {
    // Highlighting text logic. runs on each update to search term
    // sets an array which corresponds to the index of the letters that should highlight.
    const outdoorLocationStringLC = outdoorLocationString.toLowerCase();
    const searchTermLC = searchTerm.toLowerCase();
    const searchTermArray = searchTermLC.split('');
    const matchStartIndex = outdoorLocationStringLC.indexOf(searchTermLC);
    let indices: number[] = [];

    // loop through outdoor location string, starting at the matching term index.
    for (let i = matchStartIndex; i <= outdoorLocationStringLC.length; i++) {
      // then for each iteration, loop through search term letters
      searchTermArray.forEach((letter) => {
        // if current letter matches iteration of letter from searchterm
        if (outdoorLocationStringLC[i] === letter) {
          const lastIndice = indices[indices.length - 1];
          // ignore empty array, and check if that the number to be added is adjacent in sequence to the previous one
          if (indices.length === 0 || (i === lastIndice + 1 && i > lastIndice)) {
            // ensure highlighted letters do not exceed searchterm length
            if (indices.length < searchTermArray.length) {
              // add to indices array
              indices.push(i);
            }
            return;
          }
        }
      });
    }
    setHighlightTextArray(indices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <>
      {splitStringArray.map((letter, i) => {
        const letterShouldHighlight = highlightTextArray.includes(i);
        return letterShouldHighlight ? letter : <StyledSpan key={i}>{letter}</StyledSpan>;
      })}
    </>
  );
}
