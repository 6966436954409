import React from 'react';
import styled from 'styled-components';

export type NoDataComponentType = {
  text: string | React.ReactNode;
  selectedSearchMenuOptionLabel?: string;
};

const StyledDiv = styled.div`
  width: 100%;
  text-align: center;
  margin: 0px auto;
  background-color: #f9f9fb;
  border: 1px solid #bcbec066;
  padding: 30px;
  p {
    font-size: 15px;
    color: #414042;
    margin: 0;
  }
`;

export default function NoDataComponent({
  text,
  selectedSearchMenuOptionLabel,
}: NoDataComponentType) {
  return (
    <StyledDiv data-test-id="no-results-message">
      <p>
        <>
          {text}{' '}
          {selectedSearchMenuOptionLabel && (
            <>
              in <b> {selectedSearchMenuOptionLabel}</b>
            </>
          )}
        </>
      </p>
    </StyledDiv>
  );
}
