import { FullConfigContext } from 'contexts/fullConfig.context';
import useFeaturePermissionRedirect from 'hooks/useFeaturePermissionRedirect';
import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { featurePermissionType } from 'types/permissions';
import { getArraysIntersection } from 'utils/utils';

export default function UserFeatureCheckRouterWrapper({
  children,
  routeFeaturePermissions,
}: {
  children: React.ReactNode;
  routeFeaturePermissions: featurePermissionType[] | undefined;
}): any {
  let routeHasPermissions = true;

  const redirectToFeatureDependantHomepage = useFeaturePermissionRedirect();
  const location = useLocation();
  const { userFeaturePermissionsArray } = useContext(FullConfigContext);

  if (routeFeaturePermissions) {
    routeHasPermissions =
      getArraysIntersection(userFeaturePermissionsArray, routeFeaturePermissions).length !== 0;
  }

  useEffect(() => {
    // useeffect to check if user is routing to landing page, and redirect to correct homepage
    if (location.pathname === '/') {
      redirectToFeatureDependantHomepage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return routeHasPermissions ? children : <Navigate to="/no-feature-permission" />;
}
