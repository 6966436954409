import React, { useContext } from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { FullConfigContext } from 'contexts/fullConfig.context';

type TabListTypes = {
  handleTabSelect: (key: string) => void;
  activeKey: string;
  newBeaconQueryCount: number;
  registeredBeaconQueryCount: number;
};

const TabsStyled = styled(Tabs)`
  max-width: 425px;
  .ant-tabs-tab {
    padding-bottom: 5px;
  }
  .ant-tabs-nav-more {
    display: none; // hide default elipsis button
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    height: 3px;
  }
`;

export default function Tablist({
  activeKey,
  handleTabSelect,
  newBeaconQueryCount,
  registeredBeaconQueryCount,
}: TabListTypes) {
  const { userAccessPermissions } = useContext(FullConfigContext);

  const tabItems = [
    {
      key: '1',
      label: `New Beacons (${newBeaconQueryCount})`,
      disabled: !userAccessPermissions.hasWrite, // disable without write permission.
    },
    {
      key: '2',
      label: `Registered Beacons (${registeredBeaconQueryCount})`,
    },
  ];
  return (
    <TabsStyled moreIcon={null} activeKey={activeKey} onChange={handleTabSelect} items={tabItems} />
  );
}
