import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import VenueApi from 'api/venue/venue.api';
import { VenuesContext } from './venues.context';
import MapPlaceholderLoadingScreen from '../components/MapPlaceholderLoadingScreen';

type VenueFilesContextTypes = {
  buildingFeatures: any;
  levelsFeatures: any;
  unitFeatures: any;
};

export const VenueFilesContext = createContext({} as VenueFilesContextTypes);

type VenueFilesProviderProps = {
  children: ReactNode;
};

// A context that fetches the files needed to render the GeoJSON on map using specified venue ID.
const VenueFilesProvider = ({ children }: VenueFilesProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);

  // features to store geoJson data required for maps
  const [buildingFeatures, setBuildingFeatures] = useState([]); // buildings info to render geojson from.
  const [levelsFeatures, setLevelsFeatures] = useState([]); //outline of buildings. | Default, when no building selected.
  const [unitFeatures, setUnitFeatures] = useState([]); //outline of rooms, show when building is clicked.

  const { selectedVenueObj } = useContext(VenuesContext);

  const value: VenueFilesContextTypes = {
    buildingFeatures,
    levelsFeatures,
    unitFeatures,
  };

  const geojsonDataObj = [
    { key: 'building', setState: setBuildingFeatures, fileName: 'building.geojson' },
    { key: 'levels', setState: setLevelsFeatures, fileName: 'level.geojson' },
    { key: 'unit', setState: setUnitFeatures, fileName: 'unit.geojson' },
  ];
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  useEffect(() => {
    //loop through all required files and set features to relevant states.
    // set isLoading to false, after looping through all files and resolving promises.
    // update whenever selectedVenueID is changed, and pass new venueID into apicall.
    const venueApi = new VenueApi(token);

    let count = 0;
    setIsLoading(true);

    geojsonDataObj.forEach((file: any) => {
      venueApi
        .getVenueFile(selectedVenueObj.venue_id, file.fileName)
        .then(async (res) => {
          await file.setState(res.features);
          count++;
          if (count === geojsonDataObj.length) {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alertErrorMessage('Could not load venue files.');
          console.error(err);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenueObj]);

  return !isLoading ? (
    <VenueFilesContext.Provider value={value}>{children}</VenueFilesContext.Provider>
  ) : (
    <MapPlaceholderLoadingScreen />
  );
};

export default VenueFilesProvider;
