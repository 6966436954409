import React from 'react';
import Button from 'components/Button/Button';
import ErrorIcon from 'assets/icons/error-2.svg';
import { getStatusText } from './utils';

export default function ModalInvalidFileContent({
  isAssetUpload,
  fileName,
  fileStatus,
  setUploadModalOpen,
}: {
  isAssetUpload: boolean;
  fileName: string;
  fileStatus: any;
  setUploadModalOpen: (arg: boolean) => void;
}) {
  const statusText = getStatusText(fileStatus);

  return (
    <>
      <header>
        <img style={{ width: '40px', height: '40px' }} src={ErrorIcon} alt="error" />
        <h3>Bulk {isAssetUpload ? 'Assets' : 'Beacons'} Upload</h3>
      </header>
      <p style={{ fontSize: '15px' }}>
        <>
          Sorry, the <b>{fileName}</b> file could not be uploaded{statusText}
        </>
      </p>
      <footer>
        <Button onClick={() => setUploadModalOpen(false)} outline>
          OK
        </Button>
      </footer>
    </>
  );
}
