import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { alertErrorMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, getPhysicalMapVenuesOnly, getSortedArrayByValue } from 'utils/utils';
import VenueApi from 'api/venue/venue.api';
import { VenueListType } from 'types/venue';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';

type VenuesContextTypes = {
  venuesList: VenueListType[];
  isSingleVenue: boolean;
};

export const VenuesContext = createContext({} as VenuesContextTypes);

type VenuesProviderProps = { children: ReactNode };

// A context to fetch a list of available venues for the user.
const VenuesProvider = ({ children }: VenuesProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [venuesList, setVenuesList] = useState([]);

  const isSingleVenue = venuesList.length < 2;

  const value: VenuesContextTypes = {
    venuesList,
    isSingleVenue,
  };
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);

  useEffect(() => {
    // call venues API for list of all venues.
    const venueApi = new VenueApi(token);
    setIsLoading(true);

    venueApi
      .getVenuesList()
      .then((res) => {
        const physicalMapVenues = getPhysicalMapVenuesOnly(res.venues);
        const sortedVenuesList = getSortedArrayByValue(physicalMapVenues, 'name');

        setVenuesList(sortedVenuesList);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alertErrorMessage('Error fetching Venues List');
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <VenuesContext.Provider value={value}>{children}</VenuesContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
};

export default VenuesProvider;
