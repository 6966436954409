import React, { useEffect, useRef, useState } from 'react';

export default function useUploadFormDOMEvents(handleFileSelect: (arg: React.DragEvent) => void) {
  // a hook to contain the event handlers used for file upload drag and drop.
  const [fileIsDragging, setFileIsDragging] = useState(false);

  const dropFileContainerRef: any = useRef();
  const fileInputRef: any = useRef();

  function preventDefaults(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  function setIsDragging() {
    setFileIsDragging(true);
  }

  function unsetIsDragging() {
    setFileIsDragging(false);
  }

  useEffect(() => {
    // add event listeners on component mount
    const dropArea: any = dropFileContainerRef.current;
    const fileInput: any = fileInputRef.current;

    if (dropArea) {
      // prevent defaults on events
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });
      // drag start events
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, setIsDragging, false);
      });
      // drag end events
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unsetIsDragging, false);
      });
      // drop handle event
      dropArea.addEventListener('drop', handleFileSelect, false);
    }
    // input file select event
    if (fileInput) {
      fileInput.addEventListener('change', handleFileSelect, false);
    }
    return () => {
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, preventDefaults, false);
      });
      // drag start events
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, setIsDragging, false);
      });
      // drag end events
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.removeEventListener(eventName, unsetIsDragging, false);
      });
      // drop handle event
      dropArea.removeEventListener('drop', handleFileSelect, false);

      // input file select event
      fileInput.remove('change', handleFileSelect, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fileIsDragging, dropFileContainerRef, fileInputRef };
}
