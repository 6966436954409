import React from 'react';
import { StyledPlaceholderDataTableRow } from './styled';

export default function LoadingPlaceholderTableRow() {
  return (
    <StyledPlaceholderDataTableRow>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </StyledPlaceholderDataTableRow>
  );
}
