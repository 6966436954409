import React from 'react';
import arrowRight from './arrow-right.svg';

const ArrowCell: React.FC = () => {
  return (
    <img
      className="u-no-pointer-event"
      src={arrowRight}
      alt="view details"
      width={10}
      height={10}
    />
  );
};

export default ArrowCell;
