import React from 'react';
import { Radio, Space } from 'antd';
import { colours } from 'styling/colours';
import { TabContentType } from './AssetStatusUpdateModalController';

export default function UpdateStatusContent({
  header,
  icon,
  setState,
  availableAssetStates,
  currentValue,
  selectedAssetDetails,
}: TabContentType) {
  const assetHasStatus =
    selectedAssetDetails.status_assignment?.asset_status !== null &&
    selectedAssetDetails.status_assignment?.asset_status !== undefined;

  function handleOptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setState(value);
  }

  return (
    <>
      <header>
        <img src={icon} alt={header} />
        <h3>{header}</h3>
      </header>
      <p style={{ color: colours.secondaryText }}>Update this assets status to:</p>

      <Radio.Group size="large" onChange={(e: any) => handleOptionChange(e)} value={currentValue}>
        <Space size="middle" direction="vertical">
          {availableAssetStates.map((state) => {
            return (
              <Radio key={state.asset_status_id} value={state.asset_status_id}>
                {state.description}
              </Radio>
            );
          })}

          {assetHasStatus && (
            <Radio value={'clear'}>
              <b>Clear Status</b>
            </Radio>
          )}
        </Space>
      </Radio.Group>
    </>
  );
}
