import axios from 'axios';
import config from 'react-global-configuration';
import { AssetStatusType } from 'types/AssetStatus';
import { getAssetAPIFilterEnum } from 'views/BeaconManagementPage/enum';

class AssetsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getAssets = async (
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
    searchTerm = '',
    searchCategory = '',
  ) => {
    const queryString = new URLSearchParams();

    queryString.append('limit', `50`);
    if (sortColumn !== '') {
      queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    if (nextToken !== '' && nextToken !== null) {
      queryString.append('next', nextToken);
    }

    // append search params if they exist:
    if (searchCategory !== '' && searchTerm !== '') {
      if (searchCategory === 'location') {
        // location search does not need filter.
        queryString.append(`location_name_filter`, searchTerm);
      } else if (searchCategory === 'is_outdoors') {
        queryString.append(`outdoors_filter`, 'true');
      } else {
        queryString.append(getAssetAPIFilterEnum[searchCategory], searchTerm);
      }
    } else if (searchTerm !== '') {
      queryString.append(`name_filter`, searchTerm);
      queryString.append(`owner_filter`, searchTerm);
      queryString.append(`asset_type_filter`, searchTerm);
      queryString.append(`location_name_filter`, searchTerm);
    }

    const response = await fetch(
      `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`,
      this.axiosConfig,
    );
    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getSingleAssetById = async (assetId: string) => {
    const response = await fetch(
      `${config.get().DATA_API_URL}/assets/${encodeURIComponent(assetId)}`,
      this.axiosConfig,
    );
    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const res = await response.json();

      return res;
    }
  };

  public getLocalizedAssetById = async (assetId: string) => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '1');
    queryString.append('asset_id_filter', assetId);

    const response = await fetch(
      `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`,
      this.axiosConfig,
    );
    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const res = await response.json();

      return res;
    }
  };

  public getSingleOptionSearchEstimates = async (
    searchTerm = '',
    searchCategory = '',
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
  ) => {
    const queryString = new URLSearchParams();

    queryString.append('limit', `50`);
    if (sortColumn !== '') {
      queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    if (searchCategory === 'location') {
      // location search does not need filter.
      queryString.append(`location_name_filter`, searchTerm);
    } else if (searchCategory === 'is_outdoors') {
      // is_outdoors requires specific boolean flag.
      queryString.append(`outdoors_filter`, 'true');
    } else {
      queryString.append(getAssetAPIFilterEnum[searchCategory], searchTerm);
    }

    if (nextToken !== '') {
      queryString.append('next', nextToken);
    }

    const response = await fetch(
      `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`,
      this.axiosConfig,
    );

    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getAllOptionsSearchEstimates = async (
    searchTerm = '',
    nextToken = '',
    sortColumn = '',
    sortDirection = '',
  ) => {
    const queryString = new URLSearchParams();

    queryString.append('limit', `50`);
    if (sortColumn !== '') {
      queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    queryString.append(`name_filter`, searchTerm);
    queryString.append(`owner_filter`, searchTerm);
    queryString.append(`asset_type_filter`, searchTerm);
    queryString.append(`location_name_filter`, searchTerm);

    if (nextToken !== '' && nextToken !== null) {
      queryString.append('next', nextToken);
    }
    const response = await fetch(
      `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`,
      this.axiosConfig,
    );

    if (!response.ok) {
      const res = await response.json();

      throw new Error(`Error: unable to access endpoint. ${res.detail}`);
    } else {
      const { items, next, total } = await response.json();
      return {
        results: items,
        next: next,
        total: total,
      };
    }
  };

  public getRegisteredBeaconMapAssets = async (
    venueID: string | null,
    term?: string,
    controller?: any,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '200');
    queryString.append('has_location_filter', 'true'); // ensure all assets have a location.

    if (venueID) {
      // note we are using $must syntax to force an AND comparison so we filter by venue name.
      queryString.append(`venue_id_filter`, `${venueID}`);
    }
    if (term) {
      // only search by asset name.
      queryString.append(`name_filter`, term);
    }

    return axios.get(`${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`, {
      ...this.axiosConfig,
      signal: controller ? controller.signal : null,
    });
  };

  public getRegisteredBeaconFilteredMapAssets = async (
    venueID: string | null,
    assetOwnerQueryArray: string[],
    assetSubtypeQueryArray: string[],
    assetStatusQueryArray: string[],
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '200');
    queryString.append('has_location_filter', 'true'); // ensure all assets have a location.

    if (venueID) {
      // note we are using $must syntax to force an AND comparison so we filter by venue name.
      queryString.append(`venue_id_filter`, `${venueID}`);
    }

    assetOwnerQueryArray.forEach((assetOwnerValue) => {
      queryString.append(`owner_ids_filter`, assetOwnerValue);
    });

    assetSubtypeQueryArray.forEach((assetTypeValue) => {
      queryString.append(`asset_type_ids_filter`, assetTypeValue);
    });

    assetStatusQueryArray.forEach((assetStatusValue) => {
      if (assetStatusValue === 'null') {
        // exception for null for API
        queryString.append('status_ids_filter', '__null');
      } else {
        queryString.append('status_ids_filter', assetStatusValue);
      }
    });

    return axios.get(
      `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public checkAssetExistsByAssetName = async (assetName: string) => {
    const queryString = new URLSearchParams();
    queryString.append(`name_filter`, assetName);

    const url = `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: this.config,
    });
    const { count } = await response.json();
    return count > 0;
  };

  public checkAssetExistsByAssetID = async (assetID: string) => {
    const queryString = new URLSearchParams();
    queryString.append(`asset_id_filter`, assetID);

    const url = `${config.get().DATA_API_URL}/localized/assets?${queryString.toString()}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: this.config,
    });
    const { count } = await response.json();
    return count > 0;
  };

  public updateAssetDetails = async (newAssetDetails: any) => {
    const url = `${config.get().DATA_API_URL}/assets`;
    return axios.put(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public createNewAsset = async (newAssetDetails: any) => {
    const url = `${config.get().DATA_API_URL}/assets`;
    return axios.post(url, JSON.stringify(newAssetDetails), this.axiosConfig);
  };

  public deleteAsset = async (assetID: string) => {
    const url = `${config.get().DATA_API_URL}/assets/${encodeURIComponent(assetID)}`;
    return axios.delete(url, this.axiosConfig);
  };

  public getJobTemplateFile = async () => {
    const url = `${config.get().DATA_API_URL}/entity/asset/job/template`;

    return axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Content-Disposition': 'attachment;',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  public postBulkUpload = async (file: any) => {
    const url = `${config.get().DATA_API_URL}/assets/bulk_upload`;
    const data = new FormData();

    data.append('data', file);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: data,
    });
    if (!response.ok) {
      const errRes = await response.json();

      throw errRes;
    }
    return await response;
  };

  public getAssetStatuses = async () => {
    const url = `${config.get().DATA_API_URL}/asset_statuses`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    if (!response.ok) {
      const errRes = await response.json();

      throw errRes;
    }
    return await response.json();
  };

  public getAssetStatusClearTimes = async () => {
    const url = `${config.get().DATA_API_URL}/asset_status_clear_periods`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    if (!response.ok) {
      const errRes = await response.json();

      throw errRes;
    }

    return await response.json();
  };

  public getAssetStatusAssignmentDetails = async (asset_id: string) => {
    const url = `${config.get().DATA_API_URL}/asset_status_assignments/${asset_id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    if (!response.ok) {
      const errRes = await response.json();

      throw errRes;
    }
    return await response.json();
  };

  public postStatusAssignment = async (data: AssetStatusType | {}) => {
    const url = `${config.get().DATA_API_URL}/asset_status_assignment`;
    return axios.post(url, JSON.stringify(data), this.axiosConfig);
  };
}

export default AssetsApi;
