import React, { useContext, useState } from 'react';
import BeaconInformationPanel from '../components/BeaconInformationPanel';
import AssetInformationPanelForm from '../forms/AssetInformationPanelForm';
import Button from 'components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { StyledImportCreateAssetsContainer } from '../components/Styled';
import AssetsApi from 'api/assets/assets.api';
import { getIdToken, getUUID } from 'utils/utils';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import { AuthenticationContext } from 'contexts/authentication.context';
import BeaconsApi from 'api/beacons/beacons.api';
import { useQueryClient } from 'react-query';
import { IFormInputs } from 'types/formInterface';

import { UnregisteredBeaconType } from 'types/BeaconType';

type ImportCreateAssetsScreenTypes = {
  beaconsForImport: UnregisteredBeaconType[];
  setImportCreateSelected: (param: boolean) => void;
};
type importCreateAssetDataType = {
  assetName: string;
  assetSubType: {
    label: string;
    value: string;
  };
  assetOwner: {
    label: string;
    value: string;
  };
  description: string;
};

export default function ImportCreateAssetsScreen({
  beaconsForImport,
  setImportCreateSelected,
}: ImportCreateAssetsScreenTypes) {
  const selectedBeacon = beaconsForImport[0]; // only import the first selected beacon.
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const assetsApi = new AssetsApi(token);
  const beaconsApi = new BeaconsApi(token);
  const queryClient = useQueryClient();

  function handleCancel() {
    setImportCreateSelected(false);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setError,
    clearErrors,
  } = useForm<IFormInputs>({ reValidateMode: 'onSubmit', mode: 'onChange' });

  const onSubmit = handleSubmit((data: importCreateAssetDataType) => {
    setApiCallInProgress(true);
    // call to check asset name doesnt exist first
    assetsApi
      .checkAssetExistsByAssetName(data.assetName)
      .then(async (assetExists) => {
        setFormSubmitted(true);
        if (assetExists) {
          setError('assetName', {
            type: 'custom',
            message: 'Asset name specified already exists.',
          });
          setApiCallInProgress(false);

          return;
        } else {
          const assetID = getUUID();
          const date = new Date();

          // body for POST assets
          const newAssetDetails = {
            asset: {
              asset_id: assetID,
              asset_name: data.assetName,
              asset_type_id: data.assetSubType.value,
              owner_id: data.assetOwner.value,
              description: data.description,
              assigned_beacon_id: selectedBeacon.beacon_id,
            },
          };

          // first call createNewAsset, and on success we call postBeacon
          // to insert the new beacon and assign to the asset

          beaconsApi
            .createBeacon({
              beacon: {
                ...selectedBeacon,
                import_info: {
                  import_timestamp: date.toISOString(), // generate timestamp clientside.
                  source: selectedBeacon.source,
                  order_id: selectedBeacon.order_id,
                },
              },
            })
            .then(() => {
              assetsApi
                .createNewAsset(newAssetDetails)

                .then(() => {
                  alertSuccessMessage(`1 New Beacon has been imported & created an asset`);
                  setApiCallInProgress(false);
                  setImportCreateSelected(false);
                  queryClient.invalidateQueries('new-beacons');
                  queryClient.invalidateQueries('registered-beacons');
                })
                .catch(() => {
                  alertErrorMessage('Error importing beacon');
                  setApiCallInProgress(false);
                });
            })
            .catch((err) => {
              const message = err.response.data.detail;
              alertErrorMessage('Error importing beacon');
              setApiCallInProgress(false);
              console.log(message);
            });
        }
      })
      .catch(() => {
        alertErrorMessage('Error importing beacon');
        setApiCallInProgress(false);
      });
  });

  return (
    <StyledImportCreateAssetsContainer>
      <div className="content">
        <BeaconInformationPanel selectedBeacon={selectedBeacon} />

        <div className="dashed-line" />

        <AssetInformationPanelForm
          errors={errors}
          register={register}
          watch={watch}
          setSubmitEnabled={setSubmitEnabled}
          handleSubmit={handleSubmit}
          Controller={Controller}
          control={control}
          formSubmitted={formSubmitted}
          clearErrors={clearErrors}
        />
      </div>

      <div className="button-container">
        <Button
          style={{ width: '170px', height: '50px' }}
          outline
          onClick={handleCancel}
          id="cancelImportCreateAsset"
        >
          Cancel
        </Button>
        <Button
          style={{ width: '170px', height: '50px' }}
          type="submit"
          id="saveImportCreateAsset"
          onClick={onSubmit}
          disabled={!submitEnabled}
          isLoading={apiCallInProgress}
        >
          Import & Create
        </Button>
      </div>
    </StyledImportCreateAssetsContainer>
  );
}
