import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import { getIdToken } from 'utils/utils';
import { AuthenticationContext } from './authentication.context';
import AssetsApi from 'api/assets/assets.api';
import { alertErrorMessage } from 'utils/alerts';
import { AssetStatesObjectType, assetStatusClearType } from 'types/AssetStatus';
import { FullConfigContext } from './fullConfig.context';

type AssetStatusProviderType = { children: ReactNode };

interface AssetStatesContextI {
  availableAssetStates: AssetStatesObjectType[];
  assetClearTimes: assetStatusClearType[];
  assetStatusApisHaveSucceeded: boolean;
}

export const AssetStatesContext = createContext({} as AssetStatesContextI);

// a simple conte to get and store available asset statuses, so components can read this.
function AssetStatusProvider({ children }: AssetStatusProviderType) {
  const [loaded, setLoaded] = useState(false);
  const [assetStatusApisHaveSucceeded, setAssetStatusApisHaveSucceeded] = useState(true);
  const [availableAssetStates, setAvailableAssetStates] = useState<AssetStatesObjectType[] | []>(
    [],
  );
  const [assetClearTimes, setAssetClearTimes] = useState<assetStatusClearType[]>([]);

  const { authState } = useContext(AuthenticationContext);
  const { availableFeaturesObject, userAccessPermissions } = useContext(FullConfigContext);

  useEffect(() => {
    if (availableFeaturesObject.asset_status && userAccessPermissions.hasRead) {
      const idToken = getIdToken(authState);
      const assetsApi = new AssetsApi(idToken);
      const apiCalls = [
        {
          call: assetsApi.getAssetStatuses,
          setState: (res: any) => setAvailableAssetStates(res),
        },
        {
          call: assetsApi.getAssetStatusClearTimes,
          setState: (res: any) => setAssetClearTimes(res),
        },
      ];

      apiCalls.forEach((apiCallObj, i) => {
        apiCallObj
          .call()
          .then((res: any) => {
            apiCallObj.setState(res);
          })
          .catch((err: any) => {
            alertErrorMessage('Could not get asset statuses');
            setAssetStatusApisHaveSucceeded(false);
            console.log(err);
          })
          .finally(() => {
            if (i + 1 === apiCalls.length) {
              setLoaded(true);
            }
          });
      });
    } else {
      setLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <AssetStatesContext.Provider
      value={{ availableAssetStates, assetClearTimes, assetStatusApisHaveSucceeded }}
    >
      {children}
    </AssetStatesContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
}

export default AssetStatusProvider;
