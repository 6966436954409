import React from 'react';
import { StyledUploadFormCollapsedButton } from 'views/BeaconManagementPage/components/Styled';

import arrowImg from './icons/arrow.svg';

export default function UploadFormCollapsed({
  setFormCollapsed,
}: {
  setFormCollapsed: (arg: boolean) => void;
}) {
  function handleOpen() {
    setFormCollapsed(false);
  }
  return (
    <StyledUploadFormCollapsedButton onClick={handleOpen}>
      Upload File <img style={{ transform: 'rotate(180deg)' }} src={arrowImg} alt="arrow" />
    </StyledUploadFormCollapsedButton>
  );
}
