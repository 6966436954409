import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

const StyledHeader: any = styled.header`
  margin-bottom: 10px;
  h2 {
    font-size: 18px;
    font-weight: ${(props: any) => props.bold ? 'bold' : 'normal'};
    color: ${colours.secondaryText};
  }
`;

export default function FormHeader({ text, bold = false }: { text: string | ReactNode, bold?: boolean }) {
  return (
    <StyledHeader bold={bold}>
      <h2>{text}</h2>
    </StyledHeader>
  );
}
