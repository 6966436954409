import React, { ReactElement } from 'react';
import { GlobalContext } from 'contexts/global.context';
import { useContext, useEffect } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { VenuesContext } from '../contexts/venues.context';

// A HOC component to publish GLOBAL_context FROM various local map contexts.
// all global state should be Published FROM this component, and SUBSCRIBED INTO the map state not the components themselves.
export default function PublishStateToGlobalContextWrapper({
  children,
}: {
  children: ReactElement;
}): React.ReactElement {
  const { selectedVenueObj } = useContext(VenuesContext);
  const { buildingSelectedID, selectedFloorOrdinal } = useContext(MapControlContext);
  const { GLOBAL_setSelectedMapVenueObj, GLOBAL_setBuildingSelectedID, GLOBAL_setMapFloorOrdinal } =
    useContext(GlobalContext);

  useEffect(() => {
    // PUBLISH to GLOBAL CTX useEffect on component unmount.

    return () => {
      GLOBAL_setSelectedMapVenueObj(selectedVenueObj);
      GLOBAL_setBuildingSelectedID(buildingSelectedID);
      GLOBAL_setMapFloorOrdinal(selectedFloorOrdinal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenueObj, buildingSelectedID, selectedFloorOrdinal]);

  return children;
}
