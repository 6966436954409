import React, { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import {
  getAssociatedLevelIDsArrayFromOrdinal,
  getLevelFeatureDataFromOrdinal,
} from '../utils/utils';
import { StyledFloorSelectorControlDiv } from '../Styled';
import useBuildingLevelUtilHooks from '../hooks/useBuildingLevelUtilHooks';
import useLeafletDomEvents from '../hooks/useLeafletDomEvents';
import FloorCountBadge from './FloorCountBadge';
import useMapHooks from '../hooks/useMapHooks';

// determine levels associated with buildings by filtering matching building_ids in levels.geojson.
// each feature returned represents a level or floor for that building
// we then filter the features by unique ordinals,
// sort them numerically,
// loop over sorted levels and render a button only if building has multiple unique ordinals.
export default function FloorSelectorControl() {
  const {
    setFloorSelectedIDArray,
    setClickedMarkerID,
    setSelectedFloorOrdinal,
    selectedFloorOrdinal,
  } = useContext(MapControlContext);

  const { sortedLevelsWithUniqueOrdinals } = useBuildingLevelUtilHooks();
  const { preventLeafletClickPropagation } = useLeafletDomEvents();
  const { fitMapToBoundsOfGeoJSON } = useMapHooks();

  function handleFloorSelect(ev: React.SyntheticEvent, level: any) {
    ev.preventDefault();
    preventLeafletClickPropagation(ev.target); // prevent clicks and double clicks propagating & causing zooms.

    // loop through all levels and get array of associated level_ids
    const associatedLevelIDsArray = getAssociatedLevelIDsArrayFromOrdinal(
      level.properties.ordinal,
      sortedLevelsWithUniqueOrdinals,
    );
    const selectedLevelFeatureData = getLevelFeatureDataFromOrdinal(
      level.properties.ordinal,
      sortedLevelsWithUniqueOrdinals,
    );

    setFloorSelectedIDArray(associatedLevelIDsArray);
    setSelectedFloorOrdinal(level.properties.ordinal);
    setClickedMarkerID(''); // reset clicked marker ID to hide info boxes.
    fitMapToBoundsOfGeoJSON(selectedLevelFeatureData); // center map to feature bounds of selected floor.
  }

  return (
    <StyledFloorSelectorControlDiv data-test-id="floor-selector">
      {sortedLevelsWithUniqueOrdinals.map((level: any) => {
        // render a button for each unique ordinal.
        const floorIsSelected = selectedFloorOrdinal === level.properties.ordinal;

        return (
          <div className="button-container" key={level.properties.ordinal}>
            <button
              className={floorIsSelected ? '--active' : ''}
              onClick={(ev) => handleFloorSelect(ev, level)}
            >
              {level.properties.ordinal}
              <FloorCountBadge ordinal={level.properties.ordinal} />
            </button>
          </div>
        );
      })}
    </StyledFloorSelectorControlDiv>
  );
}
