import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export default function MapTooltipAnimationWrapper({ children }: { children: React.ReactNode }) {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <CSSTransition in={animated} timeout={1} classNames="map-tooltip">
      {children}
    </CSSTransition>
  );
}
