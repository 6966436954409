import React, { useContext, useState } from 'react';
import { SearchFilterContext } from '../../contexts/searchFilter.context';
import { AssetConfigContext } from 'contexts/assetConfig.context';
import { getReactSelectOptionsFromAssetStatusData } from '../../utils/utils';
import { FullConfigContext } from 'contexts/fullConfig.context';
import { AssetStatesContext } from 'contexts/assetStates.context';
import { MobileSearchBarStyled } from '../mobile/mobileStyles';
import MobileMapDropdownCheckboxInputSelect from './DropDownInput/MobileMapDropdownCheckboxInputSelect';
import { getReactSelectObjectArrayFromValueKeysArray, getSortedArrayByValue } from 'utils/utils';

function MobileDropdownFilterInputController({
  resetSearchFromCheckbox,
}: {
  resetSearchFromCheckbox: () => void;
}) {
  const [currentMenuOpenType, setCurrentMenuOpenType] = useState<string | null>(null);

  const {
    setAssetOwnerCheckedListArray,
    setAssetSubtypeCheckedListArray,
    setAssetStatusCheckedListArray,
    setFiltersModified,
    assetOwnerCheckedListArrayRef,
    assetSubtypeCheckedListArrayRef,
    assetStatusCheckedListArrayRef,
  } = useContext(SearchFilterContext);

  const { assetOwners, assetSubtype } = useContext(AssetConfigContext);
  const { availableAssetStates } = useContext(AssetStatesContext);
  const { availableFeaturesObject } = useContext(FullConfigContext);

  const defaultAssetStatusOptions = getReactSelectOptionsFromAssetStatusData(availableAssetStates);

  return (
    <>
      <p className="searchbar-readout">Filter</p>
      <MobileSearchBarStyled>
        <MobileMapDropdownCheckboxInputSelect
          options={getSortedArrayByValue(assetSubtype, 'value')}
          preplaceholdertext="Asset Type:"
          type="subtype"
          setStateMethod={setAssetSubtypeCheckedListArray}
          setCurrentMenuOpenType={setCurrentMenuOpenType}
          currentMenuOpenType={currentMenuOpenType}
          dataTestId={'asset_type_filter'}
          setFiltersModified={setFiltersModified}
          resetSearchFromCheckbox={resetSearchFromCheckbox}
          selectedOptionsRef={getReactSelectObjectArrayFromValueKeysArray(
            assetSubtypeCheckedListArrayRef.current,
            getSortedArrayByValue(assetSubtype, 'value'),
          )}
        />
        <MobileMapDropdownCheckboxInputSelect
          options={getSortedArrayByValue(assetOwners, 'value')}
          preplaceholdertext="Owner:"
          type="owner"
          setStateMethod={setAssetOwnerCheckedListArray}
          setCurrentMenuOpenType={setCurrentMenuOpenType}
          currentMenuOpenType={currentMenuOpenType}
          dataTestId={'owner_filter'}
          setFiltersModified={setFiltersModified}
          resetSearchFromCheckbox={resetSearchFromCheckbox}
          selectedOptionsRef={getReactSelectObjectArrayFromValueKeysArray(
            assetOwnerCheckedListArrayRef.current,
            getSortedArrayByValue(assetOwners, 'value'),
          )}
        />
        {availableFeaturesObject.asset_status && (
          <MobileMapDropdownCheckboxInputSelect
            options={defaultAssetStatusOptions}
            preplaceholdertext="Status:"
            type="status"
            setStateMethod={setAssetStatusCheckedListArray}
            setCurrentMenuOpenType={setCurrentMenuOpenType}
            currentMenuOpenType={currentMenuOpenType}
            dataTestId="asset_status_filter"
            setFiltersModified={setFiltersModified}
            resetSearchFromCheckbox={resetSearchFromCheckbox}
            selectedOptionsRef={getReactSelectObjectArrayFromValueKeysArray(
              assetStatusCheckedListArrayRef.current,
              defaultAssetStatusOptions,
            )}
          />
        )}
      </MobileSearchBarStyled>
    </>
  );
}

export default MobileDropdownFilterInputController;
