import React, { useContext } from 'react';
import { recentUsersColumns } from 'components/DataTable/columns/recentUsersColumns';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import RecentUsersTable from 'views/RecentUsersPage/RecentUsersTable';
import { RecentUsersContext } from 'contexts/recentUsers.context';
import { RecentUsersHeader } from './RecentUsersHeader';
import useRecentUsers from './RecentUsersHook';

export default function RecentUsersTableContainer() {
  const {
    usersList,
    networkError,
    searchTerm,
    selectedSearchMenuOptionLabel,
    isLoading,
    isSorting,
  } = useContext(RecentUsersContext);
  const { SortHandler, SearchHandler } = useRecentUsers();

  let emptyText = <>No users to display</>;
  if (searchTerm !== '') {
    emptyText = (
      <>
        Sorry, no results were found for {searchTerm}
        {selectedSearchMenuOptionLabel !== '' && (
          <>
            &nbsp;in <b>{selectedSearchMenuOptionLabel}</b>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <RecentUsersHeader SearchHandler={SearchHandler} />
      <main>
        <div className="container">
          <>
            <DataTableStatusWrapper
              error={networkError}
              status={isLoading && !isSorting ? 'loading' : ''}
            >
              <RecentUsersTable
                columns={recentUsersColumns}
                data={usersList}
                defaultSortFieldId="last_seen"
                handleSort={SortHandler}
                noDataComponent={<NoDataComponent text={emptyText} />}
              />
            </DataTableStatusWrapper>
          </>
        </div>
      </main>
    </>
  );
}
