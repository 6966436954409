import React from 'react';

const AssetManagementIcon = (): any => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3245_28)">
        <path d="M17.4376 13.275L16.7626 13.1625C16.6501 12.9375 16.5376 12.6 16.4251 12.375L16.8751 11.8125C17.1001 11.5875 16.9876 11.25 16.7626 11.025C16.5376 10.8 16.2001 10.8 15.9751 11.025L15.4126 11.475C15.3001 11.3625 15.1876 11.3625 14.9626 11.25C14.8501 11.25 14.7376 11.1375 14.5126 11.1375L14.4001 10.4625C14.4001 10.125 14.0626 9.9 13.8376 9.9C13.5001 9.9 13.2751 10.125 13.2751 10.4625L13.1626 11.1375C13.0501 11.1375 12.9376 11.25 12.7126 11.25C12.6001 11.3625 12.3751 11.3625 12.2626 11.475L11.7001 11.025C11.4751 10.8 11.1376 10.8 10.9126 11.025C10.6876 11.25 10.6876 11.5875 10.9126 11.8125L11.3626 12.375C11.2501 12.6 11.1376 12.9375 11.0251 13.1625L10.3501 13.275C10.0126 13.275 9.7876 13.6125 9.7876 13.8375C9.7876 14.175 10.0126 14.4 10.3501 14.4L11.0251 14.625C11.0251 14.7375 11.1376 14.85 11.1376 15.075C11.2501 15.1875 11.2501 15.4125 11.3626 15.525L10.9126 16.0875C10.6876 16.3125 10.8001 16.65 10.9126 16.875C11.1376 17.1 11.4751 17.1 11.7001 16.9875L12.2626 16.5375C12.4876 16.65 12.8251 16.7625 13.0501 16.875L13.1626 17.55C13.1626 17.8875 13.5001 18.1125 13.7251 18.1125C14.0626 18.1125 14.2876 17.8875 14.2876 17.55L14.4001 16.875C14.7376 16.7625 14.9626 16.65 15.1876 16.5375L15.7501 16.9875C15.9751 17.2125 16.3126 17.1 16.5376 16.875C16.7626 16.65 16.7626 16.3125 16.5376 16.0875L16.0876 15.525C16.2001 15.4125 16.2001 15.3 16.3126 15.075C16.4251 14.9625 16.4251 14.85 16.4251 14.625L17.1001 14.5125C17.4376 14.5125 17.6626 14.175 17.6626 13.95C18.0001 13.6125 17.7751 13.275 17.4376 13.275ZM13.5001 15.6375C12.8251 15.525 12.1501 14.9625 12.0376 14.175C11.8126 12.825 12.9376 11.7 14.1751 12.0375C14.8501 12.15 15.5251 12.7125 15.6376 13.5C15.8626 14.85 14.7376 15.975 13.5001 15.6375Z" />
        <path d="M4.1625 18C1.9125 18 0 16.2 0 13.8375C0 11.475 1.8 9.675 4.1625 9.675C6.525 9.675 8.325 11.475 8.325 13.8375C8.325 16.2 6.4125 18 4.1625 18ZM4.1625 10.8C2.475 10.8 1.125 12.15 1.125 13.8375C1.125 15.525 2.475 16.875 4.1625 16.875C5.85 16.875 7.2 15.525 7.2 13.8375C7.2 12.15 5.85 10.8 4.1625 10.8Z" />
        <path d="M4.1625 8.2125C1.8 8.2125 0 6.4125 0 4.1625C0 1.9125 1.8 0 4.1625 0C6.525 0 8.325 1.8 8.325 4.1625C8.325 6.525 6.4125 8.2125 4.1625 8.2125ZM4.1625 1.125C2.475 1.125 1.125 2.475 1.125 4.1625C1.125 5.85 2.475 7.2 4.1625 7.2C5.85 7.2 7.2 5.85 7.2 4.1625C7.2 2.475 5.85 1.125 4.1625 1.125Z" />
        <path d="M13.8373 8.2125C11.5873 8.2125 9.6748 6.4125 9.6748 4.05C9.6748 1.6875 11.5873 0 13.8373 0C16.0873 0 17.9998 1.8 17.9998 4.1625C17.9998 6.525 16.1998 8.2125 13.8373 8.2125ZM13.8373 1.125C12.1498 1.125 10.7998 2.475 10.7998 4.1625C10.7998 5.85 12.1498 7.2 13.8373 7.2C15.5248 7.2 16.8748 5.85 16.8748 4.1625C16.8748 2.475 15.5248 1.125 13.8373 1.125Z" />
      </g>
      <defs>
        <clipPath id="clip0_3245_28">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssetManagementIcon;
