import { Menu } from 'antd';
import styled from 'styled-components';
import arrowRight from './arrow-right.svg';
import { coloursNames } from 'styling/colours';

export const StyledButton = styled.button`
  display: block;
  outline: none;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  color: ${(props) => props.color};
  border-radius: 3px;
  padding: 0px 18px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background: rgba(188, 190, 192, 0.15);
    border-radius: 3px;
    color: #00aeef;
  }
`;
export const StyledDropdownWrapper = styled.div`
  overflow-x: hidden;

  p {
    font-family: 'MuseoSlab';
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    padding: 0 25px 0 0;
    position: relative;
    text-align: right;
    cursor: pointer;
    color: ${coloursNames.midGrey};
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: ${coloursNames.deepSkyBlue};
      transition: color 0.2s ease-in;
    }
    &:after {
      content: '';
      background: url(${arrowRight}) 0 0 no-repeat;
      background-size: 10px 10px;
      transform: rotate(90deg);
      width: 10px;
      height: 10px;
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
`;
export const overlayStyle = {
  marginTop: '10px',
  minWidth: '186px',
  maxWidth: '186px',
  width: '100%',
};

export const StyledMenu = styled(Menu)`
  width: 100%;
  border: 1px solid #e4e4e4;
  border-width: 0 1px 1px 1px;
  border-radius: 3px !important;

  li.ant-menu-item {
    margin: 0px !important;
    padding: 0px;
    height: initial;
    width: 100%;
    .ant-menu-title-content {
      margin: 0;
      display: block;
    }
    &:hover {
      background-color: initial !important;
    }
    &:first-child {
      margin-top: 10px !important;
    }
    &:last-child {
      margin-bottom: 10px !important;
    }
  }
`;
