import React from 'react';
import { icons } from 'assets/icons';
import { Label } from 'components/Label';
import { Vertical } from 'gls/lib';
import moment from 'moment';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { colours } from 'styling/colours';
import { getEndOfDay, getFirstOfDay } from 'utils/date';
import { CSSProperties } from 'styled-components';

type DatePickInputComponentProps = {
  value: Date | string | undefined | null;
  label?: string;
  name?: string;
  ariaLabel?: string;
  required?: boolean;
  disabled?: boolean;
  id?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  allowClear?: boolean;
  popupStyle?: CSSProperties;
  format?: string;
  showNow?: boolean;
  onChange: (value: Dayjs | null, dateString: string | string[]) => void;
};

export const DatePickInputComponent = (props: DatePickInputComponentProps) => {
  const {
    ariaLabel = 'Date Picker',
    disabled,
    id = 'date-input',
    name = 'date-picker',
    label,
    required,
    value,
    placeholder = 'Select Date',
    onChange,
    minDate,
    maxDate,
    allowClear,
    popupStyle,
    format,
    showNow,
  } = props;

  moment.locale(navigator.language);

  const disabledDateHandler = (current: Dayjs) => {
    if (minDate && !isNaN(minDate.getTime()) && current.isBefore(getFirstOfDay(minDate))) {
      return true;
    }
    if (maxDate && !isNaN(maxDate.getTime()) && current.isAfter(getEndOfDay(maxDate))) {
      return true;
    }
    return false;
  };

  return (
    <Vertical
      horizontalAlign="left"
      spacing={0}
      aria-label={ariaLabel}
      width="fit-content"
      maxWidth="300px"
    >
      {label && <Label value={label} required={required} />}
      <Vertical spacing={0}>
        <DatePicker
          allowClear={allowClear}
          defaultValue={value === null || value === undefined ? undefined : dayjs(value)}
          disabled={disabled}
          format={format}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          id={id}
          disabledDate={disabledDateHandler}
          suffixIcon={<icons.TaskSchedulerIcon fill={colours.secondaryText} />}
          showNow={showNow}
          popupStyle={popupStyle}
        />
      </Vertical>
    </Vertical>
  );
};

DatePickInputComponent.displayName = 'DatePicker';
