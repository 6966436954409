import React from 'react';
import BatteryCell from '../cells/BatteryCell';
import LocationCellAsset from '../cells/LocationCellAsset';
import ArrowCell from '../cells/ArrowCell';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import LastSeenCell from 'components/DataTable/cells/LastSeenCell';
import AssetStatusCell from '../cells/AssetStatusCell';
import { featuresType } from 'types/config';

export function getAssetTableColumns(availableFeatureObj: featuresType): any[] {
  return [
    {
      name: 'ASSET NAME',
      selector: (row: EstimateType) => <DefaultCell value={row.asset_name} />,
      sortable: true,
      minWidth: '240px',
      maxWidth: '410px',
      sortField: 'asset_name',
    },
    {
      name: 'ASSET TYPE',
      selector: (row: EstimateType) => <DefaultCell value={row.asset_type.name} />,
      sortable: true,
      minWidth: '180px',
      maxWidth: '240px',
      overflow: 'false',
      sortField: 'asset_type',
    },
    {
      name: 'ASSET ID',
      selector: (row: EstimateType) => <DefaultCell value={row.asset_id} />,
      sortable: true,
      minWidth: '230px',
      maxWidth: '410px',
      overflow: 'false',
      sortField: 'asset_id',
    },
    {
      name: 'STATUS',
      selector: (row: EstimateType) => (
        <AssetStatusCell value={row.status_assignment?.asset_status?.description} />
      ),
      sortable: true,
      overflow: 'false',
      minWidth: '260px',
      maxWidth: '410px',
      sortField: 'status',
      omit: !availableFeatureObj.asset_status,
    },
    {
      name: 'LOCATION',
      cell: (row: EstimateType) => <LocationCellAsset value={row} />,
      sortable: true,
      overflow: 'false',
      minWidth: '260px',
      maxWidth: '410px',
      sortField: 'location',
    },
    {
      name: 'OWNER',
      sortable: true,
      selector: (row: EstimateType) => <DefaultCell value={row.owner.name} />,
      sortField: 'asset_owner',
      minWidth: '160px',
      maxWidth: '200px',
    },
    {
      name: 'LAST SEEN',
      sortable: true,
      minWidth: '140px',
      maxWidth: '140px',
      cell: (row: EstimateType) => <LastSeenCell timestamp={row.last_location_update_timestamp} />,
      sortField: 'last_update', // API is mapped to last_location_update_timestamp for last_update
    },
    {
      name: 'BATTERY',
      cell: (row: EstimateType) => <BatteryCell {...row} />,
      sortable: true,
      sortField: 'battery_level',
      minWidth: '120px',
      maxWidth: '120px',
    },
    {
      right: true,
      sortable: false,
      cell: () => <ArrowCell />,
      sortField: '',
      width: '10px',
    },
  ];
}
