import React, { useState } from 'react';
import { colours } from 'styling/colours';
import AssetInfoIcon from './AssetInfoIcon';
import AssetActivityIcon from './AssetActivityIcon';
import { ButtonResetCss } from 'components/Button/styled';
import styled from 'styled-components';
import AssetDetailsTabContent from './AssetDetailsTabContent';
import AssetActivityTabContent from './AssetActivityTabContent';
import { EstimateType } from 'types/Estimate';

const StyledTabContainer = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  margin-bottom: 30px;
  li {
    width: 50%;

    button {
      ${ButtonResetCss};
      padding: 30px 40px;
      text-align: center;
      width: 100%;
      background: white;
      color: ${colours.secondaryText};
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
    border-bottom: 2px solid #f8f9fb;
    border-top: 2px solid #f8f9fb;

    &.--selected {
      border-top: 1px solid #f8f9fb;
      border-bottom: 1px solid #f8f9fb;
      button {
        background: #f8f9fb;
        color: ${colours.secondary};
      }
      border-bottom: 3px solid ${colours.secondary};
    }
  }
`;

export default function TabController({
  selectedAssetDetails,
  updateAssetStatusModalOpen,
}: {
  selectedAssetDetails: EstimateType;
  updateAssetStatusModalOpen: boolean;
}) {
  const [detailsTabIsSelected, setDetailsTabIsSelected] = useState(true);

  return (
    <>
      <StyledTabContainer>
        <li className={detailsTabIsSelected ? '--selected' : ''}>
          <button onClick={() => setDetailsTabIsSelected(true)}>
            <AssetInfoIcon isActive={detailsTabIsSelected} />
            Asset Details
          </button>
        </li>
        <li className={detailsTabIsSelected ? '' : '--selected'}>
          <button onClick={() => setDetailsTabIsSelected(false)}>
            <AssetActivityIcon isActive={!detailsTabIsSelected} />
            Asset Activity
          </button>
        </li>
      </StyledTabContainer>

      {detailsTabIsSelected ? (
        <AssetDetailsTabContent asset={selectedAssetDetails} />
      ) : (
        <AssetActivityTabContent
          updateAssetStatusModalOpen={updateAssetStatusModalOpen}
          asset={selectedAssetDetails}
        />
      )}
    </>
  );
}
