import FileUploadModal from 'components/FileUpload/Modals/FileUploadModal';
import ProcessingErrorModal from 'components/FileUpload/Modals/ProcessingErrorModal';
import React, { useState } from 'react';

import UploadFileForm from '../Form/UploadFileForm';
import { UploadFailedStatusMessageType } from '../type';

export default function AssetUploadFormController() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [proccesingErrorModalOpen, setProccesingErrorModalOpen] = useState(false);
  const [processingErrors, setProcessingErrors] = useState<UploadFailedStatusMessageType[] | []>(
    [],
  );

  return (
    <>
      <UploadFileForm
        isAssetUpload
        isCollapsable={false}
        setSelectedFile={setSelectedFile}
        setUploadModalOpen={setUploadModalOpen}
      />

      {uploadModalOpen && selectedFile && (
        <FileUploadModal
          isAssetUpload
          setUploadModalOpen={setUploadModalOpen}
          setProcessingErrors={setProcessingErrors}
          selectedFile={selectedFile}
          setProccesingErrorModalOpen={setProccesingErrorModalOpen}
        />
      )}

      {proccesingErrorModalOpen && (
        <ProcessingErrorModal
          isAssetUpload
          setProccesingErrorModalOpen={setProccesingErrorModalOpen}
          processingErrors={processingErrors}
        />
      )}
    </>
  );
}
