import LoadingPlaceholderDataTable from 'components/Loading/LoadingPlaceholderDataTable';
import React, { useEffect, useState } from 'react';
import { StyledTableContainer } from './style';

type DataTableStatusWrapperTypes = {
  status: string;
  error: any;
  children: React.ReactNode;
  isLoadingParam?: boolean;
};

export default function DataTableStatusWrapper({
  status,
  error,
  children,
  isLoadingParam,
}: DataTableStatusWrapperTypes): any {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // ensure loading screen is present between renders.
    setIsLoading(status === 'loading');
    if (isLoadingParam) {
      setIsLoading(true);
    }
  }, [status, isLoadingParam]);

  if (error && error.response) {
    if (error.response.status === 503) {
      return (
        <StyledTableContainer>
          Cannot retrieve new beacons currently. The third party system may be unavailable, please
          try again later.
        </StyledTableContainer>
      );
    }
  } else return isLoading ? <LoadingPlaceholderDataTable /> : children ? children : null;
}
