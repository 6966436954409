import React from 'react';

import AssetMarker from '../components/Markers/AssetMarker';
import { EstimateType } from 'types/Estimate';

export default function OutdoorAssetMarkersLayer({
  assetMarkers,
}: {
  assetMarkers: EstimateType[];
}) {
  return (
    <>
      {assetMarkers.map((asset) => {
        return <AssetMarker key={asset.asset_id} asset={asset} />;
      })}
    </>
  );
}
