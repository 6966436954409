import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

export default function ComponentAnimationWrapper({ children }: { children: React.ReactNode }) {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    setAnimated(true);
  }, []);

  return (
    <CSSTransition unmountOnExit in={animated} timeout={1000} classNames="component-animation">
      {children}
    </CSSTransition>
  );
}
