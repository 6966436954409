/* eslint-disable jsx-a11y/click-events-have-key-events */
import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-leaflet';
import { StyledBigTooltipInner } from '../../Styled';
import { getIdToken, getValueSafely } from 'utils/utils';
import infoImg from '../../icons/info.svg';
import closeImg from '../../icons/close.svg';
import AccuracyRangeSpan from '../InfoBoxSpans/AccuracyRangeSpan';
import BatteryReadoutSpan from '../InfoBoxSpans/BatteryReadoutSpan';
import LocationSpan from '../InfoBoxSpans/LocationSpan';
import TooltipDescriptionPanel from './TooltipDescriptionPanel';
import LastSeenReadoutSpan from '../InfoBoxSpans/LastSeenReadoutSpan';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import AssetStatusUpdateModalController from 'components/Modals/AssetStatusUpdateModal/AssetStatusUpdateModalController';
import { alertErrorMessage } from 'utils/alerts';
import { AssetStatesContext } from 'contexts/assetStates.context';
import AssetStatusReadoutSpan from '../InfoBoxSpans/AssetStatusReadoutSpan';
import AssetsApi from 'api/assets/assets.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { FullConfigContext } from 'contexts/fullConfig.context';

type AssetMarkerLargeInfoboxTooltipType = {
  asset: EstimateType;
  handleCloseInfoBox: () => void;
};

function getCreatedDateString(asset: EstimateType) {
  if (!asset.creation_timestamp) return '––';

  const addedOnDate = asset.creation_timestamp
    ? format(new Date(asset.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  return addedOnDate;
}

function LargeInfoBoxTooltip({ asset, handleCloseInfoBox }: AssetMarkerLargeInfoboxTooltipType) {
  const [currentAsset, setCurrentAsset] = useState(asset);
  const [updateAssetStatusModalOpen, setUpdateAssetStatusModalOpen] = useState(false);
  const { assetStatusApisHaveSucceeded } = useContext(AssetStatesContext);
  const { authState } = useContext(AuthenticationContext);
  const { availableFeaturesObject } = useContext(FullConfigContext);

  function handleCloseModal() {
    setUpdateAssetStatusModalOpen(false);
  }

  function handleSuccessfulUpdate() {
    const idToken = getIdToken(authState);
    const assetsApi = new AssetsApi(idToken);

    assetsApi
      .getLocalizedAssetById(currentAsset.asset_id)
      .then((res) => {
        const returnedAsset: EstimateType = res.items[0];
        setCurrentAsset(returnedAsset);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  function handleUpdateAssetStatusModal() {
    // alert user and dont open modal if required apis have failed
    if (!assetStatusApisHaveSucceeded || !availableFeaturesObject.asset_status) {
      alertErrorMessage("Asset Status can't be updated right now, try again in a few moments");
      return;
    }
    setUpdateAssetStatusModalOpen(true);
  }

  useEffect(() => {
    // update current asset with new asset details on poll.
    setCurrentAsset(asset);
  }, [asset]);

  return (
    <>
      <Tooltip
        className="tooltip-large"
        interactive={true}
        direction="right"
        offset={[20, 0]}
        opacity={1}
        permanent
      >
        <StyledBigTooltipInner>
          <img
            alt="close"
            className="close-button"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            src={closeImg}
            onClick={() => {
              handleCloseInfoBox();
            }}
          />
          <div className="tooltip-inner-container">
            <div className="tooltip-inner-content">
              <div className="information-line">
                <h4>Asset Name:</h4>

                <span>{getValueSafely(currentAsset.asset_name)}</span>
              </div>
              <div className="information-line">
                <h4>Asset Type:</h4>
                <span>{<DefaultCell value={currentAsset.asset_type.name} />}</span>
              </div>
              <div className="information-line">
                <h4>Asset ID:</h4>
                <span style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                  {getValueSafely(currentAsset.asset_id)}
                </span>
              </div>
              <div className="information-line">
                <h4>Added On:</h4>
                <span>{getCreatedDateString(asset)}</span>
              </div>
              <div className="information-line">
                <h4>Owner:</h4>
                <span>
                  <DefaultCell value={currentAsset.owner.name} />
                </span>
              </div>

              <TooltipDescriptionPanel asset={currentAsset} />
              {availableFeaturesObject.asset_status && (
                <div className="information-line">
                  <h4>Status:</h4>
                  <span className="asset-status-span">
                    <AssetStatusReadoutSpan
                      value={currentAsset.status_assignment?.asset_status?.description}
                    />
                    <button onClick={() => handleUpdateAssetStatusModal()}>
                      Update Asset Status
                    </button>
                  </span>
                </div>
              )}
            </div>

            <div className="hr"></div>

            <div className="tooltip-inner-content">
              <div className="information-sentance">
                <img src={infoImg} alt="information" />
                <p>Please note, estimated information was derived from the beacon.</p>
              </div>
              <div className="information-line">
                <h4>Last Seen:</h4>
                <LastSeenReadoutSpan timestamp={currentAsset.last_location_update_timestamp} />
              </div>
              <div className="information-line">
                <h4>Accuracy Range:</h4>
                <AccuracyRangeSpan asset={currentAsset} />
              </div>

              <div className="information-line">
                <h4>Battery:</h4>
                <BatteryReadoutSpan asset={currentAsset} />
              </div>
              <div className="information-line">
                <h4>Location:</h4>
                <LocationSpan asset={currentAsset} />
              </div>
              <div className="information-line">
                <h4>Beacon ID:</h4>
                <span>{getValueSafely(currentAsset.assigned_beacon!.id)}</span>
              </div>
              <div className="information-line">
                <h4>Beacon Type:</h4>
                <span>
                  {getValueSafely(currentAsset.assigned_beacon!.beacon_type)}
                  {currentAsset.assigned_beacon!.import_info.source &&
                    ` - ${currentAsset.assigned_beacon!.import_info.source}`}
                </span>
              </div>
            </div>
          </div>
        </StyledBigTooltipInner>
      </Tooltip>
      {updateAssetStatusModalOpen && (
        <AssetStatusUpdateModalController
          selectedAssetDetails={currentAsset}
          closeHandler={handleCloseModal}
          callback={handleSuccessfulUpdate}
        />
      )}
    </>
  );
}

export default React.memo(LargeInfoBoxTooltip);
