import { useContext } from 'react';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { checkEstimatesShouldDisplayConditions } from '../utils/utils';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { UserType } from 'types/userInfo';

export default function useFilteredUsers() {
  const { apiCallInProgress, showUsersChecked } = useContext(MapControlContext);
  const { searchType } = useContext(SearchFilterContext);
  // a simple function to return all users passed in, or empty array depending if a condition is not met.
  // note that this function depends on context being up to date. If this is used before a context is updated, it will not have correct values.
  function getVisibleUsers(users: UserType[]) {
    const usersShouldDisplay = checkEstimatesShouldDisplayConditions(
      [showUsersChecked, true],
      [apiCallInProgress, false],
      [searchType !== 'assets', true],
    );

    return usersShouldDisplay ? users : [];
  }

  return getVisibleUsers;
}
