import { useContext } from 'react';
import { alertErrorMessage } from 'utils/alerts';

import { URLParamContext } from '../contexts/urlParam.context';
import {
  checkBuildingUUIDExistsWithinBuildingFeatures,
  checkLevelIDExistsWithinLevelFeatures,
  getAPIMatchedAssetFromBeaconID,
} from '../utils/utils';
import { VenueFilesContext } from '../contexts/venuefiles.context';
import {
  checkFullAssetURLParamsExist,
  checkFullMobileClickThroughParamsExist,
} from '../utils/urlParamUtils';
import { EstimateType } from 'types/Estimate';

export default function useURLParamEffects() {
  const { buildingFeatures, levelsFeatures } = useContext(VenueFilesContext);
  const { urlParams } = useContext(URLParamContext);

  function checkHandleMatchedAssetExistsFromAPI(assets: EstimateType[]) {
    // function to alert user that asset could not be found if url params are provided, but the asset is not returned from API.
    // for use when URL params are provided for the click through to map from asset list feature.
    const matchedAsset = getAPIMatchedAssetFromBeaconID(assets, urlParams.beaconID);

    if (!matchedAsset && checkFullAssetURLParamsExist()) {
      alertErrorMessage('Could not locate Asset from Asset List');
    }
  }

  function checkBuildingAndLevelAreWithinVenueData(): boolean {
    // check the level id param exists within the venues available level features
    // and that the building id param has a match from venues avaialable buildings
    return (
      checkLevelIDExistsWithinLevelFeatures(levelsFeatures, urlParams.levelID) &&
      checkBuildingUUIDExistsWithinBuildingFeatures(buildingFeatures, urlParams.buildingID)
    );
  }

  function checkHandleMatchedVenueFromMobileParams() {
    // function to check if user has valid URL params from click through from mobile app

    if (checkFullMobileClickThroughParamsExist() && !checkBuildingAndLevelAreWithinVenueData()) {
      alertErrorMessage('Parameters not supported for this venue');
    }
  }
  return {
    checkHandleMatchedAssetExistsFromAPI,
    checkHandleMatchedVenueFromMobileParams,
    checkBuildingAndLevelAreWithinVenueData,
  };
}
