import { AssetStatesContext } from 'contexts/assetStates.context';
import { useContext } from 'react';

export default function useAssetStatus() {
  const { availableAssetStates } = useContext(AssetStatesContext);

  function getStatusDescriptionFromID(assetID: string) {
    const match = availableAssetStates.filter(
      (stateObj) => stateObj.asset_status_id === assetID,
    )[0];
    return match.description;
  }

  return { getStatusDescriptionFromID };
}
