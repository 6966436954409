import useFeaturePermissionRedirect from 'hooks/useFeaturePermissionRedirect';
import React, { useEffect } from 'react';

export default function AuthenticationCallbackPage() {
  // this page simply gives the application something to fallback to after being redirected from Cognito signin ui.
  const redirectToFeatureDependantHomepage = useFeaturePermissionRedirect();

  useEffect(() => {
    // we then redirect user to the correct page after successful login.
    redirectToFeatureDependantHomepage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container">
        <p>Authentication Successful</p>
      </div>
    </main>
  );
}
