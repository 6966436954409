import HeaderDefault from 'components/Header/HeaderDefault';
import React, { useState } from 'react';
import { UserLogsTable } from './UserLogsTable';
import { UserLogTopBar } from './UserLogTopBar';
import { ScrolledMain } from 'components/ScrolledMain';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';

export default function UserLogsPage() {
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [searchValue, setSearchValue] = useState('');
  const { isMobileDevice } = useDevice();

  if (isMobileDevice) {
    return <WidthNotSupportedPage text={'This feature is not available on narrow screens.'} />;
  }

  const handleSearch = (value: string) => {
    console.log('search', value);
  };

  return (
    <>
      <HeaderDefault itemCount={totalCount} CountTitle="Total Log" />
      <ScrolledMain>
        <div className="container">
          <UserLogTopBar
            onSearch={handleSearch}
            searchTerm={searchValue}
            setSearchTerm={setSearchValue}
          />
          <UserLogsTable totalCount={totalCount} setTotalCount={setTotalCount} />
        </div>
      </ScrolledMain>
    </>
  );
}
