import axios from 'axios';
import config from 'react-global-configuration';

class UserApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getUsersMostRecentUpdateFirst = async (
    limit: number = 200,
    sortColumn: string = '',
    sortDirection: string = '',
    searchTerm = '',
    searchIn = '',
    nextToken = '',
  ): Promise<any> => {
    const queryString = new URLSearchParams();

    if (sortColumn !== '') {
      queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`);
    }

    if (searchTerm !== '') {
      if (searchIn === 'is_outdoors') {
        queryString.append('outdoors_filter', 'true');
      } else {
        if (searchIn === '') {
          queryString.append('name_filter', searchTerm);
          queryString.append('location_name_filter', searchTerm);
        } else {
          queryString.append(searchIn, searchTerm);
        }
      }
    }

    if (nextToken !== '' && nextToken !== null) {
      queryString.append('next', nextToken);
    }

    queryString.append('limit', limit.toString());

    return axios.get(
      `${config.get().DATA_API_URL}/localized/users?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getUsersMap = async (
    venueID: string | null,
    term?: string | null,
    controller?: any,
  ): Promise<any> => {
    const queryString = new URLSearchParams();

    if (venueID) {
      queryString.append('venue_id_filter', venueID);
    }
    if (term) {
      queryString.append('name_filter', term);
    }
    queryString.append('limit', '200');

    return axios.get(`${config.get().DATA_API_URL}/localized/users?${queryString.toString()}`, {
      ...this.axiosConfig,
      signal: controller ? controller.signal : null,
    });
  };
}
export default UserApi;
