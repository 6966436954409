import React, { createContext, useState, ReactNode } from 'react';

type AssetManagementProps = { children: ReactNode };

interface IAssetManagementProps {
  assetFormHasChanges: boolean;
  setAssetFormHasChanges: (value: boolean) => void;
  showCancelConfirmationDialog: boolean;
  setShowCancelConfirmationDialog: (value: boolean) => void;
}

export const AssetManagementContext = createContext({} as IAssetManagementProps);

const AssetManagementProvider = ({ children }: AssetManagementProps) => {
  // a context provider to contain state used within Asset Management Page, so it can be accessed by the navigation to prevent redirects,
  const [assetFormHasChanges, setAssetFormHasChanges] = useState<boolean>(false);
  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState<boolean>(false);

  return (
    <AssetManagementContext.Provider
      value={{
        assetFormHasChanges,
        setAssetFormHasChanges,
        showCancelConfirmationDialog,
        setShowCancelConfirmationDialog,
      }}
    >
      {children}
    </AssetManagementContext.Provider>
  );
};
export default AssetManagementProvider;
