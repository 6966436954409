import { GlobalContext } from 'contexts/global.context';
import { useContext } from 'react';

export default function useSubscribeToGlobalContext() {
  const globalContext: any = useContext(GlobalContext);

  // A hook function to check globalContext for a value, and return it if value is not null.
  // otherwise return fallback val.
  // if no fallback, return null.
  function getGlobalCTXValueOrFallback(globalContextStateString: string, fallbackValue?: any): any {
    const globalContextHasProp = Object.prototype.hasOwnProperty.call(
      globalContext,
      globalContextStateString,
    );
    if (
      globalContextHasProp &&
      globalContext[globalContextStateString] !== null &&
      globalContext[globalContextStateString] !== ''
    ) {
      return globalContext[globalContextStateString];
    } else return fallbackValue ? fallbackValue : null;
  }

  // similar function to above, except we return boolean if value exists or not.
  function checkGlobalCTXValueExists(globalContextStateString: string): boolean {
    const globalContextHasProp = Object.prototype.hasOwnProperty.call(
      globalContext,
      globalContextStateString,
    );
    return (
      globalContextHasProp &&
      globalContext[globalContextStateString] !== null &&
      globalContext[globalContextStateString] !== ''
    );
  }

  return { getGlobalCTXValueOrFallback, checkGlobalCTXValueExists };
}
