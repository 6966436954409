import styled, { css } from 'styled-components';

export const ButtonStyledAsLink = styled.button`
  padding: 0;
  background: none;
  outline: none;
  border: none;
  text-decoration: underline;
  color: #00aeef;
  font-weight: 600;
  font-family: 'MuseoSans';

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonResetCss = css`
  padding: 0;
  background: none;
  outline: none;
  border: none;
`;
