import { DomEvent } from 'leaflet';

export default function useLeafletDomEvents() {
  function preventLeafletClickPropagation(el: HTMLElement | any) {
    // prevent clicks propagating to unintended map elements.
    DomEvent.disableClickPropagation(el);
  }

  return { preventLeafletClickPropagation };
}
