import axios from 'axios';
import config from 'react-global-configuration';

export function callGetUserInfo(token: string) {
  const REACT_CONFIG = config.get();
  const cognitoDomain = REACT_CONFIG.COGNITO_DOMAIN;

  const url = REACT_CONFIG.SHOULD_USE_COGNITO
    ? `https://${cognitoDomain}/oauth2/userInfo`
    : `http://${cognitoDomain}/me`;

  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
