import React, { useState, useEffect } from 'react';
import { StyledError } from './Styled';
import { checkForMultipleErrorTypes } from 'utils/utils';

function getErrorString(errType: string, errorMsg: string): string {
  switch (errType) {
    case 'maxLength':
      return 'Value too long';
    case 'validate':
      return 'Asset name specified already exists.';
    case 'custom':
      return errorMsg;
    case 'patternAssetidAlphaNum':
      return errorMsg;
    case 'patternAssetidStartend':
      return errorMsg;
    case 'asset-id-exists':
      return 'Asset ID specified already exists.';

    default:
      return '';
  }
}

export default function FieldErrorMessage({ errors }: { errors: any }): any {
  const [errorString, setErrorString] = useState('');

  useEffect(() => {
    const errorType = errors.type;
    const errorMsg = errors.message;
    const errorStr = getErrorString(errorType, errorMsg);

    setErrorString(errorStr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  if (checkForMultipleErrorTypes(errors)) {
    return Object.entries(errors.types).map((value: any) => {
      return value[1] ? <StyledError key={value[0]}>{value[1]}</StyledError> : null;
    });
  } else {
    return errorString !== '' ? <StyledError>{errorString}</StyledError> : null;
  }
}
