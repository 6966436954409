import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import LoadingPlaceholderSkeletonPage from 'components/Loading/LoadingPlaceholderPage/LoadingPlaceholderSkeletonPage';
import ConfigApi from 'api/config/config.api';
import { getIdToken } from 'utils/utils';
import { AuthenticationContext } from './authentication.context';

type AssetConfigProviderType = { children: ReactNode };

interface AssetConfigContextI {
  assetOwners: any;
  assetSubtype: any;
}
export const AssetConfigContext = createContext({} as AssetConfigContextI);

// a simple config to store asset configs, so components can read this.
function AssetConfigProvider({ children }: AssetConfigProviderType) {
  const [loaded, setLoaded] = useState(false);
  const [assetOwners, setAssetOwners] = useState([]);
  const [assetSubtype, setAssetSubtype] = useState([]);
  const { authState } = useContext(AuthenticationContext);

  useEffect(() => {
    // useEffect to call each config api and set the state.
    // we set loaded to true when all calls have completed.
    const idToken = getIdToken(authState);
    const configAPI = new ConfigApi(idToken);

    const apiCallsArray = [
      { apiCall: configAPI.getAssetOwners, setState: setAssetOwners },
      { apiCall: configAPI.getAssetSubtypes, setState: setAssetSubtype },
    ];

    apiCallsArray.forEach((entry) => {
      let index = 0;

      entry
        .apiCall()
        .then((res: any) => {
          entry.setState(res);
          index++;

          if (index === apiCallsArray.length - 1) {
            setLoaded(true);
          }
        })
        .catch((e) => {
          console.error('error getting assets config', e);
          setLoaded(true);
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loaded ? (
    <AssetConfigContext.Provider value={{ assetOwners, assetSubtype }}>
      {children}
    </AssetConfigContext.Provider>
  ) : (
    <LoadingPlaceholderSkeletonPage />
  );
}

export default AssetConfigProvider;
