import { UserType } from 'types/userInfo';
import React, { useState } from 'react';
import styled from 'styled-components';
import infoImg from './info.svg';
import RecentUserTooltipPanel from './RecentUserTooltip/RecentUserTooltipPanel';

const StyledCellContainer = styled.div`
  position: relative;

  button {
    background: none;
    padding: 0;
    outline: none;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
`;

export default function RecentUserToolTipCell({ user }: { user: UserType }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <StyledCellContainer>
      <button
        className="info-button"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        <img src={infoImg} alt="more information" />
      </button>
      {tooltipOpen && <RecentUserTooltipPanel user={user} />}
    </StyledCellContainer>
  );
}
