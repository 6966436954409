import React, { SyntheticEvent, useState } from 'react';
import FieldErrorMessage from './FieldErrorMessage';
import MaxCharacterReadout from './MaxCharacterReadout';
import { StyledLabel, RequiredAsterisk, StyledInput, StyledFieldset } from './Styled';

type FormInputGroupTypes = {
  register: any;
  errors: any;
  fieldID: string;
  fieldName: string;
  isRequired?: boolean;
  validationObj?: any;
  type?: string;
  defaultValue?: string;
  showErrors?: boolean;
  clearErrors: any;
  readOnly?: boolean;
};

export default function FormInputGroup({
  register,
  errors,
  fieldID,
  fieldName,
  isRequired,
  validationObj,
  type,
  showErrors,
  clearErrors,
  readOnly,
  defaultValue,
}: FormInputGroupTypes) {
  const inputDefaultValue = defaultValue ? defaultValue : null;

  const [typedInputLength, setTypedInputLength] = useState(() => {
    if (inputDefaultValue) {
      return inputDefaultValue.length;
    } else return 0;
  });
  const { maxLength, minLength } = validationObj;

  function handleInputChange(e: React.SyntheticEvent) {
    const target = e.target as HTMLTextAreaElement;

    clearErrors(fieldID); // clear error of field that has been changed.
    setTypedInputLength(target.value.length);
  }

  return (
    <StyledFieldset>
      <StyledLabel htmlFor={fieldID}>
        {fieldName}: {isRequired && <RequiredAsterisk />}
      </StyledLabel>
      <StyledInput
        readOnly={readOnly}
        type={type}
        id={fieldID}
        defaultValue={defaultValue}
        className={showErrors ? 'error' : ''}
        maxLength={maxLength}
        {...register(fieldID, {
          onChange: (e: SyntheticEvent) => handleInputChange(e),
          ...validationObj,
        })}
      />
      {showErrors && <FieldErrorMessage errors={errors[fieldID]} />}
      {maxLength && !showErrors && (
        <MaxCharacterReadout
          minLength={minLength}
          maxLength={maxLength}
          typedInputLength={typedInputLength}
          fieldID={fieldID}
        />
      )}
    </StyledFieldset>
  );
}
