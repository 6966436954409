import { media, style } from "typestyle";
import { colours } from "./colours";
import { TABLETDEVICEWIDTH } from "config/constants";

export const appStyle = style(
  {
    color: colours.primaryText,
  },
  media(
    { maxWidth: TABLETDEVICEWIDTH },
    {
      $nest: {
        "body &": {
          flexDirection: "column",
          fontSize: 13,
        },
      },
    }
  )
);

