import React, { useState } from 'react';
import { Content, Vertical } from 'gls';
import { icons } from 'assets/icons';
import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';
import Button from 'components/Button/Button';
import { MobileSortOption } from './MobileDataTable';
import { colours } from 'styling/colours';

type MobileSortProps = {
  SortOptions?: MobileSortOption[];
  handleSort: (sortField: string, direction: string) => Promise<void>;
  defaultSortField?: string;
  defaultSortDirection: string;
  onClose: () => void;
};

export const MobileSort = (props: MobileSortProps) => {
  const { SortOptions, handleSort, onClose, defaultSortField, defaultSortDirection } = props;
  const [sortvalue, setSortValue] = useState<string>(
    defaultSortField ? `${defaultSortDirection === 'asc' ? 'asc-' : ''}${defaultSortField}` : '',
  );

  const sortHandler = (e: RadioChangeEvent) => {
    setSortValue(e.target.value);
  };

  const onSort = () => {
    const sortField = sortvalue.indexOf('asc-') === 0 ? sortvalue.replace('asc-', '') : sortvalue;
    const sortDirection = sortvalue.indexOf('asc-') === 0 ? 'asc' : 'desc';
    handleSort(sortField, sortDirection);
    onClose();
  };

  return (
    <SortPanelStyle>
      <Content>
        <icons.CloseIcon onClick={onClose} />
      </Content>
      <Content horizontalAlign="center">
        <b>Sort</b>
      </Content>
      <Content className="sort-options-container">
        <Radio.Group onChange={sortHandler} value={sortvalue}>
          <Vertical>
            {SortOptions?.map((row, index) => (
              <>
                <Radio key={`asc-${row.sortField}`} value={`asc-${row.sortField}`}>
                  {row.title} ({row.AscTitle || 'a-z'})
                </Radio>
                <Radio key={row.sortField} value={row.sortField}>
                  {row.title} ({row.DescTitle || 'z-a'})
                </Radio>
                {index < SortOptions.length - 1 && <SeperatorStyle />}
              </>
            ))}
          </Vertical>
        </Radio.Group>
      </Content>
      <Content horizontalAlign="right">
        <Button onClick={onSort}>Done</Button>
      </Content>
    </SortPanelStyle>
  );
};

const SortPanelStyle = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .ant-radio-group {
    width: 100%;
  }

  .sort-options-container {
    margin: 40px 0;
    overflow-y: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }
`;

const SeperatorStyle = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colours.border};
  height: 2px;
`;
