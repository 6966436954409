import React, { ReactElement } from 'react';
import { colours } from 'styling/colours';
import { classes, style } from 'typestyle';

export const labelStyle = style(
  {
    display: 'block',
    fontSize: '15px',
    lineHeight: '15px',
    marginBottom: '10px',
    color: colours.primaryText,
  },
  // media({ maxWidth: MOBILE_DIMENSIONS.WIDTH }, { fontSize: '14px' }),
);

type LabelProps = {
  value: string | ReactElement;
  htmlFor?: string;
  required?: boolean;
  className?: string;
};

export const Label = React.forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const { value, htmlFor } = props;

  return (
    <label ref={ref} className={classes(labelStyle, props.className)} htmlFor={htmlFor}>
      {value}
      {props.required && (
        <span
          className={style({
            color: colours.highlight,
            fontWeight: 'bold',
            marginLeft: '4px',
          })}
        >
          *
        </span>
      )}
    </label>
  );
});

Label.displayName = 'Label';
