class ConfigBootstrapApi {
  headersConfig: object;

  public constructor() {
    this.headersConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
  }

  public getConfigBootstrap = async (CONFIG_API_URL: string) => {
    const response = await fetch(`${CONFIG_API_URL}/bootstrap/ils_web`, this.headersConfig);

    if (!response.ok) {
      throw new Error('problem fetching data');
    }
    const data = await response.json();
    return data;
  };
}

export default ConfigBootstrapApi;
