import React from 'react';

export default function UploadSuccessMessage({
  fileName,
  isAssetUpload,
}: {
  fileName: string;
  isAssetUpload: boolean;
}) {
  const textString = isAssetUpload ? 'asset' : 'beacon';

  return (
    <>
      {`${textString}s have been uploaded from the `} <b> {fileName}</b> file
    </>
  );
}
