import styled from 'styled-components';

export const StyledMapDropdownCheckboxInputSelect = styled.div`
  position: relative;

  min-width: 160px;
  width: 100%;
  max-width: 300px;
  &.--mobile {
    margin-top: 10px;
    max-width: 100%;
    &:first-of-type {
      margin-top: 0px;
    }
  }
  .input-container {
    display: flex;
    padding: 10px;
    justify-content: flex-start;
    align-items: center;
    background: white;
    border: 1px solid #bcbec0;
    border-radius: 6px;
    height: 100%;
    max-height: 40px;
    .preplaceholder {
      color: #8e9092;
      margin-right: 5px;
    }
    label {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
      white-space: nowrap;
    }
    input {
      display: none;
    }
    img {
      margin-left: auto;
    }
    &:hover {
      cursor: pointer;
      border-color: #00aeef;
    }
    &:focus {
      border-color: #00aeef;
    }
  }

  .list-container {
    position: absolute;
    top: 44px;
    left: 1px;
    z-index: 9999999;
    background: white;
    width: 100%;
    padding: 0 16px;
    box-shadow:
      0 0 1px hsla(0, 0%, 0%, 0.1),
      0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
    max-height: 380px;
    overflow-y: auto;
    border: 1px solid #bcbec0;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: flex;

        padding: 0;
        &:hover {
          cursor: pointer;
        }
        &:first-of-type {
          margin-top: 0;
          padding-top: 0px;
        }
        &:last-of-type {
          margin-bottom: 0;
        }

        label {
          padding: 13px 0;

          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          color: #3b3b3b;
          font-family: 'Helvetica Neue';
          input[type='checkbox'] {
            // hide browser default checkbox
            display: none;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:hover {
              cursor: pointer;
            }
          }
          .custom-checkbox {
            height: 18px;
            width: 18px;
            min-height: 18px;
            min-width: 18px;
            border: 1px solid #bcbec0;
            margin-right: 10px;
            border-radius: 3px;
            /* transition: background-color 0.3s ease-in-out; */

            &:after {
              display: none;
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &.--checked {
            .custom-checkbox {
              background-color: #00aeef;
              border-color: #00aeef;
              border-radius: 4px;
              /* transition: background-color 0.3s ease-in-out; */
              &:after {
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 5px;

                display: table;
                width: 5.7142857142857135px;
                height: 9.142857142857142px;
                border: 2px solid #fff;
                border-top-width: 2px;
                border-top-style: solid;
                border-top-color: rgb(255, 255, 255);
                border-top: 0;
                border-inline-start: 0;
                transform: rotate(45deg) scale(0) translate(-50%, -50%);
                opacity: 0;
                content: '';
                display: block;
                opacity: 1;
                transform: rotate(45deg) scale(1) translate(-50%, -50%);
                transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
              }
            }
          }
          &.--disabled {
            .custom-checkbox {
              cursor: not-allowed;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;
