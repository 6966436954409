import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import TriangleIcon from './triangle.svg';

const StyledButton = styled.button`
  display: block;
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  background: ${colours.secondary};
  border-radius: 50%;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  visibility: hidden;
  z-index: 0;
  &.--active {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    visibility: visible;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  span {
    color: white;
    font-family: 'MuseoSlab';
    font-size: 12px;
    width: 100%;
  }
  :hover {
    cursor: pointer;
  }
`;

type ScrollToTopButtonProps = {
  onScroll: () => void;
  show: boolean;
};

export default function ScrollToTopButton({ onScroll, show }: ScrollToTopButtonProps) {
  return (
    <StyledButton
      onClick={onScroll}
      className={show ? '--active' : ''}
      aria-label="Scroll To Top Button"
      tab-index="1"
    >
      <img src={TriangleIcon} alt="scroll to top" />
      <span>Top</span>
    </StyledButton>
  );
}
