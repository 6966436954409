import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

interface ICharsRemaining {
  maxLength: number;
  minLength?: number;
  typedInputLength: number;
  hasError?: boolean;
  fieldID: string;
}

const StyledDiv = styled.div`
  margin: 5px 0 0 0;
  span {
    color: ${colours.secondaryText};
    font-size: 12px;
  }
`;

const MinLengthText = ({ minLength }: { minLength: number }) => {
  return <>Minimum of {minLength} characters required</>;
};

export default function MaxCharacterReadout({
  maxLength,
  minLength,
  typedInputLength,
  fieldID,
}: ICharsRemaining): ReactElement<ICharsRemaining> {
  const remainingCharacters = maxLength - typedInputLength;

  return (
    <StyledDiv data-test-id={`asset-${fieldID}-characters`}>
      <span>
        {minLength && typedInputLength < minLength ? (
          <MinLengthText minLength={minLength} />
        ) : (
          `${remainingCharacters} characters remaining`
        )}
      </span>
    </StyledDiv>
  );
}
