import React, { useContext, useState } from 'react';
import { Radio, Space } from 'antd';
import { colours } from 'styling/colours';
import { TabContentType } from './AssetStatusUpdateModalController';
import useAssetStatus from 'hooks/useAssetStatus.hook';
import CustomDateTimePickerComponent from './CustomDateTimePickerComponent';
import { AssetStatesContext } from 'contexts/assetStates.context';

function getUnitLabel(minutes: number) {
  if (minutes === 1) return `${minutes} min`;
  if (minutes < 60) return `${minutes} mins`;
  else if (minutes === 60) return `1 hour`;
  else if (minutes > 60) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const hasMins = mins > 0;
    const isSingleHour = hours === 1;
    const isSingleMinute = mins === 1;

    return `${hours} ${isSingleHour ? 'hour' : 'hours'} ${hasMins ? `${mins} ${isSingleMinute ? 'min' : 'mins'} ` : ''}`;
  }
}

export default function ClearStatusContent({
  header,
  icon,
  setState,
  statusValue,
  setCustomDateUTCString,
}: TabContentType | any) {
  const { assetClearTimes } = useContext(AssetStatesContext);

  const [radioValue, setRadioValue] = useState(assetClearTimes[0].minutes.toString());
  const { getStatusDescriptionFromID } = useAssetStatus();

  const customSelected = radioValue === 'custom';

  function handleOptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setState(value);
    setRadioValue(value);
  }

  return (
    <>
      <header>
        <img src={icon} alt={header} />
        <h3>{header}</h3>
      </header>
      <p style={{ color: colours.secondaryText }}>
        Clear "{getStatusDescriptionFromID(statusValue)}" status after:
      </p>

      <Radio.Group size="large" onChange={(e: any) => handleOptionChange(e)} value={radioValue}>
        <Space size="middle" direction="vertical">
          {assetClearTimes.map((clearTimeObj) => {
            return (
              <Radio key={clearTimeObj.minutes} value={clearTimeObj.minutes.toString()}>
                {getUnitLabel(clearTimeObj.minutes)}
              </Radio>
            );
          })}

          <Radio value={'custom'}>Custom</Radio>
          {customSelected && (
            <CustomDateTimePickerComponent setCustomDateUTCString={setCustomDateUTCString} />
          )}
          <Radio value={'donotclear'}>Do not clear</Radio>
        </Space>
      </Radio.Group>
    </>
  );
}
