import React from 'react';
import { getValueSafely } from 'utils/utils';
import errorIcon from 'assets/icons/error-2.svg';
import { LOW_BATTERY_THRESHOLD } from 'config/constants';
import { EstimateType } from 'types/Estimate';
import TableCellWarning from 'components/DataTable/cells/TableCellWarning';
import { format } from 'date-fns';

export default function BatterySpan({ assetDetails }: { assetDetails: EstimateType }) {
  if (!assetDetails.assigned_beacon || !assetDetails.assigned_beacon.battery) return <>––</>;
  const hasTimestamp = assetDetails.last_location_update_timestamp !== null;

  if (assetDetails.assigned_beacon.battery <= LOW_BATTERY_THRESHOLD) {
    return (
      <TableCellWarning>
        {getValueSafely(assetDetails.assigned_beacon.battery, '%')}
        <img
          style={{
            marginLeft: '13px',
            width: '20px',
          }}
          src={errorIcon}
          alt="low battery"
        />
        {hasTimestamp && (
          <span style={{ color: 'black', marginLeft: '10px' }}>
            {`  (${format(
              new Date(assetDetails.last_location_update_timestamp),
              'dd/MM/yyyy - HH:mm',
            )})`}
          </span>
        )}
      </TableCellWarning>
    );
  } else {
    return (
      <span className="u-no-pointer-event">
        {getValueSafely(assetDetails.assigned_beacon.battery, '%')}
        {hasTimestamp && (
          <span style={{ color: 'black', marginLeft: '10px' }}>
            {`  (${format(
              new Date(assetDetails.last_location_update_timestamp),
              'dd/MM/yyyy - HH:mm',
            )})`}
          </span>
        )}
      </span>
    );
  }
}
