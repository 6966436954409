import React from 'react';
import { useLocation } from 'react-router-dom';

const RenderRouteComponent = ({ component }: { component: React.FC }) => {
  // a simple component that passes a unique location key as a prop to the route, ensuring route is re-rendered on every route update,
  // this allows user to click same route and get a component reload.
  const location = useLocation();

  return React.createElement(component, { key: location.key });
};
export default RenderRouteComponent;
