import React, { useEffect, useState, useContext } from 'react';
import Tablist from '../components/Tablist';
import NewBeaconTabContent from '../subpages/NewBeaconTabContent';
import RegisteredBeaconTabContent from '../subpages/RegisteredBeaconTabContent';
import RefreshTimestampAndButton from '../components/RefreshTimestampAndButton';
import { TabContainerStyled } from '../styles';
import usePageInfo from 'hooks/pageInfo.hook';
import { useGetNewBeacons, useGetRegisteredBeacons } from 'api/beacons/beacons.hooks';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken, scrollWindowToTop } from 'utils/utils';
import ImportCreateAssetsScreen from '../subpages/ImportCreateAssetsScreen';
import useURLSearchParams from 'hooks/useURLSearchParams.hook';
import { BeaconManagementContext } from '../contexts/BeaconManagment.context';
import { getSearchParamValue } from 'views/MapPage/utils/utils';
import { FullConfigContext } from 'contexts/fullConfig.context';
import { UnregisteredBeaconType } from 'types/BeaconType';

export default function TabController() {
  const [importCreateSelected, setImportCreateSelected] = useState(false); // set import screen.
  const [beaconsForImport, setBeaconsForImport] = useState<UnregisteredBeaconType[]>([]);
  const [newBeaconCount, setNewBeaconCount] = useState(0);
  const [registeredBeaconCount, setRegisteredBeaconCount] = useState(0);
  const [selectedTabKey, setSelectedTabKey] = useState<string>('1');

  const authContext = useContext(AuthenticationContext).authState;
  const { isRefreshing } = useContext(BeaconManagementContext);
  const { beaconIntegration } = useContext(FullConfigContext);
  const token = getIdToken(authContext);

  const newBeaconQuery: any = useGetNewBeacons(token, beaconIntegration);
  const registeredBeaconQuery: any = useGetRegisteredBeacons(token, null, 'beacon_id', 'asc');
  const queryArray = getQueryArray();
  const { userAccessPermissions } = useContext(FullConfigContext);

  const { removeAllSearchParamsFromURL } = useURLSearchParams();
  const { setContextPageTitle } = usePageInfo();

  function getQueryArray() {
    let arr = [registeredBeaconQuery];
    if (beaconIntegration !== '') {
      // do not pass newbeacon in if no intergration exists
      arr.push(newBeaconQuery);
    }
    return arr;
  }

  function handleTabSelect(key: string) {
    setSelectedTabKey(key);
    removeAllSearchParamsFromURL();
  }

  useEffect(() => {
    // set page title after changing to import screen.
    if (importCreateSelected) {
      setContextPageTitle('Import & Create Asset(s)');
    } else {
      setContextPageTitle('Beacon Management');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importCreateSelected]);

  useEffect(() => {
    // keep scroll to top when switching between import & table screen.
    setTimeout(() => {
      scrollWindowToTop();
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importCreateSelected]);

  useEffect(() => {
    // useEffect to set tab keys on initial load.
    // prevent tab change if api call is caused by user refreshing

    if (!isRefreshing) {
      if (!userAccessPermissions.hasWrite) {
        // prevent user from being on newbeacon tab without write permission
        setSelectedTabKey('2');
        return;
      }

      if (newBeaconQuery.data) {
        // set correct tab depending on res from newbeacon data.
        // and also if URL has search param, then use this.
        const newBeaconsAvailable = newBeaconQuery.data.data.count > 0;
        let defaultTabKey = newBeaconsAvailable ? '1' : '2';
        const tabValue = getSearchParamValue('tab');

        if (tabValue) {
          defaultTabKey = tabValue === 'registered' ? '2' : '1';
        }

        setSelectedTabKey(defaultTabKey);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBeaconQuery.data]);

  useEffect(() => {
    // set counts of tabs
    if (newBeaconQuery.data) setNewBeaconCount(newBeaconQuery.data.data.total || 0);
    if (registeredBeaconQuery.data) {
      setRegisteredBeaconCount(registeredBeaconQuery.data.data.total || 0);
    }
  }, [newBeaconQuery, registeredBeaconQuery]);

  return importCreateSelected ? (
    <ImportCreateAssetsScreen
      setImportCreateSelected={setImportCreateSelected}
      beaconsForImport={beaconsForImport}
    />
  ) : (
    <>
      <TabContainerStyled>
        <Tablist
          activeKey={selectedTabKey}
          handleTabSelect={handleTabSelect}
          newBeaconQueryCount={newBeaconCount}
          registeredBeaconQueryCount={registeredBeaconCount}
        />
        <RefreshTimestampAndButton queries={queryArray} />
      </TabContainerStyled>

      {selectedTabKey === '1' ? (
        <NewBeaconTabContent
          setImportCreateSelected={setImportCreateSelected}
          setBeaconsForImport={setBeaconsForImport}
          query={newBeaconQuery}
          selectedTabKey={selectedTabKey}
          beaconsForImport={beaconsForImport}
        />
      ) : (
        <RegisteredBeaconTabContent query={registeredBeaconQuery} selectedTabKey={selectedTabKey} />
      )}
    </>
  );
}
