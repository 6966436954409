import React from 'react';
import { getLastSeenString, getValueSafely } from 'utils/utils';
import LastSeenCell from '../../../../components/DataTable/cells/LastSeenCell';
import AccuracyRangeSpan from './AccuracyRangeSpan';
import BatterySpan from './BatterySpan';

import { StyledTable } from '../styles';
import infoImg from '../icons/infoBg.svg';
import useDevice from 'hooks/useDevice';
import LocationSection from './LocationSection';
import { EstimateType } from 'types/Estimate';
import { format } from 'date-fns';
import DefaultCell from 'components/DataTable/cells/DefaultCell';

// note this is used only as fallback componnent if user does not have the asset status feature.
const AssetListFlyoutDetails = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: EstimateType;
}) => {
  const deviceSource = selectedAssetDetails.assigned_beacon?.import_info.source;

  const { isMobileDevice } = useDevice();
  const addedOnDate = selectedAssetDetails.creation_timestamp
    ? format(new Date(selectedAssetDetails.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  return (
    <>
      {!isMobileDevice && (
        <>
          <StyledTable data-test-id="asset-details-name">
            <tbody>
              <tr>
                <td>Asset Name:</td>
                <td>{getValueSafely(selectedAssetDetails.asset_name)}</td>
              </tr>
              <tr>
                <td>Asset Type:</td>
                <td>{getValueSafely(selectedAssetDetails.asset_type.name)}</td>
              </tr>
            </tbody>
          </StyledTable>
          <div className="hr" />
        </>
      )}

      <StyledTable data-test-id="asset-details-id">
        <tbody>
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(selectedAssetDetails.asset_id)}</td>
          </tr>
          <tr>
            <td>Added On:</td>
            <td>{addedOnDate}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              <DefaultCell value={selectedAssetDetails.owner.name} />
            </td>
          </tr>
          <tr>
            <td valign="top">Description:</td>
            <td id="assetDetailsDescription">{getValueSafely(selectedAssetDetails.description)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <div className="hr" />
      <div className="information-sentance">
        <img src={infoImg} alt="information" />
        <p>Please note, estimated information was derived from the beacon.</p>
      </div>
      <StyledTable data-test-id="asset-details-estimate">
        <tbody>
          <tr>
            <td>Last Seen:</td>
            <td>
              <LastSeenCell timestamp={selectedAssetDetails.last_location_update_timestamp} />
              {selectedAssetDetails.last_location_update_timestamp &&
                ` ${getLastSeenString(selectedAssetDetails.last_location_update_timestamp)}`}
            </td>
          </tr>
          <tr>
            <td>Accuracy Range:</td>
            <td>
              <AccuracyRangeSpan assetDetails={selectedAssetDetails} />{' '}
            </td>
          </tr>

          <tr>
            <td>Battery:</td>
            <td>
              <BatterySpan assetDetails={selectedAssetDetails} />
            </td>
          </tr>
          <tr>
            <td valign="top">Location:</td>
            <td>
              <LocationSection assetDetails={selectedAssetDetails} />
            </td>
          </tr>
          <tr>
            <td>Beacon ID:</td>
            <td>{getValueSafely(selectedAssetDetails.assigned_beacon?.id)}</td>
          </tr>
          <tr>
            <td>Beacon Type:</td>
            <td>
              {getValueSafely(selectedAssetDetails.assigned_beacon?.beacon_type)}
              {deviceSource && ` - ${deviceSource}`}
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};
export default AssetListFlyoutDetails;
