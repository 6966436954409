import React from 'react';
import { ReactComponent as AddElementIcon } from './add-element-icon.svg';
import { ReactComponent as AddTaskIcon } from './add-task-icon.svg';
import { ReactComponent as AddIcon } from './add.svg';
import { ReactComponent as ArrowDownBlueIcon } from './arrow-down-blue.svg';
import { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
import { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
import { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
import { ReactComponent as AssistantTaskIcon } from './assistant-task.svg';
import {
  ReactComponent as CalendarIcon,
  ReactComponent as CalendarIcon3,
} from './Calendar-new.svg';
import { ReactComponent as CalendarTimeIcon } from './calendar-time.svg';
import { ReactComponent as CalendarIcon2 } from './calendar2.svg';
import { ReactComponent as CancelledTaskListIcon } from './cancelled-task-icon.svg';
import { ReactComponent as ChecklistIcon } from './checklist.svg';
import { ReactComponent as ClockIcon } from './clock-icon.svg';
import { ReactComponent as CloseNavIcon } from './Close-Nav.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as CompletedTaskListIcon } from './completed-task-icon.svg';
import { ReactComponent as ConfigCross } from './config-cross.svg';
import { ReactComponent as ConfigurationIcon } from './configuration-icon.svg';
import { ReactComponent as CrossIcon } from './cross-icon.svg';
import { ReactComponent as DashboardIcon } from './dashboard.svg';
import { ReactComponent as DebugIcon } from './debug-icon.svg';
import { ReactComponent as OutlineDeleteIcon } from './delete-icon.svg';
import { ReactComponent as DeleteIcon } from './delete.svg';
import { ReactComponent as DisableToggleOffIcon } from './disable-toggle-off-icon.svg';
import { ReactComponent as DownloadIcon } from './download-outlined.svg';
import { ReactComponent as DownloadIcon2 } from './download.svg';
import { ReactComponent as DummyIcon } from './dummy.svg';
import { ReactComponent as Dummy2Icon } from './dummy2.svg';
import { ReactComponent as EditIconOutlined } from './edit-icon-outlined.svg';
import { ReactComponent as EmailIcon } from './email.svg';
import { ReactComponent as EnvelopeOpenIcon } from './envelope-open.svg';
import { ReactComponent as ErrorBangIcon } from './error-bang.svg';
import { ReactComponent as ErrorIcon } from './error.svg';
import { ReactComponent as ExpandIcon } from './expand.svg';
import { ReactComponent as VerificationFailedTaskIcon } from './failed-task.svg';
import { ReactComponent as FavouriteOutline } from './favourite-outline.svg';
import { ReactComponent as FavouriteWithFill } from './favourite-with-fill.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as FreehandCheckIcon } from './free-hand-check-icon.svg';
import { ReactComponent as FreehandCrossIcon } from './free-hand-cross-icon.svg';
import { ReactComponent as GridIcon } from './grid.svg';
import { ReactComponent as InRangeIcon } from './in-range.svg';
import { ReactComponent as InfoIcon } from './info.svg';
import { ReactComponent as InputNumberIcon } from './input-number.svg';
import { ReactComponent as InputIcon } from './input2.svg';
import { ReactComponent as KeyValuePairsIcon } from './keyvaluepairs.svg';
import { ReactComponent as ListIcon } from './list.svg';
import { ReactComponent as ListDeleteIcon } from './ListDelete.svg';
import { ReactComponent as ListDeleteOnHoverIcon } from './ListDeleteOnHover.svg';
import { ReactComponent as LoadingIcon } from './loading.svg';
import { ReactComponent as MaintenanceSettingsIcon } from './maintenance-settings-icon.svg';
import { ReactComponent as MobileMenu } from './mobile-menu.svg';
import { ReactComponent as OpenEyeIcon } from './open-eye-icon.svg';
import { ReactComponent as OpenNavIcon } from './Open-Nav.svg';
import { ReactComponent as OutOfRangeIcon } from './out-of-range.svg';
import { ReactComponent as PasswordResetIcon } from './password-reset-icon.svg';
import { ReactComponent as PatientIcon } from './patient.svg';
import { ReactComponent as PauseIcon } from './pause.svg';
import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as PlayIcon } from './play.svg';
import { ReactComponent as RadioIcon } from './radio2.svg';
import { ReactComponent as ReopenTaskIcon } from './re-open-task.svg';
import { ReactComponent as ReopenVerificationIcon } from './re-open-verification.svg';
import { ReactComponent as ReOrderIcon } from './re-order.svg';
import { ReactComponent as RecurrentTaskListIcon } from './recurrent-task-list-icon.svg';
import { ReactComponent as Remove } from './remove-icon.svg';
import { ReactComponent as ReturnTask } from './return-task.svg';
import { ReactComponent as RolesIcon } from './roles-icon.svg';
import { ReactComponent as RulesIcon } from './rules-icon.svg';
import { ReactComponent as SaveIconCircle } from './save-icon-circle.svg';
import { ReactComponent as SaveIcon } from './save-icon.svg';
import { ReactComponent as SearchIcon } from './search.svg';
import { ReactComponent as SelectIcon } from './select.svg';
import { ReactComponent as SortIcon } from './sort-arrow.svg';
import { ReactComponent as SuccessBangIcon } from './success-bang.svg';
import { ReactComponent as SuccessIcon } from './success.svg';
import { ReactComponent as SystemAdministratorsIcon } from './system-administrators-icon.svg';
import { ReactComponent as TaskActivityIcon } from './task-activity.svg';
import { ReactComponent as TaskDetailsIcon } from './task-details.svg';
import { ReactComponent as TaskHandler } from './task-handler.svg';
import { ReactComponent as TaskListIcon } from './task-icon.svg';
import { ReactComponent as TaskQueueIcon } from './Task-Queue.svg';
import { ReactComponent as TaskSchedulerIcon } from './task-scheduler-icon.svg';
import { ReactComponent as ToggleIcon } from './toggle.svg';
import { ReactComponent as TriangleUpIcon } from './triangle-up.svg';
import { ReactComponent as UserManagementIcon } from './user-management-icon.svg';
import { ReactComponent as VerificationIcon } from './verification.svg';
import { ReactComponent as WaypointItem } from './waypoint-item.svg';
import { ReactComponent as WorkgroupsIcon } from './workgroups-icon.svg';

export const icons: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
  AddElementIcon,
  AddIcon,
  AddTaskIcon,
  ArrowDownBlueIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  AssistantTaskIcon,
  CalendarIcon,
  CalendarIcon2,
  CalendarIcon3,
  CalendarTimeIcon,
  CancelledTaskListIcon,
  ChecklistIcon,
  ClockIcon,
  CloseIcon,
  CloseNavIcon,
  CompletedTaskListIcon,
  ConfigCross,
  ConfigurationIcon,
  CrossIcon,
  DashboardIcon,
  DebugIcon,
  DeleteIcon,
  DisableToggleOffIcon,
  Dummy2Icon,
  DummyIcon,
  DownloadIcon,
  DownloadIcon2,
  EditIconOutlined,
  EmailIcon,
  EnvelopeOpenIcon,
  ErrorBangIcon,
  ErrorIcon,
  ExpandIcon,
  FavouriteOutline,
  FavouriteWithFill,
  Filter,
  FreehandCheckIcon,
  FreehandCrossIcon,
  GridIcon,
  InfoIcon,
  InputIcon,
  InputNumberIcon,
  InRangeIcon,
  KeyValuePairsIcon,
  ListDeleteIcon,
  ListDeleteOnHoverIcon,
  ListIcon,
  LoadingIcon,
  MaintenanceSettingsIcon,
  MobileMenu,
  OpenEyeIcon,
  OpenNavIcon,
  OutlineDeleteIcon,
  OutOfRangeIcon,
  PasswordResetIcon,
  PatientIcon,
  PauseIcon,
  PhoneIcon,
  PlayIcon,
  RadioIcon,
  RecurrentTaskListIcon,
  Remove,
  ReopenTaskIcon,
  ReopenVerificationIcon,
  ReOrderIcon,
  ReturnTask,
  RolesIcon,
  RulesIcon,
  SaveIcon,
  SaveIconCircle,
  SearchIcon,
  SelectIcon,
  SortIcon,
  SuccessBangIcon,
  SuccessIcon,
  SystemAdministratorsIcon,
  TaskActivityIcon,
  TaskDetailsIcon,
  TaskHandler,
  TaskListIcon,
  TaskQueueIcon,
  TaskSchedulerIcon,
  ToggleIcon,
  TriangleUpIcon,
  UserManagementIcon,
  VerificationFailedTaskIcon,
  VerificationIcon,
  WaypointItem,
  WorkgroupsIcon,
};
