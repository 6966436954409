import { SystemAdminPermissionStore } from "./SystemAdminPermissionStore";
import { RolesListStore } from "./config/RoleListStore";
import { RoleStore } from "./config/RoleStore";
import { SystemAdministratorsStore } from "./config/SystemAdministratorsStore";
import { WorkgroupsStore } from "./config/WorkgroupsStore";

export class RootStore {
  systemAdminPermissionStore: SystemAdminPermissionStore;
  
  workgroupsStore: WorkgroupsStore;
  rolesListStore: RolesListStore;
  roleStore: RoleStore;
  systemAdministratorsStore : SystemAdministratorsStore;

  constructor() {
    this.systemAdminPermissionStore = new SystemAdminPermissionStore(this);
    
    this.workgroupsStore = new WorkgroupsStore(this);
    this.rolesListStore = new RolesListStore(this);
    this.roleStore = new RoleStore(this);
    this.systemAdministratorsStore = new SystemAdministratorsStore(this);
  }
}
