// @ts-nocheck
export function initialPendo(userId: string, userEmail?: string, userName?: string) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      // @ts-ignore
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          // @ts-ignore
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      // @ts-ignore
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.eu.pendo.io/agent/static/${apiKey}/pendo.js`;
      // @ts-ignore
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');

    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function after users are authenticated in your app and your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    // @ts-ignore
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: userId, // Required if user is logged in, default creates anonymous ID
        email: userEmail, // Recommended if using Pendo Feedback, or NPS Email
        full_name: userName, // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id: window.location.host, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  })('fef5cba0-d834-40b4-5daf-c10cc6afba1f');
}
