import React, { useContext, useEffect, useState } from 'react';
import { EstimateType } from 'types/Estimate';
import LoadingBars from 'components/Loading/LoadingBars';
import { AssetStatusType } from 'types/AssetStatus';
import AssetActivityTimelineComponent from './AssetActivityTimelineComponent';
import styled from 'styled-components';
import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import AssetsApi from 'api/assets/assets.api';
import { alertErrorMessage } from 'utils/alerts';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  .loading-container {
  }
  p {
    margin-top: 20px;
  }
`;
export default function AssetActivityTabContent({
  asset,
  updateAssetStatusModalOpen,
}: {
  asset: EstimateType;
  updateAssetStatusModalOpen: boolean;
}) {
  const [activityData, setActivityData] = useState<AssetStatusType[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { authState } = useContext(AuthenticationContext);
  const idToken = getIdToken(authState);

  useEffect(() => {
    if (!updateAssetStatusModalOpen) {
      const assetsApi = new AssetsApi(idToken);
      setIsLoading(true);

      assetsApi
        .getAssetStatusAssignmentDetails(asset.asset_id)
        .then((res) => {
          setActivityData(res.items);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          alertErrorMessage('Could not fetch activity details');
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAssetStatusModalOpen]);

  return (
    <StyledDiv>
      {isLoading ? (
        <div className="loading-container">
          <LoadingBars />
          <p>Loading...</p>
        </div>
      ) : (
        <AssetActivityTimelineComponent activityDataArray={activityData} />
      )}
    </StyledDiv>
  );
}
