import React, { useContext } from 'react';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledRegisteredDropdownControlWrapper } from '../Styled';
import { BeaconSelectOptionType } from 'views/BeaconManagementPage/types';
import LoadingSelectInput from 'components/Loading/LoadingSelectInput';
import { BeaconManagementContext } from 'views/BeaconManagementPage/contexts/BeaconManagment.context';
import useCallBeaconAssignmentOptionApiCount from './useCallBeaconAssignmentOptionApiCount.hook';

type RegisteredBeaconAssignedInputSelectTypes = {
  handleSelectAssignmentChange: (arg: BeaconSelectOptionType) => void;
};

export default function RegisteredBeaconAssignedInputSelect({
  handleSelectAssignmentChange,
}: RegisteredBeaconAssignedInputSelectTypes) {
  const { isRefreshing } = useContext(BeaconManagementContext);
  const { options, defaultDropdownValue, isLoading } = useCallBeaconAssignmentOptionApiCount();

  return !isLoading && !isRefreshing ? (
    <div style={{ width: '100%' }} data-test-id="registered-beacon-status">
      <SelectInput
        defaultValue={defaultDropdownValue}
        onChange={(val: any) => handleSelectAssignmentChange(val)}
        options={options}
        components={{
          Control: ({ children, ...rest }) => (
            <StyledRegisteredDropdownControlWrapper>
              <components.Control {...rest}>
                <span className="react-control__preplaceholder">Show: </span>
                {children}
              </components.Control>
            </StyledRegisteredDropdownControlWrapper>
          ),
        }}
      />
    </div>
  ) : (
    <LoadingSelectInput />
  );
}
