import React, { useContext, useState } from 'react';
import { VenuesContext } from '../contexts/venues.context';
import SelectInput from 'components/SelectInput/SelectInput';
import { components } from 'react-select';
import { StyledMapDropdownControlWrapper, StyledVenueDropdownContainer } from '../Styled';
import {
  getReactSelectOptionsFromVenueListData,
  getVenueObjFromReactSelectValue,
} from '../utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { clearWindowURLParams } from 'utils/utils';

export type ReactSelectType = {
  label: string;
  value: string;
};

export default function VenueSelectDropdownControl() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const { venuesList, selectedVenueObj, setSelectedVenueObj } = useContext(VenuesContext);
  const { setBuildingSelectedID, setSelectedFloorOrdinal, setFloorSelectedIDArray } =
    useContext(MapControlContext);

  function handleVenueChange(reactSelectObj: ReactSelectType) {
    // ReactSelect only gives us value and label obj,
    // so we need to use this to filter the selected obj from the venues list.
    // we then update selectedVenueObj
    const newSelectedVenueObj = getVenueObjFromReactSelectValue(reactSelectObj.value, venuesList);
    setSelectedVenueObj(newSelectedVenueObj);
    setMenuIsOpen(false);
    // reset building selected, floor and index selected when venue changes.
    setBuildingSelectedID('');
    setSelectedFloorOrdinal(null);
    setFloorSelectedIDArray(['']);
    clearWindowURLParams();
  }

  // only render dropdown if there is more than one venue to choose from.
  return (
    <StyledVenueDropdownContainer>
      <div data-test-id="venue-select-input">
        <SelectInput
          options={getReactSelectOptionsFromVenueListData(venuesList)}
          defaultValue={getReactSelectOptionsFromVenueListData([selectedVenueObj])}
          onChange={(val: any) => handleVenueChange(val)}
          isSearchable={false}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => {
            setMenuIsOpen(true);
          }}
          components={{
            Control: ({ children, ...rest }) => {
              return (
                <StyledMapDropdownControlWrapper onClick={() => setMenuIsOpen(!rest.menuIsOpen)}>
                  <components.Control {...rest}>
                    <div style={{ display: 'flex' }}>
                      <span className="react-control__preplaceholder">Venue: </span>
                    </div>

                    {children}
                  </components.Control>
                </StyledMapDropdownControlWrapper>
              );
            },
            DropdownIndicator: ({ ...rest }) => {
              return (
                <button>
                  <components.DropdownIndicator {...rest} />
                </button>
              );
            },

            Menu: ({ ...rest }) => {
              return <components.Menu {...rest} />;
            },
          }}
        />
      </div>
    </StyledVenueDropdownContainer>
  );
}
