import { UploadFailedStatusMessageType } from 'components/FileUpload/type';
import { StyledUnorderedList } from 'components/List/styled';
import React from 'react';

type FailedErrorListType = {
  processingErrors: UploadFailedStatusMessageType[];
};
export default function ProcessingErrorsList({ processingErrors }: FailedErrorListType) {
  return (
    <StyledUnorderedList>
      {processingErrors.map((error) => {
        const hasIndex = error.row_index !== undefined;
        const hasColumn = error.column !== undefined;

        return (
          <li key={error.row_index}>
            {error.message && hasIndex && `Error on line ${error.row_index}, `}
            {hasColumn && `${error.column}:`} {error.message}
          </li>
        );
      })}
    </StyledUnorderedList>
  );
}
