export function toLocaleISOString(date: Date) {
  const offset = -date.getTimezoneOffset();
  const offsetHours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0');
  const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
  const offsetSign = offset >= 0 ? '+' : '-';

  return `${date.toISOString().slice(0, 19)}${offsetSign}${offsetHours}:${offsetMinutes}`;
}

export function getMaxDate(date1: Date, date2: Date) {
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();

  if (isNaN(timestamp1)) {
    return date2;
  }
  if (isNaN(timestamp2)) {
    return date1;
  }

  if (timestamp1 > timestamp2) {
    return date1;
  } else {
    return date2;
  }
}

export function getMinDate(date1: Date, date2: Date) {
  const timestamp1 = date1.getTime();
  const timestamp2 = date2.getTime();

  if (isNaN(timestamp1)) {
    return date2;
  }
  if (isNaN(timestamp2)) {
    return date1;
  }

  if (timestamp1 < timestamp2) {
    return date1;
  } else {
    return date2;
  }
}

export function getEndOfDay(date: Date) {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

export function getFirstOfDay(date: Date) {
  const endOfDay = new Date(date);
  endOfDay.setHours(0, 0, 0, 0);
  return endOfDay;
}

export function addDay(date: Date, value: number) {
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + value);
  return nextDay;
}

export function addMonth(date: Date, value: number) {
  const monthBefore = new Date(date);
  monthBefore.setMonth(monthBefore.getMonth() + value);
  return monthBefore;
}
