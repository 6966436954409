import React from 'react';

export default function AssetActivityIcon({ isActive }: { isActive: boolean }) {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2464 3.09874C14.0519 2.88384 13.8574 2.66895 13.5657 2.45405C13.3712 2.23915 13.0795 2.3466 12.885 2.5615C12.6905 2.77639 12.7877 3.09874 12.9822 3.31363C13.1767 3.52853 13.3712 3.63598 13.5657 3.85087C14.8299 5.35514 15.5106 7.18175 15.5106 9.22326C15.5106 11.2648 14.8299 13.0914 13.4684 14.5956C12.3988 15.7776 10.9401 16.5297 9.48141 16.7446C9.18968 16.7446 8.99519 17.0669 9.09243 17.2818C9.09243 17.4967 9.28692 17.7116 9.57866 17.7116C9.57866 17.7116 9.57866 17.7116 9.6759 17.7116C11.4263 17.4967 12.9822 16.6372 14.2464 15.2403C15.7051 13.6286 16.483 11.4797 16.483 9.22326C16.483 6.96686 15.7051 4.8179 14.2464 3.09874Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
      <path
        d="M2.67423 12.8763C2.3825 12.2316 2.18801 11.587 1.99352 10.9423C1.89628 10.2976 1.89628 9.76035 1.89628 9.22311C1.89628 7.1816 2.57699 5.35499 3.84117 3.85072C4.91086 2.6688 6.36953 1.91666 7.8282 1.70177C8.11994 1.70177 8.31443 1.37942 8.21718 1.16453C8.21718 0.842185 8.02269 0.62729 7.73096 0.734737C5.98055 0.949633 4.42464 1.80922 3.16046 3.20604C1.70179 4.81775 0.923828 6.96671 0.923828 9.22311C0.923828 9.8678 1.02107 10.5125 1.11832 11.0497C1.31281 11.8019 1.5073 12.554 1.89628 13.3061C2.3825 14.2732 3.06321 15.1327 3.84117 15.8849C3.93841 15.9923 4.03566 15.9923 4.1329 15.9923C4.23015 15.9923 4.42464 15.8849 4.52188 15.7774C4.71637 15.5625 4.61913 15.2402 4.42464 15.0253C3.74393 14.4881 3.16046 13.7359 2.67423 12.8763Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M12.6905 8.25612C12.6905 8.25612 12.5932 8.36357 12.5932 8.47102L6.8558 17.7115C6.75855 17.819 6.66131 17.9264 6.46682 17.9264H6.27233C6.07784 17.819 5.88335 17.4966 5.98059 17.2817L7.34202 10.8349H5.20264C5.00815 10.8349 4.9109 10.7274 4.81366 10.5125C4.71641 10.2976 4.71641 10.0827 4.81366 9.97529L10.5511 0.949675C10.6483 0.734779 10.9401 0.627331 11.1346 0.734779C11.3291 0.842227 11.5235 1.16457 11.4263 1.37947L10.0649 7.82633H12.3015H12.3987H12.496L12.5932 7.93378C12.6905 7.93378 12.6905 8.04123 12.6905 8.04123V8.14867V8.25612ZM8.41171 10.2976V10.4051L7.43926 14.9179L11.3291 8.90081H9.57865C9.38416 8.90081 9.28691 8.79336 9.18967 8.68591C9.09242 8.57847 9.09242 8.36357 9.09242 8.25612L10.0649 3.85076L6.17508 9.86784H7.92549H8.02273H8.11998H8.21722L8.31447 9.97529C8.41171 9.97529 8.41171 10.0827 8.41171 10.0827V10.2976Z"
        fill={isActive ? '#00AEEF' : '#808285'}
      />
    </svg>
  );
}
