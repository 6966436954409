import React, { ReactElement } from 'react';

type ComponentFeaturePermissionRenderWrapperType = {
  children: React.ReactElement;
  availableFeaturePermission: any;
  fallbackComponent?: ReactElement;
};

// a simple wrapper to detect if the component has the required available feauture provided by config.
// can render a fallback component if provided
export default function ComponentFeatureIsAvailableRenderWrapper({
  children,
  availableFeaturePermission, // must have this permission
  fallbackComponent,
}: ComponentFeaturePermissionRenderWrapperType) {
  if (!availableFeaturePermission) {
    return fallbackComponent ? fallbackComponent : null;
  } else return children;
}
