import useAuth from 'hooks/useAuth.hook';

export default function useBroadcastChannel() {
  //hook to create Broadcast Channel, and return func that can be used to send messages.
  // initialisation of listener happens in PrivateRoute.
  const { authSignout } = useAuth();
  const browserSupportsBroadcastChannel = window.BroadcastChannel !== undefined;

  const bc: any = browserSupportsBroadcastChannel
    ? new BroadcastChannel('ils_broadcast_notification')
    : null;

  function postBroadcastMessage(message: string) {
    if (browserSupportsBroadcastChannel) {
      bc.postMessage(message);
    }
  }

  function initialiseBroadcastMessageEventListener() {
    // initialise listener for ils broadcast API messages.
    if (browserSupportsBroadcastChannel) {
      bc.addEventListener('message', (event: any) => {
        handleBroadcastMessage(event.data);
      });
      console.log('%c[Broadcast Message listener enabled]', 'color: #26bfa5;');
    }
  }

  function handleBroadcastMessage(message: string) {
    switch (message) {
      case 'broadcast_signout':
        authSignout();

        break;

      default:
        break;
    }
  }

  return { postBroadcastMessage, initialiseBroadcastMessageEventListener };
}
