import UserApi from 'api/user/user.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import { VenuesContext } from '../contexts/venues.context';
import { useContext, useEffect, useRef, useState } from 'react';
import { getIdToken, getUsersActiveLastHour } from 'utils/utils';
import { MapControlContext } from '../contexts/mapcontrol.context';
import { SearchFilterContext } from '../contexts/searchFilter.context';
import { alertErrorMessage } from 'utils/alerts';
import { getIndoorUsersWithEstimate, getOutoorUserEstimates } from '../utils/utils';
import { UsersContext } from '../contexts/users.context';
import useHandleSearchResults from './useHandleSearchResults';

export default function useCallSearchUsers() {
  const [noUserResultsReturned, setNoUserResultsReturned] = useState(false);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const userApi = new UserApi(token);

  const { selectedVenueObj, isSingleVenue } = useContext(VenuesContext);
  const { setApiCallInProgress, setClickedMarkerID } = useContext(MapControlContext);
  const { setDoNotCalculateFloor, setInSearchMode, setInFilterMode, setSearchAPIcallInProgress } =
    useContext(SearchFilterContext);
  const { setUsersMarkers, setUsersOutsideMappedBuildingsMarkers } = useContext(UsersContext);

  const { handleUserAssetSearchResults } = useHandleSearchResults();
  const abortControllerRef: any = useRef(null);

  function callSearchUsers(term: string) {
    const selectedVenueID = isSingleVenue ? null : selectedVenueObj.venue_id;
    const termTrimmed = term.trim();
    abortControllerRef.current = new AbortController();

    setApiCallInProgress(true);
    setSearchAPIcallInProgress(true);
    setDoNotCalculateFloor(false);
    setInSearchMode(false);
    setNoUserResultsReturned(false);

    return userApi
      .getUsersMap(selectedVenueID, termTrimmed, abortControllerRef.current)
      .then((res) => {
        const { items } = res.data;
        const activeUsers = getUsersActiveLastHour(items);
        const userEstimatesWithBuilding = getIndoorUsersWithEstimate(activeUsers);
        const outdoorUserEstimates = getOutoorUserEstimates(activeUsers);
        const combinedEstimates = [...userEstimatesWithBuilding, ...outdoorUserEstimates];

        setInSearchMode(true);
        setInFilterMode(false);
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        setClickedMarkerID('');
        setUsersMarkers(userEstimatesWithBuilding);
        setUsersOutsideMappedBuildingsMarkers(outdoorUserEstimates);
        handleUserAssetSearchResults(
          combinedEstimates,
          userEstimatesWithBuilding,
          outdoorUserEstimates,
        );

        return combinedEstimates;
      })
      .catch((err) => {
        setApiCallInProgress(false);
        setSearchAPIcallInProgress(false);
        console.log(err.message);

        if (abortControllerRef.current?.signal.aborted) return; // prevent alert if API call was cancelled.
        alertErrorMessage(`Search Failed`);
      });
  }

  useEffect(() => {
    // useEffect to abort api call if component unmounts.
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { callSearchUsers, noUserResultsReturned, setNoUserResultsReturned };
}
