import React, { useContext, useEffect, useState } from 'react';
import { SearchBarContainerStyled, SearchBarStyled } from '../../Styled';
import { StyledInputContainer } from 'components/AsyncSelect/Styled';
import { MapControlContext } from '../../contexts/mapcontrol.context';
import DropdownFilterInputController from 'views/MapPage/components/SearchFilterBar/DropdownFilterInputController';

import { SearchFilterContext } from 'views/MapPage/contexts/searchFilter.context';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { PERMISSIONS } from 'config/permissions';
import DerivedAsyncSelectSearch from './DerivedAsyncSelectSearch';
import { FullConfigContext } from 'contexts/fullConfig.context';
import ShowAssetPeopleDropdown from './DropDownInput/ShowAssetPeopleDropdown';

export default function AssetMapSearchFilterForm() {
  const [currentMenuOpenType, setCurrentMenuOpenType] = useState<string | null>(null);

  const { setRecallGetAssets, setRecallGetUsers, setClickedMarkerID, showAssetsChecked } =
    useContext(MapControlContext);
  const {
    searchTerm,

    setDoNotCalculateFloor,
    setInSearchMode,
    inSearchMode,
    inFilterMode,
    setInFilterMode,
    hasSearchTerm,
    setMenuIsOpen,
  } = useContext(SearchFilterContext);
  const { assetTrackingPermissionEnabled } = useContext(FullConfigContext);

  const shouldShowAssetFilters = assetTrackingPermissionEnabled && showAssetsChecked;
  const searchTermPresent = searchTerm.length > 0; // note we hide dropdowns when there is a searchterm

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;

    setMenuIsOpen(hasSearchTerm);
  }

  useEffect(() => {
    // reset search when no searchterm.
    if (searchTerm.length < 1 && inSearchMode) {
      setDoNotCalculateFloor(true); // set to true so we dont recenter map.
      setInSearchMode(false);
      setClickedMarkerID('');
      setRecallGetAssets(true);
      setRecallGetUsers(true);
      setMenuIsOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    // useeffect to revert filters and re-call api if searchterm is present.
    if (searchTerm.length > 0 && inFilterMode) {
      setRecallGetAssets(true);
      setRecallGetUsers(true);
      setInFilterMode(false);
      setDoNotCalculateFloor(true); // set to true so we dont recenter map.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    setMenuIsOpen(searchTerm.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <SearchBarContainerStyled>
      <SearchBarStyled
        style={{
          marginBottom: !shouldShowAssetFilters || searchTermPresent ? '22px' : '',
          width: !shouldShowAssetFilters || searchTermPresent ? '100%' : '',
        }}
      >
        <StyledInputContainer
          style={{ marginRight: '10px' }}
          width={350}
          onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
        >
          <DerivedAsyncSelectSearch />
        </StyledInputContainer>
        {!searchTermPresent && (
          <ComponentFeaturePermissionRenderWrapper
            featurePermissions={[PERMISSIONS.FEATURE.USER_TRACKING]}
            requiredPermissions={[
              PERMISSIONS.FEATURE.USER_TRACKING,
              PERMISSIONS.FEATURE.ASSET_TRACKING,
            ]}
          >
            <ShowAssetPeopleDropdown
              currentMenuOpenType={currentMenuOpenType}
              setCurrentMenuOpenType={setCurrentMenuOpenType}
            />
          </ComponentFeaturePermissionRenderWrapper>
        )}
      </SearchBarStyled>
      {shouldShowAssetFilters && !searchTermPresent && (
        <SearchBarStyled>
          <DropdownFilterInputController
            currentMenuOpenType={currentMenuOpenType}
            setCurrentMenuOpenType={setCurrentMenuOpenType}
          />
        </SearchBarStyled>
      )}
    </SearchBarContainerStyled>
  );
}
