import React from 'react';
import BeaconManagementContext from './contexts/BeaconManagment.context';
import HeaderDefault from 'components/Header/HeaderDefault';
import TabController from './controllers/TabController';
import { ScrolledMain } from 'components/ScrolledMain';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';

const BeaconManagementPage: React.FC = () => {
  const { isMobileDevice } = useDevice();

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      <HeaderDefault />
      <ScrolledMain>
        <div className="container">
          <BeaconManagementContext>
            <TabController />
          </BeaconManagementContext>
        </div>
      </ScrolledMain>
    </>
  );
};
export default BeaconManagementPage;
