import { StyledAsyncSelect, StyledInputContainer } from 'components/AsyncSelect/Styled';
import ClearInputButton from 'components/Button/ClearInputButton';
import React, { useState } from 'react';
import NoSearchResultsPanel from 'views/BeaconManagementPage/components/NewBeaconSearch/NoSearchResultsPanel';

type SearchInputProps = {
  value?: string;
  onChange: (value: string) => void;
  onSearch: (value: string) => void;
  minValueLength?: number;
  isLoading?: boolean;
  placeholder?: string;
};
export const SearchInput = (props: SearchInputProps) => {
  const {
    value,
    onSearch,
    onChange,
    minValueLength = 3,
    isLoading,
    placeholder = 'Search',
  } = props;
  const [showMinimumCharactersError, setShowMinimumCharactersError] = useState(false);

  function handleClearField() {
    onChange('');
  }

  function handleInputChange(term: string) {
    onChange(term);
    setShowMinimumCharactersError(false);
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      if ((value || '').length < minValueLength) {
        setShowMinimumCharactersError(true);
        return;
      }
      if (value !== undefined && value !== '') onSearch(value);
    }
  }
  return (
    <StyledInputContainer width={350}>
      <StyledAsyncSelect
        isLoading={isLoading}
        placeholder={placeholder}
        onInputChange={handleInputChange}
        onKeyDown={(e: React.KeyboardEvent) => handleKeyPress(e)}
        inputValue={value}
        className="asyncSearch"
        classNamePrefix="asyncSearchPrefix"
        menuIsOpen={false}
      />
      {(value || '').length > 0 && (
        <ClearInputButton onClick={handleClearField} searchInProgress={isLoading} />
      )}
      {showMinimumCharactersError && <NoSearchResultsPanel />}
    </StyledInputContainer>
  );
};
