import axios from 'axios';
import config from 'react-global-configuration';
import { alertErrorMessage } from 'utils/alerts';

class BeaconsApi {
  protected token: string;
  protected config: Headers;
  protected axiosConfig: any;

  public constructor(token: string) {
    this.config = new Headers();
    this.token = token;
    this.config.set('Content-Type', 'application/json');
    this.config.set('Accept', 'application/json');
    this.config.set('Authorization', `Bearer ${this.token}`);
    this.axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    };
  }

  public getUnregisteredBeaconsBySource = async (
    source: string,
    nextToken?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');
    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/unregistered_beacons/${source}?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getUnregisteredBeacons = async (nextToken?: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');

    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/unregistered_beacons/kontakt?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getUnregisteredBeaconById = async (beaconID: string): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');
    queryString.append('beacon_id_filter', beaconID);

    return axios.get(
      `${config.get().DATA_API_URL}/unregistered_beacons/kontakt?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getLocalizedBeacons = async (
    assigned?: boolean | null,
    nextToken?: string | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');

    if (assigned !== null && assigned !== undefined) {
      queryString.append('is_assigned_to_asset_filter', `${assigned}`);
    }

    if (nextToken) queryString.append('next', nextToken);
    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return (
      axios
        .get(
          `${config.get().DATA_API_URL}/localized/beacons?${queryString.toString()}`,
          this.axiosConfig,
        )
        // error handled here because we're using react-query to call.
        .catch((err) => {
          alertErrorMessage('Could not fetch Beacons');
          console.log(err);
        })
    );
  };

  public getSearchSingleOptionLocalizedBeacons = async (
    term: string,
    searchOption: string,
    assigned?: boolean | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');
    queryString.append(`${searchOption}_filter`, `${term}`);

    if (assigned !== null && assigned !== undefined) {
      queryString.append('is_assigned_to_asset_filter', `${assigned}`);
    }

    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return axios.get(
      `${config.get().DATA_API_URL}/localized/beacons?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getSearchAllOptionsLocalizedBeacons = async (
    term: string,
    assigned?: boolean | null,
    sortField?: string,
    sortDirection?: string,
  ): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '50');
    queryString.append(`beacon_id_filter`, `${term}`);
    queryString.append(`asset_id`, `${term}`);
    queryString.append(`source_filter`, `${term}`);

    if (assigned !== null && assigned !== undefined) {
      queryString.append('is_assigned_to_asset_filter', `${assigned}`);
    }
    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);

    return axios.get(
      `${config.get().DATA_API_URL}/localized/beacons?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getLocalizedBeaconsFlexible = async (
    term: string,
    searchOption: string,
    assigned?: boolean | null,
    nextToken?: string | null,
    sortField?: string,
    sortDirection?: string,
    isSearchingAll?: boolean,
  ): Promise<any> => {
    const queryString = new URLSearchParams();

    queryString.append('limit', '50');
    if (assigned !== null && assigned !== undefined)
      queryString.append(`is_assigned_to_asset_filter`, `${assigned}`);

    if (isSearchingAll) {
      queryString.append(`beacon_id_filter`, `${term}`);
      queryString.append(`asset_id`, `${term}`);
      queryString.append(`source_filter`, `${term}`);
    } else if (searchOption) {
      queryString.append(`${searchOption}_filter`, `${term}`);
    }

    if (sortField) queryString.append('sort', `${sortDirection === 'desc' ? '-' : ''}${sortField}`);
    if (nextToken) queryString.append('next', nextToken);

    return axios.get(
      `${config.get().DATA_API_URL}/localized/beacons?${queryString.toString()}`,
      this.axiosConfig,
    );
  };

  public getLocalizedBeaconsTotal = async (assigned?: boolean | null): Promise<any> => {
    const queryString = new URLSearchParams();
    queryString.append('limit', '1');
    if (assigned !== null && assigned !== undefined) {
      queryString.append('is_assigned_to_asset_filter', `${assigned}`);
    }

    return axios
      .get(
        `${config.get().DATA_API_URL}/localized/beacons?${queryString.toString()}`,
        this.axiosConfig,
      )
      .catch((e) => e);
  };

  public createBeacon = async (beaconData: any) => {
    const url = `${config.get().DATA_API_URL}/beacons`;

    return axios.post(url, JSON.stringify(beaconData), this.axiosConfig);
  };

  public getJobTemplateFile = async () => {
    const url = `${config.get().DATA_API_URL}/device/beacon/job/template`;

    return axios({
      url: url,
      method: 'get',
      responseType: 'blob',
      headers: {
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Content-Disposition': 'attachment;',
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  public postBulkUpload = async (file: any) => {
    const url = `${config.get().DATA_API_URL}/beacons/bulk_upload`;
    const data = new FormData();

    data.append('data', file);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
      body: data,
    });

    if (!response.ok) {
      const errRes = await response.json();

      throw errRes;
    }
    return response;
  };
}

export default BeaconsApi;
