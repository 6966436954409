import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { MapControlContext } from '../contexts/mapcontrol.context';
import ComponentFeaturePermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeaturePermissionRenderWrapper';
import { PERMISSIONS } from 'config/permissions';
import { SearchFilterContext } from '../contexts/searchFilter.context';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  height: 73px;
  width: 100%;
  padding: 25px;
  > span {
    margin-right: 9px;
  }
  .ant-checkbox-wrapper {
    &:nth-of-type(2) {
      margin-left: 12px;
    }
  }
`;

export default function AssetMapTopFilterBar() {
  const {
    setAssetMarkerInfoChecked,
    showAssetsChecked,
    showUsersChecked,
    setUserMarkerInfoChecked,
  } = useContext(MapControlContext);
  const { searchType } = useContext(SearchFilterContext);
  const assetsSearchSelected = searchType !== 'users';
  const usersSearchSelected = searchType !== 'assets';

  function handleAssetMarkerInfoCheckboxToggle(ev: CheckboxChangeEvent) {
    const checked = ev.target.checked;

    setAssetMarkerInfoChecked(checked);
  }

  function handleUsersMarkerInfoCheckboxToggle(ev: CheckboxChangeEvent) {
    const checked = ev.target.checked;

    setUserMarkerInfoChecked(checked);
  }
  useEffect(() => {
    // set marker infochecked to false, whenever show assets or users is false.
    // this will ensure infoboxes are not displayed initially when show markers are toggled off, then on.
    if (!showAssetsChecked || !assetsSearchSelected) {
      setAssetMarkerInfoChecked(false);
    }
    if (!showUsersChecked || !usersSearchSelected) {
      setUserMarkerInfoChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsersChecked, showAssetsChecked, assetsSearchSelected, usersSearchSelected]);

  return (
    <StyledDiv data-test-id="display-checkbox">
      <span>Display:</span>
      {showAssetsChecked && assetsSearchSelected && (
        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.ASSET_TRACKING}
        >
          <Checkbox
            data-test-id="show-asset-name-checkbox"
            onChange={handleAssetMarkerInfoCheckboxToggle}
          >
            Asset Name & Asset Type
          </Checkbox>
        </ComponentFeaturePermissionRenderWrapper>
      )}
      {showUsersChecked && usersSearchSelected && (
        <ComponentFeaturePermissionRenderWrapper
          featurePermissions={PERMISSIONS.FEATURE.USER_TRACKING}
        >
          <Checkbox
            data-test-id="show-username-checkbox"
            onChange={handleUsersMarkerInfoCheckboxToggle}
          >
            Name
          </Checkbox>
        </ComponentFeaturePermissionRenderWrapper>
      )}
    </StyledDiv>
  );
}
