import { AuthenticationContext } from 'contexts/authentication.context';
import { getIdToken } from 'utils/utils';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadSuccessMessage from '../../../views/AssetManagementPage/components/UploadSuccessMessage';
import { UploadFailedStatusMessageType } from '../type';
import AssetsApi from 'api/assets/assets.api';
import BeaconsApi from 'api/beacons/beacons.api';

type useBulkUploadFileHandlersType = {
  isAssetUpload: boolean;
  setUploadModalOpen: (arg: boolean) => void;
  setProccesingErrorModalOpen: (arg: boolean) => void;
  setProcessingErrors: (arg: UploadFailedStatusMessageType[]) => void;
};

// A custom hook to contain all handlers used by bulk upload forms.
// This hook represents the logic for both asset upload and beacon upload.
// note All the logic is the same except for the API class used, and the navigation after completion.
export default function useBulkUploadFileHandlers({
  isAssetUpload,
  setUploadModalOpen,
  setProccesingErrorModalOpen,
  setProcessingErrors,
}: useBulkUploadFileHandlersType) {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const { authState } = useContext(AuthenticationContext);
  const idToken = getIdToken(authState);
  const derivedAPI = isAssetUpload ? new AssetsApi(idToken) : new BeaconsApi(idToken);
  const navigate = useNavigate();

  function handleConfirmUpload(selectedFile: any) {
    setUploadInProgress(true);

    derivedAPI
      .postBulkUpload(selectedFile)
      .then((res) => {
        if (res && res.status === 204) {
          setUploadInProgress(false);
          setUploadModalOpen(false);
          alertSuccessMessage(
            <UploadSuccessMessage fileName={selectedFile.name} isAssetUpload={isAssetUpload} />,
          );

          navigate(
            isAssetUpload
              ? '/assets'
              : { pathname: '/beacon-management', search: '?tab=registered' },
          );
        }
      })
      .catch((err) => {
        setUploadInProgress(false);
        setUploadFailed(true);
        if (typeof err === 'object' && err.detail) {
          // construct error array to correct format, if it is not provided by API.
          const errArray = typeof err.detail === 'string' ? [{ message: err.detail }] : err.detail;
          setProccesingErrorModalOpen(true);
          setProcessingErrors(errArray);
          setUploadModalOpen(false);
        } else {
          console.log(err);
          alertErrorMessage('Error uploading file');
        }
      });
  }

  function handleCancel() {
    setUploadModalOpen(false);
    alertSuccessMessage(
      uploadFailed
        ? `The process has been cancelled and no ${isAssetUpload ? 'assets' : 'beacons'} uploaded`
        : `Your file selection has been cancelled`,
    );
  }

  return {
    handleConfirmUpload,
    uploadInProgress,
    uploadFailed,
    handleCancel,
  };
}
