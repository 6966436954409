import React, { useEffect } from 'react';
import AssetTableContainer from './components/AssetTableContainer';
import HeaderAssetSearch from 'components/Header/HeaderAssetSearch';
import AssetListPageProvider from 'contexts/assetListPage.context';
import { ScrolledMain } from 'components/ScrolledMain';

const AssetListPage: React.FC = () => {
  useEffect(() => {
    // remove asset details localstorage key on page load to prevent contamination of asset details
    localStorage.removeItem('assetDetails');
  }, []);

  return (
    <AssetListPageProvider>
      <HeaderAssetSearch />
      <ScrolledMain>
        <div className="container">
          <AssetTableContainer />
        </div>
      </ScrolledMain>
    </AssetListPageProvider>
  );
};
export default AssetListPage;
