import React, { useEffect } from 'react';
import useAuth from 'hooks/useAuth.hook';

export default function MobileSignoutPage() {
  const { amplify } = useAuth();
  useEffect(() => {
    amplify.signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="u-flex-center">
      <div className="container"></div>
    </main>
  );
}
