import React from 'react';
import closeImg from 'assets/icons/close.svg';
import ErrorIcon from 'assets/icons/error.svg';
import { message } from 'antd';
import { StyledAlert } from './StyledAlert';

export default function ErrorAlert({ messageString }: { messageString: string }) {
  function handleClose() {
    message.destroy();
  }

  return (
    <StyledAlert>
      <img src={ErrorIcon} alt="error" style={{ width: '20px', height: '20px' }} />

      <span>{messageString}</span>
      <button onClick={handleClose}>
        <img src={closeImg} alt="close" />
      </button>
    </StyledAlert>
  );
}
