import React, { useContext, useState } from 'react';
import { getIdToken, getValueSafely } from 'utils/utils';
import { StyledTable } from '../styles';
import useDevice from 'hooks/useDevice';
import { EstimateType } from 'types/Estimate';
import { format } from 'date-fns';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import styled from 'styled-components';
import { ButtonStyledAsLink } from 'components/Button/styled';
import TabController from './tabs/TabController';
import AssetStatusUpdateModalController from 'components/Modals/AssetStatusUpdateModal/AssetStatusUpdateModalController';
import { alertErrorMessage } from 'utils/alerts';
import { AssetStatesContext } from 'contexts/assetStates.context';
import useMustUpdate from 'hooks/mustUpdate.hook';
import AssetsApi from 'api/assets/assets.api';
import { AuthenticationContext } from 'contexts/authentication.context';
import AssetStatusCell from 'components/DataTable/cells/AssetStatusCell';
import { FullConfigContext } from 'contexts/fullConfig.context';

const StyledAssetStatusCell = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AssetListFlyoutContent = ({
  selectedAssetDetails,
}: {
  selectedAssetDetails: EstimateType;
}) => {
  const [currentAsset, setCurrentAsset] = useState<any>(selectedAssetDetails);
  const [updateAssetStatusModalOpen, setUpdateAssetStatusModalOpen] = useState(false);
  const { isMobileDevice } = useDevice();
  const { assetStatusApisHaveSucceeded } = useContext(AssetStatesContext);
  const { authState } = useContext(AuthenticationContext);
  const { availableFeaturesObject } = useContext(FullConfigContext);
  const { setMustUpdate } = useMustUpdate();
  const addedOnDate = currentAsset.creation_timestamp
    ? format(new Date(currentAsset.creation_timestamp), 'dd/LL/yyyy - HH:mm')
    : '––';

  function handleUpdateAssetStatusClick() {
    // alert user and dont open modal if required apis have failed
    if (!assetStatusApisHaveSucceeded || !availableFeaturesObject.asset_status) {
      alertErrorMessage("Asset Status can't be updated right now, try again in a few moments");
      return;
    }
    setUpdateAssetStatusModalOpen(true);
  }

  function handleCloseModal() {
    setUpdateAssetStatusModalOpen(false);
  }

  function handleSuccessfulUpdate() {
    const idToken = getIdToken(authState);
    const assetsApi = new AssetsApi(idToken);

    // trigger update to call API for table data.
    setMustUpdate(true);
    // and also call api for latest asset status to update flyout value
    assetsApi
      .getLocalizedAssetById(currentAsset.asset_id)
      .then((res) => {
        const returnedAsset: EstimateType = res.items[0];
        setCurrentAsset(returnedAsset);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  return (
    <>
      {!isMobileDevice && (
        <>
          <StyledTable data-test-id="asset-details-name">
            <tbody>
              <tr>
                <td>Asset Name:</td>
                <td>{getValueSafely(currentAsset.asset_name)}</td>
              </tr>
              <tr>
                <td>Asset Type:</td>
                <td>{getValueSafely(currentAsset.asset_type.name)}</td>
              </tr>
              <tr>
                <td>Asset Status:</td>
                <td>
                  <StyledAssetStatusCell>
                    <span>
                      <AssetStatusCell
                        value={currentAsset.status_assignment?.asset_status?.description}
                      />
                    </span>
                    <ButtonStyledAsLink onClick={() => handleUpdateAssetStatusClick()}>
                      Update Asset Status
                    </ButtonStyledAsLink>
                  </StyledAssetStatusCell>
                </td>
              </tr>
            </tbody>
          </StyledTable>
          <div className="hr" />
        </>
      )}

      <StyledTable data-test-id="asset-details-id" style={{ marginBottom: '20px' }}>
        <tbody>
          {isMobileDevice && (
            <tr>
              <td>Asset Status:</td>
              <td>
                <StyledAssetStatusCell>
                  <span>
                    <AssetStatusCell
                      value={currentAsset.status_assignment?.asset_status?.description}
                    />
                  </span>

                  <ButtonStyledAsLink onClick={() => handleUpdateAssetStatusClick()}>
                    Update Asset Status
                  </ButtonStyledAsLink>
                </StyledAssetStatusCell>
              </td>
            </tr>
          )}
          <tr>
            <td>Asset ID:</td>
            <td>{getValueSafely(currentAsset.asset_id)}</td>
          </tr>
          <tr>
            <td>Added On:</td>
            <td>{addedOnDate}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>
              <DefaultCell value={currentAsset.owner.name} />
            </td>
          </tr>
          <tr>
            <td valign="top">Description:</td>
            <td id="assetDetailsDescription">{getValueSafely(currentAsset.description)}</td>
          </tr>
        </tbody>
      </StyledTable>
      <TabController
        updateAssetStatusModalOpen={updateAssetStatusModalOpen}
        selectedAssetDetails={currentAsset}
      />
      {updateAssetStatusModalOpen && (
        <AssetStatusUpdateModalController
          selectedAssetDetails={currentAsset}
          closeHandler={handleCloseModal}
          callback={() => handleSuccessfulUpdate()}
        />
      )}
    </>
  );
};
export default AssetListFlyoutContent;
