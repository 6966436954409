import React from 'react';
import styled from 'styled-components';
import { coloursNames } from 'styling/colours';

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  max-width: 30px;
  margin: 0 auto;
  .pulse-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .pulse-bubble {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${coloursNames.cobaltBlue};
  }
  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0.25;
      transform: scale(0.75);
    }
  }

  .pulse-bubble-1 {
    animation: pulse 0.4s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
    animation: pulse 0.4s ease 0.2s infinite alternate;
  }
  .pulse-bubble-3 {
    animation: pulse 0.4s ease 0.4s infinite alternate;
  }
`;
export default function LoadingDots() {
  return (
    <StyledDiv>
      <div className="pulse-container">
        <div className="pulse-bubble pulse-bubble-1"></div>
        <div className="pulse-bubble pulse-bubble-2"></div>
        <div className="pulse-bubble pulse-bubble-3"></div>
      </div>
    </StyledDiv>
  );
}
