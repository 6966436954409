import { TimePicker } from 'antd';
import { DatePickInputComponent } from 'components/Dates/DatePickInputComponent';
import { addHours, addMonths, format, isPast } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { disabledRangeTime, getDateFromHHMMTimeStr } from './utils';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;
  .ant-picker-input {
    margin-right: 10px;
  }
  .ant-picker {
    &:last-of-type {
      margin-left: 10px;
    }
  }
  .time-picker {
    margin-top: 26px;
    margin-right: 10px;
  }
  input {
    margin-right: 0 !important;
  }
`;

export default function CustomDateTimePickerComponent({
  setCustomDateUTCString,
}: {
  setCustomDateUTCString: (arg: string) => void;
}) {
  const [HHMMStr, setHHMMStr] = useState(format(addHours(new Date(), 1), 'HH:mm')); // set default to time now, plus 1 hour.

  const { control, watch, register, setValue } = useForm<any>({
    defaultValues: {
      selectedDate: getDateFromHHMMTimeStr(HHMMStr, new Date()),
    },
  });

  const selectedDate = watch('selectedDate');

  function handleTimeChange(selectedDateTime: any, HHMMStr: string) {
    // function to handle values of date and time selectors.
    const dateObj = new Date(getDateFromHHMMTimeStr(HHMMStr, selectedDateTime));
    const dateIsPast = isPast(dateObj);
    const dateObjPlusHour = addHours(dateObj, 1);
    // if user has selected a date in the past, we auto revert the value to at least the current time plus 1 hour.
    const derivedDate = dateIsPast ? dateObjPlusHour : dateObj;

    setHHMMStr(dateIsPast ? format(addHours(new Date(), 1), 'HH:mm') : HHMMStr);
    setCustomDateUTCString(derivedDate.toISOString());
  }

  useEffect(() => {
    const dateObj = new Date(selectedDate);
    setCustomDateUTCString(dateObj.toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledDiv>
      <Controller
        name="startDate"
        control={control}
        rules={{ required: true }}
        render={({ field }: any) => {
          return (
            <DatePickInputComponent
              {...field}
              {...register('selectedDate')}
              popupStyle={{ maxWidth: '300px', zIndex: '999999' }}
              allowClear={false}
              required
              value={selectedDate}
              defaultValue={selectedDate}
              name="selectedDate"
              label="Clear status at:"
              format={'DD/MM/YYYY'}
              onChange={(date: any) => {
                const derivedDate = getDateFromHHMMTimeStr(HHMMStr, date);
                setValue('selectedDate', derivedDate);
                handleTimeChange(date, HHMMStr);
              }}
              minDate={new Date()}
              maxDate={addMonths(new Date(), 2)}
            />
          );
        }}
      />
      <TimePicker
        key={selectedDate}
        required
        className="time-picker"
        showNow={false}
        hideDisabledOptions
        disabledTime={(date) => disabledRangeTime(date)}
        format={'HH:mm'}
        defaultValue={dayjs(getDateFromHHMMTimeStr(HHMMStr, selectedDate))} // set to date picker date.
        placeholder="Hr Min"
        minDate={dayjs(new Date())}
        onChange={(date, HHMMStr: any) => handleTimeChange(date, HHMMStr)}
        allowClear={false}
      />
    </StyledDiv>
  );
}
