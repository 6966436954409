import React, { createContext, ReactNode, useState } from 'react';
import { UserType } from 'types/userInfo';

type RecentUsersProviderProps = { children: ReactNode };
interface RecentUsersContextI {
  totalCount: number | undefined;
  setTotalCount: (arg: number) => void;
  usersList: UserType[];
  setUsersList: (lst: UserType[]) => void;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  searchCategory: string;
  setSearchCategory: (arg: string) => void;
  selectedSearchMenuOptionLabel: string;
  setSelectedSearchMenuOptionLabel: (arg: string) => void;
  sortColumnField: string | undefined;
  setSortColumnField: (arg: string | undefined) => void;
  sortDirection: string;
  setSortDirection: (arg: string) => void;
  isSorting: boolean;
  setIsSorting: (arg: boolean) => void;
  isSearching: boolean;
  setIsSearching: (arg: boolean) => void;
  isLoading: boolean;
  setIsLoading: (arg: boolean) => void;
  networkError: Error | undefined;
  setNetworkError: (arg: Error) => void;
  userSearchOptions: {
    value: string;
    label: string;
  }[];
  limit: number;
}
export const RecentUsersContext = createContext({} as RecentUsersContextI);

const RecentUsersProvider = ({ children }: RecentUsersProviderProps) => {
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [usersList, setUsersList] = useState<UserType[]>([]);
  const [isSorting, setIsSorting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sortColumnField, setSortColumnField] = useState<string | undefined>('-last_update'); // default sort param.
  const [sortDirection, setSortDirection] = useState('asc'); // default sort order.
  const [searchTerm, setSearchTerm] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [selectedSearchMenuOptionLabel, setSelectedSearchMenuOptionLabel] = useState('');
  const [networkError, setNetworkError] = useState<Error | undefined>();

  const userSearchOptions = [
    { value: 'name_filter', label: 'Name' },
    { value: 'location_name_filter', label: 'Location' },
    { value: 'is_outdoors', label: 'Location' },
  ];

  const limit = 200;

  return (
    <RecentUsersContext.Provider
      value={{
        limit,
        userSearchOptions,
        totalCount,
        setTotalCount,
        usersList,
        setUsersList,
        searchTerm,
        setSearchTerm,
        searchCategory,
        setSearchCategory,
        selectedSearchMenuOptionLabel,
        setSelectedSearchMenuOptionLabel,
        sortColumnField,
        setSortColumnField,
        sortDirection,
        setSortDirection,
        isSorting,
        setIsSorting,
        isSearching,
        setIsSearching,
        isLoading,
        setIsLoading,
        networkError,
        setNetworkError,
      }}
    >
      {children}
    </RecentUsersContext.Provider>
  );
};

export default RecentUsersProvider;
