export type EstimateType = {
  asset_id: string;
  asset_name: string;
  asset_type: {
    id: string;
    name: string;
    is_enabled: boolean;
  };
  owner: {
    id: string;
    name: string;
    is_enabled: boolean;
  };
  description: string;
  status_assignment: null | {
    set_at_timestamp: string;
    clear_at_timestamp: string | null;
    asset_status: null | {
      description: string;
      asset_status_id: string;
    };
    set_by: null | {
      name: string;
    };
  };
  location: {
    venue: {
      id: string;
      name: string;
    };
    region: {
      id: string;
      name: string;
    };
    coordinates: {
      latitude: number;
      longitude: number;
    };
    uncertainty: number;
    is_outdoors: boolean;
    building_level: {
      possible_buildings: [
        {
          id: string;
          name: string;
        },
      ];
      level: {
        id: string;
        name: string;
        short_name: string;
      };
    };
  };
  assigned_beacon: null | {
    id: string;
    beacon_type: string;
    battery: number;
    import_info: {
      import_timestamp: string;
      source: null | string;
      order_id: string;
    };
  };
  mobility_state: string;
  last_update_timestamp: string;
  last_location_update_timestamp: string;
  creation_timestamp: string;
};

export function isEstimateType(p: object) {
  return (p as EstimateType).asset_id !== undefined;
}
