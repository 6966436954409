import React from 'react';
import HeaderDefault from 'components/Header/HeaderDefault';
import FormController from './controllers/FormController';
import FormHeader from './components/FormHeader';
import AssetUploadFormController from 'components/FileUpload/controllers/AssetUploadFormController';
import { StyledUploadFormContainer } from 'components/FileUpload/Form/Styled';
import useDevice from 'hooks/useDevice';
import WidthNotSupportedPage from 'views/WidthNotSupportedPage/WidthNotSupportedPage';

export default function AssetManagementPage() {
  const { isMobileDevice } = useDevice();

  if (isMobileDevice) {
    return <WidthNotSupportedPage />;
  }

  return (
    <>
      <HeaderDefault />
      <main>
        <div className="container">
          <FormHeader text="UPLOAD ASSETS" />
          <StyledUploadFormContainer>
            <AssetUploadFormController />
          </StyledUploadFormContainer>
          <FormController />
        </div>
      </main>
    </>
  );
}
