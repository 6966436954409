import React, { useContext } from 'react';
import SelectInput from '../SelectInput';
import { getReactSelectObjectFromValueKey, getSortedArrayByValue } from 'utils/utils';
import StyledSelectContainer from './StyledSelectContainer';
import { AssetConfigContext } from 'contexts/assetConfig.context';

type AssetSubTypeProps = {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  className?: string;
};
interface IAssetSubType {
  value?: { value: string; label: string };
  onInputChange?: () => void;
  className?: string;
}

const AssetSubTypes: React.FunctionComponent<IAssetSubType> = (props: AssetSubTypeProps) => {
  const { assetSubtype } = useContext(AssetConfigContext);
  const alphaSortedOptions = getSortedArrayByValue(assetSubtype, 'value');
  const valueObjToDisplay = getReactSelectObjectFromValueKey(assetSubtype, props.value?.value);

  return (
    <StyledSelectContainer data-test-id="asset-type-options">
      <SelectInput
        {...props}
        isSearchable={false}
        id="assetSubType"
        name="assetSubType"
        aria-label="Asset Type"
        placeholder="Enter the Keyword"
        options={alphaSortedOptions}
        value={valueObjToDisplay}
      />
    </StyledSelectContainer>
  );
};

export default AssetSubTypes;
