import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { StyledAsyncSelect, StyledInputContainer } from 'components/AsyncSelect/Styled';
import ClearInputButton from 'components/Button/ClearInputButton';
import OptionMenu from 'components/AsyncSelect/OptionMenu';
import { clearWindowURLParams } from 'utils/utils';

type menuOptionsType = { value: string; label: string; HTMLLabel?: ReactElement };

type AsyncSelectOptionsTypes = {
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  handleEnterSearchFunc: (arg: string) => void;
  handleClickOptionSearchFunc: (searchTerm: string, searchCategory: string) => void;
  isSearching: boolean;
  setIsSearching: (arg: boolean) => void;
  setSearchCleared: (arg: boolean) => void;
  setNextToken?: (arg: string) => void;
  menuOptions: menuOptionsType[];
  selectedSearchMenuOptionLabel: string;
  setSelectedSearchMenuOptionLabel: (arg: string) => void;
  setSearchCategory: (arg: string) => void;
  placeholder: string;
  width?: number;
  setPaginationIndex?: (arg: number) => void;
};
export const outdoorLocationString = 'Outside Mapped Buildings';

function checkSearchTermCharactersMatchString(searchTerm: string) {
  return outdoorLocationString.toLowerCase().includes(searchTerm.toLowerCase());
}

export default function AssetSearchAsyncSelect({
  searchTerm,
  setSearchTerm,
  setSearchCleared,
  setNextToken,
  handleEnterSearchFunc,
  handleClickOptionSearchFunc,
  isSearching,
  setIsSearching,
  menuOptions,
  selectedSearchMenuOptionLabel,
  setSelectedSearchMenuOptionLabel,
  setSearchCategory,
  placeholder,
  width,
  setPaginationIndex,
}: AsyncSelectOptionsTypes) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isLoading, searchIsLoading] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const selectedSearchMenuOptionLabelRef = useRef('');
  const hasSearchTerm = searchTerm.length > 0;
  const showPlaceholder = selectedSearchMenuOptionLabel !== '' && !inputHasFocus;

  function handleInputChange(term: string, actionObj: any) {
    switch (actionObj.action) {
      case 'input-change':
        setSearchTerm(term);
        setIsSearching(false); // allows us to revert to cached results ref in parent useeffect.
        setMenuIsOpen(true);
        setInputHasFocus(true);

        break;
      case 'menu-close':
        // close dropdown when menu is closed.
        setMenuIsOpen(false);
        setInputHasFocus(false);

        break;
      case 'input-blur':
        // close dropdown when input blurred.
        setMenuIsOpen(false);
        setInputHasFocus(false);

        if (hasSearchTerm) {
          // revert to cached label so we can populate it in search box.
          setSelectedSearchMenuOptionLabel(selectedSearchMenuOptionLabelRef.current);
        }
        break;
      default:
        break;
    }
  }

  function handleClickOptionSearch(selectedOptionObj: { value: string; label: string }) {
    if (searchTerm.length < 1) return;
    setIsSearching(true);
    searchIsLoading(false);
    setMenuIsOpen(false);
    setInputHasFocus(false);

    handleClickOptionSearchFunc(searchTerm, selectedOptionObj.value);
    setSelectedSearchMenuOptionLabel(selectedOptionObj.label);
    selectedSearchMenuOptionLabelRef.current = selectedOptionObj.label; // store ref
    setSearchCategory(selectedOptionObj.value);
  }

  function handleEnterSearch(ev: React.KeyboardEvent) {
    if (ev.key !== 'Enter') return; // only call search on enter key.
    const target: any = ev.target;
    const searchTerm = target.value.toString();

    if (searchTerm.length < 1) return;
    setIsSearching(true);
    setSearchCategory('');
    handleEnterSearchFunc(searchTerm);
    searchIsLoading(false);
    setMenuIsOpen(false);
    setSelectedSearchMenuOptionLabel('');
    selectedSearchMenuOptionLabelRef.current = '';
  }

  function handleClearField() {
    // reset params and toggle clearfield boolean to trigger useeffect in assetlistpage.
    setSearchTerm('');
    setNextToken && setNextToken('');
    setSelectedSearchMenuOptionLabel('');
    selectedSearchMenuOptionLabelRef.current = '';
    setIsSearching(false);
    setSearchCleared(true);
    setPaginationIndex && setPaginationIndex(0);
    clearWindowURLParams();
  }

  function handleInputClick(ev: React.SyntheticEvent) {
    const target = ev.target as HTMLTextAreaElement;
    const targetClassName = target.classList[0];
    // prevent anything occuring if input was not clicked.
    if (targetClassName !== 'asyncSearchPrefix__input') return;
    setMenuIsOpen(hasSearchTerm);
    setInputHasFocus(true);
  }

  useEffect(() => {
    if (hasSearchTerm) {
      // clear search if input loses focus only BEFORE search takes place.
      if (!inputHasFocus && !isSearching) {
        setSearchTerm('');
        setSelectedSearchMenuOptionLabel('');
        selectedSearchMenuOptionLabelRef.current = '';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHasFocus, isSearching]);

  useEffect(() => {
    //clear and close menu if search term is less than 1
    if (searchTerm.length < 1) {
      setMenuIsOpen(false);
      setInputHasFocus(false);
      setSelectedSearchMenuOptionLabel('');
      selectedSearchMenuOptionLabelRef.current = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <StyledInputContainer
      role="button"
      className="input-container"
      onClick={(ev: React.SyntheticEvent) => handleInputClick(ev)}
      width={width}
    >
      {/* onClick on parent element to open menu, as React-Select does not expose an onclick event. A slightly hacky solution. a11y disabled because we do not want the parent div to be focusable.*/}
      <StyledAsyncSelect
        selectedSearchMenuOptionLabel={selectedSearchMenuOptionLabel}
        isLoading={isLoading}
        placeholder={placeholder}
        onInputChange={(newValue: string, action: any) => handleInputChange(newValue, action)}
        className={showPlaceholder ? 'asyncSearch --has-search' : 'asyncSearch'}
        classNamePrefix="asyncSearchPrefix"
        menuIsOpen={menuIsOpen}
        onKeyDown={(ev: any) => handleEnterSearch(ev)}
        inputValue={searchTerm}
        escapeClearsValue
        components={{
          Menu: (props: any) => {
            let mutatedMenuOptions = menuOptions;
            // check searchterm string contains letters from outdoor search string.
            if (!checkSearchTermCharactersMatchString(searchTerm)) {
              // if it does not, remove isOutdoors entry from array.
              mutatedMenuOptions = menuOptions.filter((item) => item.value !== 'is_outdoors');
            }
            return (
              <OptionMenu
                searchTerm={searchTerm}
                optionText="includes"
                title="REFINE MY SEARCH"
                menuOptions={mutatedMenuOptions}
                handleClickOptionSearch={handleClickOptionSearch}
                {...props}
              />
            );
          },
        }}
      />
      {hasSearchTerm && <ClearInputButton onClick={handleClearField} />}
    </StyledInputContainer>
  );
}
