import React, { ReactNode } from 'react';
import ErrorPageView from 'views/ErrorPage/ErrorPage';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Object, errorInfo: Object) {
    // eslint-disable-next-line no-console
    console.warn(`[Error]: ${error}, ${errorInfo}`);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPageView />;
    }

    // eslint-disable-next-line react/destructuring-assignment
    return <>{this.props.children}</>;
  }
}
export default ErrorBoundary;
