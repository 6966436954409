import React from 'react';
import styled from 'styled-components';
import { colours } from 'styling/colours';

type ButtonProps = {
  title?: string;
  icon: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disabledAction?: boolean;
  loading?: boolean;
  rounded?: boolean;
  secondary?: boolean;
  style?: React.CSSProperties;
  type?: 'reset' | 'submit' | undefined;
  id?: string;
  isLoading?: boolean;
  tabIndex?: number;
  ref?: any;
};

const StyledButton = styled.button<ButtonProps>`
  background: transparent;
  border: 0;
  padding: 0 12px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    & svg {
      & path {
        fill: ${colours.tertiaryText};
      }
    }
  }
`;

const IconButton = React.forwardRef((props: ButtonProps, ref: any) => (
  <StyledButton {...props} ref={ref} disabled={props.disabled || props.isLoading}>
    {props.icon}
  </StyledButton>
));

IconButton.displayName = 'IconButton';
export default IconButton;
