import React, { useState, useContext } from 'react';
import AssetListFlyoutContent from './content/AssetListFlyoutContent';
import Button from 'components/Button/Button';
import { StyledAssetListFlyout, StyledButtonsContainer } from './styles';
import { getIdToken, getValueSafely } from 'utils/utils';
import ArchiveAssetModal from './ArchiveAssetModal';
import { AuthenticationContext } from 'contexts/authentication.context';
import { useNavigate } from 'react-router';
import { alertErrorMessage, alertSuccessMessage } from 'utils/alerts';
import useMustUpdate from 'hooks/mustUpdate.hook';
import { PERMISSIONS } from 'config/permissions';
import ComponentUserPermissionRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentUserPermissionRenderWrapper';
import useDevice from 'hooks/useDevice';
import styled from 'styled-components';
import { colours } from 'styling/colours';
import { EstimateType } from 'types/Estimate';
import AssetsApi from 'api/assets/assets.api';
import AssetListFlyoutDetails from './content/_AssetListFlyoutDetails';
import ComponentFeatureIsAvailableRenderWrapper from 'wrappers/ComponentConditionWrappers/ComponentFeatureIsAvailableRenderWrapper';
import { FullConfigContext } from 'contexts/fullConfig.context';

const AssetListFlyout = ({
  selectedAssetDetails,
  setFlyoutOpen,
  flyoutOpen,
}: {
  selectedAssetDetails: EstimateType;
  setFlyoutOpen: (arg: boolean) => void;
  flyoutOpen: boolean;
}) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobileDevice } = useDevice();

  const { availableFeaturesObject } = useContext(FullConfigContext);
  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const navigate = useNavigate();
  const assetsAPI = new AssetsApi(token);

  const { setMustUpdate } = useMustUpdate();

  const handleArchive = (assetID: string) => {
    setApiCallInProgress(true);

    assetsAPI
      .deleteAsset(assetID)
      .then(() => {
        setApiCallInProgress(false);
        setFlyoutOpen(false);
        setModalOpen(false);
        alertSuccessMessage(
          `${selectedAssetDetails.asset_name} - ${selectedAssetDetails.asset_type.name} has been archived`,
        );
        setMustUpdate(true);
      })

      .catch((err) => {
        setApiCallInProgress(false);

        console.log(err);
        alertErrorMessage(
          `An error occurred ${selectedAssetDetails.asset_name} has not been archived`,
        );
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleEditAsset = () => {
    // set asset detail in local storage, to be read from edit asset page.
    localStorage.setItem('assetDetails', JSON.stringify(selectedAssetDetails));

    navigate('/edit-asset');
  };

  return (
    <>
      <StyledAssetListFlyout
        isOpen={flyoutOpen}
        onRequestClose={() => {
          setFlyoutOpen(false);
        }}
        title={isMobileDevice ? getValueSafely(selectedAssetDetails.asset_name) : 'Asset Details'}
        subtitle={isMobileDevice && selectedAssetDetails.asset_type.name}
      >
        <ComponentFeatureIsAvailableRenderWrapper
          availableFeaturePermission={availableFeaturesObject.asset_status}
          fallbackComponent={<AssetListFlyoutDetails selectedAssetDetails={selectedAssetDetails} />}
        >
          <AssetListFlyoutContent selectedAssetDetails={selectedAssetDetails} />
        </ComponentFeatureIsAvailableRenderWrapper>
        {isMobileDevice && (
          <NotMobileSupportStyle>
            Please use a larger screen to edit or archive assets.
          </NotMobileSupportStyle>
        )}
        {!isMobileDevice && (
          <ComponentUserPermissionRenderWrapper userPermissions={[PERMISSIONS.USER.WRITE]}>
            <StyledButtonsContainer>
              <Button className="biggerButton" outline onClick={openModal} id="archiveAssetButton">
                Archive
              </Button>
              <Button className="biggerButton" onClick={handleEditAsset} id="editAssetButton">
                Edit
              </Button>
            </StyledButtonsContainer>
          </ComponentUserPermissionRenderWrapper>
        )}
      </StyledAssetListFlyout>
      {modalOpen && (
        <ArchiveAssetModal
          closeModal={closeModal}
          apiCallInProgress={apiCallInProgress}
          actionFunction={() => handleArchive(selectedAssetDetails.asset_id)}
          selectedAssetDetails={selectedAssetDetails}
        />
      )}
    </>
  );
};
export default AssetListFlyout;

const NotMobileSupportStyle = styled.div`
  width: 100%;
  text-align: center;
  color: ${colours.secondaryText};
`;
