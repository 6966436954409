import React from 'react';
import { Tooltip } from 'react-leaflet';
import { StyledSmallTooltipInner } from '../../Styled';
import { getValueSafely } from 'utils/utils';
import { EstimateType } from 'types/Estimate';
import DefaultCell from 'components/DataTable/cells/DefaultCell';

export default function SmallInfoBoxTooltip({ asset }: { asset: EstimateType }) {
  return (
    <Tooltip className="tooltip-small" opacity={1} direction="left" permanent offset={[-18, 0]}>
      <StyledSmallTooltipInner>
        <span>
          <DefaultCell value={asset.asset_type.name} />
        </span>
        <span>{getValueSafely(asset.asset_name)}</span>
      </StyledSmallTooltipInner>
    </Tooltip>
  );
}
