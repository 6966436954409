export enum FEATURE {
  ASSET_TRACKING = 'asset_tracking',
  USER_TRACKING = 'user_tracking',
}

export enum USER {
  READ = 'ILS:READ',
  WRITE = 'ILS:WRITE',
  ADMIN = 'ILS:ADMIN',
}

export const PERMISSIONS = {
  USER,
  FEATURE,
};
