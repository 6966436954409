import React from 'react';
import { StyledMobileAssetInfoFlyout } from 'views/MapPage/Styled';
import MobileAssetInfoFlyoutContent from './MobileAssetInfoFlyoutContent';
import { getValueSafely } from 'utils/utils';

import { EstimateType } from 'types/Estimate';

export default function MobileAssetInfoFlyout({
  selectedAssetDetails,
  handleCloseInfoBox,
}: {
  selectedAssetDetails: EstimateType;
  handleCloseInfoBox: () => void;
}) {
  return (
    <StyledMobileAssetInfoFlyout
      title={getValueSafely(selectedAssetDetails.asset_name)}
      isOpen
      onRequestClose={() => {
        handleCloseInfoBox();
      }}
      subtitle={selectedAssetDetails.asset_type.name}
    >
      <MobileAssetInfoFlyoutContent selectedAssetDetails={selectedAssetDetails} />
    </StyledMobileAssetInfoFlyout>
  );
}
