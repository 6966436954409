import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// hook to contain state and logic to handle nav behaviour
export default function useNavHooks({
  userHasCollapsed,
  navCollapsedDefault,
  navIsCollapsed,
  setNavIsCollapsed,
  setUserHasCollapsed,
}: {
  userHasCollapsed: boolean;
  navCollapsedDefault: boolean;
  navIsCollapsed: boolean;
  setNavIsCollapsed: (arg: boolean) => void;
  setUserHasCollapsed: (arg: boolean) => void;
}) {
  const bodyEl = document.querySelector('body');

  const location = useLocation();
  const currentHref = location.pathname;
  const [activeHref, setActiveHref] = useState(currentHref);

  function toggleNavOpen() {
    bodyEl?.classList.toggle('--nav-collapsed');

    setNavIsCollapsed(!navIsCollapsed);
    setUserHasCollapsed(!navIsCollapsed);
  }

  useEffect(() => {
    // handle nav collapse when changing href.
    // ensure correct class is on body, when component loading, and sync internal collapse state to default.
    if (userHasCollapsed) {
      // ensure user action takes priority over default.
      setNavIsCollapsed(true);
      bodyEl?.classList.add('--nav-collapsed');
    } else {
      // otherwise follow route default.
      setNavIsCollapsed(navCollapsedDefault);
      if (!navCollapsedDefault) {
        bodyEl?.classList.remove('--nav-collapsed');
      } else {
        bodyEl?.classList.add('--nav-collapsed');
      }
    }

    // This is needed in case the user types in the address bar
    setActiveHref(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    userHasCollapsed,
    setUserHasCollapsed,
    setNavIsCollapsed,
    setActiveHref,
    navIsCollapsed,
    activeHref,
    toggleNavOpen,
  };
}
