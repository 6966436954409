/*
SystemAdministratorsStore class handles all states, API calls, form management and form validation,
  and logic of listing, adding, editing and deleting system administrators.
*/
import {
  deleteRemoveSystemAdmin,
  getNonSystemAdmins,
  getSystemAdmins,
  getUserAdminPermissions,
  postUpdateSystemAdminPermissions
} from "api/config/systemAdmin";
import { SystemAdminPermission, SystemAdminUserDto } from "views/config/SystemAdministrators/AdminPermissionsDto";
import { FieldState, FormState } from "formstate";
import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "stores/RootStore";
import { KeyValuePair } from "utils/common";
import { required } from "utils/formstate";
import { isDeepEqual } from "utils/utils";

export type SystemAdminFormState = {
  users: FieldState<string[]>;
  sysAdminReadOnly: FieldState<boolean>;
  workgroupsAndRoles: FieldState<boolean>;
  userManagement: FieldState<boolean>;
  systemAdministrators: FieldState<boolean>;
};

export class SystemAdministratorsStore {

  makeForm = (userId?: string, permissions?: SystemAdminPermission[]): FormState<SystemAdminFormState> =>
    new FormState<SystemAdminFormState>({
      users: new FieldState(userId ? [userId] : []).validators(required),
      sysAdminReadOnly: new FieldState<boolean>(true),
      workgroupsAndRoles: new FieldState<boolean>(permissions
        ? permissions.find(r => r === SystemAdminPermission.ManageGroupsAndRoles) !== undefined
        : false),
      userManagement: new FieldState<boolean>(permissions
        ? permissions.find(r => r === SystemAdminPermission.ManageUsers) !== undefined
        : false),
      systemAdministrators: new FieldState<boolean>(permissions
        ? permissions.find(r => r === SystemAdminPermission.ManageSystemAdmins) !== undefined
        : false),
    });

  root: RootStore;
  currentAdmin: SystemAdminUserDto | null = null;
  currentUserPermissions: SystemAdminPermission[] = [];
  editingMode = false;
  loading = false;
  listLoading = true;
  deleteModalShow = false;
  form = this.makeForm();
  nonSystemAdmins: KeyValuePair[] = [];
  systemAdmins: SystemAdminUserDto[] = [];

  constructor(root: RootStore) {
    this.root = root;
    makeObservable(this, {
      editingMode: observable,
      currentAdmin: observable,
      loading: observable,
      listLoading: observable,
      form: observable,
      nonSystemAdmins: observable,
      systemAdmins: observable,
      deleteModalShow: observable,
      currentUserPermissions: observable,

      setCurrentAdmin: action.bound,
      setEditLoading: action.bound,
      saveAdmin: action.bound,
      setEditingMode: action.bound,
      setDeleteModalShow: action.bound,
      deleteAdmin: action.bound,
      loadAdmins: action.bound,
      loadNonAdmins: action.bound,
      reset: action.bound, 

      isAnyChange: computed,

    });
  }

  setDeleteModalShow(show: boolean) { this.deleteModalShow = show }
  setEditLoading(isLoading: boolean) { this.loading = isLoading }
  setCurrentAdmin(user: SystemAdminUserDto | null) { this.currentAdmin = user }

  reset() {
    this.currentAdmin = null;
    this.currentUserPermissions = [];
    this.editingMode = false;
    this.loading = false;
    this.listLoading = true;
    this.deleteModalShow = false;
    this.nonSystemAdmins = [];
    this.systemAdmins = [];
  }

  setEditingMode(mode: boolean) {
    this.editingMode = mode;
    if (this.editingMode) {
      if (this.currentAdmin) {
        getUserAdminPermissions(this.currentAdmin.id)
          .then(permissions => {
            this.form = this.makeForm(this.currentAdmin?.id, permissions)
            this.currentUserPermissions = permissions;
          })
      } else {
        this.form = this.makeForm()
      }
      this.form.validate()
    }

  }

  getAdminPermissions() {
    const permissions = [];

    if (this.form.$.sysAdminReadOnly.value) {
      permissions.push(SystemAdminPermission.ViewSystemConfiguration);
    }
    if (this.form.$.systemAdministrators.value) {
      permissions.push(SystemAdminPermission.ManageSystemAdmins);
    }
    if (this.form.$.userManagement.value) {
      permissions.push(SystemAdminPermission.ManageUsers);
    }
    if (this.form.$.workgroupsAndRoles.value) {
      permissions.push(SystemAdminPermission.ManageGroupsAndRoles);
    }

    return permissions;
  }

  loadAdmins() {
    this.listLoading = true
    getSystemAdmins()
      .then(res => {
        this.systemAdmins = res;
      })
      .finally(() => this.listLoading = false)
  }

  loadNonAdmins() {
    getNonSystemAdmins()
      .then((res) => {
        this.nonSystemAdmins = res.map((user) => ({ key: user.name, value: user.id }));
      })
  }

  saveAdmin() {
    this.loading = true;
    return postUpdateSystemAdminPermissions({
      users: this.form.$.users.value,
      permissions: this.getAdminPermissions()
    })
      .then(() => {
        this.loadAdmins();
        this.editingMode = false;
      })
      .finally(() => this.loading = false)
  }

  deleteAdmin() {
    if (!this.currentAdmin)
      return Promise.reject();
    this.loading = true;
    return deleteRemoveSystemAdmin(this.currentAdmin.id)
      .then(() => {
        this.loadAdmins();
      })
      .finally(() => {
        this.loading = false;
        this.deleteModalShow = false;
      })
  }

  get isAnyChange() {
    if (!this.currentAdmin) {
      return true;
    }
    return !isDeepEqual(this.getAdminPermissions(), this.currentUserPermissions)
  }
}
