import React from 'react';
import { StyledKeyLegend } from '../Styled';
import KeyLegendPanelContents from './KeyLegendPanelContents';

export default function DesktopKeyLegendPanel() {
  return (
    <StyledKeyLegend data-test-id="legend">
      <KeyLegendPanelContents />
    </StyledKeyLegend>
  );
}
