import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from 'contexts/authentication.context';
import { alertErrorMessage } from 'utils/alerts';
import { getIdToken, getNextCount } from 'utils/utils';
import DataTableStatusWrapper from 'components/DataTable/DataTableStatusWrapper';
import NoDataComponent from 'components/DataTable/NoDataComponent';
import { StyledDataTableWrapper } from 'components/DataTable/style';
import DataTable, { TableColumn } from 'react-data-table-component';
import DefaultCell from 'components/DataTable/cells/DefaultCell';
import { datatableStyle } from 'components/DataTable/DataTableStyling.config';
import ShowMoreButton from 'components/DataTable/ShowMoreButton';
import { UserLog } from 'types/userLog';
import { icons } from 'assets/icons';
import { colours } from 'styling/colours';
import { Tooltip } from 'antd';
import { UserLogsStyled } from './Styled';
import { StyledButton } from 'components/Header/components/UserInfoDropdown/style';
import UserLogApi from 'api/user/userLog.api';

type UserLogsTableProps = {
  setTotalCount: (arg: number) => void;
  totalCount?: number;
};

export const UserLogsTable = ({ setTotalCount, totalCount }: UserLogsTableProps) => {
  const [userLogsList, setUserLogsList] = useState<UserLog[]>([]);
  const [nextToken, setNextToken] = useState(undefined);
  const [apiCallInProgress, setApiCallInProgress] = useState(true);
  const [fetchingNextPage, setFetchingNextPage] = useState(false);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const nextCount = getNextCount(paginationIndex, totalCount);

  const authContext = useContext(AuthenticationContext).authState;
  const token = getIdToken(authContext);
  const userLogApi = new UserLogApi(token);

  function handlePagination() {
    setFetchingNextPage(true);

    userLogApi
      .getUsersLogs()
      .then((res: any) => {
        const { items, next } = res.data;
        const combinedResults: UserLog[] = [...userLogsList, ...items];

        setUserLogsList(combinedResults);
        setNextToken(next);
        setApiCallInProgress(false);
        setFetchingNextPage(false);
        setPaginationIndex(paginationIndex + 1);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch User Logs');
        setApiCallInProgress(false);
        setFetchingNextPage(false);
        console.error(err);
      });
  }

  const downloadLogFile = (row: any) => {
    console.log(row);
    // TODO: download file
  };

  useEffect(() => {
    setApiCallInProgress(true);

    userLogApi
      .getUsersLogs()
      .then((res) => {
        const { items, next, total } = res.data;

        setUserLogsList(items);
        setTotalCount(total);
        setNextToken(next);
        setApiCallInProgress(false);
      })
      .catch((err) => {
        alertErrorMessage('Could not fetch Users');
        console.error(err.message);
        setApiCallInProgress(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userLogsColumns = [
    {
      name: (
        <>
          <span className="header-title">QUICK ACCESS REPORTS</span>
          <Tooltip
            placement="right"
            title={`These quick access reports are generated fortnightly. 
              Reports are only stored for the appropriate length of time to 
              comply with our data-retention agreements.`}
          >
            <icons.InfoIcon height={14} width={14} fill={colours.primary} />
          </Tooltip>
        </>
      ),
      selector: (row: any) => <DefaultCell value={row?.name} />,
      sortable: false,
      overflow: 'false',
      grow: 1.5,
    },
    {
      name: '',
      selector: (row: any) => (
        <StyledButton onClick={() => downloadLogFile(row)}>
          <icons.DownloadIcon2 fill={colours.secondary} />
        </StyledButton>
      ),
      sortable: false,
      width: '100px',
    },
  ] as unknown as TableColumn<UserLog>[];

  return (
    <UserLogsStyled>
      <DataTableStatusWrapper error={false} status={apiCallInProgress ? 'loading' : ''}>
        <StyledDataTableWrapper className={`table-container --disable-sort}`} data-test-id="table">
          <DataTable
            columns={userLogsColumns}
            data={userLogsList}
            customStyles={datatableStyle}
            noDataComponent={<NoDataComponent text={'No file to display'} />}
            persistTableHead
            defaultSortFieldId="id"
            defaultSortAsc
            sortServer
          />
          {nextToken && nextCount > 0 && (
            <ShowMoreButton
              fetchFunction={handlePagination}
              nextIsLoading={fetchingNextPage}
              nextCount={nextCount}
            />
          )}
        </StyledDataTableWrapper>
      </DataTableStatusWrapper>
    </UserLogsStyled>
  );
};
