import { MOBILEDEVICEWIDTH } from 'config/constants';
import { useEffect, useState } from 'react';

const breakWidth = MOBILEDEVICEWIDTH;
const minimumWidth = 375; // pixel 3a width
const tabletWidth = 1280;
const shortViewportHeight = 899;
export const MIN_SELECT_MENU_HEIGHT = 330;

function isMobileWidth() {
  return window.innerWidth <= breakWidth;
}
function isMinimumSupportedWidth() {
  return window.innerWidth >= minimumWidth;
}
function isTabletWidth() {
  return window.innerWidth <= tabletWidth;
}

function isShortViewportHeight() {
  return window.innerHeight <= shortViewportHeight;
}

type Dimension = { width: number; height: number };

const useDevice = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(isMobileWidth());
  const [isTabletDevice, setIsTabletDevice] = useState(isTabletWidth());
  const [isShortViewportDevice, setIsShortViewportDevice] = useState(isShortViewportHeight());
  const [screenIsMinimumSupportedWidth, setScreenIsMinimumSupportedWidth] =
    useState(isMinimumSupportedWidth());
  const [dimension, setDimension] = useState<Dimension>({ height: 0, width: 0 });

  function handleResize() {
    setIsMobileDevice(isMobileWidth());
    setIsTabletDevice(isTabletWidth());
    setIsShortViewportDevice(isShortViewportHeight());
    setScreenIsMinimumSupportedWidth(isMinimumSupportedWidth());
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { dimension, isMobileDevice, isTabletDevice, screenIsMinimumSupportedWidth, isShortViewportDevice };
};

export default useDevice;
